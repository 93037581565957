import React, { useState, useEffect } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import "./ProfilePhotoUpload.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useAuthContext } from "../../hooks/useAuthContext";
import { initializeApp, getApps } from "firebase/app";
import { getAuth } from "firebase/auth";
import firebase from "../../Firebase";
import Cookies from "js-cookie";

if (!getApps().length) {
  initializeApp(firebase);
}

const DND_ITEM_TYPE = "picture";
// DraggablePicture component allows each picture to be draggable within the grid
const DraggablePicture = ({
  picture,
  index,
  movePicture,
  handleFileChange,
  handleDelete,
}) => {
  const [, drag] = useDrag(
    () => ({
      type: DND_ITEM_TYPE,
      item: { index },
    }),
    [index]
  );

  const [, drop] = useDrop(
    () => ({
      accept: DND_ITEM_TYPE,
      hover(item) {
        const dragIndex = item.index;
        const hoverIndex = index;
        if (dragIndex === hoverIndex) {
          return;
        }
        movePicture(dragIndex, hoverIndex);
        item.index = hoverIndex;
      },
    }),
    [index, movePicture]
  );

  return (
    <div ref={(node) => drag(drop(node))} className="photo-upload-square">
      {picture && (
        <div className="delete-icon" onClick={() => handleDelete(index)}>
          <FontAwesomeIcon icon={faTimes} />
        </div>
      )}

      {picture ? (
        <img
          src={picture}
          alt={`Profile ${index + 1}`}
          className="profile-photo-preview"
        />
      ) : (
        <>
          <input
            type="file"
            id={`file-input-${index}`}
            className="file-input"
            onChange={(event) => handleFileChange(index, event)}
            style={{ display: "none" }} // Hide the input element
          />
          <label htmlFor={`file-input-${index}`} className="file-label">
            <div className="upload-placeholder">+</div>
          </label>
        </>
      )}
    </div>
  );
};

const ProfilePicturesGrid = ({ onImageLinksUpdate }) => {
  const [pictures, setPictures] = useState(Array(5).fill(null));
  const [uploadTrigger, setUploadTrigger] = useState(0);
  const maxPhotos = 5;

  const { user } = useAuthContext();

  const [token, setToken] = useState(null);
  const [csrfToken, setCsrfToken] = useState(null); // New state variable for CSRF token

  useEffect(() => {
    const fetchCsrfToken = async () => {
      // Fetch the CSRF token from the "mytoken" cookie
      const csrfToken1 = Cookies.get("mytoken");
      setCsrfToken(csrfToken1);
    };

    fetchCsrfToken();
  }, []);

  useEffect(() => {
    const fetchToken = async () => {
      const token = await firebase.auth().currentUser.getIdToken(true);
      setToken(token);
    };

    console.log('csrfToken',csrfToken)

    fetchToken();
  }, []); // Empty dependency array means this effect runs once on component mount

  useEffect(() => {
    const fetchProfilePictures = async () => {
      if (!user || !user.userId) {
        console.error("User ID is not available.");
        return;
      }

      try {
        console.log(
          "Sending POST request to fetch profile pictures and csrfToken is",
          csrfToken
        );
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/userpics`,
          {
            method: "POST",
            body: JSON.stringify({
              userId: user.userId,
              _csrf: csrfToken, // Include the CSRF token in the body of the request
            }),
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.status === 200) {
          const data = await response.json();
          setPictures(data.pics);
          console.log("response pictures back:", data);
        } else {
          throw new Error("Failed to fetch profile pictures");
        }
      } catch (error) {
        console.error("Error fetching profile pictures:", error);
      }
    };

    if (token) {
      // Only fetch profile pictures if token is not null
      fetchProfilePictures();
    }
  }, [user.userId, uploadTrigger, token, csrfToken]); // Add csrfToken to dependency array

  const handleFileChange = async (index, event) => {
    const file = event.target.files[0];
    if (file) {
      // Pass the file directly to handleUpload
      await handleUpload(index, file);
    }
  };

  const handleDelete = async (index) => {
    const updatedPictures = pictures.filter((_, i) => i !== index);
    setPictures(updatedPictures);
    await updateBackend(updatedPictures); // Function to update the backend
  };

  const handleUpload = async (index, file) => {
    // console.log("csrfToken1", csrfToken);

    if (!file ) {
      console.error("No file selected for upload or no csrfToken.");
      
      return;
    }

    // Create a new FormData instance
    const formData = new FormData();

    // Append the file and user ID to the form data
    formData.append("userPic", file);
    formData.append("userId", user.userId);

    // Define the headers
    const requestHeaders = {
      Authorization: `Bearer ${token}`,
      "CSRF-Token": csrfToken, // Include the CSRF token in the request headers
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/upload/profilePic`,
        {
          method: "POST",
          body: formData, // Send the form data
          headers: requestHeaders,
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log("Response from POST request:", data);
        // Update the pictures state with the new picture URLs
        setPictures(data.user.pics);
      } else {
        console.error("Error from server:", response.statusText);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const movePicture = (dragIndex, hoverIndex) => {
    setPictures((prevPictures) => {
      const newPictures = Array.from(prevPictures);
      const [draggedPicture] = newPictures.splice(dragIndex, 1);
      newPictures.splice(hoverIndex, 0, draggedPicture);
      return newPictures;
    });
  };

  // useEffect(() => {
  //   if (!csrfToken) {
  //     console.error("No csrfToken.");
  //     return;
  //   }
  // });

  // useEffect(() => {
  //   if (!csrfToken) {
  //     console.error("No csrfToken.");
  //     return;
  //   }
  // });

  useEffect(() => {
    const updatePictureOrder = async () => {
      // if (!csrfToken || pictures.every((pic) => pic === null)) {
      //   console.error("No csrfToken or pictures to update.");
      //   return;
      // }
  
      const formData = new FormData();
      formData.append("userId", user.userId);
      formData.append("pics", JSON.stringify(pictures)); // Since FormData doesn't support arrays directly, stringify it
      formData.append("_csrf", csrfToken);
  
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/userpics`, {
          method: "PUT",
          body: formData, // Send the FormData
          headers: {
            Authorization: `Bearer ${token}`,
          },
          credentials: "include", // Include cookies with the request
        });
  
        if (response.status === 200) {
          console.log("Picture order updated successfully");
        } else {
          throw new Error("Failed to update picture order");
        }
      } catch (error) {
        console.error("Error updating picture order:", error);
      }
    };
  
    if (pictures.some((pic) => pic !== null)) {
      updatePictureOrder();
    }
  }, [pictures, token, csrfToken]);
  
  const updateBackend = async (updatedPictures) => {
    if (!csrfToken) {
      console.error("No csrfToken.");
      return;
    }
  
    const formData = new FormData();
    formData.append("userId", user.userId);
    formData.append("pics", JSON.stringify(updatedPictures)); // Since FormData doesn't support arrays directly, stringify it
    formData.append("_csrf", csrfToken);
  
    try {
      console.log("Sending PUT request to update pictures");
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/userpics/`, {
        method: "PUT",
        body: formData, // Send the FormData
        headers: {
          Authorization: `Bearer ${token}`,
        },
        credentials: "include", // Include cookies with the request
      });
  
      if (response.status === 200) {
        console.log("Pictures updated successfully");
      } else {
        throw new Error("Failed to update pictures");
      }
    } catch (error) {
      console.error("Error updating pictures:", error);
    }
  };
  // Ensure that the pictures array always has 5 elements
  const filledPictures = [...pictures];
  while (filledPictures.length < maxPhotos) {
    filledPictures.push(null);
  }

  return (
    <DndProvider backend={HTML5Backend}>
      {/* <div className="photo-upload-wrapper"> */}
      {/* <h2>My Photos</h2> */}
      <div className="photo-upload-container">
        {filledPictures.map((picture, index) => (
          <DraggablePicture
            key={index} // For simplicity, using index as key here; consider using unique IDs for production
            index={index}
            picture={picture}
            movePicture={movePicture}
            handleFileChange={handleFileChange}
            handleDelete={handleDelete}
          />
        ))}
      </div>
      {/* </div> */}
    </DndProvider>
  );
};

export default ProfilePicturesGrid;
