import { useEffect } from "react";
import { useCommunitiesContext } from "../hooks/useCommunitiesContext";
//import { useParams } from 'react-router-dom';
import "./IndividualGroup.css";
import ICommunity from "../components/ICommunity";

const IndividualGroup = () => {
  const currentURL = window.location.href;

  // Split the URL by '/'
  const segments = currentURL.split("/");

  // Get the last segment (part) of the URL
  const lastSegment = segments.pop();

  //const [community, setCommunity] = useState(null);
  const { communities, dispatch } = useCommunitiesContext();
  useEffect(() => {
    const fetchCommunities = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/communities`,
        {
          method: "GET",
        }
      );
      const json = await response.json();

      if (response.ok) {
        dispatch({ type: "SET_COMMUNITIES", payload: json });
      }
    };

    fetchCommunities();
  }, [dispatch]);

  return (
    <div className="igroup">
      <h1>Individual Group</h1>
      {communities
        .filter((community) => community._id === lastSegment)
        .map((filteredCommunity) => (
          <ICommunity
            community={filteredCommunity}
            key={filteredCommunity._id}
          />
        ))}
    </div>
  );
};

export default IndividualGroup;
