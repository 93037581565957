import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import EmailAlertsCheck from '../components/Modal/EmailAlertsCheck';
import "./Settings.css";
import "./ProfileUpdate.css"; 
import "./Onboarding.css"; 
import ProfileBasics from "../components/Profile&Settings/ProfileBasics";
import NearestLocationForm from '../components/Location/NearestLocationField';
import ProfilePicturesGrid from '../components/Uploads/ProfilePhotoUpload';
import DynamicProfileSeperatePopUpEditcopy from "../components/Profile&Settings/DynamicProfileSeperatePopUpEditcopy";
import CommuniSettings from "../components/Profile&Settings/CommuniSettings";
import Alerts from '../components/Profile&Settings/AlertsSettings';
import { useAuthContext } from '../hooks/useAuthContext';

const Onboarding = () => {
  const [step, setStep] = useState(1);
  const [isChecked, setIsChecked] = useState(false);
  const [communityNames, setCommunityNames] = useState([]);
  const [imageLinks, setImageLinks] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const { user } = useAuthContext();
  const navigate = useNavigate();

  
  useEffect(() => {
    console.log('onboarding happening and user is', user);
  }, [user]); 

  console.log('onboarding happening and user is', user)

  const nextStep = () => {

    
    if (step === user.listOfCommunities.length + 4) {
      if (!isChecked) {
        setShowModal(true);
      } else {
        setStep(prevStep => prevStep + 1);
      }
    } else {
      setStep(prevStep => prevStep + 1);
    }
  };

  useEffect(() => {
    if (step === user.listOfCommunities.length + 6) {
      setTimeout(() => {
        navigate('/tester1');
      }, 6000); // 6000 milliseconds = 6 seconds
    }
  }, [step]);

  const handleCommunityName = (communityName, index) => {
    setCommunityNames(prevNames => {
      const newNames = [...prevNames];
      newNames[index] = communityName;
      return newNames;
    });
  };

  const handleImageLinksUpdate = (links) => {
    setImageLinks(links);
  };

  useEffect(() => {
    // Simulate the loading of components
    setTimeout(() => {
      setStep(1);
    }, 4000);
  }, []);

  return (
    <div>
      {showModal && (
        <EmailAlertsCheck 
          onClose={() => setShowModal(false)}
          onConfirm={() => {
            setShowModal(false);
            setStep(prevStep => prevStep + 1);
          }}
        />
      )}
      <SwitchTransition>
        <CSSTransition
          key={step}
          addEndListener={(node, done) => {
            node.addEventListener("transitionend", done, false);
          }}
          classNames='fade'
        >
          <div>
            {step === 1 && <div>
              <h1>Welcome!</h1>
              <p>To get you set up, please complete the following:</p>
              <ProfileBasics />
            </div>}
            {step === 2 && <div>
              <p>Let us know where you are based to help find people nearby.</p>
              <NearestLocationForm />
            </div>}
            {step === 3 && <div>
              <p>Please upload a few pictures of yourself. The first will be your profile image.</p>
              <ProfilePicturesGrid onImageLinksUpdate={handleImageLinksUpdate} />
            </div>}
            {Array.isArray(user.listOfCommunities) && user.listOfCommunities.map((community, index) => (
              step === index + 4 && <div>
                <p>Fill in your community info for: </p>
                <h1>{communityNames[index]}</h1>
                <DynamicProfileSeperatePopUpEditcopy
                  user={user}
                  communityId={community.toString()}
                  onCommunityName={(name) => handleCommunityName(name, index)}
                />
              </div>
            ))}
            {step === user.listOfCommunities.length + 4 && <div>
              {/* <p>And finally, set your alerts!</p> */}
              <Alerts onCheckboxChange={setIsChecked} />
            </div>}
            {step === user.listOfCommunities.length + 5 && <div>
              <p>And finally, set your settings!</p>
              <CommuniSettings 
                user={user}
                communityId={user.listOfCommunities[user.listOfCommunities.length - 1].toString()}
                onCommunityName={(name) => handleCommunityName(name, user.listOfCommunities.length - 1)}
              />
            </div>}
            {step === user.listOfCommunities.length + 6 && <div>
              <h1>Your account is complete!  Welcome to the Interconnector</h1>
              <h2>You'll now be taken to your messages where you can connect with people</h2>
            </div>}
            <div>
              {step < user.listOfCommunities.length + 6 && <button onClick={nextStep}>Next Step</button>}
              {step < user.listOfCommunities.length + 5 && <p>Progress: {step}/{user.listOfCommunities.length + 5}</p>}
            </div>
          </div>
        </CSSTransition>
      </SwitchTransition>
    </div>
  );
}

export default Onboarding;



       



// import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { CSSTransition, SwitchTransition } from 'react-transition-group';
// import "./Settings.css";
// import "./ProfileUpdate.css"; 
// import "./Onboarding.css"; 
// import ProfileBasics from "../components/Profile&Settings/ProfileBasics";
// import NearestLocationForm from '../components/Location/NearestLocationField';
// import ProfilePicturesGrid from '../components/Uploads/ProfilePhotoUpload';
// import DynamicProfileSeperatePopUpEditcopy from "../components/Profile&Settings/DynamicProfileSeperatePopUpEditcopy";
// import CommuniSettings from "../components/Profile&Settings/CommuniSettings";
// import Alerts from '../components/Profile&Settings/AlertsSettings';
// import { useAuthContext } from '../hooks/useAuthContext';

// const Onboarding = () => {
//   const [step, setStep] = useState(1);
//   const [isChecked, setIsChecked] = useState(false);
//   const [communityNames, setCommunityNames] = useState([]);
//   const [imageLinks, setImageLinks] = useState([]);
//   const { user } = useAuthContext();
//   const navigate = useNavigate();
  
//   const nextStep = () => {
//     if (step === user.listOfCommunities.length + 4) {
//       if (!isChecked) {
//                 if (window.confirm("Are you sure?")) {
//                   setStep(prevStep => prevStep + 1);
//                 }
//       } else {
//         setStep(prevStep => prevStep + 1);
//       }
//     } else {
//       setStep(prevStep => prevStep + 1);
//     }
//   };

//   const handleCommunityName = (communityName, index) => {
//     setCommunityNames(prevNames => {
//       const newNames = [...prevNames];
//       newNames[index] = communityName;
//       return newNames;
//     });
//   };

//   const handleImageLinksUpdate = (links) => {
//     setImageLinks(links);
//   };

//   useEffect(() => {
//     // Simulate the loading of components
//     setTimeout(() => {
//       setStep(1);
//     }, 4000);
//   }, []);

//   return (
//     <div>
//       <SwitchTransition>
//         <CSSTransition
//           key={step}
//           addEndListener={(node, done) => {
//             node.addEventListener("transitionend", done, false);
//           }}
//           classNames='fade'
//         >
//           <div>
//             {step === 1 && <div>
//               <h1>Welcome!</h1>
//               <p>To get you set up, please complete the following:</p>
//               <ProfileBasics />
//             </div>}
//             {step === 2 && <div>
//               <p>Let us know where you are based to help find people nearby.</p>
//               <NearestLocationForm />
//             </div>}
//             {step === 3 && <div>
//               <p>Please upload a few pictures of yourself. The first will be your profile image.</p>
//               <ProfilePicturesGrid onImageLinksUpdate={handleImageLinksUpdate} />
//             </div>}
//             {Array.isArray(user.listOfCommunities) && user.listOfCommunities.map((community, index) => (
//               step === index + 4 && <div>
//                 <p>Fill in your community info for: </p>
//                 <h1>{communityNames[index]}</h1>
//                 <DynamicProfileSeperatePopUpEditcopy
//                   user={user}
//                   communityId={community.toString()}
//                   onCommunityName={(name) => handleCommunityName(name, index)}
//                 />
//               </div>
//             ))}
//             {step === user.listOfCommunities.length + 4 && <div>
//               <p>And finally, set your alerts!</p>
//               <Alerts onCheckboxChange={setIsChecked} />
//             </div>}
//             {step === user.listOfCommunities.length + 5 && <div>
//               <p>And finally, set your settings!</p>
//               <CommuniSettings 
//                 user={user}
//                 communityId={user.listOfCommunities[user.listOfCommunities.length - 1].toString()}
//                 onCommunityName={(name) => handleCommunityName(name, user.listOfCommunities.length - 1)}
//               />
//             </div>}
//             {step === user.listOfCommunities.length + 6 && <div>
//               <h1>Your account is complete!  Welcome to the Interconnector</h1>
//               <h2>You'll now be taken to your messages where you can connect with people</h2>
//             </div>}
//             <div>
//               {step < user.listOfCommunities.length + 6 && <button onClick={nextStep}>Next Step</button>}
//               {step < user.listOfCommunities.length + 5 && <p>Progress: {step}/{user.listOfCommunities.length + 5}</p>}
//             </div>
//           </div>
//         </CSSTransition>
//       </SwitchTransition>
//     </div>
//   );
// }

// export default Onboarding;

// import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { CSSTransition, SwitchTransition } from 'react-transition-group';
// import "./Settings.css";
// import "./ProfileUpdate.css"; 
// import "./Onboarding.css"; 
// import ProfileBasics from "../components/Profile&Settings/ProfileBasics";
// import NearestLocationForm from '../components/Location/NearestLocationField';
// import ProfilePicturesGrid from '../components/Uploads/ProfilePhotoUpload';
// import DynamicProfileSeperatePopUpEditcopy from "../components/Profile&Settings/DynamicProfileSeperatePopUpEditcopy";
// import CommuniSettings from "../components/Profile&Settings/CommuniSettings";
// import Alerts from '../components/Profile&Settings/AlertsSettings';
// import { useAuthContext } from '../hooks/useAuthContext';

// const Onboarding = () => {
//   const [step, setStep] = useState(1);
//   const [isChecked, setIsChecked] = useState(false); // Add this line
//   const [communityNames, setCommunityNames] = useState([]);
//   const [imageLinks, setImageLinks] = useState([]);
//   const { user } = useAuthContext();
//   const navigate = useNavigate();
  
//   const nextStep = () => {
//     if (step === user.listOfCommunities.length + 4) {
//       if (!isChecked) {
//         if (window.confirm("Are you sure?")) {
//           setStep(prevStep => prevStep + 1);
//         }
//       } else {
//         setStep(prevStep => prevStep + 1);
//       }
//     } else {
//       setStep(prevStep => prevStep + 1);
//     }
//   };


//   const handleCommunityName = (communityName, index) => {
//     setCommunityNames(prevNames => {
//       const newNames = [...prevNames];
//       newNames[index] = communityName;
//       return newNames;
//     });
//   };

//   const handleImageLinksUpdate = (links) => {
//     setImageLinks(links);
//   };

//   useEffect(() => {
//     // Simulate the loading of components
//     setTimeout(() => {
//       setStep(1);
//     }, 4000);
//   }, []);

//   useEffect(() => {
//     if (step === user.listOfCommunities.length + 5) {
//       setTimeout(() => {
//         navigate('/tester1');
//       }, 4000);
//     }
//   }, [step, user.listOfCommunities.length, navigate]);

//   return (
//     <div>
//       <SwitchTransition>
//         <CSSTransition
//           key={step} // Updated key
//           addEndListener={(node, done) => {
//             node.addEventListener("transitionend", done, false);
//           }}
//           classNames='fade'
//         >
//           <div>
//             {step === 1 && <div>
//               <h1>Welcome!</h1>
//               <p>To get you set up, please complete the following:</p>
//               <ProfileBasics />
//             </div>}
//             {step === 2 && <div>
//               <p>Let us know where you are based to help find people nearby.</p>
//               <NearestLocationForm />
//             </div>}
//             {step === 3 && <div>
//               <p>Please upload a few pictures of yourself. The first will be your profile image.</p>
//               <ProfilePicturesGrid onImageLinksUpdate={handleImageLinksUpdate} />
//             </div>}
//             {Array.isArray(user.listOfCommunities) && user.listOfCommunities.map((community, index) => (
//               step === index + 4 && <div>
//                 <p>Fill in your community info for: </p>
//                 <h1>{communityNames[index]}</h1>

//                 <DynamicProfileSeperatePopUpEditcopy
//                   user={user}
//                   communityId={community.toString()}
//                   onCommunityName={(name) => handleCommunityName(name, index)}
//                 />
//               </div>
//             ))}
//             {step === user.listOfCommunities.length + 4 && <div>
//             <p>And finally, set your alerts!</p>
//             <Alerts onCheckboxChange={setIsChecked} />
//           </div>}
//             {step === user.listOfCommunities.length + 5 && <div>
//               <p>And finally, set your settings!</p>
//               <CommuniSettings 
//                 user={user}
//                 communityId={user.listOfCommunities[user.listOfCommunities.length - 1].toString()}
//                 onCommunityName={(name) => handleCommunityName(name, user.listOfCommunities.length - 1)}
//               />
//             </div>}
//             {step === user.listOfCommunities.length + 6 && <div>
//               <h1>Your account is complete!  Welcome to the Interconnector</h1>
//               <h2>You'll now be taken to your messages where you can connect with people</h2>

//             </div>}
//             <div>
//               {step < user.listOfCommunities.length + 5 && <button onClick={nextStep}>Next Step</button>}
//               {step < user.listOfCommunities.length + 4 && <p>Progress: {step}/{user.listOfCommunities.length + 4}</p>}
//             </div>
//           </div>
//         </CSSTransition>
//       </SwitchTransition>
//     </div>
//   );
// }

// export default Onboarding;


// import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { CSSTransition, SwitchTransition } from 'react-transition-group';
// import "./Settings.css";
// import "./ProfileUpdate.css"; 
// import "./Onboarding.css"; 
// import ProfileBasics from "../components/Profile&Settings/ProfileBasics";
// import NearestLocationForm from '../components/Location/NearestLocationField';
// import ProfilePicturesGrid from '../components/Uploads/ProfilePhotoUpload';
// import DynamicProfileSeperatePopUpEditcopy from "../components/Profile&Settings/DynamicProfileSeperatePopUpEditcopy";
// import CommuniSettings from "../components/Profile&Settings/CommuniSettings";
// import { useAuthContext } from '../hooks/useAuthContext';
// import Alerts from '../components/Profile&Settings/AlertsSettings';

// const Onboarding = () => {
//   const [step, setStep] = useState(1);
//   const [communityNames, setCommunityNames] = useState([]);
//   const [imageLinks, setImageLinks] = useState([]);
//   const { user } = useAuthContext();
//   const navigate = useNavigate();

//   const nextStep = () => {
//     setStep(prevStep => prevStep + 1);
//   };

//   const handleCommunityName = (communityName, index) => {
//     setCommunityNames(prevNames => {
//       const newNames = [...prevNames];
//       newNames[index] = communityName;
//       return newNames;
//     });
//   };

//   const handleImageLinksUpdate = (links) => {
//     setImageLinks(links);
//   };

//   useEffect(() => {
//     // Simulate the loading of components
//     setTimeout(() => {
//       setStep(1);
//     }, 4000);
//   }, []);

//   useEffect(() => {
//     if (step === user.listOfCommunities.length + 5) {
//       setTimeout(() => {
//         navigate('/tester1');
//       }, 4000);
//     }
//   }, [step, user.listOfCommunities.length, navigate]);

//   return (
//     <div>
//       <SwitchTransition>
//         <CSSTransition
//           key={step} // Updated key
//           addEndListener={(node, done) => {
//             node.addEventListener("transitionend", done, false);
//           }}
//           classNames='fade'
//         >
//           <div>
//             {step === 1 && <div>
//               <h1>Welcome!</h1>
//               <p>To get you set up, please complete the following:</p>
//               <ProfileBasics />
//             </div>}
//             {step === 2 && <div>
//               <p>Let us know where you are based to help find people nearby.</p>
//               <NearestLocationForm />
//             </div>}
//             {step === 3 && <div>
//               <p>Please upload a few pictures of yourself. The first will be your profile image.</p>
//               <ProfilePicturesGrid onImageLinksUpdate={handleImageLinksUpdate} />
//             </div>}
//             {Array.isArray(user.listOfCommunities) && user.listOfCommunities.map((community, index) => (
//               step === index + 4 && <div>
//                 <p>Fill in your community info for: </p>
//                 <h1>{communityNames[index]}</h1>

//                 <DynamicProfileSeperatePopUpEditcopy
//                   user={user}
//                   communityId={community.toString()}
//                   onCommunityName={(name) => handleCommunityName(name, index)}
//                 />
//               </div>
//             ))}
//             {step === user.listOfCommunities.length + 4 && <div>
//               <p>And finally, set your settings!</p>
//               <CommuniSettings 
//                 user={user}
//                 communityId={user.listOfCommunities[user.listOfCommunities.length - 1].toString()}
//                 onCommunityName={(name) => handleCommunityName(name, user.listOfCommunities.length - 1)}
//               />
//             </div>}
//             {step === user.listOfCommunities.length + 5 && <div>
//               <h1>Your account is complete!  Welcome to the Interconnector</h1>
//               <h2>You'll now be taken to your messages where you can connect with people</h2>

//             </div>}
//             <div>
//               {step < user.listOfCommunities.length + 5 && <button onClick={nextStep}>Next Step</button>}
//               {step < user.listOfCommunities.length + 4 && <p>Progress: {step}/{user.listOfCommunities.length + 4}</p>}
//             </div>
//           </div>
//         </CSSTransition>
//       </SwitchTransition>
//     </div>
//   );
// }

// export default Onboarding;