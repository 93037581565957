import React, { useState, useEffect } from 'react';
import "./NewConversations.css";
import Conversation from './CopyConversation';
import { useAuthContext } from '../../hooks/useAuthContext';
import { useMessengerContext } from '../../hooks/useMessengerContext';

const Conversations = ({onConversationSelectForCSS}) => {
  const { state, dispatch } = useMessengerContext();

  const { conversations, unopenedMessages } = state;

  const handleConversationSelect = (conversation) => {
    dispatch({ type: 'SET_CURRENT_CONVERSATION', payload: conversation });
    onConversationSelectForCSS();
  
    // if (Array.isArray(unopenedMessages)) {
    //   const updatedUnopenedMessages = unopenedMessages.filter(
    //     conversationId => conversationId !== conversation._id
    //   );
  
    //   if (updatedUnopenedMessages.length !== unopenedMessages.length) {
    //     dispatch({ type: 'SET_UNOPENED_MESSAGES', payload: updatedUnopenedMessages });
    //   }
    // } else {
    //   console.error('unopenedMessages is not loaded or not an array');
    // }
  };

  console.log('unopenedMessages',unopenedMessages)
  
  return (
    <>
      <h4 id="ConversationHeaderMessages"> Messages </h4>
      <div>
        {conversations && conversations.map((c) => (
          <div key={c._id} onClick={() => handleConversationSelect(c)}>
            <Conversation
              conversation={c}
              // Safely check if unopenedMessages is an array before calling .includes
              newMessage={c.unReadMessages}
            />
          </div>
        ))}
      </div>
    </>
  );
}

export default Conversations;


// import React, { useState, useEffect } from 'react';
// import "./CopyConversation.css";
// import Conversation from './CopyConversation';
// import { useAuthContext } from '../../hooks/useAuthContext';
// import { useMessengerContext } from '../../hooks/useMessengerContext';

// const Conversations = ({onConversationSelectForCSS}) => {
//   const { state, dispatch } = useMessengerContext();

//   const { conversations, unopenedMessages } = state

//   const handleConversationSelect = (conversation) => {
//     dispatch({ type: 'SET_CURRENT_CONVERSATION', payload: conversation });
//     onConversationSelectForCSS();
  
//     // Filter out the selected conversation ID from the unopenedMessages array
//     const updatedUnopenedMessages = unopenedMessages.filter(
//       conversationId => conversationId !== conversation._id
//     );
  
//     // Only dispatch if the array has changed (i.e., an item was filtered out)
//     if (updatedUnopenedMessages.length !== unopenedMessages.length) {
//       dispatch({ type: 'SET_UNOPENED_MESSAGES', payload: updatedUnopenedMessages });
//     }
//   };
  
//   return (
//     <div>
//       {conversations && conversations.map((c) => (
//         <div key={c._id} onClick={() => handleConversationSelect(c)}>
//           <Conversation
//             conversation={c}
//             newMessage={unopenedMessages?.conversationIds.includes(c._id)}// Keep null for now!
//           />
//         </div>
//       ))}
//     </div>
//   );
// }

// export default Conversations;


// import React, { useState, useEffect } from 'react';
// import "./Conversation.css";

// const Conversation = ({conversation, currentUser}) => {
//   const [user, setUser] = useState(null);
//   const [communityBot, setCommunityBot] = useState(null);
//   useEffect(() => {
//     if (conversation.communityBot) {
//       const communityId = conversation.members.find((m) => m !== currentUser.userId);
//       const getCommunityBot = async () => {
//         try {
//           const response = await fetch(`/api/communities/${communityId}`, {
//             method: 'GET',
//             headers: {
//               'Content-Type': 'application/json'
//             }
//           });
//           if (!response.ok) {
//             throw new Error('Network response was not ok');
//           }
//           const json = await response.json();
//           console.log('Community Bot Conversation:', json);
//           setCommunityBot(json);
//         } catch (error) {
//           console.error('Failed to fetch community bot:', error);
//         }
//       }
//       getCommunityBot();
//     } else {
//       const friendId = conversation.members.find((m) => m !== currentUser.userId);
//       const getUsers = async () => {
//         try {
//           const response = await fetch('/api/dynamic/', {
//             method: 'POST',
//             headers: {
//               'Content-Type': 'application/json'
//             },
//             body: JSON.stringify({ _id: friendId})
//           });
//           if (!response.ok) {
//             throw new Error('Network response was not ok');
//           }
//           const json = await response.json();
//           console.log('Sender Conversation:', json);
//           setUser(json);
//         } catch (error) {
//           console.error('Failed to fetch user:', error);
//         }
//       }
//       getUsers();
//     }
//   }, [currentUser, conversation]);

//   return (
//     <div className="conversation">
//       <img className="conversationImg" 
//         src={conversation.communityBot ? (communityBot && communityBot.pics ? communityBot.pics[0] :
//          "https://upload.wikimedia.org/wikipedia/commons/thumb/a/aa/Saw_Spiral.svg/768px-Saw_Spiral.svg.png") : 
//          (user && user.user.profilePicture ? user.user.profilePicture : 
//          "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png")} alt=""/>
//       <div className="span conversationName">{conversation.communityBot ? (communityBot && communityBot.title) : (user && user.user.firstName)}</div>
//     </div>
//   );
// }

// export default Conversation;

// import React, { useState, useEffect } from 'react';
// import "./Conversation.css";

// const Conversation = ({conversation, currentUser}) => {
//   const [user, setUser] = useState(null);

//   useEffect(() =>{
//     //if conversation.communityBot(true) then 
//    // const communityBotId = conversation.members.find((m) => m !== currentUser.userId);
//   /*    const getcommunityBot = async () => {
//       const response = await fetch('/api/community/', {
//         method: 'POST',
//         body: JSON.stringify({ _id: communityBotId}),
//         headers: {
//           'Content-Type': 'application/json'
//         }
//       });
//       const json = await response.json();
//       console.log('Conversation:', json);
//       setCommunityBot(json)
//     }

//     getUsers(); 
//   },[currentUser, conversation]);
  
//   // ELSE: */

//     const friendId = conversation.members.find((m) => m !== currentUser.userId);

//     const getUsers = async () => {
//       const response = await fetch('/api/dynamic/', {
//         method: 'POST',
//         body: JSON.stringify({ _id: friendId}),
//         headers: {
//           'Content-Type': 'application/json'
//         }
//       });
//       const json = await response.json();
//       console.log('Conversation:', json);
//       setUser(json)
//     }

//     getUsers(); 
//   },[currentUser, conversation]);

//   return (
//     <div className="conversation">
//         <img className="conversationImg" 
//         //{if conversation.communityBot(true) then} src={user && user.user.profilePicture ? user.user.profilePicture : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"} alt=""/>
//         {user && <div className="span conversationName">{user.user.firstName}</div>}    </div>
//         //else src={communityBot && communityBot.profilePicture ? communityBot.profilePicture : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"} alt=""/>
//         {user && <div className="span conversationName">{user.user.firstName}</div>} 
//   );
// }

// export default Conversation;