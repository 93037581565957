import React, { useState, useEffect } from "react";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useMessengerContext } from "../../hooks/useMessengerContext";
import "./MessengerInput.css";
import useSocket from "../../hooks/useSocket";
import { initializeApp, getApps } from "firebase/app";
import { getAuth, getIdToken } from "firebase/auth";
import firebase from "../../Firebase";
import Cookies from "js-cookie";

if (!getApps().length) {
  initializeApp(firebase);
}

const MessengerInput = () => {
  const [newMessage, setNewMessage] = useState("");
  const { user } = useAuthContext();
  const { state, dispatch } = useMessengerContext();
  const { currentConversation, community } = state;
  const { sendMessage } = useSocket(user?.userId, currentConversation?._id);
  const [csrfToken, setCsrfToken] = useState(null); // New state variable for CSRF token
  const [token, setToken] = useState(null);
  let controller; // Controller for aborting fetch request

  // Fetch the CSRF token when the component mounts
  useEffect(() => {
    const fetchCsrfToken = async () => {
      // Fetch the CSRF token from the "mytoken" cookie
      const csrfToken = Cookies.get("mytoken");
      setCsrfToken(csrfToken);
    };

    fetchCsrfToken();
  }, []);

  useEffect(() => {
    const fetchToken = async () => {
      const token = await firebase.auth().currentUser.getIdToken(true);
      setToken(token);
    };

    fetchToken();
  }, []); // Empty dependency array means this effect runs once on component mount

  const notifyBackend = async (conversationId, messageId, userId) => {
    // Create an AbortController instance
    const controller = new AbortController();
    const { signal } = controller;

    const newCsrfToken = Cookies.get("mytoken");
    if (newCsrfToken !== csrfToken) {
      console.log("CSRF token old:", csrfToken);
      console.log("CSRF token new:", newCsrfToken);
      console.log("messageId 2nd api:", messageId);
      console.log("userId 2nd api:", userId);
      setCsrfToken(newCsrfToken);
    }

    console.log("token", token);
    console.log("CSRF token new? second api", newCsrfToken);
    // Define the headers including CSRF token and Authorization token
    const requestHeaders = {
      Authorization: `Bearer ${token}`,
      "X-CSRF-Token": newCsrfToken, // Use the new CSRF token here
    };

    try {
      console.log("second api happening");

      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/messages/lastMessageViewed`,

        {
          method: "POST", // Assuming you're making a POST request
          headers: {
            ...requestHeaders,
            "Content-Type": "application/json",
          },
          // credentials: "include", // To include cookies in the request (if your authentication relies on cookies)
          signal: signal,
          body: JSON.stringify({ conversationId, messageId, userId }), // Include all the props in the body
        }
      );

      if (!response.ok) {
        throw new Error("Network second api response was not ok");
      }
      if (response.ok) {
        console.log("response.ok second api update last message", response.ok);
      }

      const data = await response.json();
      console.log(data); // Process the data
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const receiver = currentConversation.members.find(
      (member) => member !== user.userId
    );
     
    const message = {
      sender: user.userId,
      receiver: receiver,
      text: newMessage,
      conversationId: currentConversation._id,
      communityId: receiver === community.communityId ? community.communityId : null,
    };


    console.log('community888',message)
    const auth = getAuth();
    const token = await getIdToken(auth.currentUser);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/messages/`,
        {
          method: "POST",
          body: JSON.stringify(message),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "X-CSRF-Token": csrfToken, // Include the CSRF token here
          },
        }
      );

      const data = await response.json();

      const messageToReciever = {
        sender: user.userId,
        receiver: currentConversation.members.find(
          (member) => member !== user.userId
        ),
        text: newMessage,
        conversationId: currentConversation._id,
        messageId: data._id,
      };

      sendMessage(messageToReciever);

      // If there's an ongoing request, abort it
      if (controller) {
        controller.abort();
      }

      // Start a new request and store the controller
      notifyBackend(currentConversation._id, data._id, message.receiver);

      dispatch({ type: "ADD_MESSAGE", payload: data });
      dispatch({
        type: "UPDATE_LAST_READ_MESSAGE_ID",
        payload: { userId: user.userId, messageId: data._id },
      });

      // Clear the input box
      setNewMessage("");
    } catch (err) {
      console.error("Failed to send message:", err);
    }
  };

  return (
    <div className="chatMessengerInput">
      <textarea
        className="chatMessengerInputTextArea"
        placeholder="write something"
        onChange={(e) => setNewMessage(e.target.value)}
        value={newMessage}
        onKeyPress={(e) => {
          if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault(); // Prevents the default action of inserting a newline
            handleSubmit(e); // Calls the function to send the message
          }
        }}
      ></textarea>
      <button className="chatSubmitButton" onClick={handleSubmit}>
        Send
      </button>
    </div>
  );
};

export default MessengerInput;

// import React, { useState, useEffect } from 'react';
// import { useAuthContext } from '../../hooks/useAuthContext';
// import { useMessengerContext } from '../../hooks/useMessengerContext';
// import "./MessengerInput.css";
// import useSocket from '../../hooks/useSocket';
// import { initializeApp, getApps } from 'firebase/app';
// import { getAuth, getIdToken } from 'firebase/auth';
// import firebase from '../../Firebase';
// import Cookies from 'js-cookie';

// if (!getApps().length) {
//   initializeApp(firebase);
// }

// const MessengerInput = () => {
//   const [newMessage, setNewMessage] = useState("");
//   const { user } = useAuthContext();
//   const { state, dispatch } = useMessengerContext();
//   const { currentConversation } = state;
//   const { sendMessage } = useSocket(user?.userId, currentConversation?._id);
//   const [csrfToken, setCsrfToken] = useState(null); // New state variable for CSRF token

//    // Fetch the CSRF token when the component mounts
//  useEffect(() => {
//   const fetchCsrfToken = async () => {
//     // Fetch the CSRF token from the "mytoken" cookie
//     const csrfToken = Cookies.get('mytoken');
//     setCsrfToken(csrfToken);
//   };

//   fetchCsrfToken();
// }, []);

// const handleSubmit = async (e) => {
//   e.preventDefault();

//   const message = {
//     sender: user.userId,
//     receiver: currentConversation.members.find(member => member !== user.userId),
//     text: newMessage,
//     conversationId: currentConversation._id,
//   };

//   const auth = getAuth();
//   const token = await getIdToken(auth.currentUser);

//   try {
//     const response = await fetch('api/messages/', {
//       method: 'POST',
//       body: JSON.stringify(message),
//       headers: {
//         'Content-Type': 'application/json',
//         'Authorization': `Bearer ${token}`,
//         'X-CSRF-Token': csrfToken // Include the CSRF token here
//       }
//     });

//     const data = await response.json();

//     const messageToReciever = {
//       sender: user.userId,
//       receiver: currentConversation.members.find(member => member !== user.userId),
//       text: newMessage,
//       conversationId: currentConversation._id,
//       messageId: data._id,
//       csrfToken: csrfToken,
//       token: token,
//     };

//     sendMessage(messageToReciever);

//     dispatch({ type: 'ADD_MESSAGE', payload: data });
//     dispatch({ type: 'UPDATE_LAST_READ_MESSAGE_ID', payload: { userId: user.userId, messageId: data._id } });

//     // Clear the input box
//     setNewMessage('');
//   } catch (err) {
//     console.error('Failed to send message:', err);
//   }
// };

//   return (
//     <div className="chatMessengerInput">
//       <textarea
//         className="chatMessengerInputTextArea"
//         placeholder="write something"
//         onChange={(e) => setNewMessage(e.target.value)}
//         value={newMessage}
//         onKeyPress={(e) => {
//           if (e.key === 'Enter' && !e.shiftKey) {
//             e.preventDefault(); // Prevents the default action of inserting a newline
//             handleSubmit(e); // Calls the function to send the message
//           }
//         }}
//       ></textarea>
//       <button className="chatSubmitButton" onClick={handleSubmit}>Send</button>
//     </div>
//   );
// }

// export default MessengerInput;
