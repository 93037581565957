import React, { createContext, useReducer, useContext, useEffect, useState } from 'react';
import { useAuthContext } from '../hooks/useAuthContext';
import { initializeApp, getApps } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import firebase from '../Firebase';

if (!getApps().length) {
  initializeApp(firebase);
}

const initialState = {
  messages: [],
  currentConversation: null,
  otherUserDisplay: null,
  community: null,
  userDisplayProfile: null,
};

const messengerReducer = (state, action) => {
  switch (action.type) {
    case 'SET_MESSAGES':
      return { ...state, messages: action.payload };
    case 'ADD_MESSAGE':
      return { ...state, messages: [...state.messages, action.payload] };
    case 'SET_CURRENT_CONVERSATION':
      return { ...state, currentConversation: action.payload };
    case 'SET_CONVERSATIONS':
      return { ...state, conversations: action.payload };
    case 'ADD_CONVERSATION':
      return { ...state, conversations: [action.payload, ...state.conversations] };
    case 'SET_OTHER_USER_DISPLAY':
      return { ...state, otherUserDisplay: action.payload };
    case 'SET_COMMUNITY_DISPLAY':
      return { ...state, community: action.payload };
    case 'SET_USER_DISPLAY':
      return { ...state, userDisplayProfile: action.payload };
    case 'SET_LAST_READ_MESSAGE_ID':
        return { ...state, lastReadMessageId: action.payload };
    case 'UPDATE_LAST_READ_MESSAGE_ID':
      console.log('UPDATE_LAST_READ_MESSAGE_ID action fired', action.payload);
      const updatedLastRead = state.currentConversation.lastRead.map(item =>
        item.user === action.payload.userId
          ? { ...item, message: action.payload.messageId }
          : item
      );
      return {
        ...state,
        currentConversation: {
          ...state.currentConversation,
          lastRead: updatedLastRead,
        },
      };
    case 'UPDATE_CONVERSATION_UNREAD':
      return {
        ...state,
        conversations: state.conversations.map(conversation => 
          conversation._id === action.payload
            ? { ...conversation, unReadMessages: true }
            : conversation
        )
      };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

export const MessengerContext = createContext();



export const MessengerContextProvider = ({ children }) => {
  console.log('Rendering MessengerContextProvider');

  const [state, dispatch] = useReducer(messengerReducer, initialState);
  const { user } = useAuthContext();
  const [token, setToken] = useState(null);
  const auth = getAuth();


  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        const idToken = await user.getIdToken(true);
        setToken(idToken);
      }
    });
  
    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  const fetchWithToken = async (url, options) => {
    const response = await fetch(url, {
      ...options,
      headers: {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  };

  useEffect(() => {
    if (state.currentConversation && state.currentConversation.lastRead) {
      const newLastReadMessageId = state.currentConversation.lastRead.find(lr => lr.user === user.userId)?.message;
      dispatch({ type: 'SET_LAST_READ_MESSAGE_ID', payload: newLastReadMessageId });
      console.log('lastReadMessageId context', newLastReadMessageId)
    }
  }, [state.currentConversation, user]);

  useEffect(() => {
  if (!user || !state.currentConversation || !token) return;
  const getMessages = async () => {
    if (state.currentConversation?._id) {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/messages/${state.currentConversation._id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        });
        const json = await response.json();
        dispatch({ type: 'SET_MESSAGES', payload: json });
      } catch (error) {
        console.error('Failed to fetch messages:', error);
      }
    }
  };
  getMessages();
}, [state.currentConversation, user, token]);

useEffect(() => {
  if (!user || !token) return;
  const getConversations = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/conversations/getConversationsWithUnreadMessages/${user.userId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      });
      const json = await response.json();
      dispatch({ type: 'SET_CONVERSATIONS', payload: json });
    } catch (error) {
      console.error('Failed to fetch conversations:', error);
    }
  };
  getConversations();
}, [state.currentConversation, user, token]);

useEffect(() => {
  if (!user || !token) return;
  const getUsers = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/userProfile/getBasicProfileToDisplay`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ userId: user.userId }),
      });
      const json = await response.json();
      dispatch({ type: 'SET_USER_DISPLAY', payload: json });
    } catch (error) {
      console.error('Failed to fetch user:', error);
    }
  };
  getUsers();
}, [user, token]);

useEffect(() => {
  if (!user || !state.currentConversation || !token) return;
  const getCommunityAndOtherUser = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/communities/display/${state.currentConversation.communityId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      });
      const communityJson = await response.json();
      dispatch({ type: 'SET_COMMUNITY_DISPLAY', payload: communityJson });

      const otherUserId = state.currentConversation.members.find((m) => m !== user.userId && m !== state.currentConversation.communityId);
      if (otherUserId) {
        const requestBody = {
          userId: otherUserId,
          communityId: state.currentConversation.communityId,
        };
        const userResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/userProfile/getProfileToDisplay`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify(requestBody),
        });
        const userJson = await userResponse.json();
        dispatch({ type: 'SET_OTHER_USER_DISPLAY', payload: userJson });
      }
    } catch (error) {
      console.error('Failed to fetch community or user:', error);
    }
  };
  getCommunityAndOtherUser();
}, [state.currentConversation, user, token]);

  

  return (
    <MessengerContext.Provider value={{ state, dispatch }}>
      {children}
    </MessengerContext.Provider>
  );
};

export const useMessenger = () => {
  const context = useContext(MessengerContext);
  if (context === undefined) {
    throw new Error('useMessenger must be used within a MessengerProvider');
  }
  return context;
};