/* global google */
import React, { useState, useEffect } from 'react';
import Autocomplete from 'react-google-autocomplete';
import boroughMapping from "./postCodeMappings.json";
import { useAuthContext } from '../../hooks/useAuthContext';
import "../Profile&Settings/ProfileAll.css"
import "./NearestLocationField.css";
import { initializeApp, getApps } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import firebase from '../../Firebase';
import Cookies from 'js-cookie';

if (!getApps().length) {
  initializeApp(firebase);
} 

const getNeighborhoodInfo = (address, callback) => {
  console.log('getNeighborhoodInfo called with address:', address);
  const geocoder = new google.maps.Geocoder();

  geocoder.geocode({ 'address': address }, (results, status) => {
    console.log('Geocoder response status:', status);
    if (status === 'OK') {
      console.log('Geocoder results:', results);
      const addressComponents = results[0].address_components;
      let localAreaInfo = null;

      // Check if the entered address matches any area name in the boroughMapping
      const areaNameMatch = Object.values(boroughMapping).find(areaName => address.includes(areaName));
      if (areaNameMatch) {
        console.log('Area name match found:', areaNameMatch);
        callback(areaNameMatch);
        return;
      }

      // Attempt to find the borough using the postcode
      const postcodeComponent = addressComponents.find(component =>
        component.types.includes('postal_code')
      );
      if (postcodeComponent) {
        const postcode = postcodeComponent.long_name.split(' ')[0]; // Get the first part of the postcode
        localAreaInfo = boroughMapping[postcode];
      }

      // If we found local area information, call the callback with it
      if (localAreaInfo) {
        console.log('Local area info found:', localAreaInfo);
        callback(localAreaInfo);
      } else {
        // Fallback to borough if postcode mapping is not found
        const boroughComponent = addressComponents.find(component =>
          component.types.includes('postal_town') || component.types.includes('locality')
        );
        if (boroughComponent) {
          localAreaInfo = boroughComponent.long_name;
        }

        if (localAreaInfo) {
          console.log('Borough found:', localAreaInfo);
          callback(localAreaInfo);
        } else {
          console.log('No borough info found in the address components.');
          callback('Area not found');
        }
      }
    } else {
      console.log('Geocoder failed to find the address.');
      callback('Area not found');
    }
  });
};

const NearestLocationForm = () => {
  const [localArea, setLocalArea] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const { user } = useAuthContext();
  const [json, setJson] = useState(null);
  const [formData, setFormData] = useState({ user: {}, ProfileBasics: {} });
  const [coordinates, setCoordinates] = useState(null);
  const [csrfToken, setCsrfToken] = useState(null);
  const [token, setToken] = useState(null);

  useEffect(() => {
    const fetchCsrfToken = async () => {
      const csrfToken = Cookies.get('mytoken');
      setCsrfToken(csrfToken);
    };

    fetchCsrfToken();
  }, []);

  useEffect(() => {
    const fetchToken = async () => {
      const token = await firebase.auth().currentUser.getIdToken(true);
      setToken(token);
    };

    fetchToken();
  }, []);

  useEffect(() => {
    if (localArea) {
      updateBackend(formData, user);
    }
  }, [localArea, user, formData]);

  useEffect(() => {
    if (user) {
      console.log('fetching location')
      const fetchProfile = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/userProfile/`, {
            method: 'POST',
            body: JSON.stringify({ _id: user.userId }),
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`, // Include the JWT here
              'X-CSRF-Token': csrfToken // Include the CSRF token here
            }
          });

          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          const json = await response.json();
          setJson(json);
          setLocalArea(json.user.localArea)
          setCoordinates(json.user.coordinates)
          console.log('json location!:', json);
          console.log('use effect location fetch response1', response)
        } catch (error) {
          console.error('An error occurred while fetching the profile:', error);
        }
      };

      fetchProfile();
    }
  }, [user, token, csrfToken]); // Include token and csrfToken in the dependency array

  useEffect(() => {
    console.log('local area:', localArea);
  }, [localArea]);

const updateBackend = async (formData, user) => {
  console.log('Updating backend with formData:', formData);
  const url = `${process.env.REACT_APP_API_BASE_URL}/userProfile/updateProfile`;

  if (user && formData) {
    console.log('Updating UserBasics Profile info:', user.userId);

    // Ensure the JWT token is fresh and valid
    const freshToken = await firebase.auth().currentUser.getIdToken(true);
    setToken(freshToken); // Update the state with the fresh token

    // Ensure the CSRF token is present and valid
    const freshCsrfToken = Cookies.get('mytoken'); // Potentially refresh or re-validate the CSRF token
    setCsrfToken(freshCsrfToken); // Update the state with the fresh CSRF token

    const requestBody = {
      userId: user.userId,
      UserBasics: formData // Use formData directly
    };

    console.log('Sending POST request with body:', requestBody);

    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(requestBody),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${freshToken}`, // Use the fresh JWT token
        'X-CSRF-Token': freshCsrfToken // Use the fresh CSRF token
      }
    });

    if (!response.ok) {
      // Handle different error statuses here, potentially refreshing tokens or redirecting to login
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const json = await response.json();
    console.log('Response:', json);
  }
};

useEffect(() => {
  if (localArea && coordinates) {
    const formData = {
      localArea: localArea,
      coordinates: coordinates
    };
    updateBackend(formData, user); // Pass formData and user to updateBackend
  }
}, [localArea, coordinates, user]); // 

const handlePlaceSelected = (place) => {
  if (!place || !place.formatted_address) {
    console.error('Location Not Found');
    setErrorMessage('Location Not Found');
    return;
  }
  console.log('Place selected:', place);
  const address = place.formatted_address;
  console.log('Formatted address:', address);
  getNeighborhoodInfo(address, (borough) => {
    console.log('borough!!', borough)
    setLocalArea(borough);
    const coordinates = [place.geometry.location.lng(), place.geometry.location.lat()];
    setCoordinates(coordinates); // Set coordinates state
    const formData = { localArea: borough, coordinates: coordinates };
    updateBackend(formData, user); // Adjusted call to updateBackend
    setErrorMessage('');
  });
};

return (
  <div className="location-form"> {/* Apply both location-form and dynamic-form classes */}
    <div style={{ marginBottom: '10px' }}>
      <label htmlFor="userLocation">Your Location: </label>
      <span id="localArea">{localArea}</span>
    </div>
    <div className="autocomplete-container"> {/* Apply autocomplete-container class */}
      <Autocomplete
        apiKey='YOUR_API_KEY_HERE'
        onPlaceSelected={handlePlaceSelected}
        options={{
          types: ['geocode', 'establishment'],
          componentRestrictions: { country: 'uk' },
        }}
        defaultValue={localArea}
        placeholder="Type your location in here"
        className="autocomplete-input" // This class should be changed to match the CSS rules for text inputs
      />
    </div>
    {errorMessage && <div style={{color: 'red'}}>{errorMessage}</div>}
  </div>
);
}
export default NearestLocationForm;
  // import React, { useState, useEffect } from "react";
// import Autocomplete from 'react-google-autocomplete';
// import boroughMapping from "./postCodeMappings.json";
// import { useAuthContext } from "../../hooks/useAuthContext";
// import "../Profile&Settings/ProfileAll.css";
// import "./NearestLocationField.css";
// import { initializeApp, getApps } from "firebase/app";
// import firebase from "../../Firebase";
// import Cookies from "js-cookie";

// if (!getApps().length) {
//   initializeApp(firebase);
// }

// const NearestLocationForm = () => {
//   const [localArea, setLocalArea] = useState('');
//   const [showManualEntry, setShowManualEntry] = useState(false);
//   const [errorMessage, setErrorMessage] = useState('');
//   const { user } = useAuthContext();
//   const [json, setJson] = useState(null);
//   const [formData, setFormData] = useState({ user: {}, ProfileBasics: {} });
//   const [coordinates, setCoordinates] = useState(null);
//   const [csrfToken, setCsrfToken] = useState(null);
//   const [token, setToken] = useState(null);

//   useEffect(() => {
//     console.log("Fetching CSRF token...");
//     const fetchCsrfToken = async () => {
//       const csrfToken = Cookies.get('mytoken');
//       console.log("CSRF Token fetched:", csrfToken);
//       setCsrfToken(csrfToken);
//     };

//     fetchCsrfToken();
//   }, []);

//   useEffect(() => {
//     console.log("Fetching Firebase auth token...");
//     const fetchToken = async () => {
//       const token = await firebase.auth().currentUser.getIdToken(true);
//       console.log("Firebase auth token fetched:", token);
//       setToken(token);
//     };

//     fetchToken();
//   }, []);

//   useEffect(() => {
//     console.log("Fetching profile information...");
//     if (user) {
//       const fetchProfile = async () => {
//         try {
//           console.log("Sending request to fetch user profile...");
//           const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/userProfile/`, {
//             method: 'POST',
//             body: JSON.stringify({ _id: user.userId }),
//             headers: {
//               'Content-Type': 'application/json',
//               'Authorization': `Bearer ${token}`,
//               'X-CSRF-Token': csrfToken,
//             }
//           });

//           if (!response.ok) {
//             throw new Error(`HTTP error! status: ${response.status}`);
//           }

//           const json = await response.json();
//           console.log("User profile fetched successfully:", json);
//           setJson(json);
//           setLocalArea(json.user.localArea);
//           setCoordinates(json.user.coordinates);
//         } catch (error) {
//           console.error('An error occurred while fetching the profile:', error);
//         }
//       };

//       fetchProfile();
//     } else {
//       console.log("User not logged in, skipping profile fetch.");
//     }
//   }, [user, token, csrfToken]);

//   useEffect(() => {
//     console.log("Fetching both Firebase auth token and CSRF token...");
//     const fetchTokenAndCsrf = async () => {
//       const token = await firebase.auth().currentUser.getIdToken(true);
//       const csrfToken = Cookies.get("mytoken");
//       console.log("Firebase auth token and CSRF token fetched:", token, csrfToken);
//       setToken(token);
//       setCsrfToken(csrfToken);
//     };
  
//     fetchTokenAndCsrf();
//   }, []);
  
  
//   useEffect(() => {
//     console.log('local area:', localArea);
//   }, [localArea, token, csrfToken]); // Added token and csrfToken as dependencies
  
//   const handlePlaceSelected = async (place) => {
//     console.log('Place selected:', place); // Verify that this is being called
//     if (!place || !place.formatted_address) {
//       console.error('Location Not Found');
//       setErrorMessage('Location Not Found');
//       setShowManualEntry(false);
//       return;
//     }
    
//     const localArea = place.formatted_address; // Simplify for demonstration
//     const coordinates = place.geometry ? { lat: place.geometry.location.lat(), lng: place.geometry.location.lng() } : null;
    
//     setLocalArea(localArea); // Update localArea state
//     setCoordinates(coordinates); // Update coordinates state if available
    
//     try {
//       await updateBackend(localArea, coordinates); // Ensure updateBackend is awaited
//     } catch (error) {
//       console.error('Error updating backend:', error);
//       setErrorMessage('Failed to update location');
//     }
//   };

//   const updateBackend = async (localArea, coordinates) => {
//   console.log('Updating backend with local area:', localArea)
//   const url = `${process.env.REACT_APP_API_BASE_URL}/userProfile/updateProfile`; // Use the server URL
  
//   if (user && typeof localArea === 'string' && token && csrfToken) { // Check if token and csrfToken are available
//     console.log('Updating UserBasics Profile info:', user.userId);
  
//     const requestBody = {
//       userId: user.userId,
//       UserBasics: {
//         localArea: localArea,
//         coordinates: coordinates // Add coordinates to the request body
//       }
//     };
  
//     console.log('Sending POST request with body:', requestBody);
  
//     const response = await fetch(url, {
//       method: 'POST',
//       body: JSON.stringify(requestBody),
//       headers: {
//         'Content-Type': 'application/json',
//         'Authorization': `Bearer ${token}`, // Include the JWT here
//         'X-CSRF-Token': csrfToken // Include CSRF token here
//       }
//     });
//     if (!response.ok) {
//       throw new Error(`HTTP error! status: ${response.status}`);
//     }
//     const json = await response.json();
//     console.log('Response:', json);
//   }
// };
  
//   return (
//     <div className="location-form"> {/* Apply both location-form and dynamic-form classes */}
//       <div style={{ marginBottom: '10px' }}>
//         <label htmlFor="userLocation">Your Location: </label>
//         <p>Type your location and select from the dropdown</p>
//         {/* <span id="localArea">{localArea}</span> */}
//       </div>
//       <div className="autocomplete-container"> {/* Apply autocomplete-container class */}
//         <Autocomplete
//           onPlaceSelected={handlePlaceSelected}
//           options={{
//             types: ['geocode', 'establishment'],
//             componentRestrictions: { country: 'uk' },
//           }}
//           value={localArea} // Bind the value to localArea state
//           onChange={(e) => setLocalArea(e.target.value)} // Update localArea state on change
//           placeholder="Enter your postcode or local area "
//           className="autocomplete-input" // This class should be changed to match the CSS rules for text inputs
//         />
//       </div>
//       {errorMessage && <div style={{color: 'red'}}>{errorMessage}</div>}
//     </div>
//   );
// }
// export default NearestLocationForm;

// /* global google */
// import React, { useState, useEffect } from 'react';
// import Autocomplete from 'react-google-autocomplete';
// import boroughMapping from "./postCodeMappings.json";
// import { useAuthContext } from '../../hooks/useAuthContext';
// import "../Profile&Settings/ProfileAll.css"
// import "./NearestLocationField.css";
// import { initializeApp, getApps } from 'firebase/app';
// import { getAuth } from 'firebase/auth';
// import firebase from '../../Firebase';
// import Cookies from 'js-cookie';

// if (!getApps().length) {
//   initializeApp(firebase);
// } 

//   const getNeighborhoodInfo = (address, callback) => {
//     console.log('getNeighborhoodInfo called with address:', address);
//     const geocoder = new google.maps.Geocoder();

//     geocoder.geocode({ 'address': address }, (results, status) => {
//       console.log('Geocoder response status:', status);
//       if (status === 'OK') {
//         console.log('Geocoder results:', results);
//         const addressComponents = results[0].address_components;
//         let localAreaInfo = null;

//         // Check if the entered address matches any area name in the boroughMapping
//         const areaNameMatch = Object.values(boroughMapping).find(areaName => address.includes(areaName));
//         if (areaNameMatch) {
//           console.log('Area name match found:', areaNameMatch);
//           callback(areaNameMatch);
//           return;
//         }

//         // Attempt to find the borough using the postcode
//         const postcodeComponent = addressComponents.find(component =>
//           component.types.includes('postal_code')
//         );
//         if (postcodeComponent) {
//           const postcode = postcodeComponent.long_name.split(' ')[0]; // Get the first part of the postcode
//           localAreaInfo = boroughMapping[postcode];
//         }

//         // If we found local area information, call the callback with it
//         if (localAreaInfo) {
//           console.log('Local area info found:', localAreaInfo);
//           callback(localAreaInfo);
//         } else {
//           // Fallback to borough if postcode mapping is not found
//           const boroughComponent = addressComponents.find(component =>
//             component.types.includes('postal_town') || component.types.includes('locality')
//           );
//           if (boroughComponent) {
//             localAreaInfo = boroughComponent.long_name;
//           }

//           if (localAreaInfo) {
//             console.log('Borough found:', localAreaInfo);
//             callback(localAreaInfo);
//           } else {
//             console.log('No borough info found in the address components.');
//             callback('Area not found');
//           }
//         }
//       } else {
//         console.log('Geocoder failed to find the address.');
//         callback('Area not found');
//       }
//     });
//   };


//   const NearestLocationForm = () => {
//     const [localArea, setLocalArea] = useState('');
//     const [showManualEntry, setShowManualEntry] = useState(false);
//     const [errorMessage, setErrorMessage] = useState('');
//     const { user } = useAuthContext();
//     const [json, setJson] = useState(null);
//     const [formData, setFormData] = useState({ user: {}, ProfileBasics: {} });
//     const [coordinates, setCoordinates] = useState(null);
//     const [csrfToken, setCsrfToken] = useState(null);
//     const [token, setToken] = useState(null);
  
//     useEffect(() => {
//       const fetchCsrfToken = async () => {
//         const csrfToken = Cookies.get('mytoken');
//         setCsrfToken(csrfToken);
//       };
  
//       fetchCsrfToken();
//     }, []);
  
//     useEffect(() => {
//       const fetchToken = async () => {
//         const token = await firebase.auth().currentUser.getIdToken(true);
//         setToken(token);
//       };
  
//       fetchToken();
//     }, []);



//   useEffect(() => {
//     if (localArea) {
//       updateBackend(formData, user);
//     }
//   }, [localArea, user, formData]);

//   useEffect(() => {
//     if (user) {
//       console.log('fetching location')
//       const fetchProfile = async () => {
//         try {
//           const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/userProfile/`, {
//             method: 'POST',
//             body: JSON.stringify({ _id: user.userId }),
//             headers: {
//               'Content-Type': 'application/json',
//               'Authorization': `Bearer ${token}`, // Include the JWT here
//               'X-CSRF-Token': csrfToken // Include the CSRF token here
//             }
//           });
  
//           if (!response.ok) {
//             throw new Error(`HTTP error! status: ${response.status}`);
//           }
  
//           const json = await response.json();
//           setJson(json);
//           setLocalArea(json.user.localArea)
//           setCoordinates(json.user.coordinates)
//           console.log('json location!:', json);
//           console.log('use effect location fetch response1', response)
//         } catch (error) {
//           console.error('An error occurred while fetching the profile:', error);
//         }
//       };
  
//       fetchProfile();
//     }
//   }, [user, token, csrfToken]); // Include token and csrfToken in the dependency array
  
//   useEffect(() => {
//     console.log('local area:', localArea);
//   }, [localArea]);


// useEffect(() => {
//   console.log('local area:', localArea);
// }, [localArea]);

// const updateBackend = async (formData, user) => {
//   console.log('Updating backend with formData:', formData);
//   const url = `${process.env.REACT_APP_API_BASE_URL}/userProfile/updateProfile`;

//   if (user && formData) {
//     console.log('Updating UserBasics Profile info:', user.userId);

//     // Ensure the JWT token is fresh and valid
//     const freshToken = await firebase.auth().currentUser.getIdToken(true);
//     setToken(freshToken); // Update the state with the fresh token

//     // Ensure the CSRF token is present and valid
//     const freshCsrfToken = Cookies.get('mytoken'); // Potentially refresh or re-validate the CSRF token
//     setCsrfToken(freshCsrfToken); // Update the state with the fresh CSRF token

//     const requestBody = {
//       userId: user.userId,
//       UserBasics: formData // Use formData directly
//     };

//     console.log('Sending POST request with body:', requestBody);

//     const response = await fetch(url, {
//       method: 'POST',
//       body: JSON.stringify(requestBody),
//       headers: {
//         'Content-Type': 'application/json',
//         'Authorization': `Bearer ${freshToken}`, // Use the fresh JWT token
//         'X-CSRF-Token': freshCsrfToken // Use the fresh CSRF token
//       }
//     });

//     if (!response.ok) {
//       // Handle different error statuses here, potentially refreshing tokens or redirecting to login
//       throw new Error(`HTTP error! status: ${response.status}`);
//     }

//     const json = await response.json();
//     console.log('Response:', json);
//   }
// };

// // Adjust the useEffect hook that updates the backend when localArea changes
// useEffect(() => {
//   if (localArea && coordinates) {
//     const formData = {
//       localArea: localArea,
//       coordinates: coordinates
//     };
//     updateBackend(formData, user); // Pass formData and user to updateBackend
//   }
// }, [localArea, coordinates, user]); // 


//   // In handlePlaceSelected and getCurrentLocation, adjust how updateBackend is called
// // Example adjustment in handlePlaceSelected:
// const handlePlaceSelected = (place) => {
//   if (!place || !place.formatted_address) {
//     console.error('Location Not Found');
//     setErrorMessage('Location Not Found');
//     setShowManualEntry(false);
//     return;
//   }
//   console.log('Place selected:', place);
//   const address = place.formatted_address;
//   console.log('Formatted address:', address);
//   getNeighborhoodInfo(address, (borough) => {
//     console.log('borough!!', borough)
//     setLocalArea(borough);
//     const coordinates = [place.geometry.location.lng(), place.geometry.location.lat()];
//     setCoordinates(coordinates); // Set coordinates state
//     const formData = { localArea: borough, coordinates: coordinates };
//     updateBackend(formData, user); // Adjusted call to updateBackend
//     setShowManualEntry(false);
//     setErrorMessage('');
//   });
// };
  
//   const getCurrentLocation = () => {
//     if (navigator.geolocation) {
//       navigator.geolocation.getCurrentPosition((position) => {
//         const coords = { lat: position.coords.latitude, lng: position.coords.longitude };
//         setCoordinates([coords.lng, coords.lat]); // Set coordinates state
//         const geocoder = new google.maps.Geocoder();
  
//         geocoder.geocode({ 'location': coords }, (results, status) => {
//           if (status === 'OK') {
//             const address = results[0].formatted_address;
//             getNeighborhoodInfo(address, (borough) => {
//               updateBackend(borough, [coords.lng, coords.lat]); // Call updateBackend with coordinates
//             });
//           } else {
//             console.error('Geocoder failed due to: ' + status);
//           }
//         });
//       }, (error) => {
//         console.error('Geolocation error: ' + error.message);
//       });
//     } else {
//       console.error('Geolocation is not supported by this browser.');
//     }
//   };

//   const handleFindLocationClick = () => {
//     getCurrentLocation();
//   };

  

//   return (
//     <div className="location-form"> {/* Apply both location-form and dynamic-form classes */}
//       <div style={{ marginBottom: '10px' }}>
//         <label htmlFor="userLocation">Your Location: </label>
//         <span id="localArea">{localArea}</span>
//       </div>
//       <div className="autocomplete-container"> {/* Apply autocomplete-container class */}
//         <Autocomplete
//           apiKey='YOUR_API_KEY_HERE'
//           onPlaceSelected={handlePlaceSelected}
//           options={{
//             types: ['geocode', 'establishment'],
//             componentRestrictions: { country: 'uk' },
//           }}
//           defaultValue={localArea}
//           placeholder="Enter your postcode or local area "
//           className="autocomplete-input" // This class should be changed to match the CSS rules for text inputs
//         />
//         <button className="button" onClick={handleFindLocationClick}>Use My Location</button>
//       </div>
//       {errorMessage && <div style={{color: 'red'}}>{errorMessage}</div>}
//     </div>
//   );
// }
//   export default NearestLocationForm;

// /* global google */
// import React, { useState } from 'react';
// import Autocomplete from 'react-google-autocomplete';
// import boroughMapping from "./postCodeMappings.json";
// import "../Profile&Settings/ProfileAll.css"
// import "./NearestLocationField.css";


// const getNeighborhoodInfo = (address, callback) => {
//   console.log('getNeighborhoodInfo called with address:', address);
//   const geocoder = new google.maps.Geocoder();

//   geocoder.geocode({ 'address': address }, (results, status) => {
//     console.log('Geocoder response status:', status);
//     if (status === 'OK') {
//       console.log('Geocoder results:', results);
//       const addressComponents = results[0].address_components;
//       let localAreaInfo = null;

//       // Check if the entered address matches any area name in the boroughMapping
//       const areaNameMatch = Object.values(boroughMapping).find(areaName => address.includes(areaName));
//       if (areaNameMatch) {
//         console.log('Area name match found:', areaNameMatch);
//         callback(areaNameMatch);
//         return;
//       }

//       // Attempt to find the borough using the postcode
//       const postcodeComponent = addressComponents.find(component =>
//         component.types.includes('postal_code')
//       );
//       if (postcodeComponent) {
//         const postcode = postcodeComponent.long_name.split(' ')[0]; // Get the first part of the postcode
//         localAreaInfo = boroughMapping[postcode];
//       }

//       // If we found local area information, call the callback with it
//       if (localAreaInfo) {
//         console.log('Local area info found:', localAreaInfo);
//         callback(localAreaInfo);
//       } else {
//         // Fallback to borough if postcode mapping is not found
//         const boroughComponent = addressComponents.find(component =>
//           component.types.includes('postal_town') || component.types.includes('locality')
//         );
//         if (boroughComponent) {
//           localAreaInfo = boroughComponent.long_name;
//         }

//         if (localAreaInfo) {
//           console.log('Borough found:', localAreaInfo);
//           callback(localAreaInfo);
//         } else {
//           console.log('No borough info found in the address components.');
//           callback('Area not found');
//         }
//       }
//     } else {
//       console.log('Geocoder failed to find the address.');
//       callback('Area not found');
//     }
//   });
// };

// const NearestLocationForm = () => {
//   const [localArea, setLocalArea] = useState('');
//   const [showManualEntry, setShowManualEntry] = useState(false);
//   const [errorMessage, setErrorMessage] = useState(''); // State to hold error messages

//   const handlePlaceSelected = (place) => {
//     if (!place || !place.formatted_address) {
//       console.error('Location Not Found');
//       setErrorMessage('Location Not Found'); // Update the state to show the error message
//       setShowManualEntry(false); // Optionally, hide the manual entry field
//       return; // Exit the function to prevent further execution
//     }
//     console.log('Place selected:', place);
//     const address = place.formatted_address;
//     console.log('Formatted address:', address);
//     getNeighborhoodInfo(address, (borough) => {
//       setLocalArea(borough);
//       setShowManualEntry(false); // Hide the manual entry field once the localArea state is updated
//       setErrorMessage(''); // Clear any previous error messages
//     });
//   };

//   const getCurrentLocation = () => {
//     if (navigator.geolocation) {
//       navigator.geolocation.getCurrentPosition((position) => {
//         const coords = { lat: position.coords.latitude, lng: position.coords.longitude };
//         const geocoder = new google.maps.Geocoder();

//         geocoder.geocode({ 'location': coords }, (results, status) => {
//           if (status === 'OK') {
//             const address = results[0].formatted_address;
//             getNeighborhoodInfo(address, (borough) => {
//               setLocalArea(borough);
//               setShowManualEntry(false); // Also hide the manual entry field when using location services
//             });
//           } else {
//             console.error('Geocoder failed due to: ' + status);
//           }
//         });
//       }, (error) => {
//         console.error('Geolocation error: ' + error.message);
//       });
//     } else {
//       console.error('Geolocation is not supported by this browser.');
//     }
//   };

//   const handleFindLocationClick = () => {
//     getCurrentLocation();
//   };

//   const handleManualEntryClick = () => {
//     setShowManualEntry(true); // Show the manual entry field when the button is clicked
//   };

//   return (
//     <div className="location-form"> {/* Apply location-form class */}
//       <div style={{ marginBottom: '10px' }}>
//         <h2>Your Location: </h2>
//         <span id="localArea">{localArea}</span>
//       </div>
//         <div className="button-container">
//       <button className="button" onClick={handleFindLocationClick}>Use My Location</button>
//       <button className="buttonSecondary" onClick={handleManualEntryClick}>Manually Set My Location</button>
//         </div>
//       {showManualEntry && (
//         <div className="autocomplete-container"> {/* Apply autocomplete-container class */}
//           <Autocomplete
//             apiKey='YOUR_API_KEY_HERE'
//             onPlaceSelected={handlePlaceSelected}
//             options={{
//               types: ['geocode', 'establishment'],
//               componentRestrictions: { country: 'uk' },
//             }}
//             placeholder="Enter your postcode or address"
//             className="autocomplete-input" // Apply autocomplete-input class
//           />
//         </div>
//       )}
//       {errorMessage && <div style={{color: 'red'}}>{errorMessage}</div>}
//     </div>
//   );
// };

// export default NearestLocationForm;











//** GOOD BUT DOESN"T WORK IF USER TYPES IN NAME OF LOCATION BUT ADDRESS WORKS e.g., cazenove road will return stoke newington, but stoke newington will not return stoke newginton  */
// /* global google */
// import React, { useState } from 'react';
// import Autocomplete from 'react-google-autocomplete';
// // import "./NearestLocationField.css";
// import boroughMapping from "./postCodeMappings.json";



// const getNeighborhoodInfo = (address, callback) => {
//   console.log('getNeighborhoodInfo called with address:', address);
//   const geocoder = new google.maps.Geocoder();

//   geocoder.geocode({ 'address': address }, (results, status) => {
//     console.log('Geocoder response status:', status);
//     if (status === 'OK') {
//       console.log('Geocoder results:', results);
//       const addressComponents = results[0].address_components;
//       let localAreaInfo = null;

//       // Attempt to find the borough using the postcode
//       const postcodeComponent = addressComponents.find(component =>
//         component.types.includes('postal_code')
//       );
//       if (postcodeComponent) {
//         const postcode = postcodeComponent.long_name.split(' ')[0]; // Get the first part of the postcode
//         localAreaInfo = boroughMapping[postcode];
//       }

//       // If we found local area information, call the callback with it
//       if (localAreaInfo) {
//         console.log('Local area info found:', localAreaInfo);
//         callback(localAreaInfo);
//       } else {
//         // Fallback to borough if postcode mapping is not found
//         const boroughComponent = addressComponents.find(component =>
//           component.types.includes('postal_town') || component.types.includes('locality')
//         );
//         if (boroughComponent) {
//           localAreaInfo = boroughComponent.long_name;
//         }

//         if (localAreaInfo) {
//           console.log('Borough found:', localAreaInfo);
//           callback(localAreaInfo);
//         } else {
//           console.log('No borough info found in the address components.');
//           callback('Area not found');
//         }
//       }
//     } else {
//       console.log('Geocoder failed to find the address.');
//       callback('Area not found');
//     }
//   });
// };

// const NearestLocationForm = () => {
//   const [localArea, setLocalArea] = useState('');
//   const [showManualEntry, setShowManualEntry] = useState(false); // State to control visibility of the manual entry field

//   const handlePlaceSelected = (place) => {
//     console.log('Place selected:', place);
//     const address = place.formatted_address;
//     console.log('Formatted address:', address);
//     getNeighborhoodInfo(address, (borough) => {
//       setLocalArea(borough);
//       setShowManualEntry(false); // Hide the manual entry field once the localArea state is updated
//     });
//   };

//   const getCurrentLocation = () => {
//     if (navigator.geolocation) {
//       navigator.geolocation.getCurrentPosition((position) => {
//         const coords = { lat: position.coords.latitude, lng: position.coords.longitude };
//         const geocoder = new google.maps.Geocoder();

//         geocoder.geocode({ 'location': coords }, (results, status) => {
//           if (status === 'OK') {
//             const address = results[0].formatted_address;
//             getNeighborhoodInfo(address, (borough) => {
//               setLocalArea(borough);
//               setShowManualEntry(false); // Also hide the manual entry field when using location services
//             });
//           } else {
//             console.error('Geocoder failed due to: ' + status);
//           }
//         });
//       }, (error) => {
//         console.error('Geolocation error: ' + error.message);
//       });
//     } else {
//       console.error('Geolocation is not supported by this browser.');
//     }
//   };

//   const handleFindLocationClick = () => {
//     getCurrentLocation();
//   };

//   const handleManualEntryClick = () => {
//     setShowManualEntry(true); // Show the manual entry field when the button is clicked
//   };

//   return (
//     <div>
//       <div style={{ marginBottom: '10px' }}>
//         <strong>Your Location: </strong>
//         <span id="localArea">{localArea}</span>
//       </div>
//       <button onClick={handleFindLocationClick}>Use My Location</button>
//       <button onClick={handleManualEntryClick}>Manually Set My Location</button>
//       {showManualEntry && (
//         <Autocomplete
//           apiKey='YOUR_API_KEY_HERE' // Replace with your actual API key
//           onPlaceSelected={handlePlaceSelected}
//           options={{
//             types: ['geocode', 'establishment'],
//             componentRestrictions: { country: 'uk' },
//           }}
//           placeholder="Enter your postcode or address"
//         />
//       )}
//     </div>
//   );
// };

// export default NearestLocationForm;

//*** WORKING NICELY BUT NOT LONDON POSTCODE SPECIFIC *** 

// /* global google */
// import React, { useState } from 'react';
// import Autocomplete from 'react-google-autocomplete';

// const getNeighborhoodInfo = (address, callback) => {
//   console.log('getNeighborhoodInfo called with address:', address);
//   const geocoder = new google.maps.Geocoder();

//   geocoder.geocode({ 'address': address }, (results, status) => {
//     console.log('Geocoder response status:', status);
//     if (status === 'OK') {
//       console.log('Geocoder results:', results);
//       const addressComponents = results[0].address_components;
//       console.log('Address Components:', addressComponents);

//       let borough = null;

//       // Attempt to find the borough in the address components
//       const boroughComponent = addressComponents.find(component =>
//         component.types.includes('postal_town') || component.types.includes('locality')
//       );
//       if (boroughComponent) {
//         borough = boroughComponent.long_name;
//       }

//       // If we found borough information, call the callback with it
//       if (borough) {
//         console.log('Borough found:', borough);
//         callback(borough);
//       } else {
//         console.log('No borough info found in the address components.');
//         callback('Borough not found');
//       }
//     } else {
//       console.log('Geocoder failed to find the address.');
//       callback('Borough not found');
//     }
//   });
// };

// const NearestLocationForm = () => {
//   const [localArea, setLocalArea] = useState('');
//   const [showManualEntry, setShowManualEntry] = useState(false); // State to control visibility of the manual entry field

//   const handlePlaceSelected = (place) => {
//     console.log('Place selected:', place);
//     const address = place.formatted_address;
//     console.log('Formatted address:', address);
//     getNeighborhoodInfo(address, (borough) => {
//       setLocalArea(borough);
//       setShowManualEntry(false); // Hide the manual entry field once the localArea state is updated
//     });
//   };

//   const getCurrentLocation = () => {
//     if (navigator.geolocation) {
//       navigator.geolocation.getCurrentPosition((position) => {
//         const coords = { lat: position.coords.latitude, lng: position.coords.longitude };
//         const geocoder = new google.maps.Geocoder();

//         geocoder.geocode({ 'location': coords }, (results, status) => {
//           if (status === 'OK') {
//             const address = results[0].formatted_address;
//             getNeighborhoodInfo(address, (borough) => {
//               setLocalArea(borough);
//               setShowManualEntry(false); // Also hide the manual entry field when using location services
//             });
//           } else {
//             console.error('Geocoder failed due to: ' + status);
//           }
//         });
//       }, (error) => {
//         console.error('Geolocation error: ' + error.message);
//       });
//     } else {
//       console.error('Geolocation is not supported by this browser.');
//     }
//   };

//   const handleFindLocationClick = () => {
//     getCurrentLocation();
//   };

//   const handleManualEntryClick = () => {
//     setShowManualEntry(true); // Show the manual entry field when the button is clicked
//   };

//   return (
//     <div>
//       <div style={{ marginBottom: '10px' }}>
//         <strong>Your Location: </strong>
//         <span id="localArea">{localArea}</span>
//       </div>
//       <button onClick={handleFindLocationClick}>Use My Location</button>
//       <button onClick={handleManualEntryClick}>Manually Set My Location</button>
//       {showManualEntry && (
//         <Autocomplete
//           apiKey='YOUR_API_KEY_HERE' // Replace with your actual API key
//           onPlaceSelected={handlePlaceSelected}
//           options={{
//             types: ['geocode', 'establishment'],
//             componentRestrictions: { country: 'uk' },
//           }}
//           placeholder="Enter your postcode or address"
//         />
//       )}
//     </div>
//   );
// };

// export default NearestLocationForm;
// /* global google */
// import React, { useState, useEffect } from 'react';
// import Autocomplete from 'react-google-autocomplete';
// import "./NearestLocationField.css";

// const getNeighborhoodInfo = (address, callback) => {
//   console.log('getNeighborhoodInfo called with address:', address);
//   const geocoder = new google.maps.Geocoder();

//   geocoder.geocode({ 'address': address }, (results, status) => {
//     console.log('Geocoder response status:', status);
//     if (status === 'OK') {
//       console.log('Geocoder results:', results);
//       const addressComponents = results[0].address_components;
//       console.log('Address Components:', addressComponents);

//       let borough = null;

//       const boroughComponent = addressComponents.find(component =>
//         component.types.includes('postal_town') || component.types.includes('locality')
//       );
//       if (boroughComponent) {
//         borough = boroughComponent.long_name;
//       }

//       if (borough) {
//         console.log('Borough found:', borough);
//         callback(borough);
//       } else {
//         console.log('No borough info found in the address components.');
//         callback('Borough not found');
//       }
//     } else {
//       console.log('Geocoder failed to find the address.');
//       callback('Borough not found');
//     }
//   });
// };

// const LocationForm = () => {
//   const [localArea, setLocalArea] = useState('');

//   useEffect(() => {
//     console.log('local area state updated:', localArea);
//   }, [localArea]);

//   const handlePlaceSelected = (place) => {
//     console.log('Place selected:', place);
//     const address = place.formatted_address;
//     console.log('Formatted address:', address);
//     getNeighborhoodInfo(address, setLocalArea);
//   };

//   const getCurrentLocation = () => {
//     if (navigator.geolocation) {
//       navigator.geolocation.getCurrentPosition((position) => {
//         const coords = { lat: position.coords.latitude, lng: position.coords.longitude };
//         const geocoder = new google.maps.Geocoder();

//         geocoder.geocode({ 'location': coords }, (results, status) => {
//           if (status === 'OK') {
//             const address = results[0].formatted_address;
//             getNeighborhoodInfo(address, setLocalArea);
//           } else {
//             console.error('Geocoder failed due to: ' + status);
//           }
//         });
//       }, (error) => {
//         console.error('Geolocation error: ' + error.message);
//       });
//     } else {
//       console.error('Geolocation is not supported by this browser.');
//     }
//   };

//   useEffect(() => {
//     getCurrentLocation();
//   }, []);

//   return (
//     <div className="location-form">
//       <div style={{ marginBottom: '10px' }}>
//         <h2>Your Location: </h2>
//         <span id="localArea" className="local-area">{localArea}</span>
//       </div>
//       <Autocomplete
//         onPlaceSelected={handlePlaceSelected}
//         options={{
//           types: ['geocode', 'establishment'],
//           componentRestrictions: { country: 'uk' },
//         }}
//         placeholder="Enter your postcode or address"
//         className="autocomplete-input" // Assuming you can pass a className prop to Autocomplete
//       />
//     </div>
//   );
// };

// export default LocationForm;

// /* global google */
// import React, { useState, useEffect } from 'react';
// import Autocomplete from 'react-google-autocomplete';

// const getNeighborhoodInfo = (address, callback) => {
//   console.log('getNeighborhoodInfo called with address:', address);
//   const geocoder = new google.maps.Geocoder();

//   geocoder.geocode({ 'address': address }, (results, status) => {
//     console.log('Geocoder response status:', status);
//     if (status === 'OK') {
//       console.log('Geocoder results:', results);
//       const addressComponents = results[0].address_components;
//       let borough = null;

//       // Attempt to find the borough in the address components
//       const boroughComponent = addressComponents.find(component =>
//         component.types.includes('administrative_area_level_3') || component.types.includes('locality')
//       );
//       if (boroughComponent) {
//         borough = boroughComponent.long_name;
//       }

//       // If we found borough information, call the callback with it
//       if (borough) {
//         console.log('Borough found:', borough);
//         callback(borough);
//       } else {
//         console.log('No borough info found in the address components.');
//         callback('Borough not found');
//       }
//     } else {
//       console.log('Geocoder failed to find the address.');
//       callback('Borough not found');
//     }
//   });
// };

// const NearestLocationForm = () => {
//   const [localArea, setLocalArea] = useState('');

//   useEffect(() => {
//     console.log('local area state updated:', localArea);
//   }, [localArea]);

//   const handlePlaceSelected = (place) => {
//     console.log('Place selected:', place);
//     const address = place.formatted_address;
//     console.log('Formatted address:', address);
//     // Call getNeighborhoodInfo with the address and a callback function to update the state
//     getNeighborhoodInfo(address, setLocalArea);
//   };

//   return (
//     <div>
//       <div style={{ marginBottom: '10px' }}>
//         <strong>Area for Your Profile: </strong>
//         <span id="localArea">{localArea}</span>
//       </div>
//       <Autocomplete
//         apiKey='AIzaSyA1rwNvmOCqoPpdoWthDJw_GHBOgefqQ84'
//         onPlaceSelected={handlePlaceSelected}
//         options={{
//           types: ['geocode', 'establishment'],
//           componentRestrictions: { country: 'uk' },
//         }}
//         placeholder="Enter your postcode or address"
//       />
//     </div>
//   );
// };

// export default NearestLocationForm;

// /* global google */
// import React, { useState, useEffect } from 'react';
// import Autocomplete from 'react-google-autocomplete';

// const NearestLocationForm = () => {
//   const [localArea, setLocalArea] = useState('');

//   useEffect(() => {
//     console.log('local area state updated:', localArea);
//   }, [localArea]);

//   const handlePlaceSelected = (place) => {
//     console.log('Place selected:', place);
//     // Assuming you want to use the neighborhood, locality, or other similar area type
//     const areaComponent = place.address_components.find(component =>
//       component.types.includes('neighborhood') ||
//       component.types.includes('locality') ||
//       component.types.includes('sublocality') ||
//       component.types.includes('administrative_area_level_2') ||
//       component.types.includes('administrative_area_level_1') ||
//       component.types.includes('administrative_area_level_3')
//     );
//     if (areaComponent) {
//       setLocalArea(areaComponent.long_name);
//     } else {
//       setLocalArea('Area not found');
//     }
//   };

//   return (
//     <div>
//       <div style={{ marginBottom: '10px' }}>
//         <strong>Area for Your Profile: </strong>
//         <span id="localArea">{localArea}</span>
//       </div>
//       <Autocomplete apiKey='AIzaSyA1rwNvmOCqoPpdoWthDJw_GHBOgefqQ84'

//         onPlaceSelected={handlePlaceSelected}
//         options={{
//           types: ['(regions)'],
//           componentRestrictions: { country: 'uk' },
//         }}
//         placeholder="Enter your area"
//       />
//     </div>
//   );
// };

// export default NearestLocationForm;




// /* global google */
// import React, { useState, useEffect } from 'react';
// import Autocomplete from 'react-google-autocomplete';

// // Define the getNeighborhoodInfo function outside the component
// const getNeighborhoodInfo = (address, callback) => {
//   console.log('getNeighborhoodInfo called with address:', address);
//   const geocoder = new google.maps.Geocoder();

//   geocoder.geocode({ 'address': address }, (results, status) => {
//     console.log('Geocoder response status:', status);
//     if (status === 'OK') {
//       console.log('Geocoder results:', results);
//       const position = results[0].geometry.location;
//       const placesService = new google.maps.places.PlacesService(document.createElement('div'));

//       placesService.nearbySearch({
//         location: position,
//         radius: 50, // Search within 500 meters of the location
//         type: ['neighborhood']
//       }, (placesResults, placesStatus) => {
//         console.log('PlacesService response status:', placesStatus);
//         if (placesStatus === google.maps.places.PlacesServiceStatus.OK) {
//           console.log('PlacesService results:', placesResults);
//           const neighborhoodInfo = placesResults.find(placeResult =>
//             placeResult.types.includes('neighborhood')
//           );

//           if (neighborhoodInfo) {
//             console.log('Neighborhood info found:', neighborhoodInfo.name);
//             callback(neighborhoodInfo.name); // Use the callback to update the state
//           } else {
//             console.log('No neighborhood info found in the results.');
//           }
//         } else {
//           console.log('PlacesService failed to find neighborhood info.');
//         }
//       });
//     } else {
//       console.log('Geocoder failed to find the address.');
//     }
//   });
// };

// const NearestLocationForm = () => {
//   const [localArea, setLocalArea] = useState('');

//   useEffect(() => {
//     console.log('local area state updated:', localArea);
//   }, [localArea]);

//   const handlePlaceSelected = (place) => {
//     console.log('Place selected:', place);
//     const address = place.formatted_address;
//     console.log('Formatted address:', address);
//     // Call getNeighborhoodInfo with the address and a callback function to update the state
//     getNeighborhoodInfo(address, setLocalArea);
//   };

//   return (
//     <div>
//       <div style={{ marginBottom: '10px' }}>
//         <strong>Area for Your Profile: </strong>
//         <span id="localArea">{localArea}</span>
//       </div>
//       <Autocomplete apiKey='AIzaSyA1rwNvmOCqoPpdoWthDJw_GHBOgefqQ84'
//         onPlaceSelected={handlePlaceSelected}
//         options={{
//           types: ['geocode', 'establishment'],
//           componentRestrictions: { country: 'uk' },
//         }}
//         placeholder="Enter your postcode or address"
//       />
//     </div>
//   );
// };

// export default NearestLocationForm;

// // components/InteractiveMap.js

// import React, { useState } from 'react';
// import { MapContainer, TileLayer, GeoJSON } from 'react-leaflet';
// import 'leaflet/dist/leaflet.css';

// const NearestLocationForm = ({ areasGeoJSON, onAreaSelected }) => {
//   const [selectedArea, setSelectedArea] = useState(null);

//   const onEachFeature = (feature, layer) => {
//     layer.on({
//       click: () => {
//         setSelectedArea(feature.properties.name);
//         onAreaSelected(feature.properties.name);
//       }
//     });
//   };

//   return (
//     <MapContainer center={[51.5074, -0.1278]} zoom={10} style={{ height: '400px', width: '100%' }}>
//       <TileLayer
//         url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
//         attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
//       />
//       {areasGeoJSON && (
//         <GeoJSON
//           data={areasGeoJSON}
//           onEachFeature={onEachFeature}
//           style={{ fillColor: 'blue', weight: 1, color: 'white', fillOpacity: 0.5 }}
//         />
//       )}
//     </MapContainer>
//   );
// };

// export default NearestLocationForm;

//THIS WILL FIND LOCAL AREA BASED ON boroughMapping variable

// /* global google */
// import React, { useState, useEffect } from 'react';
// import Autocomplete from 'react-google-autocomplete';

// const boroughMapping = {
//   'N16': 'Stoke Newington',
//   // Add other postcode mappings as needed
// };

// const getNeighborhoodInfo = (address, callback) => {
//   console.log('getNeighborhoodInfo called with address:', address);
//   const geocoder = new google.maps.Geocoder();

//   geocoder.geocode({ 'address': address }, (results, status) => {
//     console.log('Geocoder response status:', status);
//     if (status === 'OK') {
//       console.log('Geocoder results:', results);
//       const addressComponents = results[0].address_components;
//       let localAreaInfo = null;

//       // Attempt to find the borough using the postcode
//       const postcodeComponent = addressComponents.find(component =>
//         component.types.includes('postal_code')
//       );
//       if (postcodeComponent) {
//         const postcode = postcodeComponent.long_name.split(' ')[0]; // Get the first part of the postcode
//         localAreaInfo = boroughMapping[postcode];
//       }

//       // If we found local area information, call the callback with it
//       if (localAreaInfo) {
//         console.log('Local area info found:', localAreaInfo);
//         callback(localAreaInfo);
//       } else {
//         console.log('No local area info found in the address components.');
//       }
//     } else {
//       console.log('Geocoder failed to find the address.');
//     }
//   });
// };
// const NearestLocationForm = () => {
//   const [localArea, setLocalArea] = useState('');

//   useEffect(() => {
//     console.log('local area state updated:', localArea);
//   }, [localArea]);

//   const handlePlaceSelected = (place) => {
//     console.log('Place selected:', place);
//     const address = place.formatted_address;
//     console.log('Formatted address:', address);
//     // Call getNeighborhoodInfo with the address and a callback function to update the state
//     getNeighborhoodInfo(address, setLocalArea);
//   };
//   return (
//     <div>
//       <div style={{ marginBottom: '10px' }}>
//         <strong>Area for Your Profile: </strong>
//         <span id="localArea">{localArea}</span>
//       </div>
//       <Autocomplete
//         apiKey='AIzaSyA1rwNvmOCqoPpdoWthDJw_GHBOgefqQ84'
//         onPlaceSelected={handlePlaceSelected}
//         options={{
//           types: ['geocode', 'establishment'],
//           componentRestrictions: { country: 'uk' },
//         }}
//         placeholder="Enter your postcode or address"
//       />
//     </div>
//   );
// };

// export default NearestLocationForm;

// import React, { useState, useEffect } from 'react';

// const NearestLocationForm = () => {
//   const [localArea, setLocalArea] = useState('');

//   useEffect(() => {
//     const script = document.createElement('script');
//     script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyA1rwNvmOCqoPpdoWthDJw_GHBOgefqQ84&libraries=places&callback=initMap`;
//     script.async = true;
//     document.body.appendChild(script);

//     window.initAutocomplete = () => {
//       const autocomplete = new window.google.maps.places.Autocomplete(
//         document.getElementById('autocomplete'),
//         { types: ['geocode', 'establishment'], componentRestrictions: { country: 'uk' } }
//       );

//       autocomplete.addListener('place_changed', () => {
//         const place = autocomplete.getPlace();
//         const neighborhoodComponent = place.address_components.find(component =>
//           component.types.includes('neighborhood')
//         );
//         if (neighborhoodComponent) {
//           setLocalArea(neighborhoodComponent.long_name);
//         } else {
//           setLocalArea('Neighborhood not found');
//         }
//       });
//     };

//     return () => {
//       document.body.removeChild(script);
//     };
//   }, []);

//   return (
//     <div>
//       <div style={{ marginBottom: '10px' }}>
//         <strong>Area for Your Profile: </strong>
//         <span id="localArea">{localArea}</span>
//       </div>
//       <input id="autocomplete" placeholder="Enter your postcode or address" type="text" />
//     </div>
//   );
// };

// export default NearestLocationForm;

// import React, { useState, useEffect } from 'react';
// import Autocomplete from 'react-google-autocomplete';

// const NearestLocationForm = () => {
//   const [localArea, setLocalArea] = useState('');

//   useEffect(() => {
//     console.log('local area:', localArea); // This will log the updated value of localArea
//   }, [localArea]); // This effect depends on localArea and runs whenever localArea changes

//   useEffect(() => {
//     const loadGoogleMapsScript = () => {
//       if (window.google) {
//         return; // Google Maps already loaded
//       }
//       const script = document.createElement('script');
//       script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyA1rwNvmOCqoPpdoWthDJw_GHBOgefqQ84&libraries=places&callback=initMap`;
//       script.async = true;
//       script.defer = true;
//       document.body.appendChild(script);
//     };

//     loadGoogleMapsScript();
//   }, []);

//   const handlePlaceSelected = (place) => {
//     console.log('handlePlaceSelected activated');
//     const localAreaComponent = place.address_components.find(component =>
//       component.types.includes('neighborhood') || component.types.includes('sublocality_level_1')
//     );
//     console.log('localAreaComponent', localAreaComponent);
//     if (localAreaComponent) {
//       setLocalArea(localAreaComponent.long_name);
//     } else {
//       const postalTownComponent = place.address_components.find(component =>
//         component.types.includes('postal_town')
//       );
//       if (postalTownComponent) {
//         setLocalArea(postalTownComponent.long_name);
//       } else {
//         setLocalArea('Area not found');
//       }
//     }
//   };

//   return (
//     <div>
//       <div style={{ marginBottom: '10px' }}>
//         <strong>Area for Your Profile: </strong>
//         <span id="localArea">{localArea}</span>
//       </div>
//       <Autocomplete 
//         onPlaceSelected={handlePlaceSelected}
//         options={{
//           types: ['geocode', 'establishment'],
//           componentRestrictions: { country: 'uk' },
//         }}
//         placeholder="Enter your postcode or address"
//       />
//     </div>
//   );
// };

// export default NearestLocationForm;

// import React, { useState, useEffect } from 'react';
// import Autocomplete from 'react-google-autocomplete';

// const NearestLocationForm = () => {
//   const [localArea, setLocalArea] = useState('');

//   useEffect(() => {
//     console.log('local area:', localArea); // This will log the updated value of localArea
//   }, [localArea]); // This effect depends on localArea and runs whenever localArea changes

//   const handlePlaceSelected = (place) => {
//     console.log('handlePlaceSelected activated');
//     // Extract the address components and find the one you consider as the local area
//     // Considering 'neighborhood' and 'sublocality_level_1' for more local specificity
//     const localAreaComponent = place.address_components.find(component =>
//       component.types.includes('neighborhood') || component.types.includes('sublocality_level_1')
//     );
//     console.log('localAreaComponent',localAreaComponent)
//     if (localAreaComponent) {
//       setLocalArea(localAreaComponent.long_name); // Setting the long_name of the component
//     } else {
//       // Fallback to postal_town if neighborhood or sublocality_level_1 is not available
//       const postalTownComponent = place.address_components.find(component =>
//         component.types.includes('postal_town')
//       );
//       console.log('place.address_components',place.address_components)

//       if (postalTownComponent) {
//         setLocalArea(postalTownComponent.long_name);
//       } else {
//         setLocalArea('Area not found');
//       }
//     }
//   };

//   return (
//     <div>
//       {/* Display the local area in a span tag if localArea is set */}
//       <div style={{ marginBottom: '10px' }}>
//         <strong>Area for Your Profile: </strong>
//         <span id="localArea">{localArea}</span>
//       </div>
//       <Autocomplete apiKey='AIzaSyA1rwNvmOCqoPpdoWthDJw_GHBOgefqQ84'
//         onPlaceSelected={handlePlaceSelected}
//         options={{
//           types: ['geocode', 'establishment'],
//           componentRestrictions: { country: 'uk' },
//         }}
//         placeholder="Enter your postcode or address"
//       />
//     </div>
//   );
// };

// export default NearestLocationForm;

// import React, { useState, useEffect } from 'react';
// import Autocomplete from 'react-google-autocomplete';

// const NearestLocationForm = () => {
//   const [localArea, setLocalArea] = useState('');

//   useEffect(() => {
//     console.log('local area:', localArea); // This will log the updated value of localArea
//   }, [localArea]); // This effect depends on localArea and runs whenever localArea changes

//   const handlePlaceSelected = (place) => {
//     // Extract the address components and find the one you consider as the local area
//     // Now also considering 'neighborhood' in the search
//     const addressComponent = place.address_components.find(component =>
//       component.types.includes('postal_town')
//     );
//     if (addressComponent) {
//       setLocalArea(addressComponent.long_name); // Ensure you're setting the long_name of the component
//     } else {
//       setLocalArea('Area not found');
//     }
//   };

//   return (
//     <div>
//       {/* Display the local area in a span tag if localArea is set */}
//       {(
//         <div style={{ marginBottom: '10px' }}>
//           <strong>Area for Your Profile: </strong>
//           <span id="localArea">{localArea}</span>
//         </div>
//       )}
//       <Autocomplete
//         onPlaceSelected={handlePlaceSelected}
//         options={{
//           types: ['geocode', 'establishment'],
//           componentRestrictions: { country: 'uk' },
//         }}
//         placeholder="Enter your postcode or address"
//       />
//     </div>
//   );
// };

// export default NearestLocationForm;


//***** */


// import React, { useState } from 'react';
// import Autocomplete from 'react-google-autocomplete';

// const NearestLocationForm = () => {
//   const [localArea, setLocalArea] = useState('');

//   const handlePlaceSelected = (place) => {
//     // Extract the address components and find the one you consider as the local area
//     const addressComponent = place.address_components.find(component =>
//       component.types.includes('sublocality') || component.types.includes('locality')
//     );
//     setLocalArea(addressComponent ? addressComponent.long_name : '');
//   };

//   return (
//     <div>
//       <div>Local Area: {localArea}</div>
//       <Autocomplete
//         apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
//         onPlaceSelected={handlePlaceSelected}
//         options={{
//           types: ['geocode', 'establishment'],
//           componentRestrictions: { country: 'uk' },
//         }}
//         placeholder="Enter your postcode or address"
//       />
//     </div>
//   );
// };

// export default NearestLocationForm;