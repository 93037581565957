import React, { useState } from 'react';
import axios from 'axios';

const UNSPLASH_ACCESS_KEY = '5QIyBk01liMGNQqrDZ_mToUpKR_jIKXtscPSzKU7oD0'; // Replace with your actual Unsplash API key

const Test = () => {
  const [formData, setFormData] = useState({
    communityName: '',
    type: '',
    description: '',
    location: '',
    categories: '',
    subgroups: '',
    imageSearch: '' // Added new field for image search
  });
  const [apiResponse, setApiResponse] = useState(null);
  const [imageUrl, setImageUrl] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleImageSearch = async (e) => {
    if (e.key === 'Enter') { // Trigger image search on Enter key press
      e.preventDefault(); // Prevent form submission
      try {
        const query = formData.imageSearch;
        const response = await axios.get('https://api.unsplash.com/photos/random', {
          params: {
            query: query,
            client_id: UNSPLASH_ACCESS_KEY
          }
        });
        setImageUrl(response.data.urls.regular);
      } catch (error) {
        console.error('Error fetching image from Unsplash:', error);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('http://localhost:4000/api/communiRecord/createRecord', {
        ...formData,
        image: imageUrl // Changed key from imageUrl to image
      });
      console.log('Form submitted successfully:', response.data);
      setApiResponse(response.data);
    } catch (error) {
      console.error('Error submitting form:', error);
      setApiResponse(null);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="communityName">Community Name:</label>
          <input
            type="text"
            id="communityName"
            name="communityName"
            value={formData.communityName}
            onChange={handleChange}
            maxLength={45}
            required
          />
        </div>

        <div>
          <label htmlFor="type">Type:</label>
          <input
            type="text"
            id="type"
            name="type"
            value={formData.type}
            onChange={handleChange}
            maxLength={10}
            required
          />
        </div>

        <div>
          <label htmlFor="description">Who is the community for:</label>
          <textarea
            id="description"
            name="description"
            value={formData.description}
            onChange={handleChange}
            maxLength={400}
            required
          />
        </div>

        <div>
          <label htmlFor="location">Location:</label>
          <input
            type="text"
            id="location"
            name="location"
            value={formData.location}
            onChange={handleChange}
            maxLength={20}
            required
          />
        </div>
 
        <div>
          <label htmlFor="categories">Categories by ability/stage:</label>
          <input
            type="text"
            id="categories"
            name="categories"
            value={formData.categories}
            onChange={handleChange}
            maxLength={100}
          />
        </div>

        <div>
          <label htmlFor="subgroups">Common specific interests or subgroups:</label>
          <textarea
            id="subgroups"
            name="subgroups"
            value={formData.subgroups}
            onChange={handleChange}
            maxLength={200}
          />
        </div>

        <div> {/* Modified form field for image search */}
          <label htmlFor="imageSearch">Image Search:</label>
          <input
            type="text"
            id="imageSearch"
            name="imageSearch"
            value={formData.imageSearch}
            onChange={handleChange}
            onKeyPress={handleImageSearch} // Added onKeyPress event handler
            required
          />
        </div>

        {imageUrl && (
          <div>
            <h3>Community Image:</h3>
            <img src={imageUrl} alt="Community" style={{ maxWidth: '100%', height: 'auto' }} />
          </div>
        )}

        <button type="submit">Submit</button>
      </form>
      {apiResponse && (
        <div>
          <h3>API Response:</h3>
          <pre>{JSON.stringify(apiResponse, null, 2)}</pre>
        </div>
      )}
    </>
  );
};

export default Test;