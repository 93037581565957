import CommunityForm from "../components/CommunityForm"

const NewCommunityEntry = () => {

    return (

    <CommunityForm />

    )
}

export default NewCommunityEntry