import { useEffect } from "react";
import { useCommunitiesContext } from "../hooks/useCommunitiesContext";
//import { useParams } from 'react-router-dom';
import "./IndividualJoin.css";
import SignUpForm from "../components/Signupform";
import { Link } from 'react-router-dom';

// ...
import { useAuthContext } from "../hooks/useAuthContext";

<h5 id="h5-form">Sign up or <Link to="/login" style={{ textDecoration: 'underline' }}>log in</Link> to join this group</h5>

const IndividualJoin = () => {
  const { x, dispatch } = useCommunitiesContext();
  // const user = useAuthContext();

  // useEffect(() => {
  //   if(!user) return
  //   navigate("/Profileupdate");
  //   }

  // }, [user])

  useEffect(() => {
    const fetchCommunities = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/communities`
      );

      const json = await response.json();

      if (response.ok) {
        dispatch({ type: "SET_COMMUNITIES", payload: json });
      }
    };

    fetchCommunities();
  }, [dispatch]);

  const currentURL = window.location.href;
  const segments = currentURL.split("/");
  const lastSegment = segments.pop();
  const { communities } = useCommunitiesContext();

  const myCommunity = communities.find((tree) => tree._id === lastSegment);

  // Check if myCommunity is defined before rendering the component
  if (!myCommunity) {
    // Optionally, render a loading indicator or a message
    return <div>Loading...</div>;
  }

  return  (
    <div className="community-signup-form">
      {/* <h1 id="community-title-join">
        Join
      </h1> */}
      <h2 id="community-second-title">
         Join {myCommunity.title}
      </h2>
      <h2 id="community-second-title">
         {myCommunity.subtitle}
      </h2>
      <h3 id="community-third-title">
        <strong>Interconnector</strong>
      </h3>
      <div className="image-container">
        <img
          src={myCommunity.pics[0]}
          alt="Image place holder"
          className="community-signup-form-image"
        />
      </div>
      <div className="community-signup-form">
        <h5 id="h5-form">Sign up or log in to join this group</h5>
        <SignUpForm community={myCommunity} communityId={myCommunity._id} />
      </div>
    </div>
  );
};

export default IndividualJoin;

// import { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom"; // Import useNavigate for redirection
// import { useCommunitiesContext } from "../hooks/useCommunitiesContext";
// import { useAuthContext } from "../hooks/useAuthContext"; // Import useAuthContext to access user information
// import "./IndividualJoin.css";
// import SignUpForm from "../components/SignUpForm";
// import ClipLoader from "react-spinners/ClipLoader"; // Import a spinner component

// const IndividualJoin = () => {
//   const { communities, dispatch } = useCommunitiesContext();
//   const { user } = useAuthContext(); // Access user information
//   const [isLoading, setIsLoading] = useState(false); // State to manage loading state
//   const navigate = useNavigate(); // Hook for redirection

//   useEffect(() => {
//     const fetchCommunities = async () => {
//       const response = await fetch('/api/communities');
//       const json = await response.json();

//       if (response.ok) {
//         dispatch({ type: 'SET_COMMUNITIES', payload: json });
//       }
//     };

//     fetchCommunities();
//   }, [dispatch]);

//   const handleJoin = async () => {
//     if (!user) return; // Ensure user is defined
//     setIsLoading(true); // Set loading state to true

//     try {
//       const response = await fetch('/api/joinCommunity', {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify({ communityId: myCommunity._id, userId: user.userId }),
//       });

//       if (response.ok) {
//         navigate('/profileUpdate'); // Redirect on success
//       } else {
//         // Handle errors or unsuccessful response
//         console.error('Failed to join community');
//       }
//     } catch (error) {
//       console.error('Error joining community:', error);
//     } finally {
//       setIsLoading(false); // Reset loading state
//     }
//   };

//   const currentURL = window.location.href;
//   const segments = currentURL.split('/');
//   const lastSegment = segments.pop();
//   const myCommunity = communities.find(tree => tree._id === lastSegment);

//   if (!myCommunity) {
//     return <div>Loading...</div>;
//   }

//   if (isLoading) {
//     return <ClipLoader color="#52bfd9" size={100} />; // Display spinner while loading
//   }

//   return (
//     <div className="ijoin">
//       <h4>Join the</h4>
//       <img
//         src="https://t3.ftcdn.net/jpg/02/48/42/64/360_F_248426448_NVKLywWqArG2ADUxDq6QprtIzsF82dMF.jpg"
//         alt="Image placeholder"
//         className="community-image"
//       />
//       <h2><strong>{myCommunity.title}</strong></h2>
//       <h2>Interconnector</h2>
//       <div className="i-community">
//         <h3>Sign up or log in to join this group</h3>
//         {/* Replace SignUpForm with a button to handle join action */}
//         <button onClick={handleJoin}>Join</button>
//       </div>
//     </div>
//   );
// };

// export default IndividualJoin;
