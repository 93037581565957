import React, { useState, useEffect } from "react";
import axios from "axios";
import SwipeableViews from "react-swipeable-views";
import { useAuthContext } from "../../../hooks/useAuthContext";
import "./ProfilePhotoUpload.css";
import DynamicProfileSeperatePopUpEditDisplay from "./DynamicCommunity";
import DOMPurify from "dompurify";

const ProfileCombinedDisplay = ({ userId }) => {
  const [pictures, setPictures] = useState([]);
  const [json, setJson] = useState(null);

  useEffect(() => {
    if (!userId) return;
    const fetchProfilePictures = async () => {
      if (userId) {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}/userpics`,

            {
              userId: userId,
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );

          if (response.status === 200) {
            setPictures(response.data.pics);
          } else {
            throw new Error("Failed to fetch profile pictures");
          }
        } catch (error) {
          console.error("Error fetching profile pictures:", error);
        }
      }
    };

    const fetchProfile = async () => {
      if (!userId) return;

      if (userId) {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/userProfile/`,
          {
            method: "POST",
            body: JSON.stringify({ _id: userId }),
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const json = await response.json();
        setJson(json);
      }
    };

    fetchProfilePictures();
    fetchProfile();
  }, [userId]);

  const calculateAge = (dateOfBirth) => {
    const birthday = new Date(dateOfBirth);
    const ageDifMs = Date.now() - birthday.getTime();
    const ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  };

  const sanitizeUrl = (url) => {
    const sanitizedUrl = DOMPurify.sanitize(url);
    const a = document.createElement("a");
    a.href = sanitizedUrl;
    return a.href;
  };

  return (
    <div className="profile-combined">
      <SwipeableViews enableMouseEvents>
        {pictures.map((picture, index) => (
          <div key={index} className="photo-swipe-view">
            <img src={sanitizeUrl(picture)} alt={`Profile ${index + 1}`} />
          </div>
        ))}
      </SwipeableViews>
      {json && json.user && (
        <header className="profile-info">
          <div className="profile-name">Name: {json.user.firstName}</div>
          <div className="profile-age">
            Age: {calculateAge(json.user.dateOfBirth)}
          </div>
          <DynamicProfileSeperatePopUpEditDisplay />
        </header>
      )}
    </div>
  );
};

export default ProfileCombinedDisplay;
