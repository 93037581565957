import { createContext, useReducer, useEffect} from 'react'

export const CommunitiesContext = createContext()

export const communitiesReducer = (state, action) => {
  switch (action.type) {
    case 'SET_COMMUNITIES':
      return { 
        communities: action.payload 
      }
    case 'SET_COMMUNITY':
      return { 
        communities: action.payload
        }
    case 'CREATE_COMMUNITY':
      return { 
        communities: [action.payload, ...state.communities] 
      }
    case 'DELETE_COMMUNITY':
      return { 
        communities: state.communities.filter(w => w._id !== action.payload._id) 
      }
    default:
      return state
  }
}
export const CommunitiesContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(communitiesReducer, { 
    communities: [] 
  });

  useEffect(() => {
    console.log('hell ')
  }, [])
  
  return (
    <CommunitiesContext.Provider value={{ ...state, dispatch }}>
      { children }
    </CommunitiesContext.Provider>
  )
}