import React, { useState, useEffect, useRef } from 'react';
import "./Tester1.css";
import Conversations from '../components/Conversations/NewConversations';
import { useAuthContext } from '../hooks/useAuthContext';
import ChatBox from '../components/Message/NewChatBox';
import { useMessengerContext } from '../hooks/useMessengerContext';
import useSocket from '../hooks/useSocket';
import ChatProfile from '../components/Message/ChatProfile';

const Messenger = () => {
  
  const { state, dispatch } = useMessengerContext();
  const { currentConversation } = state
  const { user } = useAuthContext();

  // Activate the useSocket hook
  const { arrivalMessage, unOpenedMessage } = useSocket(user?.userId, currentConversation?._id);

  const [activeComponent, setActiveComponent] = useState('chatMenu');

  const handleConversationSelect = (conversation) => {
    setActiveComponent('chatBox');
  };

  const handleBackToMenu = () => {
    setActiveComponent('chatMenu');
  };
  
  const handleBackToChatBox = () => {
    setActiveComponent('chatBox');
  };

  const handleToChatProfile = () => {
    setActiveComponent('chatProfile');
  };

  // Use the useEffect hook to scroll to the bottom of the page when it opens
  useEffect(() => {
    if (activeComponent === 'chatBox' || activeComponent === 'chatProfile') {
      window.scrollTo(0, document.body.scrollHeight);
    }
  }, [activeComponent]);

  return (
    <div className="messenger">
      <div className={`chatMenu ${activeComponent !== 'chatMenu' ? 'hideOnMobile' : ''} `}>
        <div className="chatMenuWrapper">
          <Conversations 
            onConversationSelectForCSS={handleConversationSelect}
          />
        </div>
      </div>
      <div className={`chatBox ${activeComponent !== 'chatBox' ? 'hideOnMobile' : ''} `}>
        <div className="chatBoxWrapper">
        {currentConversation ? 
    <ChatBox onBackToChatMenuForCSS={handleBackToMenu} onToChatProfileForCSS={handleToChatProfile} /> 
    : 
    <div className="centered-text">
        <p>&larr;</p>
        {/* <p>You'll get suggestions to connect with people from community bots and you can chat with connections too!</p> */}
        <p>Click on a message to the left to find suggestions from community bots and chat with connections :)</p>
    </div>
} 
</div>      </div>
      <div className={`chatProfile ${activeComponent !== 'chatProfile' ? 'hideOnMobile' : ''} ${activeComponent === 'chatMenu' ? 'hideOnDesktop' : ''}`}>
        <div className="chatProfileWrapper">
          <ChatProfile 
            onBackToChatBoxForCSS={handleBackToChatBox}
          />
        </div>
      </div>
    </div>
  );
}

export default Messenger;
// import React, { useState, useEffect, useRef } from 'react';
// import "./Tester1.css";
// import Conversations from '../components/Conversations/NewConversations';
// import { useAuthContext } from '../hooks/useAuthContext';
// import ChatBox from '../components/Message/NewChatBox';
// import { useMessengerContext } from '../hooks/useMessengerContext';
// import useSocket from '../hooks/useSocket';
// import ChatProfile from '../components/Message/ChatProfile';

// const Messenger = () => {
  
//   const { state, dispatch } = useMessengerContext();
//   const { currentConversation } = state
//   const { user } = useAuthContext();

//  // Activate the useSocket hook
//  const { arrivalMessage, unOpenedMessage } = useSocket(user?.userId, currentConversation?._id);

//     const [activeComponent, setActiveComponent] = useState('chatMenu');

//     const handleConversationSelect = (conversation) => {
//       setActiveComponent('chatBox');
//     };

//     const handleBackToMenu = () => {
//       setActiveComponent('chatMenu');
//     };
    
//     const handleBackToChatBox = () => {
//       setActiveComponent('chatBox');
//     };
  
//     const handleToChatProfile = () => {
//       setActiveComponent('chatProfile');
//     };
 
//     return (
//       <div className="messenger">
//         <div className={`chatMenu ${activeComponent !== 'chatMenu' ? 'hideOnMobile' : ''} `}>
//           <div className="chatMenuWrapper">
//             <Conversations 
//               onConversationSelectForCSS={handleConversationSelect}
//             />
//           </div>
//         </div>
//         <div className={`chatBox ${activeComponent !== 'chatBox' ? 'hideOnMobile' : ''} `}>
//           <div className="chatBoxWrapper">
//             {currentConversation ? <ChatBox onBackToChatMenuForCSS={handleBackToMenu} onToChatProfileForCSS={handleToChatProfile} /> : <h1>Open a conversation to start chatting</h1>}
//           </div>
//         </div>
//         <div className={`chatProfile ${activeComponent !== 'chatProfile' ? 'hideOnMobile' : ''} ${activeComponent === 'chatMenu' ? 'hideOnDesktop' : ''}`}>
//           <div className="chatProfileWrapper">
//             <ChatProfile 
//               onBackToChatBoxForCSS={handleBackToChatBox}
//             />
//           </div>
//         </div>
//       </div>
//     );
// }

// export default Messenger;

// const [activeComponent, setActiveComponent] = useState('ChatMenu');
// const [selectedConversation, setSelectedConversation] = useState(null);

// const handleConversationSelect = (conversation) => {
//   setSelectedConversation(conversation);
//   setActiveComponent('ChatBox');
// };


// return (
// <div className="messenger">
//   <div className="chatMenu">
//     <div className="chatMenuWrapper">
//       <Conversations 
//         onConversationSelect={handleConversationSelect}
//       />
//     </div>
//   </div>
//   <div className="chatBox">
//     <div className="chatBoxWrapper">
//       <ChatBox 
//         currentConversation={selectedConversation}
//         onBack={handleBackToMenu}
//       />
//     </div>
//   </div> 
// </div>

// );
// }


///////////////////////////////


// <div className="messenger">
// <div className="chatMenu">
//   <div className="chatMenuWrapper">
//     <Conversations 
//       onConversationSelect={handleConversationSelect}
//     />
//   </div>
// </div>
// <div className="chatBox">
//   <div className="chatBoxWrapper">
//     <Messages 
//       currentConversation={selectedConversation}
//     />
//   </div>
// </div> 
// </div>
