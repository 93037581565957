import React, { useState, useEffect, useRef } from "react";
import "./Messenger.css";
import Conversation from "../components/Conversations/Conversation";
import Message from "../components/Message/Message";
import ChatOnline from "../components/ChatOnline/ChatOnline";
import { useAuthContext } from "../hooks/useAuthContext";
import { io } from "socket.io-client";
import ProfileCombinedDisplay from "../components/Profile&Settings/Display/ProfileDisplay";

const Messenger = () => {
  const [conversations, setConversations] = useState([]);
  const [currentChat, setCurrentChat] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [arrivalMessage, setArrivalMessage] = useState(null);
  const [otherUser, setOtherUser] = useState(null);
  const [community, setCommunity] = useState(null);
  const [userDisplayProfile, setUserDisplayProfile] = useState(null);
  const [unOpenedMessage, setUnOpenedMessage] = useState(null);

  // const [socket, setSocket] =useState(null)
  const socket = useRef();
  const { user } = useAuthContext();
  const scrollRef = useRef();
  const backendUrl =
    process.env.REACT_APP_ENV_ENVIRONMENT === "development"
      ? "ws://localhost:4000"
      : "wss://api.theinterconnector.com";

  useEffect(() => {
    socket.current = io(backendUrl);
    console.log("socket.currentsocket.currentsocket.current", socket.current);
    socket.current.on("getMessage", (data) => {
      setArrivalMessage({
        sender: data.senderId,
        text: data.text,
        createdAt: Date.now(),
      });
      console.log("set arrival message done");

      socket.current.on("updateAlert", (data) => {
        setUnOpenedMessage((currentState) => ({
          ...currentState,
          unopenedMessages: [...currentState.unopenedMessages, data.sendId],
          conversationIds: [
            ...currentState.conversationIds,
            data.conversationId,
          ],
        }));
        console.log("unopened message alert updated via websocket");
      });
    });
  }, []);

  // useEffect(() => {
  //   const otherUser = currentChat.members.find((m) => m !== user.userId);
  //   setOtherUser(otherUser)

  // }, [currentChat, user]);

  useEffect(() => {
    arrivalMessage &&
      currentChat?.members.includes(arrivalMessage.sender) &&
      setMessages((prev) => [...prev, arrivalMessage]);
  }, [arrivalMessage, currentChat]);

  useEffect(() => {
    if (!user) return;
    // socket.current.disconnect();
    socket.current.emit("updateUserConversation", {
      userId: user.userId,
      conversationId: currentChat?._id,
    });
    socket.current.emit("addUser", user.userId, currentChat?._id);
    socket.current.on("getUsers", (users) => {
      console.log("socketUsers", users);
    });
    return () => {
      socket.current.off("getUsers");
    };
  }, [currentChat]);

  useEffect(() => {
    if (!user) return; // Add this line to check if user is null
    const getConversations = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/conversations` + user.userId,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const json = await response.json();
        setConversations(json);
      } catch (error) {
        console.error("Failed to fetch conversations:", error);
      }
    };
    getConversations();
  }, [user]);

  useEffect(() => {
    if (!user) return; // Add this line to check if user is null
    const getMessages = async () => {
      if (currentChat?._id) {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/messages/` + currentChat._id,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          const json = await response.json();
          setMessages(json);
        } catch (error) {
          console.error("Failed to fetch messages:", error);
        }
      }
    };
    getMessages();
  }, [currentChat, user]);

  useEffect(() => {
    if (!user || !currentChat) return; // Add this line to check if user or currentChat is null
    setCommunity(null);
    setOtherUser(null);

    const getCommunity = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/communities/display/${currentChat.communityId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const json = await response.json();
        setCommunity(json);
      } catch (error) {
        console.error("Failed to fetch community bot:", error);
      }
    };
    getCommunity();

    if (!currentChat.communityBot) {
      const friendId = currentChat.members.find((m) => m !== user.userId);
      const getUsers = async () => {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/userProfile/getBasicProfileToDisplay`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ userId: friendId }),
            }
          );
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          const json = await response.json();
          setOtherUser(json);
          console.log("otherUser", json);
        } catch (error) {
          console.error("Failed to fetch user:", error);
        }
      };
      getUsers();
    }
  }, [currentChat, user]);

  //getUserProfileInfo

  useEffect(() => {
    if (!user) return;
    const getUsers = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/userProfile/getBasicProfileToDisplay`,

          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ userId: user.userId }),
          }
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const json = await response.json();
        setUserDisplayProfile(json);
        console.log("otherUser", json);
      } catch (error) {
        console.error("Failed to fetch user:", error);
      }
    };

    getUsers();
  }, [user]);

  const handleSubmit = async (e) => {
    if (!user) return; // Add this check

    e.preventDefault();
    const message = {
      sender: user.userId,
      text: newMessage,
      conversationId: currentChat._id,
    };

    const receiverId = currentChat.members.find(
      (member) => member !== user.userId
    );

    socket.current.emit("sendMessage", {
      senderId: user.userId,
      receiverId,
      text: newMessage,
      conversationId: currentChat._id,
    });

    console.log("senderId", user.userId);
    console.log("receiverId", receiverId);
    console.log("newMessage", newMessage);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/messages/`,
        {
          method: "POST",
          body: JSON.stringify(message),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      setMessages([...messages, data]);
      setNewMessage("");
    } catch (err) {
      console.error("Failed to send message:", err);
    }
  };

  useEffect(() => {
    if (!user) return;
    const getUnopenedMessages = async () => {
      try {
        const userId = user.userId;

        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/messages/getUnopenedMessages`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ userId }), // Send userId in the body of the request
          }
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setUnOpenedMessage(data);
        console.log("unopenedmessage", data);
      } catch (error) {
        console.error("Failed to fetch unopened messages:", error);
      }
    };
    getUnopenedMessages();
  }, [user]);

  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  // Function to determine the correct picture URL
  const getOtherUserPic = (message) => {
    if (message.sender === currentChat.communityId) {
      return community?.pics;
    } else if (message.suggestions > 0) {
      return community?.pics;
    } else {
      return otherUser?.pics;
    }
  };

  console.log("userDisplayProfile", userDisplayProfile);

  useEffect(() => {
    if (!user) return;

    const messageOpenedUpdate = async () => {
      // Check if the current chat's ID is in the array of unopened message conversation IDs

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/messages/messageOpenedUpdate`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            // Ensure that the body includes userId and the ID of the current chat
            body: JSON.stringify({
              userId: user.userId,
              conversationId: currentChat._id,
            }),
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        console.log("messageOpenedUpdate", data);
        setUnOpenedMessage(data); // Assuming setUnOpenedMessage is a state setter function
      } catch (error) {
        console.error("Failed to update message opened status:", error);
      }
    };

    // Call messageOpenedUpdate if userId and currentChat are defined
    if (unOpenedMessage?.conversationIds.includes(currentChat?._id)) {
      console.log(`Condition is true for conversation ID: ${currentChat._id}`);
      console.log("messageOpenedUpdate!@@");
      messageOpenedUpdate();
    }
  }, [user, currentChat]); // Dependencies array, effect runs when userId or currentChat changes

  return (
    <div className="messenger">
      <div className="chatMenu">
        <div className="chatMenuWrapper">
          {conversations.map((c) => (
            <div
              key={c._id}
              onClick={() => {
                setCurrentChat(c);
                console.log("cccc", c._id);
              }}
            >
              <Conversation
                key={c._id}
                conversation={c}
                currentUser={user}
                newMessage={unOpenedMessage?.conversationIds.includes(c._id)}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="chatBox">
        <div className="chatBoxWrapper">
          {currentChat ? (
            <>
              <div className="chatBoxHeader">
                <h3>Chat with {otherUser?.name}</h3>
              </div>
              <div className="chatBoxTop">
                {messages.map((m) => (
                  <div ref={scrollRef} key={m._id}>
                    <Message
                      message={m}
                      own={m.sender === user.userId}
                      otherUserPic={getOtherUserPic(m)}
                      reciverUserPic={userDisplayProfile.pics}
                    />
                  </div>
                ))}
              </div>
              <div className="chatBoxBottom">
                <textarea
                  className="chatMessageInput"
                  placeholder="write something"
                  onChange={(e) => setNewMessage(e.target.value)}
                  value={newMessage}
                  onKeyPress={(e) => {
                    if (e.key === "Enter" && !e.shiftKey) {
                      e.preventDefault(); // Prevents the default action of inserting a newline
                      handleSubmit(e); // Calls the function to send the message
                    }
                  }}
                ></textarea>
                <button className="chatSubmitButton" onClick={handleSubmit}>
                  Send
                </button>
              </div>
            </>
          ) : (
            <span className="noConversationText">
              Open a conversation to start a chat.
            </span>
          )}
        </div>
      </div>
      <div className="chatProfile">
        <div className="chatchatProfileView">
          <ProfileCombinedDisplay userId={otherUser?.userId} />
        </div>
      </div>
    </div>
  );
};

export default Messenger;

//NON HEADER

//   return (
//     <div className="messenger">
//       <div className="chatMenu">
//         <div className="chatMenuWrapper">
//           <input placeholder="Search for friends" className="chatMenuInput" />
//           {conversations.map((c) => (
//   <div key={c._id} onClick={() => {
//     setCurrentChat(c);  console.log('cccc',c._id)
//   }}>
// {
//     <Conversation
//       key={c._id}
//       conversation={c}
//       currentUser={user}
//       newMessage={unOpenedMessage?.conversationIds.includes(c._id)}
//     />

// }
//             </div>
//           ))}
//         </div>
//       </div>
//       <div className="chatBox">
//         <div className="chatBoxWrapper">
//           {currentChat ? (
//             <>
//               <div className="chatBoxTop">
//                 {messages.map((m) => (
//                   <div ref={scrollRef} key={m._id}>
//                     <Message
//                       message={m}
//                       own={m.sender === user.userId}
//                       otherUserPic={getOtherUserPic(m)}
//                       reciverUserPic={userDisplayProfile.pics}
//                     />
//                   </div>
//                 ))}
//               </div>
//               <div className="chatBoxBottom">
//                 <textarea
//                   className="chatMessageInput"
//                   placeholder="write something"
//                   onChange={(e) => setNewMessage(e.target.value)}
//                   value={newMessage}
//                 ></textarea>
//                 <button className="chatSubmitButton" onClick={handleSubmit}>Send</button>
//               </div>
//             </>
//           ) : (
//             <span className="noConversationText">Open a conversation to start a chat.</span>
//           )}
//         </div>
//       </div>
//       <div className="chatOnline">
//         <div className="chatOnlineWrapper">
//         <ProfileCombinedDisplay userId={otherUser?.userId} />
//         </div>
//       </div>
//     </div>
//   );
// };

// // //HEADER:

// return (
//   <div className="messenger">
//     <div className="chatMenu">
//       <div className="chatMenuWrapper">
//         <input placeholder="Search for friends" className="chatMenuInput" />
//         {conversations.map((c) => (
//           <div key={c._id} onClick={() => {
//             setCurrentChat(c);  console.log('cccc',c._id)
//           }}>
//             <Conversation
//               key={c._id}
//               conversation={c}
//               currentUser={user}
//               newMessage={unOpenedMessage?.conversationIds.includes(c._id)}
//             />
//           </div>
//         ))}
//       </div>
//     </div>
//     <div className="chatBox">
//       <div className="chatBoxWrapper">
//         {currentChat ? (
//           <>
//             {/* Header bar added here */}
//             <div className="chatBoxHeader">
//               {/* You can add any top-level information you want about the chat here */}
//               <h3>Chat with {otherUser?.username}</h3>
//               {/* Add more details as needed */}
//             </div>
//             <div className="chatBoxTop">
//               {messages.map((m) => (
//                 <div ref={scrollRef} key={m._id}>
//                   <Message
//                     message={m}
//                     own={m.sender === user.userId}
//                     otherUserPic={getOtherUserPic(m)}
//                     reciverUserPic={userDisplayProfile.pics}
//                   />
//                 </div>
//               ))}
//             </div>
//             <div className="chatBoxBottom">
//               <textarea
//                 className="chatMessageInput"
//                 placeholder="write something"
//                 onChange={(e) => setNewMessage(e.target.value)}
//                 value={newMessage}
//               ></textarea>
//               <button className="chatSubmitButton" onClick={handleSubmit}>Send</button>
//             </div>
//           </>
//         ) : (
//           <span className="noConversationText">Open a conversation to start a chat.</span>
//         )}
//       </div>
//     </div>
//     <div className="chatOnline">
//       <div className="chatOnlineWrapper">
//         <ProfileCombinedDisplay userId={otherUser?.userId} />
//       </div>
//     </div>
//   </div>
// );

// import React, { useState, useEffect, useRef } from 'react';
// import "./Messenger.css";
// import Conversation from '../components/Conversations/Conversation';
// import Message from '../components/Message/Message';
// import ChatOnline from '../components/ChatOnline/ChatOnline';
// import { useAuthContext } from '../hooks/useAuthContext';
// import {io} from "socket.io-client"

// const Messenger = () => {
//   const [conversations, setConversations] = useState([]);
//   const [currentChat, setCurrentChat] = useState(null);
//   const [messages, setMessages] = useState([]);
//   const [newMessage, setNewMessage] = useState("");
//   const [arrivalMessage, setArrivalMessage] =useState(null)
//   const [otherUser, setOtherUser] = useState(null);
//   const [community, setCommunity] = useState(null);
//   const [userDisplayProfile, setUserDisplayProfile] = useState(null)

//   // const [socket, setSocket] =useState(null)
//   const socket = useRef()
//   const { user } = useAuthContext();
//   const scrollRef = useRef();

// useEffect(()=>{

//  socket.current = io("ws://localhost:8900")
//  socket.current.on("getMessage", data =>{
//   setArrivalMessage({
//     sender: data.senderId,
//     text: data.text,
//     createdAt: Date.now(),
//   })
//   console.log('set arrival message done')

// })
// },[])

// useEffect(()=>{
//   arrivalMessage && currentChat?.members.includes(arrivalMessage.sender) &&
//     setMessages(prev=>[...prev, arrivalMessage])
// },[arrivalMessage, currentChat])

//   useEffect(()=>{
//     if (!user) return
//     socket.current.emit("addUser", user.userId);
//     socket.current.on("getUsers",users=>{
//       console.log("socketUsers",users)
//     })
//     },[user])

//   useEffect(() => {
//     if (!user) return; // Add this line to check if user is null
//     const getConversations = async () => {
//       try {
//         const response = await fetch('/api/conversations/' + user.userId, {
//           method: 'GET',
//           headers: {
//             'Content-Type': 'application/json'
//           }
//         });
//         const json = await response.json();
//         setConversations(json);
//       } catch (error) {
//         console.error('Failed to fetch conversations:', error);
//       }
//     };
//     getConversations();
//   }, [user]);
//   useEffect(() => {
//     if (!user) return; // Add this line to check if user is null
//     const getMessages = async () => {
//       if (currentChat?._id) {
//         try {
//           const response = await fetch('api/messages/' + currentChat._id, {
//             method: 'GET',
//             headers: {
//               'Content-Type': 'application/json'
//             }
//           });
//           const json = await response.json();
//           setMessages(json);
//         } catch (error) {
//           console.error('Failed to fetch messages:', error);
//         }
//       }
//     };
//     getMessages();
//   }, [currentChat, user]);

//   useEffect(() => {
//     if (!user || !currentChat) return; // Add this line to check if user or currentChat is null
//     setCommunity(null);
//     setOtherUser(null);

//       const getCommunity = async () => {
//         try {
//           const response = await fetch(`/api/communities/display/${currentChat.communityId}`, {
//             method: 'GET',
//             headers: {
//               'Content-Type': 'application/json'
//             }
//           });
//           if (!response.ok) {
//             throw new Error('Network response was not ok');
//           }
//           const json = await response.json();
//           setCommunity(json);
//         } catch (error) {
//           console.error('Failed to fetch community bot:', error);
//         }
//       };
//       getCommunity();

//       if (!currentChat.communityBot){
//       const friendId = currentChat.members.find((m) => m !== user.userId);
//       const getUsers = async () => {
//         try {
//           const response = await fetch('/api/userProfile/getBasicProfileToDisplay', {
//             method: 'POST',
//             headers: {
//               'Content-Type': 'application/json'
//             },
//             body: JSON.stringify({ userId: friendId })
//           });
//           if (!response.ok) {
//             throw new Error('Network response was not ok');
//           }
//           const json = await response.json();
//           setOtherUser(json);
//           console.log('otherUser',json)
//         } catch (error) {
//           console.error('Failed to fetch user:', error);
//         }
//       };
//       getUsers();
//     }}
//   , [currentChat, user]);

// //getUserProfileInfo

// useEffect(() => {
//   const getUsers = async () => {
//     try {
//       const response = await fetch('/api/userProfile/getBasicProfileToDisplay', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json'
//         },
//         body: JSON.stringify({ userId: user.userId })
//       });
//       if (!response.ok) {
//         throw new Error('Network response was not ok');
//       }
//       const json = await response.json();
//       setUserDisplayProfile(json);
//       console.log('otherUser', json);
//     } catch (error) {
//       console.error('Failed to fetch user:', error);
//     }
//   };

//   getUsers();
// }, [user]);

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const message = {
//       sender: user.userId,
//       text: newMessage,
//       conversationId: currentChat._id
//     };

//     const receiverId = currentChat.members.find(member=> member !==user.userId)

//     socket.current.emit("sendMessage", {
//       senderId: user.userId,
//       receiverId,
//       text: newMessage,
//     })

//     console.log('senderId',user.userId)
//     console.log('receiverId',receiverId)
//     console.log('newMessage',newMessage)

//     try {
//       const response = await fetch('api/messages/', {
//         method: 'POST',
//         body: JSON.stringify(message),
//         headers: {
//           'Content-Type': 'application/json'
//         }
//       });
//       const data = await response.json();
//       setMessages([...messages, data]);
//       setNewMessage('');
//     } catch (err) {
//       console.error('Failed to send message:', err);
//     }
//   };

//   useEffect(() => {
//     scrollRef.current?.scrollIntoView({ behavior: "smooth" });
//   }, [messages]);

//   // Function to determine the correct picture URL
//   const getOtherUserPic = (message) => {
//     if (message.sender === currentChat.communityId) {
//       return community?.pics;
//     } else if (message.suggestions > 0) {
//       return community?.pics;
//     } else {
//       return otherUser?.pics;
//     }
//   };

//   console.log('userDisplayProfile',userDisplayProfile)

//   return (
//     <div className="messenger">
//       <div className="chatMenu">
//         <div className="chatMenuWrapper">
//           <input placeholder="Search for friends" className="chatMenuInput" />
//           {conversations.map((c) => (
//             <div key={c._id} onClick={() => setCurrentChat(c)}>
//               <Conversation conversation={c} currentUser={user} />
//             </div>
//           ))}
//         </div>
//       </div>
//       <div className="chatBox">
//         <div className="chatBoxWrapper">
//           {currentChat ? (
//             <>
//               <div className="chatBoxTop">
//                 {messages.map((m) => (
//                   <div ref={scrollRef} key={m._id}>
//                     <Message
//                       message={m}
//                       own={m.sender === user.userId}
//                       otherUserPic={getOtherUserPic(m)}
//                       reciverUserPic={userDisplayProfile.pics}
//                     />
//                   </div>
//                 ))}
//               </div>
//               <div className="chatBoxBottom">
//                 <textarea
//                   className="chatMessageInput"
//                   placeholder="write something"
//                   onChange={(e) => setNewMessage(e.target.value)}
//                   value={newMessage}
//                 ></textarea>
//                 <button className="chatSubmitButton" onClick={handleSubmit}>Send</button>
//               </div>
//             </>
//           ) : (
//             <span className="noConversationText">Open a conversation to start a chat.</span>
//           )}
//         </div>
//       </div>
//       <div className="chatOnline">
//         <div className="chatOnlineWrapper">
//           <ChatOnline />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Messenger;
