import "./Form.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getAuth,
  createUserWithEmailAndPassword,
  updateProfile,
  signInWithPopup,
  GoogleAuthProvider,
  sendSignInLinkToEmail,
} from "firebase/auth";
import firebaseApp from "../Firebase";
import useCreateNewUser from "../hooks/useCreateNewUser";

const Signupform = ({ communityId }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [signUpError, setSignUpError] = useState("");
  const navigate = useNavigate();
  const auth = getAuth(firebaseApp);
  const { createNewUser, isLoading, error } = useCreateNewUser(); // Use the custom hook

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!termsAccepted) {
      setSignUpError("You must accept the terms and conditions to sign up.");
      return;
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      // Update the user's display name
      await updateProfile(user, { displayName: name });

      // Pass the user information to the createNewUser hook
      await createNewUser(user.email, user.displayName, communityId, user.uid);

      // Navigate to the desired page after successful sign-up
      navigate("/Onboarding");
    } catch (error) {
      console.error("Error signing up:", error);
      console.error("Error code:", error.code);
      console.error("Error message:", error.message);

      if (error.code === "auth/weak-password") {
        setSignUpError(
          "Password is not strong enough. Please use a stronger password."
        );
      } else if (error.code === "auth/email-already-in-use") {
        setSignUpError(
          "The email address is already in use. Please use a different email."
        );
      } else if (error.code === "auth/invalid-email") {
        setSignUpError(
          "The email address is invalid. Please enter a valid email."
        );
      } else if (error.code === "auth/operation-not-allowed") {
        setSignUpError("Sign-up is not allowed. Please contact support.");
      } else if (error.code === "auth/too-many-requests") {
        setSignUpError("Too many requests. Please try again later.");
      } else {
        setSignUpError("An error occurred during sign-up. Please try again.");
      }
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      // Pass the user information to the createNewUser hook
      await createNewUser(user.email, user.displayName, communityId, user.uid);

      // Handle successful Google sign-in
      navigate("/ProfileUpdate");
    } catch (error) {
      console.error("Error signing in with Google:", error);
      if (error.code === "auth/account-exists-with-different-credential") {
        setSignUpError(
          "The email address is already associated with a different sign-in method. Please use that method or reset your password."
        );
      } else {
        setSignUpError(
          "An error occurred during Google sign-in. Please try again."
        );
      }
    }
  };
  const handleEmailLinkSignIn = async () => {
    try {
      const emailLink = window.prompt("Please enter your email for sign up");
      if (emailLink) {
        const termsLink = "http://yourwebsite.com/terms-and-conditions"; // Replace with your terms and conditions URL
        const termsAccepted = window.confirm(
          `Do you agree with the terms and conditions? You can read them here: ${termsLink}`
        );
        if (!termsAccepted) {
          setSignUpError(
            "You must accept the terms and conditions to sign up."
          );
          return;
        }
        setEmail(emailLink);
        const actionCodeSettings = {
          url: "http://localhost:3000/signup", // Replace with your app's URL
          handleCodeInApp: true,
        };
        await sendSignInLinkToEmail(auth, emailLink, actionCodeSettings);
        window.localStorage.setItem("emailForSignIn", emailLink);
        setSignUpError("Check your email for the sign-in link.");

        // After the user signs in with the link, get the user's information
        auth.onAuthStateChanged(async (user) => {
          if (user) {
            // Pass the user information to the createNewUser hook
            await createNewUser(emailLink, "jj", communityId, user.uid);
          }
        });
      } else {
        setSignUpError("Email is required for sign up.");
      }
    } catch (error) {
      console.error("Error sending sign-in link:", error);
      setSignUpError(
        "An error occurred while sending the sign-in link. Please try again."
      );
    }
  };
  return (
    <form className="form-container signup" onSubmit={handleSubmit}>
      <h3 id="signup-heading" className="form-heading">
        Sign Up
      </h3>
      <label className="form-label">Name:</label>
      <input
        className="form-input"
        type="text"
        onChange={(e) => setName(e.target.value)}
        value={name}
      />

      <label className="form-label">Email address:</label>
      <input
        className="form-input"
        type="email"
        onChange={(e) => setEmail(e.target.value)}
        value={email}
      />

      <label className="form-label">Password:</label>
      <input
        className="form-input"
        type="password"
        onChange={(e) => setPassword(e.target.value)}
        value={password}
      />

<label className="form-label">
  <input
    id="checkbox-signup"
    type="checkbox"
    onChange={(e) => setTermsAccepted(e.target.checked)}
    checked={termsAccepted}
  />
  I accept the <a href="https://docs.google.com/document/d/1rPdUTbFMvdvY6baHMuJK3Z2aXAeLeMpNq_xooHFzjyI/edit?usp=sharing" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'underline' }}>terms and conditions</a> and the <a href="https://docs.google.com/document/d/1RHa0yEKefmVeNwBKA_tI7MSoI-tuw9XPUznS_a2fsQw/edit?usp=sharing" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'underline' }}>privacy policy</a>
</label>
      {signUpError && <p className="error-message">{signUpError}</p>}

      <button className="form-button">Sign up</button>

      <div className="social-buttons">
        <button className="social-button" onClick={handleGoogleSignIn}>
          Sign up with Google
        </button>
        <button className="social-button" onClick={handleEmailLinkSignIn}>
          Sign up with Email Link
        </button>
      </div>
    </form>
  );
};

export default Signupform;

////////////////////////////////////////////

// import "./Form.css";
// import { useState } from "react";
// import { useNavigate } from "react-router-dom";
// import { getAuth, createUserWithEmailAndPassword, updateProfile, signInWithPopup, GoogleAuthProvider, sendSignInLinkToEmail } from "firebase/auth";
// import firebaseApp from "../Firebase";
// import useCreateNewUser from "../hooks/useCreateNewUser";

// const SignUpForm = ({ communityId }) => {
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const [name, setName] = useState("");
//   const [termsAccepted, setTermsAccepted] = useState(false);
//   const [signUpError, setSignUpError] = useState("");
//   const navigate = useNavigate();
//   const auth = getAuth(firebaseApp);
//   const { createNewUser, isLoading, error } = useCreateNewUser(); // Use the custom hook

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     if (!termsAccepted) {
//       setSignUpError("You must accept the terms and conditions to sign up.");
//       return;
//     }

//     try {
//       const userCredential = await createUserWithEmailAndPassword(auth, email, password);
//       const user = userCredential.user;

//       // Update the user's display name
//       await updateProfile(user, { displayName: name });

//       // Pass the user information to the createNewUser hook
//       await createNewUser(user.email, user.displayName, user.uid);

//       // Navigate to the desired page after successful sign-up
//       navigate('/ProfileUpdate');
//     } catch (error) {
//       console.error('Error signing up:', error);
//       console.error('Error code:', error.code);
//       console.error('Error message:', error.message);

//       if (error.code === 'auth/weak-password') {
//         setSignUpError('Password is not strong enough. Please use a stronger password.');
//       } else if (error.code === 'auth/email-already-in-use') {
//         setSignUpError('The email address is already in use. Please use a different email.');
//       } else if (error.code === 'auth/invalid-email') {
//         setSignUpError('The email address is invalid. Please enter a valid email.');
//       } else if (error.code === 'auth/operation-not-allowed') {
//         setSignUpError('Sign-up is not allowed. Please contact support.');
//       } else if (error.code === 'auth/too-many-requests') {
//         setSignUpError('Too many requests. Please try again later.');
//       } else {
//         setSignUpError('An error occurred during sign-up. Please try again.');
//       }
//     }
//   };

//   const handleGoogleSignIn = async () => {
//     try {
//       const provider = new GoogleAuthProvider();
//       const result = await signInWithPopup(auth, provider);
//       const user = result.user;
//       // Handle successful Google sign-in
//       navigate('/dashboard');
//     } catch (error) {
//       console.error('Error signing in with Google:', error);
//       if (error.code === 'auth/account-exists-with-different-credential') {
//         setSignUpError('The email address is already associated with a different sign-in method. Please use that method or reset your password.');
//       } else {
//         setSignUpError('An error occurred during Google sign-in. Please try again.');
//       }
//     }
//   };

//   const handleEmailLinkSignIn = async () => {
//     try {
//       const actionCodeSettings = {
//         url: 'http://localhost:3000/signup', // Replace with your app's URL
//         handleCodeInApp: true,
//       };
//       await sendSignInLinkToEmail(auth, email, actionCodeSettings);
//       window.localStorage.setItem('emailForSignIn', email);
//       setSignUpError('Check your email for the sign-in link.');
//     } catch (error) {
//       console.error('Error sending sign-in link:', error);
//       setSignUpError('An error occurred while sending the sign-in link. Please try again.');
//     }
//   };

//   return (
//     <form className="form-container signup" onSubmit={handleSubmit}>
//       <h3 id="signup-heading" className="form-heading">Sign Up</h3>
//       <label className="form-label">Name:</label>
//       <input className="form-input" type="text" onChange={(e) => setName(e.target.value)} value={name} />

//       <label className="form-label">Email address:</label>
//       <input className="form-input" type="email" onChange={(e) => setEmail(e.target.value)} value={email} />

//       <label className="form-label">Password:</label>
//       <input className="form-input" type="password" onChange={(e) => setPassword(e.target.value)} value={password} />

//       <label className="form-label">
//         <input type="checkbox" onChange={(e) => setTermsAccepted(e.target.checked)} checked={termsAccepted} />
//         I accept the terms and conditions
//       </label>
//       {signUpError && <p className="error-message">{signUpError}</p>}

//       <button className="form-button">Sign up</button>

//       <div className="social-buttons">
//         <button className="social-button" onClick={handleGoogleSignIn}>Sign up with Google</button>
//         <button className="social-button" onClick={handleEmailLinkSignIn}>Sign up with Email Link</button>
//       </div>
//     </form>
//   );
// };

// export default SignUpForm;

////////////////////////////////////////////////////////

// import "./Form.css";
// import { useState } from "react";
// import { useNavigate } from "react-router-dom";
// import { getAuth, createUserWithEmailAndPassword, updateProfile, signInWithPopup, GoogleAuthProvider, sendSignInLinkToEmail } from "firebase/auth";
// import firebaseApp from "../Firebase";

// const SignUpForm = ({ communityId }) => {
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const [name, setName] = useState("");
//   const [termsAccepted, setTermsAccepted] = useState(false);
//   const [signUpError, setSignUpError] = useState("");
//   const navigate = useNavigate();
//   const auth = getAuth(firebaseApp);

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     if (!termsAccepted) {
//       setSignUpError("You must accept the terms and conditions to sign up.");
//       return;
//     }

//     try {
//       const userCredential = await createUserWithEmailAndPassword(auth, email, password);
//       const user = userCredential.user;

//       // Update the user's display name
//       await updateProfile(user, { displayName: name });

//       // Navigate to the desired page after successful sign-up
//       navigate('/ProfileUpdate');
//     } catch (error) {
//       console.error('Error signing up:', error);
//       console.error('Error code:', error.code);
//       console.error('Error message:', error.message);

//       if (error.code === 'auth/weak-password') {
//         setSignUpError('Password is not strong enough. Please use a stronger password.');
//       } else if (error.code === 'auth/email-already-in-use') {
//         setSignUpError('The email address is already in use. Please use a different email.');
//       } else if (error.code === 'auth/invalid-email') {
//         setSignUpError('The email address is invalid. Please enter a valid email.');
//       } else if (error.code === 'auth/operation-not-allowed') {
//         setSignUpError('Sign-up is not allowed. Please contact support.');
//       } else if (error.code === 'auth/too-many-requests') {
//         setSignUpError('Too many requests. Please try again later.');
//       } else {
//         setSignUpError('An error occurred during sign-up. Please try again.');
//       }
//     }
//   };

//   const handleGoogleSignIn = async () => {
//     try {
//       const provider = new GoogleAuthProvider();
//       const result = await signInWithPopup(auth, provider);
//       const user = result.user;
//       // Handle successful Google sign-in
//       navigate('/dashboard');
//     } catch (error) {
//       console.error('Error signing in with Google:', error);
//       if (error.code === 'auth/account-exists-with-different-credential') {
//         setSignUpError('The email address is already associated with a different sign-in method. Please use that method or reset your password.');
//       } else {
//         setSignUpError('An error occurred during Google sign-in. Please try again.');
//       }
//     }
//   };

//   const handleEmailLinkSignIn = async () => {
//     try {
//       const actionCodeSettings = {
//         url: 'http://localhost:3000/signup', // Replace with your app's URL
//         handleCodeInApp: true,
//       };
//       await sendSignInLinkToEmail(auth, email, actionCodeSettings);
//       window.localStorage.setItem('emailForSignIn', email);
//       setSignUpError('Check your email for the sign-in link.');
//     } catch (error) {
//       console.error('Error sending sign-in link:', error);
//       setSignUpError('An error occurred while sending the sign-in link. Please try again.');
//     }
//   };

//   return (
//     <form className="form-container signup" onSubmit={handleSubmit}>
//       <h3 id="signup-heading" className="form-heading">Sign Up</h3>
//       <label className="form-label">Name:</label>
//       <input className="form-input" type="text" onChange={(e) => setName(e.target.value)} value={name} />

//       <label className="form-label">Email address:</label>
//       <input className="form-input" type="email" onChange={(e) => setEmail(e.target.value)} value={email} />

//       <label className="form-label">Password:</label>
//       <input className="form-input" type="password" onChange={(e) => setPassword(e.target.value)} value={password} />

//       <label className="form-label">
//         <input type="checkbox" onChange={(e) => setTermsAccepted(e.target.checked)} checked={termsAccepted} />
//         I accept the terms and conditions
//       </label>
//       {signUpError && <p className="error-message">{signUpError}</p>}

//       <button className="form-button">Sign up</button>

//       <div className="social-buttons">
//         <button className="social-button" onClick={handleGoogleSignIn}>Sign up with Google</button>
//         <button className="social-button" onClick={handleEmailLinkSignIn}>Sign up with Email Link</button>
//       </div>
//     </form>
//   );
// };

// export default SignUpForm;
///////////////////////////////////////// 1 /////////////////////////////////////////

// import "./Form.css";
// import { useState } from "react";
// import { useNavigate } from "react-router-dom";
// import { getAuth, createUserWithEmailAndPassword, updateProfile, signInWithPopup, GoogleAuthProvider, sendSignInLinkToEmail } from "firebase/auth";
// import firebaseApp from "../Firebase";

// const SignUpForm = ({ communityId }) => {
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const [name, setName] = useState("");
//   const [termsAccepted, setTermsAccepted] = useState(false);
//   const [signUpError, setSignUpError] = useState("");
//   const navigate = useNavigate();
//   const auth = getAuth(firebaseApp);

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     if (!termsAccepted) {
//       setSignUpError("You must accept the terms and conditions to sign up.");
//       return;
//     }

//     try {
//       const userCredential = await createUserWithEmailAndPassword(auth, email, password);
//       const user = userCredential.user;

//       // Update the user's display name
//       await updateProfile(user, { displayName: name });

//       // Navigate to the desired page after successful sign-up
//       navigate('/dashboard');
//     } catch (error) {
//       console.error('Error signing up:', error);
//       console.error('Error code:', error.code);
//       console.error('Error message:', error.message);

//       if (error.code === 'auth/weak-password') {
//         setSignUpError('Password is not strong enough. Please use a stronger password.');
//       } else if (error.code === 'auth/email-already-in-use') {
//         setSignUpError('The email address is already in use. Please use a different email.');
//       } else if (error.code === 'auth/invalid-email') {
//         setSignUpError('The email address is invalid. Please enter a valid email.');
//       } else if (error.code === 'auth/operation-not-allowed') {
//         setSignUpError('Sign-up is not allowed. Please contact support.');
//       } else if (error.code === 'auth/too-many-requests') {
//         setSignUpError('Too many requests. Please try again later.');
//       } else {
//         setSignUpError('An error occurred during sign-up. Please try again.');
//       }
//     }
//   };

//   const handleGoogleSignIn = async () => {
//     try {
//       const provider = new GoogleAuthProvider();
//       const result = await signInWithPopup(auth, provider);
//       const user = result.user;
//       // Handle successful Google sign-in
//       navigate('/dashboard');
//     } catch (error) {
//       console.error('Error signing in with Google:', error);
//       if (error.code === 'auth/account-exists-with-different-credential') {
//         setSignUpError('The email address is already associated with a different sign-in method. Please use that method or reset your password.');
//       } else {
//         setSignUpError('An error occurred during Google sign-in. Please try again.');
//       }
//     }
//   };

//   const handleEmailLinkSignIn = async () => {
//     try {
//       const actionCodeSettings = {
//         url: 'http://localhost:3000/signup', // Replace with your app's URL
//         handleCodeInApp: true,
//       };
//       await sendSignInLinkToEmail(auth, email, actionCodeSettings);
//       window.localStorage.setItem('emailForSignIn', email);
//       setSignUpError('Check your email for the sign-in link.');
//     } catch (error) {
//       console.error('Error sending sign-in link:', error);
//       setSignUpError('An error occurred while sending the sign-in link. Please try again.');
//     }
//   };

//   return (
//     <form className="form-container signup" onSubmit={handleSubmit}>
//       <h3 id="signup-heading" className="form-heading">Sign Up</h3>
//       <label className="form-label">Name:</label>
//       <input className="form-input" type="text" onChange={(e) => setName(e.target.value)} value={name} />

//       <label className="form-label">Email address:</label>
//       <input className="form-input" type="email" onChange={(e) => setEmail(e.target.value)} value={email} />

//       <label className="form-label">Password:</label>
//       <input className="form-input" type="password" onChange={(e) => setPassword(e.target.value)} value={password} />

//       <label className="form-label">
//         <input type="checkbox" onChange={(e) => setTermsAccepted(e.target.checked)} checked={termsAccepted} />
//         I accept the terms and conditions
//       </label>
//       {signUpError && <p className="error-message">{signUpError}</p>}

//       <button className="form-button">Sign up</button>

//       <div className="social-buttons">
//         <button className="social-button" onClick={handleGoogleSignIn}>Sign up with Google</button>
//         <button className="social-button" onClick={handleEmailLinkSignIn}>Sign up with Email Link</button>
//       </div>
//     </form>
//   );
// };

// export default SignUpForm;
///////////////////////////////////////// 3 /////////////////////////////////////////

// import "./Form.css";
// import { useState } from "react";
// import { useNavigate } from "react-router-dom";
// import firebase from 'firebase/compat/app'; // Use the 'compat/app' import
// import 'firebase/compat/auth'; // Use the 'compat/auth' import
// import firebaseApp, { auth } from "../Firebase"; // Renamed the import to avoid conflict

// const SignUpForm = ({ communityId }) => {
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const [name, setName] = useState("");
//   const [termsAccepted, setTermsAccepted] = useState(false);
//   const navigate = useNavigate();

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     if (!termsAccepted) {
//       // Show an error message that the user must accept the terms and conditions
//       return;
//     }

//     try {
//       // Create a new user with email and password
//       const userCredential = await auth.createUserWithEmailAndPassword(email, password);
//       const user = userCredential.user;

//       // Update the user's display name
//       await user.updateProfile({ displayName: name });

//       // Sign in the user
//       await auth.signInWithEmailAndPassword(email, password);

//       // Navigate to the desired page after successful sign-up
//       navigate('/dashboard');
//     } catch (error) {
//       console.error('Error signing up:', error);
//       // Handle sign-up error
//     }
//   };

//   return (
//     <form className="form-container signup" onSubmit={handleSubmit}>
//       <h3 id="signup-heading" className="form-heading">Sign Up</h3>
//       <label className="form-label">Name:</label>
//       <input className="form-input" type="text" onChange={(e) => setName(e.target.value)} value={name} />

//       <label className="form-label">Email address:</label>
//       <input className="form-input" type="email" onChange={(e) => setEmail(e.target.value)} value={email} />

//       <label className="form-label">Password:</label>
//       <input className="form-input" type="password" onChange={(e) => setPassword(e.target.value)} value={password} />

//       <label className="form-label">
//         <input type="checkbox" onChange={(e) => setTermsAccepted(e.target.checked)} checked={termsAccepted} />
//         I accept the terms and conditions
//       </label>

//       <button className="form-button">Sign up</button>
//     </form>
//   );
// };

// export default SignUpForm;

// /////////////////////////////////////// 1 /////////////////////////////////////////

// import "./Form.css";
// import { useState } from "react";
// import { useNavigate } from "react-router-dom";
// import { useAuth0 } from "@auth0/auth0-react";

// const SignUpForm = ({ communityId }) => {
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const [name, setName] = useState("");
//   const [termsAccepted, setTermsAccepted] = useState(false);
//   const { loginWithRedirect } = useAuth0();
//   const navigate = useNavigate();

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     if (!termsAccepted) {
//       // Show an error message that the user must accept the terms and conditions
//       return;
//     }

//     // Here you would call the Auth0 Management API to create the user
//     // and set the `terms_accepted` property in the user's `user_metadata`

//     loginWithRedirect({
//       screen_hint: "signup"
//     });
//   };

//   return (
//     <form className="form-container signup" onSubmit={handleSubmit}>
//       <h3 id="signup-heading" className="form-heading">Sign Up</h3>
//       <label className="form-label">Name:</label>
//       <input className="form-input" type="text" onChange={(e) => setName(e.target.value)} value={name} />

//       <label className="form-label">Email address:</label>
//       <input className="form-input" type="email" onChange={(e) => setEmail(e.target.value)} value={email} />

//       <label className="form-label">Password:</label>
//       <input className="form-input" type="password" onChange={(e) => setPassword(e.target.value)} value={password} />

//       <label className="form-label">
//         <input type="checkbox" onChange={(e) => setTermsAccepted(e.target.checked)} checked={termsAccepted} />
//         I accept the terms and conditions
//       </label>

//       <button className="form-button">Sign up</button>
//     </form>
//   );
// };

// export default SignUpForm;
