import { useEffect} from "react"
import { useCommunitiesContext } from "../hooks/useCommunitiesContext"
import "./Login.css";
import LoginForm from "../components/LoginForm"

const Login = () => {
  

  return (
    
  
    <div className="ijoin">

      <img
        src="https://t3.ftcdn.net/jpg/02/48/42/64/360_F_248426448_NVKLywWqArG2ADUxDq6QprtIzsF82dMF.jpg" // Replace with the actual image path or URL
        alt="Image place holder"
        className="community-image" // Add a class for styling if needed
      />
      

      <div className="i-community">
      
        <LoginForm/>
     
      </div>
      </div>

  )
}

export default Login