import { useState } from "react";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import firebaseApp from "../../Firebase";

const ForgotPasswordForm = () => {
  const [email, setEmail] = useState("");
  const auth = getAuth(firebaseApp);

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    try {
      await sendPasswordResetEmail(auth, email);
      alert("If we've registered your email address, we've sent a password reset link to it.");
    } catch (error) {
      console.error('Error sending password reset email:', error);
    }
  };

  return (
    <form className="form-container forgot-password" onSubmit={handleForgotPassword}>
      <h1 className="form-heading">Forgot Password</h1>

      <label className="form-label">Email</label>
      <input className="form-input" type="email" onChange={(e) => setEmail(e.target.value)} value={email} />

      <button className="form-button">Send Reset Link</button>
    </form>
  );
};

export default ForgotPasswordForm;