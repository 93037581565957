import { useAuthContext } from "./useAuthContext";
import { auth } from "../Firebase"; // Import Firebase auth module
import useLocalStorage from "../hooks/useLocalStorage";

export const useLogout = () => {
  const { dispatch } = useAuthContext();

  const logout = async () => {
    // Log out from Firebase
    try {
      await auth.signOut();
    } catch (error) {
      console.error("Failed to log out", error);
    }

    // Remove user from storage
    localStorage.removeItem('user');

    // Dispatch logout action 
    dispatch({type: 'LOGOUT'});
  }

  return {logout};
}