import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import DynamicProfileSeperatePopUpEditcopy from "../components/Profile&Settings/DynamicProfileSeperatePopUpEditcopy";
import { useAuthContext } from '../hooks/useAuthContext';

const JoinAdditionalCommunity = () => {
  const { user } = useAuthContext();
  const [communityName, setCommunityName] = useState(''); // Single string to hold community name
  const navigate = useNavigate();
  const { communityId } = useParams(); // Use useParams to get communityId from URL

  const handleCommunityName = (name) => {
    // Update the community name
    setCommunityName(name);
  };

  const handleCompleteClick = () => {
    // Navigate to /tester2
    navigate('/tester1');
  };

  return (
    <div>
      <h1>Fill in</h1>
      {/* <p>Tell the community a bit about yourself to start connecting with others!</p> */}
      {user && <DynamicProfileSeperatePopUpEditcopy
        user={user}
        communityId={communityId} // Use communityId from useParams
        onCommunityName={handleCommunityName} // No index needed
      />}
      <button onClick={handleCompleteClick}>Complete</button>
    </div>
  );
}

export default JoinAdditionalCommunity;
