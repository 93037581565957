import { useContext } from 'react';
import { MessengerContext } from '../context/MessengerContext';

export const useMessengerContext = () => {
  const context = useContext(MessengerContext);

  if (!context) {
    throw new Error('useMessengerContext must be used within a MessengerContextProvider');
  }

  return context;
};