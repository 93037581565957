import React from 'react';
import { useMessengerContext } from '../../hooks/useMessengerContext';
import "./ChatBoxHeader.css";

const ChatBoxHeader = ({ onBackToChatMenuForCSS, onToChatProfileForCSS }) => {

    const { state, dispatch, } = useMessengerContext();
    const { otherUserDisplay, community, currentConversation } = state;
    console.log('community:', community)
    console.log('currentConversation:', currentConversation)

    return (
      <div className="headerContainer">   
        <button id="backButton" onClick={onBackToChatMenuForCSS}>&#8678;</button>
        <img 
          className="conversationImg" 
          src={currentConversation.members.includes(currentConversation.communityId) ? community?.pics[0] : otherUserDisplay?.pics[0]} 
          alt="" 
          onClick={onToChatProfileForCSS}
        />
        <div id="chatTitle">
          Chat with {
            currentConversation.members.includes(currentConversation.communityId) 
              ? `${community?.title} AI Bot` 
              : otherUserDisplay?.name
          }
        </div>
      </div>
    );  
};

export default ChatBoxHeader;