// The issue seems to be with how the index is passed to the DynamicProfileSeperatePopUpEditcopy component.
// To ensure the index is correctly passed and used, we can explicitly pass it as a prop and use it within the component.

import React, { useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import "./ProfileUpdate.css";
import DynamicProfileSeperatePopUpEditcopy from "../components/Profile&Settings/DynamicProfileSeperatePopUpEditcopy";
import ProfileBasics from "../components/Profile&Settings/ProfileBasics";
import NearestLocationForm from '../components/Location/NearestLocationField';
import ProfilePicturesGrid from '../components/Uploads/ProfilePhotoUpload';
import { useAuthContext } from '../hooks/useAuthContext';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const ProfileUpdate = () => {
  const [expanded, setExpanded] = useState(false);
  const [communityNames, setCommunityNames] = useState([]);
  const [imageLinks, setImageLinks] = useState([]);
  const [profileInfo, setProfileInfo] = useState(null);
  const [dynamicProfile, setDynamicProfile] = useState([]);

  const { user } = useAuthContext();

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleImageLinksUpdate = (links) => {
    setImageLinks(links);
  };

  console.log('userlistOfCommunities', user.listOfCommunities)

  const handleCommunityName = (communityName, index) => {
    setCommunityNames(prevNames => {
      const newNames = [...prevNames];
      newNames[index] = communityName;
      return newNames;
    });
  };

  const updateDynamicProfile = (index, value) => {
    setDynamicProfile(prevProfiles => {
      const updatedProfiles = [...prevProfiles];
      updatedProfiles[index] = value;
      return updatedProfiles;
    });
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="profile-update-container">
        <h1>Edit Your Profile!</h1>
        <h5>You have a separate profile for each group you are part, each which shares your basic info, photos and location</h5>
        <div id="profileEditableDetails">
          <div className="accordion-container">
            {/* Basic Info Accordion */}
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} className="custom-accordion-summary">
                <h2>Basic Info - {profileInfo ? <span style={{color: 'green'}}>✓</span> : <span style={{color: 'red'}}>⚠️</span>} </h2>
              </AccordionSummary>
              <AccordionDetails>
                <ProfileBasics setProfileInfo={setProfileInfo} />
              </AccordionDetails> 
            </Accordion>
            {/* Location Accordion */}
            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <h2>Location</h2>
              </AccordionSummary>
              <AccordionDetails>
                <NearestLocationForm />
              </AccordionDetails>
            </Accordion>
            {/* Photos Accordion */}
            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} className="custom-accordion-summary">
                <div className="summary-content">
                  <h2>Photos</h2>
                </div>
                <div className={`summary-image ${expanded === 'panel3' ? 'summary-image-hidden' : ''}`}>
                  {imageLinks.slice(0, 4).map((link, index) => (
                    <img key={index} id={`photo-icon-${index}`} src={link} alt={`icon-${index}`} />
                  ))}
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <ProfilePicturesGrid onImageLinksUpdate={handleImageLinksUpdate} />
              </AccordionDetails>
            </Accordion>
            {/* Other Accordions */}
            {Array.isArray(user.listOfCommunities) && user.listOfCommunities.map((community, index) => (
              <Accordion key={index} expanded={expanded === `panel${index + 5}`} onChange={handleChange(`panel${index + 5}`)}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                {console.log('dynamicProfile[index]',dynamicProfile)} <h2>{`${communityNames[index]} Info`} {dynamicProfile[index] ? <span style={{color: 'green'}}>✓</span> : <span style={{color: 'red'}}>⚠️</span>} </h2>                </AccordionSummary>
                <AccordionDetails>
                  <DynamicProfileSeperatePopUpEditcopy
                    user={user}
                    communityId={community.toString()}
                    index={index} // Explicitly pass index as a prop
                    onCommunityName={(name) => handleCommunityName(name, index)}
                    setDynamicProfile={(value) => updateDynamicProfile(index, value)}
                  />
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        </div>
      </div>
    </DndProvider>
  );
}

export default ProfileUpdate;

// import React, { useState } from 'react';
// import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import "./ProfileUpdate.css";
// import DynamicProfileSeperatePopUpEditcopy from "../components/Profile&Settings/DynamicProfileSeperatePopUpEditcopy";
// import ProfileBasics from "../components/Profile&Settings/ProfileBasics";
// import NearestLocationForm from '../components/Location/NearestLocationField';
// import ProfilePicturesGrid from '../components/Uploads/ProfilePhotoUpload';
// import { useAuthContext } from '../hooks/useAuthContext';
// import { DndProvider } from 'react-dnd';
// import { HTML5Backend } from 'react-dnd-html5-backend';

// const ProfileUpdate = () => {
//   const [expanded, setExpanded] = useState(false);
//   const [communityNames, setCommunityNames] = useState([]); // Array to hold community names
//   const [imageLinks, setImageLinks] = useState([]); // State to hold image links
//   const [profileInfo, setProfileInfo] = useState(null);
//   const [dynamicProfile, setDynamicProfile] = useState([]);


//   const { user } = useAuthContext();

//   console.log('profileUpdateuser', user)

//   const handleChange = (panel) => (event, isExpanded) => {
//     setExpanded(isExpanded ? panel : false);
//   };

//   // Callback function to update image links state
//   const handleImageLinksUpdate = (links) => {
//     setImageLinks(links);
//     console.log('links',links)
//   };

//   const handleCommunityName = (communityName, index) => {
//     // Update the community name at the specific index
//     setCommunityNames(prevNames => {
//       const newNames = [...prevNames];
//       newNames[index] = communityName;
//       return newNames;
//     });
//   };


//   const updateDynamicProfile = (index, value) => {
//     // Update the dynamic profile at the specific index
//     setDynamicProfile(prevProfiles => {
//       const updatedProfiles = [...prevProfiles];
//       updatedProfiles[index] = value;
//       return updatedProfiles;
//     });
//   };


//   return (
//     <DndProvider backend={HTML5Backend}>
//       <div className="profile-update-container">
//         <h1>Edit Your Profile!</h1>
//         <h5>You have a seperate profile for each group you are part, each which shares your basic info, photos and location</h5>
//         <div id="profileEditableDetails">
//           <div className="accordion-container">
//             <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
//               <AccordionSummary expandIcon={<ExpandMoreIcon />} className="custom-accordion-summary">
//                 <div className="summary-content">
//                   <h2>Photos</h2>
//                 </div>
//                 <div className={`summary-image ${expanded === 'panel3' ? 'summary-image-hidden' : ''}`}>
//                   {imageLinks.slice(0, 4).map((link, index) => (
//                     <img key={index} id={`photo-icon-${index}`} src={link} alt={`icon-${index}`} />
//                   ))}
//                 </div>
//               </AccordionSummary>
//               <AccordionDetails>
//                 <ProfilePicturesGrid onImageLinksUpdate={handleImageLinksUpdate} />
//               </AccordionDetails>
//             </Accordion>
//             {/* Basic Info Accordion */}
//             <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
//               <AccordionSummary expandIcon={<ExpandMoreIcon />} className="custom-accordion-summary">
//                 <h2>Basic Info - {profileInfo ? <span style={{color: 'green'}}>✓</span> : <span style={{color: 'red'}}>⚠️</span>} </h2>
//               </AccordionSummary>
//               <AccordionDetails>
//                 <ProfileBasics setProfileInfo={setProfileInfo} />
//               </AccordionDetails> 
//             </Accordion>
//             {/* Location Accordion */}
//             <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
//               <AccordionSummary expandIcon={<ExpandMoreIcon />}>
//                 <h2>Location</h2>
//               </AccordionSummary>
//               <AccordionDetails>
//                 <NearestLocationForm />
//               </AccordionDetails>
//             </Accordion>
// {/* Dynamic Community Accordions */}
// {Array.isArray(user.listOfCommunities) && user.listOfCommunities.map((community, index) => (
//   <Accordion key={index} expanded={expanded === `panel${index + 5}`} onChange={handleChange(`panel${index + 5}`)}>
//  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
//   {/* Update to check dynamicProfile for the current index */}
//   <h2>{`${communityNames[index]} Info`} {dynamicProfile[index] ? <span style={{color: 'green'}}>✓</span> : <span style={{color: 'red'}}>⚠️</span>} </h2>
// </AccordionSummary>
// <AccordionDetails>
//   <DynamicProfileSeperatePopUpEditcopy
//     user={user}
//     communityId={community.toString()}
//     onCommunityName={(name) => handleCommunityName(name, index)}
//     // Update setDynamicProfile to handle index using the updated approach
//     setDynamicProfile={(value) => updateDynamicProfile(index, value)}
//   />
//   {console.log('user profile update', user)}
// </AccordionDetails>
//   </Accordion>
// ))}
//           </div>
//         </div>
//       </div>
//     </DndProvider>
//   );
// }

// export default ProfileUpdate;


// import React, { useState, useEffect } from 'react';
// import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import "./ProfileUpdate.css";
// import DynamicProfileSeperatePopUpEditcopy from "../components/Profile&Settings/DynamicProfileSeperatePopUpEditcopy";
// import ProfileBasics from "../components/Profile&Settings/ProfileBasics";
// import { Oval as Loader } from 'react-loader-spinner';
// import NearestLocationForm from '../components/Location/NearestLocationField';
// import ProfilePicturesGrid from '../components/Uploads/ProfilePhotoUpload';
// import { useAuthContext } from '../hooks/useAuthContext';

// const ProfileUpdate = () => {
//   const [loading, setLoading] = useState(true);
//   const [expanded, setExpanded] = useState(false);
//   const [imageLinks, setImageLinks] = useState([]); // State to hold image links

//   const { user } = useAuthContext();

//   const handleChange = (panel) => (event, isExpanded) => {
//     setExpanded(isExpanded ? panel : false);
//   };

//   useEffect(() => {
//     setTimeout(() => {
//       setLoading(false);
//     }, 4000);
//   }, []);

//   // Callback function to update image links state
//   const handleImageLinksUpdate = (links) => {
//     setImageLinks(links);
//     console.log('links',links)
//   };

//   return (
//     <div className="profile-update-container">
//       <h1>Edit Your Profile!</h1>
//       {loading ? (
//         <div className="spinner-container">
//           <Loader color="#00BFFF" height={80} width={80} />
//         </div>
//       ) : (
//         <div id="profileEditableDetails">
//           <ProfilePicturesGrid />
//           {/* Other accordions */}
//           <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
//             <AccordionSummary expandIcon={<ExpandMoreIcon />}>
//               <h2>Photos</h2>
//               {/* Display first three image links as icons */}
//               {imageLinks.slice(0, 3).map((link, index) => (
//                 <img key={index} src={link} alt={`icon-${index}`} style={{ width: 30, height: 30, marginRight: 5 }} />
//               ))}
//             </AccordionSummary>
//             <AccordionDetails>
//               {/* Pass the callback function as a prop */}
//               <ProfilePicturesGrid onImageLinksUpdate={handleImageLinksUpdate} />
//             </AccordionDetails>
//           </Accordion>
//           {/* Community accordions */}
//         </div>
//       )}
//     </div>
//   );
// }

// export default ProfileUpdate;

// import React, { useState, useEffect } from 'react';
// import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import "./ProfileUpdate.css";
// import DynamicProfileSeperatePopUpEditcopy from "../components/Profile&Settings/DynamicProfileSeperatePopUpEditcopy";
// import ProfileBasics from "../components/Profile&Settings/ProfileBasics";
// import { Oval as Loader } from 'react-loader-spinner';
// import NearestLocationForm from '../components/Location/NearestLocationField';
// import ProfilePicturesGrid from '../components/Uploads/ProfilePhotoUpload';
// import { useAuthContext } from '../hooks/useAuthContext';
// import { DndProvider } from 'react-dnd';
// import { HTML5Backend } from 'react-dnd-html5-backend';

// const ProfileUpdate = () => {
//   const [loading, setLoading] = useState(true);
//   const [expanded, setExpanded] = useState(false);
//   const [communityNames, setCommunityNames] = useState([]); // Array to hold community names
//   const [imageLinks, setImageLinks] = useState([]); // State to hold image links

//   const { user } = useAuthContext();

//   console.log('profileUpdateuser', user)

//   const handleChange = (panel) => (event, isExpanded) => {
//     setExpanded(isExpanded ? panel : false);
//   };

//   useEffect(() => {
//     // Simulate the loading of components
//     setTimeout(() => {
//       setLoading(false);
//     }, 4000);
//   }, []);

//     // Callback function to update image links state
//   const handleImageLinksUpdate = (links) => {
//     setImageLinks(links);
//     console.log('links',links)
//   };

//   const handleCommunityName = (communityName, index) => {
//     // Update the community name at the specific index
//     setCommunityNames(prevNames => {
//       const newNames = [...prevNames];
//       newNames[index] = communityName;
//       return newNames;
//     });
//   };
//   return (
//     <DndProvider backend={HTML5Backend}>
//       <div className="profile-update-container">
//         <h1>Edit Your Profile!</h1>
//         {loading ? (
//           <div className="spinner-container">
//             <Loader color="#00BFFF" height={80} width={80} />
//           </div>
//         ) : (
//           <div id="profileEditableDetails">
//             <div className="accordion-container">
//               <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
//                 <AccordionSummary expandIcon={<ExpandMoreIcon />} className="custom-accordion-summary">
//                   <div className="summary-content">
//                     <h2>Photos</h2>
//                   </div>
//                   <div className={`summary-image ${expanded === 'panel3' ? 'summary-image-hidden' : ''}`}>
//                     {imageLinks.slice(0, 4).map((link, index) => (
//                       <img key={index} id={`photo-icon-${index}`} src={link} alt={`icon-${index}`} />
//                     ))}
//                   </div>
//                 </AccordionSummary>
//                 <AccordionDetails>
//                   <ProfilePicturesGrid onImageLinksUpdate={handleImageLinksUpdate} />
//                 </AccordionDetails>
//               </Accordion>
//  {/* Basic Info Accordion */}
//  <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
//  <AccordionSummary expandIcon={<ExpandMoreIcon />} className="custom-accordion-summary">
//      <h2>Basic Info</h2>
//    </AccordionSummary>
//    <AccordionDetails>
//      <ProfileBasics />
//    </AccordionDetails>
//  </Accordion>

//  {/* Location Accordion */}
//  <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
//    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
//      <h2>Location</h2>
//    </AccordionSummary>
//    <AccordionDetails>
//      <NearestLocationForm />
//    </AccordionDetails>
//  </Accordion>


//  {/* Dynamic Community Accordions */}
//  {Array.isArray(user.listOfCommunities) && user.listOfCommunities.map((community, index) => (
//    <Accordion key={index} expanded={expanded === `panel${index + 5}`} onChange={handleChange(`panel${index + 5}`)}>
//      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
//        <h2>{`${communityNames[index]} Info`}</h2>
//      </AccordionSummary>
//      <AccordionDetails>
//        <DynamicProfileSeperatePopUpEditcopy
//          user={user}
//          communityId={community.toString()}
//          onCommunityName={(name) => handleCommunityName(name, index)}
//        />
//        {console.log('user profile update',user)}
//      </AccordionDetails>
//    </Accordion>
//  ))}
//  </div>
// </div>
// )}
// </div>
// </DndProvider>

//   );
// }

// export default ProfileUpdate;




/////////////////////////////////////






// import React, { useState, useEffect } from 'react';
// import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import "./ProfileUpdate.css";
// import DynamicProfileSeperatePopUpEdit from "../components/Profile&Settings/DynamicProfileSeperatePopUpEdit";
// import ProfileBasics from "../components/Profile&Settings/ProfileBasics";
// import { Link } from 'react-router-dom';
// import { Oval as Loader } from 'react-loader-spinner'; // Import the Oval spinner
// import NearestLocationForm from '../components/Location/NearestLocationField';
// import ProfilePicturesGrid from '../components/Uploads/ProfilePhotoUpload';

// const ProfileUpdate = () => {
//   const [loading, setLoading] = useState(true);
//   const [expanded, setExpanded] = useState(false);

//   const handleChange = (panel) => (event, isExpanded) => {
//     setExpanded(isExpanded ? panel : false);
//   };

//   useEffect(() => {
//     // Simulate the loading of components
//     setTimeout(() => {
//       setLoading(false);
//     }, 2000); // Replace this with the actual loading logic for your components
//   }, []);

//   return (
//     <div className="profile-update-container">
//       <h1>Edit Your Profile!</h1>
//       {loading ? (
//         <div className="spinner-container">
//           <Loader color="#00BFFF" height={80} width={80} />
//         </div>
//       ) : (

        
//         <>
//                       <ProfilePicturesGrid />

//           <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
//             <AccordionSummary expandIcon={<ExpandMoreIcon />}>
//               <h2>Basic Info</h2>
//             </AccordionSummary>
//             <AccordionDetails>
//               <ProfileBasics />
//             </AccordionDetails>
//           </Accordion>

//           <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
//             <AccordionSummary expandIcon={<ExpandMoreIcon />}>
//               <h2>Location</h2>
//             </AccordionSummary>
//             <AccordionDetails>
//               <NearestLocationForm />
//             </AccordionDetails>
//           </Accordion>

//           <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
//             <AccordionSummary expandIcon={<ExpandMoreIcon />}>
//               <h2>Photos</h2>
//             </AccordionSummary>
//             <AccordionDetails>
//             </AccordionDetails>
//           </Accordion>

//           <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
//             <AccordionSummary expandIcon={<ExpandMoreIcon />}>
//               <h2>Theatre Lovers Info</h2>
//             </AccordionSummary>
//             <AccordionDetails>
//               <DynamicProfileSeperatePopUpEdit />
//             </AccordionDetails>
//           </Accordion>
//         </>
//       )}


//     </div>
//   );
// }

// export default ProfileUpdate;




// import React, { useState, useEffect } from 'react';
// import "./ProfileUpdate.css";
// import DynamicProfileSeperatePopUpEdit from "../components/Profile&Settings/DynamicProfileSeperatePopUpEdit";
// import ProfileBasics from "../components/Profile&Settings/ProfileBasics";
// import { Link } from 'react-router-dom';
// import { Oval as Loader } from 'react-loader-spinner'; // Import the Oval spinner
// import NearestLocationForm from '../components/Location/NearestLocationField';
// import ProfilePicturesGrid from '../components/Uploads/ProfilePhotoUpload';

// const ProfileUpdate = () => {
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     // Simulate the loading of components
//     setTimeout(() => {
//       setLoading(false);
//     }, 2000); // Replace this with the actual loading logic for your components
//   }, []);

//   return (
//     <div> {/* Single parent div replaces React fragments */}
      
//       <div>
//         <h1>Your Profile!</h1>
//       </div>
//       {loading ? (
//         <div className="spinner-container"> {/* Add a container for the spinner */}
//           <Loader color="#00BFFF" height={80} width={80} />
//         </div>
//       ) : (
//         <div> 
//                   <h1>Your Basic Info</h1>

//           <ProfileBasics />
//           <NearestLocationForm />
//           <ProfilePicturesGrid />
//           <DynamicProfileSeperatePopUpEdit />
  
//         </div>
//       )}

// <div>
//         <span><Link to="/settings">Settings</Link></span>
//       </div>
//       <div>
//         <span><Link to="/preferences">Preferences</Link></span>
//       </div>
//     </div>
//   );
// }

// export default ProfileUpdate;
// import React, { useState } from 'react';
// import "./ProfileUpdate.css";
// import DynamicProfileSeperatePopUpEdit from "../components/Profile&Settings/DynamicProfileSeperatePopUpEdit"; // Assuming both files are in the same directory
// import ProfileBasics from "../components/Profile&Settings/ProfileBasics";
// import { Link } from 'react-router-dom'


// const ProfileUpdate = () => {


//   return (
//     <>
//       <header>
//         <h1>Your Profile!</h1>
//       </header>
//       <div className="getstartedbtn">
//             <span><Link to="/settings">Settings</Link></span>
//         </div>
//         <div className="getstartedbtn">
//             <span><Link to="/preferences">Preferences</Link></span>
//         </div>
//       <ProfileBasics />
//       <DynamicProfileSeperatePopUpEdit /> 
//     </>
//   );
// }

// export default ProfileUpdate;


// import React, { useState } from 'react';
// import "./ProfileUpdate.css";
// import ProfileCommunityDynamic from "../components/Profile&Settings/ProfileCommunityDynamic";
// import ProfileBasics from "../components/Profile&Settings/ProfileBasics";

// const ProfileUpdate = () => {
//   const [activeDropdown, setActiveDropdown] = useState(null);

//   const toggleDropdown = (dropdownName) => {
//     setActiveDropdown(activeDropdown === dropdownName ? null : dropdownName);
//   };

//   return (
//     <>
//       <h1>Your Profile </h1>
//       <div>
//         <button
//           className={`profile-button ${activeDropdown === 'profile' ? 'profile-button-active' : ''}`}
//           onClick={() => toggleDropdown('profile')}
//         >
//           My Basics
//         </button>
//         {activeDropdown === 'profile' && (
//           <div className="expanded-content">
//             <ProfileBasics />
//           </div>
//         )}

//         <button
//           className={`profile-button ${activeDropdown === 'basics' ? 'profile-button-active' : ''}`}
//           onClick={() => toggleDropdown('basics')}
//         >
//           Commmunity Info
//         </button>
//         {activeDropdown === 'basics' && (
//           <div className="expanded-content">
//             <ProfileCommunityDynamic />
//           </div>
//         )}
//       </div>
//     </>
//   );
// };

// export default ProfileUpdate;

// // ** CHECK BOXES ARE GENERATED! // 

// import React, { useState, useEffect } from 'react';
// import "./DynamicForm.css";
// import { useAuthContext } from "../hooks/useAuthContext"

// const DynamicForm = () => {
//   const [json, setJson] = useState(null);
//   const [formData, setFormData] = useState(null);
//   const { user }= useAuthContext()

//   useEffect(() => {
//     const fetchProfile = async () => {
//       const response = await fetch('/api/dynamic/', {
//         method: 'POST',
//         body: JSON.stringify({ _id: '653aa4a6ce9360c69df66a49'  }), // user.userId
//         // body: JSON.stringify({ _id: user.userId }), // 
//         headers: {
//           'Content-Type': 'application/json'
//         }
//       });
//       const json = await response.json();
//       console.log('fetchProfile:', json)
//       setJson(json);
//       const userSchema = json.schema.CommunityProfiles.obj;
//       const userFormData = json.user?.CommunityProfiles ?? {};
//       setFormData({ schema: userSchema, data: userFormData });
//     };

//     fetchProfile();
//   }, []);

//   const handleInputChange = (event, communityProfileName, fieldName) => {
//     const newFormData = { ...formData };
//     if (!newFormData.data[communityProfileName]) {
//       newFormData.data[communityProfileName] = {};
//     }
//     newFormData.data[communityProfileName][fieldName] = event.target.value;
//     setFormData(newFormData);
//   };

//   const handleCheckboxChange = (event, communityProfileName, fieldName, option) => {
//     const newFormData = { ...formData };
//     if (!newFormData.data[communityProfileName]) {
//       newFormData.data[communityProfileName] = {};
//     }
//     const interests = newFormData.data[communityProfileName][fieldName] || [];
//     if (event.target.checked) {
//       interests.push(option);
//     } else {
//       const index = interests.indexOf(option);
//       if (index !== -1) {
//         interests.splice(index, 1);
//       }
//     }
//     newFormData.data[communityProfileName][fieldName] = interests;
//     setFormData(newFormData);
//   };

//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     const response = await fetch('/api/dynamic/update', {
//       method: 'POST',
//       body: JSON.stringify({ _id: user.UserId, CommunityProfiles: formData.data }),
//       headers: {
//         'Content-Type': 'application/json'
//       }
//     });
//     const json = await response.json();
//     console.log(json);
//   };

//   const renderCommunityProfiles = () => {
//     const communityProfiles = formData.data;
//     const communityProfilesArray = [];

//     const schema = formData.schema;
//     console.log('schema:', schema)
//     for (const [communityProfileName, communityProfileSchema] of Object.entries(schema)) {
//       const formattedCommunityProfileName = communityProfileName.replace(/([A-Z])/g, ' $1').trim();
//       const fields = Object.keys(communityProfileSchema);

//       const fieldElements = fields.map((fieldName) => {
//         const fieldType = communityProfileSchema[fieldName].type;
//         const fieldEnum = communityProfileSchema[fieldName].enum;
//         const fieldValue = communityProfiles[communityProfileName]?.[fieldName] ?? "";
//         if (fieldType === 'String' && fieldEnum) {
//           const optionElements = fieldEnum.map((option) => (
//             <option key={option} value={option}>
//               {option}
//             </option>
//           ));
//           return (
//             <div key={fieldName}>
//               <label htmlFor={fieldName}>{fieldName}</label>
//               <select
//                 id={fieldName}
//                 name={fieldName}
//                 value={fieldValue}
//                 onChange={(event) => handleInputChange(event, communityProfileName, fieldName)}
//               >
//                 {optionElements}
//               </select>
//             </div>
//           );

//         } 
//         else if (Array.isArray(fieldType) && fieldType[0].type === 'String' && fieldType[0].htmlref === 'checkbox') {
//           const optionElements = fieldType[0].enum.map((option) => (
//             <div key={option}>
//               <input
//                 type="checkbox"
//                 id={option}
//                 name={fieldName}
//                 value={option}
//                 checked={fieldValue.includes(option)}
//                 onChange={(event) => handleCheckboxChange(event, communityProfileName, fieldName, option)}
//               />
//               <label htmlFor={option}>{option}</label>
//             </div>
//           ));
//           return (
//             <div key={fieldName}>
//               <label>{fieldName}</label>
//               {optionElements}
//             </div>
//           );        
//         } else if (fieldType === 'String') {
//           return (
//             <div key={fieldName}>
//               <label htmlFor={fieldName}>{fieldName}</label>
//               <input
//                 type="text"
//                 id={fieldName}
//                 name={fieldName}
//                 value={fieldValue}
//                 onChange={(event) => handleInputChange(event, communityProfileName, fieldName)}
//               />
//             </div>
//           );
//         } else {
//           return null;
//         }
//   });
//       communityProfilesArray.push(
//         <div key={communityProfileName}>
//           <h3>{formattedCommunityProfileName}</h3>
//           {fieldElements}
//         </div>
//       );
//     }
//     console.log('communityProfilesArray:', communityProfilesArray)
//     return communityProfilesArray;
//   };

//   return (
//     <header>
//       <h1>Dynamic Form</h1>
//       {json && json.user && json.user.CommunityProfiles && (
//         <form onSubmit={handleSubmit}>
//           <div>
//             <p>_id: {json.user._id?.toString()}</p>
//             <p>First Name: {json.user.firstName}</p>
//             <p>Last Name: {json.user.lastName}</p>
//             <p>Age: {json.user.age}</p>
//             <p>List of Communities: {json.user.ListOfCommunities?.join(', ')}</p>
//             <p>Community Profiles:</p>
//             <ul>{renderCommunityProfiles()}</ul>
//             <button type="submit">Submit</button>
//             <p>__v: {json.user.__v}</p>
//             <p>userId: {json.user.userId?.toString()}</p>
//             <p>Schema: {JSON.stringify(json.schema)}</p>
//           </div>
//         </form>
//       )}
//     </header>
//   );
// };

// export default DynamicForm;

// ***


// // ***

// import React, { useState, useEffect } from 'react';
// import "./DynamicForm.css";
// import { useAuthContext } from "../hooks/useAuthContext"

// const DynamicForm = () => {
//   const [json, setJson] = useState(null);
//   const [formData, setFormData] = useState(null);
//   const { user }= useAuthContext()

//   useEffect(() => {
//     const fetchProfile = async () => {
//       const response = await fetch('/api/dynamic/', {
//         method: 'POST',
//         // body: JSON.stringify({ _id: '65310360235d4d38f2f395c1'  }), // user.userId
//         body: JSON.stringify({ _id: user.userId }), // 
//         headers: {
//           'Content-Type': 'application/json'
//         }
//       });
//       const json = await response.json();
//       console.log('fetchProfile:', json)
//       setJson(json);
//       const userSchema = json.schema.CommunityProfiles.obj;
//       const userFormData = json.user?.CommunityProfiles ?? {};
//       setFormData({ schema: userSchema, data: userFormData });
//     };

//     fetchProfile();
//   }, []);

//   const handleInputChange = (event, communityProfileName, fieldName) => {
//     const newFormData = { ...formData };
//     if (!newFormData.data[communityProfileName]) {
//       newFormData.data[communityProfileName] = {};
//     }
//     newFormData.data[communityProfileName][fieldName] = event.target.value;
//     setFormData(newFormData);
//   };

//   const handleCheckboxChange = (event, communityProfileName, fieldName, option) => {
//     const newFormData = { ...formData };
//     if (!newFormData.data[communityProfileName]) {
//       newFormData.data[communityProfileName] = {};
//     }
//     const interests = newFormData.data[communityProfileName][fieldName] || [];
//     if (event.target.checked) {
//       interests.push(option);
//     } else {
//       const index = interests.indexOf(option);
//       if (index !== -1) {
//         interests.splice(index, 1);
//       }
//     }
//     newFormData.data[communityProfileName][fieldName] = interests;
//     setFormData(newFormData);
//   };

//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     const response = await fetch('/api/dynamic/update', {
//       method: 'POST',
//       body: JSON.stringify({ _id: user.UserId, CommunityProfiles: formData.data }),
//       headers: {
//         'Content-Type': 'application/json'
//       }
//     });
//     const json = await response.json();
//     console.log(json);
//   };

//   const renderCommunityProfiles = () => {
//     const communityProfiles = formData.data;
//     const communityProfilesArray = [];

//     const schema = formData.schema;
//     console.log('schema:', schema)
//     for (const [communityProfileName, communityProfileSchema] of Object.entries(schema)) {
//       const formattedCommunityProfileName = communityProfileName.replace(/([A-Z])/g, ' $1').trim();
//       const fields = Object.keys(communityProfileSchema);
//       const fieldElements = fields.map((fieldName) => {
//         const fieldType = communityProfileSchema[fieldName].type;
//         const fieldEnum = communityProfileSchema[fieldName].enum;
//         const fieldValue = communityProfiles[communityProfileName]?.[fieldName] ?? "";
//         if (fieldType === 'String' && fieldEnum) {
//           const optionElements = fieldEnum.map((option) => (
//             <option key={option} value={option}>
//               {option}
//             </option>
//           ));
//           return (
//             <div key={fieldName}>
//               <label htmlFor={fieldName}>{fieldName}</label>
//               <select
//                 id={fieldName}
//                 name={fieldName}
//                 value={fieldValue}
//                 onChange={(event) => handleInputChange(event, communityProfileName, fieldName)}
//               >
//                 {optionElements}
//               </select>
//             </div>
//           );
//         } else if (fieldType === 'String' && fieldName === 'Interests') {
//           const optionElements = fieldEnum.map((option) => (
//             <div key={option}>
//               <input
//                 type="checkbox"
//                 id={option}
//                 name={fieldName}
//                 value={option}
//                 checked={fieldValue.includes(option)}
//                 onChange={(event) => handleCheckboxChange(event, communityProfileName, fieldName, option)}
//               />
//               <label htmlFor={option}>{option}</label>
//             </div>
//           ));
//           return (
//             <div key={fieldName}>
//               <label>{fieldName}</label>
//               {optionElements}
//             </div>
//           );
//         } else if (fieldType === 'String') {
//           return (
//             <div key={fieldName}>
//               <label htmlFor={fieldName}>{fieldName}</label>
//               <input
//                 type="text"
//                 id={fieldName}
//                 name={fieldName}
//                 value={fieldValue}
//                 onChange={(event) => handleInputChange(event, communityProfileName, fieldName)}
//               />
//             </div>
//           );
//         } else {
//           return null;
//         }
//       });
//       communityProfilesArray.push(
//         <div key={communityProfileName}>
//           <h3>{formattedCommunityProfileName}</h3>
//           {fieldElements}
//         </div>
//       );
//     }
//     console.log('communityProfilesArray:', communityProfilesArray)
//     return communityProfilesArray;
//   };

//   return (
//     <header>
//       <h1>Dynamic Form</h1>
//       {json && json.user && json.user.CommunityProfiles && (
//         <form onSubmit={handleSubmit}>
//           <div>
//             <p>_id: {json.user._id?.toString()}</p>
//             <p>First Name: {json.user.firstName}</p>
//             <p>Last Name: {json.user.lastName}</p>
//             <p>Age: {json.user.age}</p>
//             <p>List of Communities: {json.user.ListOfCommunities?.join(', ')}</p>
//             <p>Community Profiles:</p>
//             <ul>{renderCommunityProfiles()}</ul>
//             <button type="submit">Submit</button>
//             <p>__v: {json.user.__v}</p>
//             <p>userId: {json.user.userId?.toString()}</p>
//             <p>Schema: {JSON.stringify(json.schema)}</p>
//           </div>
//         </form>
//       )}
//     </header>
//   );
// };

// export default DynamicForm;

// //***

// //CODE A 

// import React, { useState, useEffect } from 'react';
// import "./DynamicForm.css";
// import { useAuthContext } from "../hooks/useAuthContext"

// const DynamicForm = () => {
//   const [json, setJson] = useState(null);
//   const [formData, setFormData] = useState(null);
//   const { user }= useAuthContext()

//   useEffect(() => {
//     const fetchProfile = async () => {

//     const response = await fetch('/api/dynamic/', {
//         method: 'POST',
//         body: JSON.stringify({ _id: user.userId }), // _id: user.userId   65310360235d4d38f2f395c1  '65378b5ea601bc00d090069f' 
//         headers: {
//           'Content-Type': 'application/json'
//         }
//       });
//       const json = await response.json();
//       setJson(json);
//       console.log('Dynamic Form Json from dynamic.js:', json);
//       // console.log('console.log(json.user.CommunityProfiles:', json.user.CommunityProfiles)
//       console.log('console.log(json.schema.CommunityProfiles):', json.schema.CommunityProfiles)
//       const userSchema = json.schema.CommunityProfiles.obj;
//       const userFormData = json.user?.CommunityProfiles ?? {};
//       setFormData({ schema: userSchema, data: userFormData });

//     };

//     fetchProfile();
//   }, []);

//   const handleInputChange = (event, communityProfileName, fieldName) => {
//     const newFormData = { ...formData };
//     if (!newFormData.data[communityProfileName]) {
//       newFormData.data[communityProfileName] = {};
//     }
//     newFormData.data[communityProfileName][fieldName] = event.target.value;
//     setFormData(newFormData);
//   };


//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     const response = await fetch('/api/dynamic/update', {
//       method: 'POST',
//       body: JSON.stringify({ _id: user.UserId, CommunityProfiles: formData.data }),
//       headers: {
//         'Content-Type': 'application/json'
//       }
//     });
//     console.log('CommunityProfiles formData.data from dynamicform.js:', formData.data)
//     const json = await response.json();
//     console.log(json);
//   };

//   const renderCommunityProfiles = () => {
//     const communityProfiles = formData.data;
//     const communityProfilesArray = [];

//     const schema = formData.schema;
//     for (const [communityProfileName, communityProfileSchema] of Object.entries(schema)) {
      
//       console.log(`Community profile name: ${communityProfileName}`);
//       console.log(`Community profile schema: ${communityProfileSchema}`);

//       // Add spaces to community profile name if it is camel case
//       const formattedCommunityProfileName = communityProfileName.replace(/([A-Z])/g, ' $1').trim();
//       const fields = Object.keys(communityProfileSchema.type);
//       // console.log('fields (object.keys):', fields)  
//       const fieldElements = fields.map((fieldName) => {
//         const fieldType = communityProfileSchema.type[fieldName].type;
//         // console.log('communityProfileSchema.type[fieldName].type:', fieldType)
//         const fieldEnum = communityProfileSchema.type[fieldName].enum;
//         // console.log('communityProfileSchema.type[fieldName].enum:', fieldEnum)
//         // const fieldValue = communityProfiles[communityProfileName]?.[fieldName] || fieldEnum[0];
//         const fieldValue = communityProfiles[communityProfileName]?.[fieldName] ?? "";
//         // console.log('communityProfiles[communityProfileName]?.[fieldName] ?? ', fieldValue)
//         if (fieldType === 'String' && fieldEnum) {
//           console.log('** String && fieldEnum')
//           const optionElements = fieldEnum.map((option) => (
//             <option key={option} value={option}>
//               {option}
//             </option>
//           ));
//           return (
//             <div key={fieldName}>
//               <label htmlFor={fieldName}>{fieldName}</label>
//               <select
//                 id={fieldName}
//                 name={fieldName}
//                 value={fieldValue}
//                 onChange={(event) => handleInputChange(event, communityProfileName, fieldName)}
//               >
//                 {optionElements}
//               </select>
//             </div>
//           );
//         } else if (fieldType === 'String') {
//           return (
//             <div key={fieldName}>
//               <label htmlFor={fieldName}>{fieldName}</label>
//               <input
//                 type="text"
//                 id={fieldName}
//                 name={fieldName}
//                 value={fieldValue}
//                 onChange={(event) => handleInputChange(event, communityProfileName, fieldName)}
//               />
//             </div>
//           );
//         } else {
//           return null;
//         }
//       });
//       communityProfilesArray.push(
//         <div key={communityProfileName}>
//           <h3>{formattedCommunityProfileName}</h3>
//           {fieldElements}
//         </div>
//       );
//     }

//     return communityProfilesArray;
//   };

//   return (
//     <header>
//       <h1>Dynamic FormM</h1>
//       {json && json.user && json.user.CommunityProfiles && (
//           <form onSubmit={handleSubmit}>
//           <div>
//             <p>_id: {json.user._id?.toString()}</p>
//             <p>First Name: {json.user.firstName}</p>
//             <p>Last Name: {json.user.lastName}</p>
//             <p>Age: {json.user.age}</p>
//             <p>List of Communities: {json.user.ListOfCommunities?.join(', ')}</p>
//             <p>Community Profiles:</p>
//             <ul>{renderCommunityProfiles()}</ul>
//             <button type="submit">Submit</button>
//             <p>__v: {json.user.__v}</p>
//             <p>userId: {json.user.userId?.toString()}</p>
//             <p>Schema: {JSON.stringify(json.schema)}</p>
//           </div>
//         </form>
//       )}
//     </header>
//   );
// };

// export default DynamicForm;


// ** GENERATES A FORM (works)  for fields that are only present in both json.user and json.schema

// import React, { useState, useEffect } from 'react';
// import "./DynamicForm.css";
// import { useAuthContext } from "../hooks/useAuthContext"

// const DynamicForm = () => {
//   const [json, setJson] = useState(null);
//   const [formData, setFormData] = useState(null);
//   const { user }= useAuthContext()

//   useEffect(() => {
//     const fetchProfile = async () => {

//     const response = await fetch('/api/dynamic/', {
//         method: 'POST',
//         body: JSON.stringify({ _id: '65310360235d4d38f2f387c1' }), //user.userId
//         headers: {
//           'Content-Type': 'application/json'
//         }
//       });
//       const json = await response.json();
//       setJson(json);
//       console.log('Dynamic Form Json from dynamic.js:', json);
//       // console.log('console.log(json.user.CommunityProfiles:', json.user.CommunityProfiles)
//       console.log('console.log(json.schema.CommunityProfiles):', json.schema.CommunityProfiles)
//       const userSchema = json.schema.CommunityProfiles.obj;
//       const userFormData = json.user?.CommunityProfiles ?? {};
//       setFormData({ schema: userSchema, data: userFormData });

//     };

//     fetchProfile();
//   }, []);

//   const handleInputChange = (event, communityProfileName, fieldName) => {
//     const newFormData = { ...formData };
//     if (!newFormData.data[communityProfileName]) {
//       newFormData.data[communityProfileName] = {};
//     }
//     newFormData.data[communityProfileName][fieldName] = event.target.value;
//     setFormData(newFormData);
//   };


//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     const response = await fetch('/api/dynamic/update', {
//       method: 'POST',
//       body: JSON.stringify({ _id: '65310360235d4d38f2f395c1', CommunityProfiles: formData.data }),
//       headers: {
//         'Content-Type': 'application/json'
//       }
//     });
//     console.log('CommunityProfiles formData.data from dynamicform.js:', formData.data)
//     const json = await response.json();
//     console.log(json);
//   };

//   const renderCommunityProfiles = () => {
//     const communityProfiles = formData.data;
//     const communityProfilesArray = [];

//     const schema = formData.schema;
//     for (const [communityProfileName, communityProfileSchema] of Object.entries(schema)) {
//       // Add spaces to community profile name if it is camel case
//       const formattedCommunityProfileName = communityProfileName.replace(/([A-Z])/g, ' $1').trim();
//       const fields = Object.keys(communityProfileSchema.type);
//       const fieldElements = fields.map((fieldName) => {
//         const fieldType = communityProfileSchema.type[fieldName].type;
//         const fieldEnum = communityProfileSchema.type[fieldName].enum;
//         const fieldValue = communityProfiles[communityProfileName]?.[fieldName] ?? '';
//         if (fieldType === 'String' && fieldEnum) {
//           const optionElements = fieldEnum.map((option) => (
//             <option key={option} value={option}>
//               {option}
//             </option>
//           ));
//           return (
//             <div key={fieldName}>
//               <label htmlFor={fieldName}>{fieldName}</label>
//               <select
//                 id={fieldName}
//                 name={fieldName}
//                 value={fieldValue}
//                 onChange={(event) => handleInputChange(event, communityProfileName, fieldName)}
//               >
//                 {optionElements}
//               </select>
//             </div>
//           );
//         } else if (fieldType === 'String') {
//           return (
//             <div key={fieldName}>
//               <label htmlFor={fieldName}>{fieldName}</label>
//               <input
//                 type="text"
//                 id={fieldName}
//                 name={fieldName}
//                 value={fieldValue}
//                 onChange={(event) => handleInputChange(event, communityProfileName, fieldName)}
//               />
//             </div>
//           );
//         } else {
//           return null;
//         }
//       });
//       communityProfilesArray.push(
//         <div key={communityProfileName}>
//           <h3>{formattedCommunityProfileName}</h3>
//           {fieldElements}
//         </div>
//       );
//     }

//     return communityProfilesArray;
//   };

//   return (
//     <header>
//       <h1>Dynamic FormM</h1>
//       {json && json.user && json.user.CommunityProfiles && (
//           <form onSubmit={handleSubmit}>
//           <div>
//             <p>_id: {json.user._id?.toString()}</p>
//             <p>First Name: {json.user.firstName}</p>
//             <p>Last Name: {json.user.lastName}</p>
//             <p>Age: {json.user.age}</p>
//             <p>List of Communities: {json.user.ListOfCommunities?.join(', ')}</p>
//             <p>Community Profiles:</p>
//             <ul>{renderCommunityProfiles()}</ul>
//             <button type="submit">Submit</button>
//             <p>__v: {json.user.__v}</p>
//             <p>userId: {json.user.userId?.toString()}</p>
//             <p>Schema: {JSON.stringify(json.schema)}</p>
//           </div>
//         </form>
//       )}
//     </header>
//   );
// };

// export default DynamicForm;

// ** 

// import React, { useState, useEffect } from 'react';
// import "./DynamicForm.css";

// const DynamicForm = () => {
//   const [json, setJson] = useState(null);
//   const [formData, setFormData] = useState(null);

//   useEffect(() => {
//     const fetchProfile = async () => {
//       const response = await fetch('/api/dynamic/', {
//         method: 'POST',
//         body: JSON.stringify({ _id: '6512ccf72ebb554003b40b4a' }),
//         headers: {
//           'Content-Type': 'application/json'
//         }
//       });
//       const json = await response.json();
//       setJson(json);
//       const userSchema = json.schema.CommunityProfiles.obj;
//       const userFormData = json.user?.CommunityProfiles ?? {};
//       setFormData({ schema: userSchema, data: userFormData });
//       console.log(json);
//     };

//     fetchProfile();
//   }, []);

//   const handleInputChange = (event, communityProfileName, fieldName) => {
//     const newFormData = { ...formData };
//     newFormData.data[communityProfileName][fieldName] = event.target.value;
//     setFormData(newFormData);
//   };

//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     const response = await fetch('/api/dynamic/update', {
//       method: 'POST',
//       body: JSON.stringify({ _id: '6512ccf72ebb554003b40b4a', CommunityProfiles: formData.data }),
//       headers: {
//         'Content-Type': 'application/json'
//       }
//     });
//     const json = await response.json();
//     console.log(json);
//   };

//   const renderCommunityProfiles = () => {
//     const communityProfiles = formData.data;
//     const communityProfilesArray = [];

//     const schema = formData.schema;
//     for (const [communityProfileName, communityProfileSchema] of Object.entries(schema)) {
//       // Add spaces to community profile name if it is camel case
//       const formattedCommunityProfileName = communityProfileName.replace(/([A-Z])/g, ' $1').trim();
//       const fields = Object.keys(communityProfileSchema.type);
//       const fieldElements = fields.map((fieldName) => {
//         const fieldType = communityProfileSchema.type[fieldName].type;
//         const fieldOptions = communityProfileSchema.type[fieldName].options;
//         const fieldValue = communityProfiles[communityProfileName]?.[fieldName] ?? '';
//         if (fieldType === 'String') {
//           return (
//             <div key={fieldName}>
//               <label htmlFor={fieldName}>{fieldName}</label>
//               <input
//                 type="text"
//                 id={fieldName}
//                 name={fieldName}
//                 value={fieldValue}
//                 onChange={(event) => handleInputChange(event, communityProfileName, fieldName)}
//               />
//             </div>
//           );
//         } else if (fieldType === 'Select') {
//           const optionElements = fieldOptions.map((option) => (
//             <option key={option} value={option}>
//               {option}
//             </option>
//           ));
//           return (
//             <div key={fieldName}>
//               <label htmlFor={fieldName}>{fieldName}</label>
//               <select
//                 id={fieldName}
//                 name={fieldName}
//                 value={fieldValue}
//                 onChange={(event) => handleInputChange(event, communityProfileName, fieldName)}
//               >
//                 {optionElements}
//               </select>
//             </div>
//           );
//         } else {
//           return null;
//         }
//       });
//       communityProfilesArray.push(
//         <div key={communityProfileName}>
//           <h3>{formattedCommunityProfileName}</h3>
//           {fieldElements}
//         </div>
//       );
//     }

//     return communityProfilesArray;
//   };



//   return (
//     <header>
//       <h1>Dynamic Form</h1>
//       {json && json.user && json.user.CommunityProfiles && (
//         <form onSubmit={handleSubmit}>
//           <div>
//             <p>_id: {json.user._id?.toString()}</p>
//             <p>First Name: {json.user.firstName}</p>
//             <p>Last Name: {json.user.lastName}</p>
//             <p>Age: {json.user.age}</p>
//             <p>List of Communities: {json.user.ListOfCommunities?.join(', ')}</p>
//             <p>Community Profiles:</p>
//             <ul>{renderCommunityProfiles()}</ul>
//             <button type="submit">Submit</button>
//             <p>__v: {json.user.__v}</p>
//             <p>userId: {json.user.userId?.toString()}</p>
//             <p>Schema: {JSON.stringify(json.schema)}</p>
//           </div>
//         </form>
//       )}
//     </header>
//   );
// };


// // **** DYNAMIC GENERATION WORKS BUT YET SUBMIT API OR CAN TAKE DIFFERENT TYPES **



// import React, { useState, useEffect } from 'react';
// import "./DynamicForm.css";

// const DynamicForm = () => {
//   const [json, setJson] = useState(null);
//   const [formData, setFormData] = useState(null);

//   useEffect(() => {
//     const fetchProfile = async () => {
//       const response = await fetch('/api/dynamic/', {
//         method: 'POST',
//         body: JSON.stringify({ _id: '6512ccf72ebb554003b40b4a' }),
//         headers: {
//           'Content-Type': 'application/json'
//         }
//       });
//       const json = await response.json();
//       setJson(json);
//       const userSchema = json.schema.CommunityProfiles.obj;
//       const userFormData = json.user.CommunityProfiles;
//       setFormData({ schema: userSchema, data: userFormData });
//       console.log(json);
//     };

//     fetchProfile();
//   }, []);

//   const handleInputChange = (event, communityProfileName, fieldName) => {
//     const newFormData = { ...formData };
//     newFormData.data[communityProfileName][fieldName] = event.target.value;
//     setFormData(newFormData);
//   };

//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     const response = await fetch('/api/dynamic/update', {
//       method: 'POST',
//       body: JSON.stringify({ _id: '6512ccf72ebb554003b40b4a', CommunityProfiles: formData.data }),
//       headers: {
//         'Content-Type': 'application/json'
//       }
//     });
//     const json = await response.json();
//     console.log(json);
//   };

//   const renderCommunityProfiles = () => {
//     const communityProfiles = formData.data;
//     const communityProfilesArray = [];

//     const schema = formData.schema;
//     for (const [communityProfileName, communityProfileSchema] of Object.entries(schema)) {
//       // Add spaces to community profile name if it is camel case
//       const formattedCommunityProfileName = communityProfileName.replace(/([A-Z])/g, ' $1').trim();
//       const fields = Object.keys(communityProfileSchema.type);
//       const fieldElements = fields.map((fieldName) => {
//         const fieldType = communityProfileSchema.type[fieldName].type;
//         const fieldValue = communityProfiles[communityProfileName]?.[fieldName];
//         return (
//           <div key={fieldName}>
//             <label htmlFor={fieldName}>{fieldName}</label>
//             <input
//               type={fieldType}
//               id={fieldName}
//               name={fieldName}
//               value={fieldValue}
//               onChange={(event) => handleInputChange(event, communityProfileName, fieldName)}
//             />
//           </div>
//         );
//       });
//       communityProfilesArray.push(
//         <div key={communityProfileName}>
//           <h3>{formattedCommunityProfileName}</h3>
//           {fieldElements}
//         </div>
//       );
//     }

//     return communityProfilesArray;
//   };

//   return (
//     <header>
//       <h1>Dynamic Form</h1>
//       {json && (
//         <form onSubmit={handleSubmit}>
//           <div>
//             <p>_id: {json.user._id.toString()}</p>
//             <p>First Name: {json.user.firstName}</p>
//             <p>Last Name: {json.user.lastName}</p>
//             <p>Age: {json.user.age}</p>
//             <p>List of Communities: {json.user.ListOfCommunities.join(', ')}</p>
//             <p>Community Profiles:</p>
//             <ul>{renderCommunityProfiles()}</ul>
//             <button type="submit">Submit</button>
//             <p>__v: {json.user.__v}</p>
//             <p>userId: {json.user.userId.toString()}</p>
//             <p>Schema: {JSON.stringify(json.schema)}</p>
//           </div>
//         </form>
//       )}
//     </header>
//   );
// };

// // ****

// // *****

// import React, { useState, useEffect } from 'react';
// import "./DynamicForm.css";

// const DynamicForm = () => {
//   const [json, setJson] = useState(null);
//   const [formData, setFormData] = useState(null);

//   useEffect(() => {
//     const fetchProfile = async () => {
//       const response = await fetch('/api/dynamic/', {
//         method: 'POST',
//         body: JSON.stringify({ _id: '6512ccf72ebb554003b40b4a' }),
//         headers: {
//           'Content-Type': 'application/json'
//         }
//       });
//       const json = await response.json();
//       setJson(json);
//       const userSchema = json.schema.CommunityProfiles.obj;
//       const userFormData = json.user.CommunityProfiles;
//       setFormData({ schema: userSchema, data: userFormData });
//       console.log(json);
//     };

//     fetchProfile();
//   }, []);

//   const renderCommunityProfiles = () => {
//     const communityProfiles = formData.data;
//     const communityProfilesArray = [];

//     const schema = formData.schema;
//     for (const [communityProfileName, communityProfileSchema] of Object.entries(schema)) {
//       // Add spaces to community profile name if it is camel case
//       const formattedCommunityProfileName = communityProfileName.replace(/([A-Z])/g, ' $1').trim();
//       const fields = Object.keys(communityProfileSchema.type);
//       const fieldElements = fields.map((fieldName) => {
//         const fieldType = communityProfileSchema.type[fieldName].type;
//         const fieldValue = communityProfiles[communityProfileName]?.[fieldName];
//         return (
//           <div key={fieldName}>
//             <label htmlFor={fieldName}>{fieldName}</label>
//             <input
//               type={fieldType}
//               id={fieldName}
//               name={fieldName}
//               value={fieldValue}
//             />
//           </div>
//         );
//       });
//       communityProfilesArray.push(
//         <div key={communityProfileName}>
//           <h3>{formattedCommunityProfileName}</h3>
//           {fieldElements}
//         </div>
//       );
//     }

//     return communityProfilesArray;
//   };

//   return (
//     <header>
//       <h1>Dynamic Form</h1>
//       {json && (
//         <form>
//           <div>
//             <p>_id: {json.user._id.toString()}</p>
//             <p>First Name: {json.user.firstName}</p>
//             <p>Last Name: {json.user.lastName}</p>
//             <p>Age: {json.user.age}</p>
//             <p>List of Communities: {json.user.ListOfCommunities.join(', ')}</p>
//             <p>Community Profiles:</p>
//             <ul>{renderCommunityProfiles()}</ul>
//             <p>__v: {json.user.__v}</p>
//             <p>userId: {json.user.userId.toString()}</p>
//             <p>Schema: {JSON.stringify(json.schema)}</p>
//           </div>
//         </form>
//       )}
//     </header>
//   );
// };

//// *****


// import React, { useState, useEffect } from 'react';
// import "./DynamicForm.css";

// const DynamicForm = () => {
//   const [json, setJson] = useState(null);
//   const [formData, setFormData] = useState(null);

//   useEffect(() => {
//     const fetchProfile = async () => {
//       const response = await fetch('/api/dynamic/', {
//         method: 'POST',
//         body: JSON.stringify({ _id: '6512ccf72ebb554003b40b4a' }),
//         headers: {
//           'Content-Type': 'application/json'
//         }
//       });
//       const json = await response.json();
//       setJson(json);
//       const schema = json.schema.CommunityProfiles.obj;
//       const defaultFormData = {};
//       for (const [communityProfileName, communityProfileSchema] of Object.entries(schema)) {
//         const fields = Object.keys(communityProfileSchema.type);
//         const defaultFieldValues = {};
//         for (const fieldName of fields) {
//           const fieldType = communityProfileSchema.type[fieldName].type;
//           defaultFieldValues[fieldName] = fieldType === "String" ? "" : 0;
//         }
//         defaultFormData[communityProfileName] = defaultFieldValues;
//       }
//       const userFormData = json.user.CommunityProfiles;
//       const mergedFormData = { ...defaultFormData, ...userFormData };
//       setFormData(mergedFormData);
//       console.log(json);
//     };

//     fetchProfile();
//   }, []);

//   const renderCommunityProfiles = () => {
//     const communityProfiles = formData;
//     const communityProfilesArray = [];

//     const schema = json.schema.CommunityProfiles.obj;
//     for (const [communityProfileName, communityProfileSchema] of Object.entries(schema)) {
//       const fields = Object.keys(communityProfileSchema.type);
//       const fieldElements = fields.map((fieldName) => {
//         const fieldType = communityProfileSchema.type[fieldName].type;
//         const fieldValue = communityProfiles[communityProfileName]?.[fieldName];
//         return (
//           <div key={fieldName}>
//             <label htmlFor={fieldName}>{fieldName}</label>
//             <input
//               type={fieldType}
//               id={fieldName}
//               name={fieldName}
//               value={fieldValue}
//             />
//           </div>
//         );
//       });
//       communityProfilesArray.push(
//         <div key={communityProfileName}>
//           <h3>{communityProfileName}</h3>
//           {fieldElements}
//         </div>
//       );
//     }

//     return communityProfilesArray;
//   };

//   return (
//     <header>
//       <h1>Dynamic Form</h1>
//       {json && (
//         <form>
//           <div>
//             <p>_id: {json.user._id.toString()}</p>
//             <p>First Name: {json.user.firstName}</p>
//             <p>Last Name: {json.user.lastName}</p>
//             <p>Age: {json.user.age}</p>
//             <p>List of Communities: {json.user.ListOfCommunities.join(', ')}</p>
//             <p>Community Profiles:</p>
//             <ul>{renderCommunityProfiles()}</ul>
//             <p>__v: {json.user.__v}</p>
//             <p>userId: {json.user.userId.toString()}</p>
//             <p>Schema: {JSON.stringify(json.schema)}</p>
//           </div>
//         </form>
//       )}
//     </header>
//   );
// };

// export default DynamicForm;



// import React, { useState, useEffect } from 'react';
// import "./DynamicForm.css";

// const DynamicForm = () => {
//   const [json, setJson] = useState(null);
//   const [formData, setFormData] = useState(null);

//   useEffect(() => {
//     const fetchProfile = async () => {
//       const response = await fetch('/api/dynamic/', {
//         method: 'POST',
//         body: JSON.stringify({ _id: '6512ccf72ebb554003b40b4a' }),
//         headers: {
//           'Content-Type': 'application/json'
//         }
//       });
//       const json = await response.json();
//       setJson(json);
//       setFormData(json.user.CommunityProfiles);
//       console.log(json);
//     };

//     fetchProfile();
//   }, []);

//   const renderCommunityProfiles = () => {
//     const communityProfiles = formData;
//     const communityProfilesArray = [];

//     for (const [key, value] of Object.entries(communityProfiles)) {
//       if (key !== '_id') {
//         communityProfilesArray.push(
//           <div key={key}>
//             <h3>{key}</h3>
//             {Object.entries(value).map(([propName, propValue]) => (
//               <div key={propName}>
//                 <label htmlFor={propName}>{propName}</label>
//                 <input
//                   type="text"
//                   id={propName}
//                   name={propName}
//                   value={propValue}
//                 />
//               </div>
//             ))}
//           </div>
//         );
//       }
//     }

//     return communityProfilesArray;
//   };

//   return (
//     <header>
//       <h1>Dynamic Form</h1>
//       {json && (
//         <form>
//           <div>
//             <p>_id: {json.user._id.toString()}</p>
//             <p>First Name: {json.user.firstName}</p>
//             <p>Last Name: {json.user.lastName}</p>
//             <p>Age: {json.user.age}</p>
//             <p>List of Communities: {json.user.ListOfCommunities.join(', ')}</p>
//             <p>Community Profiles:</p>
//             <ul>{renderCommunityProfiles()}</ul>
//             <p>__v: {json.user.__v}</p>
//             <p>userId: {json.user.userId.toString()}</p>
//             <p>Schema: {JSON.stringify(json.schema)}</p>
//           </div>
//         </form>
//       )}
//     </header>
//   );
// };

// export default DynamicForm;


// import React, { useState, useEffect } from 'react';
// import "./DynamicForm.css";


// const DynamicForm = () => {
//   const [json, setJson] = useState(null);
//   const [formData, setFormData] = useState(null);

//   useEffect(() => {
//     const fetchProfile = async () => {
//       const response = await fetch('/api/dynamic/', {
//         method: 'POST',
//         //Next line hard coded for now
//         body: JSON.stringify({ _id: '6512ccf72ebb554003b40b4a' }),
//         headers: {
//           'Content-Type': 'application/json'
//         }
//       });
//       const json = await response.json();
//       setJson(json);
//       setFormData(json.user.CommunityProfiles);
//       console.log(json);
//     };

//     fetchProfile();
//   }, []);

//   const renderCommunityProfiles = () => {
//     const communityProfiles = formData;
//     const communityProfilesArray = [];

//     for (const [key, value] of Object.entries(communityProfiles)) {
//       if (key !== '_id') {
//         communityProfilesArray.push(
//           <div key={key}>
//             <h3>{key}</h3>
//             {Object.entries(value).map(([propName, propValue]) => (
//               <div key={propName}>
//                 <label htmlFor={propName}>{propName}</label>
//                 <input
//                   type="text"
//                   id={propName}
//                   name={propName}
//                   value={propValue}
//                   onChange={(e) =>
//                     setFormData({
//                       ...formData,
//                       [key]: {
//                         ...formData[key],
//                         [propName]: e.target.value,
//                       },
//                     })
//                   }
//                 />
//               </div>
//             ))}
//           </div>
//         );
//       }
//     }

//     return communityProfilesArray;
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const response = await fetch('/api/dynamic/update', {
//       method: 'POST',
//       body: JSON.stringify({ communityProfiles: formData }),
//       headers: {
//         'Content-Type': 'application/json'
//       }
//     });
//     const updatedJson = await response.json();
//     setJson(updatedJson);
//     setFormData(updatedJson.user.CommunityProfiles);
//   };

//   return (
//     <header>
//       <h1>Dynamic Form</h1>
//       {json && (
//         <form onSubmit={handleSubmit}>
//           <div>
//             <p>_id: {json.user._id.toString()}</p>
//             <p>First Name: {json.user.firstName}</p>
//             <p>Last Name: {json.user.lastName}</p>
//             <p>Age: {json.user.age}</p>
//             <p>List of Communities: {json.user.ListOfCommunities.join(', ')}</p>
//             <p>Community Profiles:</p>
//             <ul>{renderCommunityProfiles()}</ul>
//             <p>__v: {json.user.__v}</p>
//             <p>userId: {json.user.userId.toString()}</p>
//             <p>Schema: {JSON.stringify(json.schema)}</p>
//           </div>
//           <button type="submit">Save Changes</button>
//         </form>
//       )}
//     </header>
//   );
// };

// export default DynamicForm;