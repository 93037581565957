import React from 'react';
import "./Settings.css";
import PreferencesSeperatePopUpEdit from "../components/Profile&Settings/PreferencesSeperatePopUpEdit"; // Assuming both files are in the same directory
import { Link } from 'react-router-dom'
import LocationForm from '../components/Location/NearestLocationField';


const Preferences = () => {
  return (
    <>
      <header>
        <h1>Interconection Preferences</h1>
        {/* <h3> Give us a bit more info to help enhance your interconnection</h3> */}
      </header>
      <PreferencesSeperatePopUpEdit /> 
      <div className="getstartedbtn">
            <span><Link to="/profileupdate">Profile</Link></span>
        </div>
        <div className="getstartedbtn">
            <span><Link to="/Settings">Settings</Link></span>
        </div>
    </>
  );
}
export default Preferences;