import React from 'react';
import "./EmailAlertsCheck.css";
const EmailAlertsCheck = ({ onClose, onConfirm }) => {
  return (
    <>
      <div className="modal-overlay" />
      <div className="modal">
        <h2>Are you sure?</h2>
        <p>To use the service, we need to be able to contact you by email about other members to connect with.  </p>
{/* 
        <p>If you don't have email alerts set, your account will be
          inactive. 
          
        </p> */}
        <button onClick={onClose}>No</button>
        <button onClick={onConfirm}>Yes</button>
      </div>
    </>
  );
};

export default EmailAlertsCheck;