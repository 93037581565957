import { useEffect, useRef, useState } from "react";
import { io } from "socket.io-client";
import { useMessengerContext } from "./useMessengerContext";
import { useAuthContext } from "./useAuthContext";

const useSocket = (x, conversationId) => {
  const socket = useRef();
  const [unOpenedMessage, setUnOpenedMessage] = useState(null);
  const { dispatch } = useMessengerContext();
  const { user } = useAuthContext();
  const userId = user ? user.userId : null;
  const backendUrl =
    `${process.env.REACT_APP_ENV_ENVIRONMENT}` === "development"
      ? "ws://localhost:4000"
      : "wss://api.theinterconnector.com";

  useEffect(() => {
    // Initialize socket connection
    socket.current = io(backendUrl);

    socket.current.on("connect", () => {
      console.log("Socket connection state: Connected");
    });

    socket.current.on("disconnect", () => {
      console.log("Socket connection state: Disconnected");
    });

    // Listen for incoming messages
    socket.current.on("getMessage", (data) => {
      console.log("getMessage from use Socket", data);

      // Dispatch an action to add the new message to the global state
      dispatch({
        type: "ADD_MESSAGE",
        payload: {
          sender: data.sender,
          text: data.text,
          createdAt: Date.now(),
          messageId: data.messageId,
        },
      });
    });

    // Listen for update alerts
    socket.current.on("updateAlert", (data) => {
      console.log("updateALERTED");

      dispatch({
        type: "UPDATE_CONVERSATION_UNREAD",
        payload: data.conversationId,
      });
    });

    // Listen for new conversation
    socket.current.on("receiveConversation", (conversation) => {
      // Dispatch an action to add the new conversation
      dispatch({ type: "ADD_CONVERSATION", payload: conversation });
    });

    // Cleanup on unmount
    return () => {
      console.log("Component is unmounting, disconnecting socket...");
      socket.current.disconnect();
    };
  }, []);

  useEffect(() => {
    if (!userId || !conversationId) return;

    // Emit events when the user or current chat changes
    socket.current.emit("updateUserConversation", {
      userId: userId,
      conversationId: conversationId,
    });
    socket.current.emit("addUser", userId, conversationId);

    // Cleanup listeners on unmount or when dependencies change
    return () => {
      // socket.current.off("getUsers");
      // socket.current.off("getMessage");
      // socket.current.off("updateAlert");
    };
  }, [userId, conversationId]);

  // Function to send a message
  const sendMessage = (message) => {
    socket.current.emit("sendMessage", message);
    console.log("message sent by user, socket ID:", socket.current.id);
  };

  useEffect(() => {
    if (!socket.current) return;

    socket.current.on("lastReadMessageUpdated", ({ messageId, userId }) => {
      dispatch({
        type: "UPDATE_LAST_READ_MESSAGE_ID",
        payload: { userId: userId, messageId: messageId },
      });

      console.log("lastReadMessageUpdated from use Socket", messageId, userId);
    });

    return () => {
      socket.current.off("lastReadMessageUpdated");
    };
  }, []);

  return { unOpenedMessage, sendMessage }; //arrivalMessage
};

export default useSocket;

//   useEffect(() => {
//     // Initialize socket connection
//     socket.current = io("ws://localhost:8900");

//     // Listen for incoming messages
//     socket.current.on("getMessage", data => {
//       // Dispatch an action to add the new message to the global state
//       dispatch({
//         type: 'ADD_MESSAGE',
//         payload: {
//           sender: data.senderId,
//           text: data.text,
//           createdAt: Date.now(),
//         }
//       });
//     });

//     // Listen for update alerts
//     socket.current.on("updateAlert", data => {
//       console.log('updateALERTED')

//       dispatch({
//         type: 'UPDATE_CONVERSATION_UNREAD',
//         payload: data.conversationId
//       });
//     });

//     // Cleanup on unmount
//     return () => {
//       socket.current.disconnect();
//     };
//   }, []);

//   useEffect(() => {
//     if (!userId || !conversationId) return;

//     // Emit events when the user or current chat changes
//     socket.current.emit("updateUserConversation", {
//       userId: userId,
//       conversationId: conversationId
//     });
//     socket.current.emit("addUser", userId, conversationId);

//     // Cleanup listeners on unmount or when dependencies change
//     return () => {
//       socket.current.off("getUsers");
//       socket.current.off("getMessage");
//       socket.current.off("updateAlert");
//     };
//   }, [userId, conversationId]);

//   // Function to send a message
//   const sendMessage = (message) => {
//     socket.current.emit("sendMessage", message);
//     console.log('message sent by use socks')
//   };

//   return { unOpenedMessage, sendMessage }; //arrivalMessage
// };

// export default useSocket;
