import { useState, useEffect } from "react";
import { useAuthContext } from "./useAuthContext";
import { initializeApp, getApps } from "firebase/app";
import { getAuth, getIdToken } from "firebase/auth";
import firebase from "../Firebase";
import Cookies from "js-cookie";

if (!getApps().length) {
  initializeApp(firebase);
}

export const useLogin = () => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const [csrfToken, setCsrfToken] = useState(null);
  const { dispatch } = useAuthContext();

  console.log('user login happening')

  // Fetch the CSRF token when the component mounts
  useEffect(() => {
    const fetchCsrfToken = async () => {
      // Fetch the CSRF token from the "mytoken" cookie
      const token = Cookies.get("mytoken");
      setCsrfToken(token);
    };

    fetchCsrfToken();
  }, []);

  const login = async (uid) => {
    setIsLoading(true);
    setError(null);

    console.log("uid2:", uid);

    const body = JSON.stringify({ uid });

    // Log the body being sent
    console.log("body:", body);

    // Get the JWT token from Firebase Auth
    const auth = getAuth();
    const token = await getIdToken(auth.currentUser);

    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/user/login`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Include the JWT in the Authorization header
          "CSRF-Token": csrfToken, // Include the CSRF token in the request headers
        },
        body: body,
      }
    );
    const json = await response.json();

    if (!response.ok) {
      setIsLoading(false);
      setError(json.error);
      return false; // Return false if response is not ok
    }
    if (response.ok) {
      // save the user to local storage
      console.log("json useLogin:", json);
      localStorage.setItem("user", JSON.stringify(json));

      // update the auth context
      dispatch({ type: "LOGIN", payload: json });

      // update loading state
      setIsLoading(false);

      return true; // Return true if response is ok
    }
  };

  return { login, error, isLoading };
};
