import axios from "axios";
import { useState, useContext, useEffect } from "react"; // Added useEffect import
import { AuthContext } from "../context/AuthContext";
import { initializeApp, getApps } from "firebase/app";
import { getAuth, getIdToken } from "firebase/auth";
import firebase from "../Firebase";
import Cookies from "js-cookie";

if (!getApps().length) {
  initializeApp(firebase);
}

const useCreateNewUser = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [csrfToken, setCsrfToken] = useState(null);
  const { dispatch } = useContext(AuthContext);
  const auth = getAuth();

  console.log("user create new user happening")
  // Fetch the CSRF token when the component mounts
  useEffect(() => {
    const fetchCsrfToken = async () => {
      // Fetch the CSRF token from the "mytoken" cookie
      const token = Cookies.get("mytoken");
      setCsrfToken(token);
    };

    fetchCsrfToken();
  }, []);

  const createNewUser = async (email, name, communityId, uid) => {
    setIsLoading(true);
    setError(null);

    try {
      const idToken = await getIdToken(auth.currentUser);
      const csrfToken = Cookies.get("mytoken"); // Fetch the CSRF token from the "mytoken" cookie
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/user/signup`,
        { email, name, communityId, uid },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
            "X-CSRF-Token": csrfToken, // Include the CSRF token in the request headers
          },
        }
      );
      const json = response.data;

      if (response.status !== 200) {
        setIsLoading(false);
        setError(json.error);
        return { success: false };
      }

      console.log("User created in the backend:", json);

      // Save the user to local storage
      localStorage.setItem("user", JSON.stringify(json));

      // Update the auth context
      dispatch({ type: "LOGIN", payload: json });

      setIsLoading(false);
      return { success: true };
    } catch (error) {
      console.error("Error creating user in the backend:", error);
      setError(error.message);
      setIsLoading(false);
      return { success: false };
    }
  };

  return { createNewUser, isLoading, error };
};

export default useCreateNewUser;

// import { useState, useContext } from 'react';
// import { AuthContext } from '../context/AuthContext';

// const useCreateNewUser = () => {
//   const [isLoading, setIsLoading] = useState(false);
//   const [error, setError] = useState(null);
//   const { dispatch } = useContext(AuthContext);

//   const createNewUser = async (email, name) => {
//     setIsLoading(true);
//     setError(null);

//     try {
//       const response = await fetch('/api/user/signup', {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify({ email, name }),
//       });

//       const json = await response.json();

//       if (!response.ok) {
//         setIsLoading(false);
//         setError(json.error);
//         return { success: false };
//       }

//       if (response.ok) {
//         console.log('User created in the backend:', json);

//         // Save the user to local storage
//         localStorage.setItem('user', JSON.stringify(json));

//         // Update the auth context
//         dispatch({ type: 'LOGIN', payload: json });

//         setIsLoading(false);
//         return { success: true };
//       }
//     } catch (error) {
//       console.error('Error creating user in the backend:', error);
//       setError(error.message);
//       setIsLoading(false);
//       return { success: false };
//     }
//   };

//   return { createNewUser, isLoading, error };
// };

// export default useCreateNewUser;
