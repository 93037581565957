import React, { useState, useEffect } from "react";
import { FaEdit } from "react-icons/fa";
import "./DynamicProfileSeperatePopUpEdit.css";
import PopupForm from "./Functions/PopupForm";
import { FaTimes } from "react-icons/fa"; // Import the close icon
import { initializeApp, getApps } from "firebase/app";
import { getAuth } from "firebase/auth";
import firebase from "../../Firebase";
import Cookies from "js-cookie";

if (!getApps().length) {
  initializeApp(firebase);
}

const auth = getAuth();

const CommunityProfileComponent = ({
  communityProfileName,
  formattedCommunityProfileName,
  EditableFieldElements,
  handleSubmitCommunityProfile,
}) => {
  return (
    <div key={communityProfileName} className="dynamic-form">
      <h1 id="profileHeader">{formattedCommunityProfileName} Settings</h1>
      {/* Removed the conditional rendering for the popup form */}
      {EditableFieldElements}
    </div>
  );
};

const CommuniSettings = ({ user, communityId, onCommunityName }) => {
  console.log("user", user);
  console.log("communityId", communityId);

  const [json, setJson] = useState(null);
  const [formData, setFormData] = useState("");
  const [communityName, setCommunityName] = useState("");
  const [csrfToken, setCsrfToken] = useState(null); // New state variable for CSRF token

  // Define a variable to hold the current controller outside of your useEffect hook
  let currentController = null;

  useEffect(() => {
    const fetchCsrfToken = async () => {
      // Fetch the CSRF token from the "mytoken" cookie
      const csrfToken = Cookies.get("mytoken");
      setCsrfToken(csrfToken);
    };

    fetchCsrfToken();
  }, []);

  useEffect(() => {
    if (user && communityId) {
      const fetchProfile = async () => {
        // If there's an ongoing request, cancel it
        if (currentController) {
          currentController.abort();
        }

        // Create a new AbortController for the new request
        currentController = new AbortController();
        const signal = currentController.signal;

        try {
          console.log("logging /api/settings/user/singleCommunitSettine");
          console.log("user.", user);

          const token = await auth.currentUser.getIdToken(true);

          const response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/settings/user/oneCommuniSettings`,
            {
              method: "POST",
              body: JSON.stringify({
                _id: user.userId,
                communityId: communityId,
              }),
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "X-CSRF-Token": csrfToken, // Include the CSRF token here
              },
              signal: signal, // Pass the signal to the fetch call
            }
          );

          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          const json = await response.json();
          setJson(json);
          console.log("json!!!!!!!!", json);
          const userSchema = await json.communiUserSettingsSchema;
          const userFormData = (await json.communiUserSettings) ?? {};
          const communityName = await json.communityName;
          setCommunityName(communityName);
          // onCommunityName(communityName)
          console.log("communityName:", communityName);
          console.log("userSchema:", userSchema);
          console.log("userFormData:", userFormData);
          setFormData({ schema: userSchema, data: userFormData });
        } catch (error) {
          if (error.name === "AbortError") {
            // Handle fetch being aborted
            console.log("Fetch aborted:", error);
          } else {
            // Handle other errors
            console.error("Fetch error:", error);
          }
        }
      };

      fetchProfile();
    }

    // Clean up function to abort fetch request on component unmount
    return () => {
      if (currentController) {
        currentController.abort();
      }
    };
  }, [user, csrfToken]); // Add csrfToken to the dependency array

  useEffect(() => {
    if (!formData) return;
    // This code runs after formData has been updated
    handleSubmitCommunitySettings();
  }, [formData]); // Dependency array, effect runs when formData changes

  const handleSubmitCommunitySettings = async (event) => {
    // event.preventDefault();
    const payload = {
      communityId: communityId,
      userId: user.userId,
      Settings: formData.data.Settings, // Assuming this is the correct structure you want to send
    };
    console.log("Submitting community settings data****:", formData); // Log the payload before sending

    const token = await auth.currentUser.getIdToken(true);

    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/settings/communiuser/update`,
      {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const json = await response.json();
    console.log("Response JSON:", json); // Log the response JSON
  };

  console.log("form data:", formData);

  const handleInputChange = (event, communityProfileName, fieldName) => {
    const { type, checked, value } = event.target;

    setFormData((prevState) => {
      // Copy the previous state
      const newState = { ...prevState };

      // Ensure the Settings object exists
      if (!newState.data.Settings) {
        newState.data.Settings = {};
      }

      // Handle boolean checkbox
      if (
        type === "checkbox" &&
        prevState.data.Settings[fieldName] !== undefined
      ) {
        newState.data.Settings[fieldName] = checked;
      }
      // Handle checkboxes with multiple options or other inputs
      else {
        newState.data.Settings[fieldName] = value;
      }

      return newState;
    });
  };

  const handleCheckboxChange = (
    event,
    communityProfileName,
    fieldName,
    option
  ) => {
    const newFormData = { ...formData };
    if (!newFormData.data.Settings) {
      newFormData.data.Settings = {};
    }
    const interests = newFormData.data.Settings[fieldName] || [];
    if (event.target.checked) {
      interests.push(option);
    } else {
      const index = interests.indexOf(option);
      if (index !== -1) {
        interests.splice(index, 1);
      }
    }
    newFormData.data.Settings[fieldName] = interests;
    setFormData(newFormData);
  };

  const renderCommunityProfiles = () => {
    const communityProfiles = formData.data;
    const communityProfilesArray = [];

    const schema = formData.schema;
    console.log('schema!?"?"?"?!:', schema);
    console.log("userId2:", user.userId);

    for (const [communityProfileName, communityProfileSchema] of Object.entries(
      schema
    )) {
      const fields = Object.keys(communityProfileSchema);
      console.log("communityProfileName", communityProfileName);
      console.log("communityProfileSchema", communityProfileSchema);

      const FieldElements = (DisableFields) => {
        return fields.map((fieldName) => {
          const fieldType = communityProfileSchema[fieldName].type;
          const fieldEnum = communityProfileSchema[fieldName].enum;
          const fieldValue = communityProfiles.Settings?.[fieldName] ?? "";
          const htmlTitle = communityProfileSchema[fieldName].htmlTitle;
          const htmlTitleAlternative =
            communityProfileSchema[fieldName].htmlTitleAlternative;
          const htmlSubheaderAlternative =
            communityProfileSchema[fieldName].htmlSubheaderAlternative;
          const htmlSubheader = communityProfileSchema[fieldName].htmlSubheader;
          const wordLimit = communityProfileSchema[fieldName].wordLimit;
          console.log("worldLimit", communityProfileSchema);

          if (fieldType === "String" && fieldEnum) {
            const optionElements = fieldEnum.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ));
            return (
              <div key={fieldName}>
                <label htmlFor={fieldName}>{fieldName}</label>
                <select
                  id={fieldName}
                  name={fieldName}
                  value={fieldValue}
                  onChange={(event) =>
                    handleInputChange(event, communityProfileName, fieldName)
                  }
                  disabled={DisableFields}
                >
                  {optionElements}
                </select>
              </div>
            );
          } else if (
            Array.isArray(fieldType) &&
            fieldType[0].type === "String" &&
            fieldType[0].htmlref === "checkbox"
          ) {
            const optionElements = fieldType[0].enum.map((option, index) => {
              const checkboxId = `${fieldName}-${index}`;
              return (
                <div key={option} className="dynamic-form">
                  <input
                    type="checkbox"
                    id={checkboxId}
                    name={fieldName}
                    value={option}
                    checked={fieldValue.includes(option)}
                    onChange={(event) =>
                      handleCheckboxChange(
                        event,
                        communityProfileName,
                        fieldName,
                        option
                      )
                    }
                    disabled={DisableFields}
                  />
                  <label
                    id="checkBoxItems"
                    htmlFor={checkboxId}
                    className="dynamic-form__checkbox-label"
                  >
                    {option}
                  </label>
                </div>
              );
            });
            return (
              <div key={fieldName} className="dynamic-form-checkboxes">
                {htmlTitle ? (
                  <label
                    id="checkBoxLabel"
                    className="dynamic-form__checkbox-label"
                  >
                    {htmlTitle}
                  </label>
                ) : (
                  <label
                    id="checkBoxLabel"
                    className="dynamic-form__checkbox-label"
                  >
                    {fieldName}
                  </label>
                )}
                {htmlSubheader && <p>{htmlSubheader}</p>}
                {optionElements}
              </div>
            );
          } else if (fieldType === "String") {
            // Check if wordLimit is greater than 20 and log if 'mediumBoxSize' class is set
            if (wordLimit >= 20) {
              console.log("The label has the class mediumBoxSize.");
              return (
                <div key={fieldName}>
                  <label htmlFor={fieldName}>
                    {htmlTitle ? htmlTitle : fieldName}
                  </label>
                  {htmlSubheader && <p>{htmlSubheader}</p>}
                  <textarea
                    id={fieldName}
                    name={fieldName}
                    value={fieldValue}
                    onChange={(event) =>
                      handleInputChange(event, communityProfileName, fieldName)
                    }
                    readOnly={DisableFields}
                    className="mediumBoxSize"
                  />
                </div>
              );
            } else {
              console.log("The label does not have the class mediumBoxSize.");
              return (
                <div key={fieldName}>
                  <label htmlFor={fieldName}>{fieldName}</label>
                  <input
                    type="text"
                    id={fieldName}
                    name={fieldName}
                    value={fieldValue}
                    onChange={(event) =>
                      handleInputChange(event, communityProfileName, fieldName)
                    }
                    readOnly={DisableFields}
                  />
                </div>
              );
            }
          } else if (fieldType === "boolean") {
            return (
              <div key={fieldName} className="dynamic-form__field">
                <label htmlFor={fieldName}>
                  {fieldValue
                    ? htmlTitle
                      ? htmlTitle
                      : fieldName
                    : htmlTitleAlternative
                    ? htmlTitleAlternative
                    : htmlTitle
                    ? htmlTitle
                    : fieldName}
                </label>{" "}
                <div className="switchContainer">
                  <label className="switch">
                    <input
                      type="checkbox"
                      id={fieldName}
                      name={fieldName}
                      checked={fieldValue}
                      onChange={(event) =>
                        handleInputChange(
                          event,
                          communityProfileName,
                          fieldName
                        )
                      }
                      disabled={DisableFields}
                    />
                    <span className="slider round"></span>
                  </label>
                  <p>{fieldValue ? htmlSubheader : htmlSubheaderAlternative}</p>
                </div>
              </div>
            );
          } else {
            return null;
          }
        });
      };

      const EditableFieldElements = FieldElements(false);

      ////Code continues

      communityProfilesArray.push(
        <CommunityProfileComponent
          key={communityProfileName} // Add this line
          communityProfileName={communityName}
          formattedCommunityProfileName={communityName}
          EditableFieldElements={EditableFieldElements}
        />
      );
    }
    console.log("communityProfilesArray:", communityProfilesArray);
    return communityProfilesArray;
  };

  console.log("formData", formData);
  return (
    <header>
      {json && json.communiUserSettings && json.communiUserSettingsSchema && (
        <div>{renderCommunityProfiles()}</div>
      )}
    </header>
  );
};

export default CommuniSettings;

// //DYNAMICALLY GENERATES ONE OR MORE SETTING PAGES BUT NOT UPDATING STATE OR DATABASES PROPERLY

// import React, { useState, useEffect } from 'react';
// import { FaEdit } from 'react-icons/fa';
// import { useAuthContext } from '../../hooks/useAuthContext';
// import PopupForm from './Functions/PopupForm';
// import "./SeperatePopUpEdit.css";

// const CommunitySettingsComponent = ({
//   communityId,
//   communitySettingsName,
//   formattedCommunitySettingsName,
//   NonEditableFieldElements,
//   EditableFieldElements,
//   handleSubmitCommunitySettings,
// }) => {
//   console.log(communityId);

//   return (
//     <div className="dynamic-form">
//       <h1>
//         {formattedCommunitySettingsName} Settings
//       </h1>
//       {EditableFieldElements}
//     </div>
//   );
// };

// const SettingsSeperatePopUpEdit = () => {
//   const [json, setJson] = useState(null);
//   const [formState, setFormState] = useState([]); // Array to hold form data and communityIds

//   const { user } = useAuthContext();
//   let currentController = null;

//   useEffect(() => {
//     if (user) {
//       const fetchSettings = async () => {
//         if (currentController) {
//           currentController.abort();
//         }
//         currentController = new AbortController();
//         const signal = currentController.signal;

//         try {
//          const response = await fetch('/api/settings/communiuser', {
//               method: 'POST',
//             body: JSON.stringify({ userId: user.userId }),
//             headers: {
//               'Content-Type': 'application/json'
//             },
//             signal: signal
//           });
//           if (!response.ok) {
//             throw new Error(`HTTP error! status: ${response.status}`);
//           }

//           const jsonData = await response.json();
//           setJson(jsonData);
//           console.log('json setting dynamic:', jsonData);

//           const initialFormState = jsonData.userSettings.map((setting, index) => ({
//             communityId: jsonData.communityId[index], // Correctly using communityId array for IDs
//             formData: setting, // Directly using the profile data
//           }));
//           setFormState(initialFormState);

//           console.log('formstate', initialFormState);

//         } catch (error) {
//           if (error.name === 'AbortError') {
//             console.log('Fetch aborted:', error);
//           } else {
//             console.error('Fetch error:', error);
//           }
//         }

//       };

//       fetchSettings();
//     }

//     return () => {
//       if (currentController) {
//         currentController.abort();
//       }
//     };
//   }, [user]);

//   const handleSubmitCommunitySettings = async (communityId) => {
//     // Your logic to handle the form submission using communityId

//   console.log('community ID for submit', communityId)

//   // Find the profile data for the given communityId
//   const settingsToUpdate = formState.find(Settings => Settings.communityId === communityId);
//   console.log('settingsToUpdate', settingsToUpdate)

//   if (settingsToUpdate) {
//     const payload = {
//       userId: user.userId,
//       communityId: communityId,
//       Settings: settingsToUpdate.formData.Settings // Correctly structured for the backend
//     };

//     console.log('Submitting community profile data:', payload); // Log the payload before sending

//     try {
//       const response = await fetch('/api/settings/communiuser/update', {
//         method: 'POST',
//         body: JSON.stringify(payload),
//         headers: {
//           'Content-Type': 'application/json',
//         },
//       });

//       if (!response.ok) {
//         throw new Error(`HTTP error! status: ${response.status}`);
//       }

//       const json = await response.json();
//       console.log('Response JSON:', json); // Log the response JSON
//     } catch (error) {
//       console.error('Error submitting community profile:', error);
//     }
//   } else {
//     console.error('Settings to update not found');
//   }
// };

// const handleFieldChange = (communityId, fieldName, event, option) => {
//   console.log('option', option);
//   const target = event.target;
//   const isChecked = target.checked; // Use checked for checkbox state
//   const isCheckbox = target.type === 'checkbox';
//   const isMultiSelect = target.options && target.multiple;
//   // Determine if the operation is for multiple checkboxes based on the presence of 'option' parameter
//   const isMultiCheckbox = option !== undefined;

//   setFormState(prevFormState => {
//     const newFormState = prevFormState.map(profile => {
//       if (profile.communityId === communityId) {
//         let updatedField;
//         if (isCheckbox && !isMultiCheckbox) {
//           // Handle single checkboxes: toggle the boolean value
//           updatedField = isChecked; // Directly use the checked state
//         } else if (isMultiSelect) {
//           // Handle multi-select dropdowns: get all selected options
//           updatedField = Array.from(target.selectedOptions).map(opt => opt.value);
//         } else if (isMultiCheckbox) {
//           // Handle updates for multiple checkboxes
//           const currentValues = profile.formData.Settings[fieldName] || [];
//           if (Array.isArray(option)) {
//             // If 'option' is an array, it means we're updating all checkboxes
//             updatedField = option; // Directly set the updated options array
//           } else {
//             // Handle individual checkbox updates within the multiple checkboxes
//             updatedField = isChecked
//               ? [...currentValues, option] // Add the option if checked
//               : currentValues.filter(val => val !== option); // Remove the option if unchecked
//           }
//         } else {
//           // Handle other fields (e.g., text inputs, single-select dropdowns)
//           updatedField = target.value; // Use value for other input types
//         }

//         // Update the specific field in the formData
//         const updatedFormData = {
//           ...profile.formData,
//           Settings: {
//             ...profile.formData.Settings,
//             [fieldName]: updatedField
//           }
//         };

//         // Return the updated profile with the modified formData
//         return {
//           ...profile,
//           formData: updatedFormData
//         };
//       }
//       return profile; // Return other profiles unchanged
//     });
//     return newFormState;
//   });

//   // Call handleSubmitCommunitySettings after the state has been updated
//   handleSubmitCommunitySettings(communityId);
// };

//   const renderCommunitySettingss = () => {
//     if (!json || !json.schemaSettings || !json.userSettings || !json.communityNames) {
//       return null;
//     }

//     return json.communityNames.map((communityName, index) => {
//       const schema = json.schemaSettings[index].Settings;
//       const data = json.userSettings[index].Settings;
//       const communityId = json.communityId[index];
//       const settingsData = formState[index].formData
//       console.log('index',index)
//       console.log('schema iterating', schema)
//       console.log('data', data)
//       console.log('communitId iter',communityId )
//       console.log('settingsData',settingsData)

// // Function to generate field elements based on whether they should be disabled
// const generateFieldElements = (disableFields) => {
//   return Object.keys(schema).map((key) => {
//     const field = schema[key];
//     const value = settingsData.Settings[key] || ''; // Use formData from formState

//     const htmlTitle = field.htmlTitle;
//     const htmlSubheader = field.htmlSubheader;;
//     const htmlSubheaderAlternative = field.htmlSubheaderAlternative;

//     if (field.type === 'String' && field.enum) {
//       // This is a dropdown (enum), not a set of checkboxes
//       const optionElements = field.enum.map((option) => (
//         <option key={option} value={option}>{option}</option>
//       ));

//       return (
//         <div key={key}>
//           <label htmlFor={key}>{key}</label>
//           <select
//             id={key}
//             name={key}
//             value={settingsData.Settings[key]}
//             onChange={(event) => handleFieldChange(communityId, key, event)}
//             disabled={disableFields}
//           >
//             {optionElements}
//           </select>
//         </div>
//       );

//     } else if (field.type === 'String') {
//       return (
//         <div key={key}>
//           <label htmlFor={key}>{key}</label>
//           <input
//             type="text"
//             id={key}
//             name={key}
//             value={value}
//             onChange={(event) => handleFieldChange(communityId, key, event)}
//             readOnly={disableFields}
// />
//         </div>
//       );
//     } else if (Array.isArray(field.type) && field.type[0].type === 'String' && field.type[0].htmlref === 'checkbox') {
//       const optionElements = field.type[0].enum.map((option) => (
//         <div key={`${key}-${option}`} className="dynamic-form__field">
//           <input
//             type="checkbox"
//             id={`${key}-${option}`}
//             name={key}
//             value={option}
//             checked={settingsData.Settings[key].includes(option)}
//             onChange={(event) => handleFieldChange(communityId, key, event, option)}
//             disabled={disableFields}
//           />
//           <label id="checkBoxItems" htmlFor={`${key}-${option}`} className="dynamic-form__checkbox-label">{option}</label>
//         </div>
//       ));

//           return (
//         <div key={key} className="dynamic-form-checkboxes">
//           <label id="checkBoxLabel" className="dynamic-form__checkbox-label">{key}</label>

//           {optionElements}
//         </div>
//       );
//     }

//    else if (field.type === 'boolean') { // Adjusted to check for a boolean type directly

//     return (
//       <div key={key} className="dynamic-form__field"> {/* Ensure the class name aligns with your styling conventions */}
//         <label htmlFor={key}>{htmlTitle}</label>
//         <div className="switchContainer"> {/* Added container with class switchContainer */}
//           <label className="switch"> {/* This class should be styled in your CSS to match the desired switch appearance */}
//             <input
//               type="checkbox"
//               id={key}
//               name={key}
//               checked={settingsData.Settings[key]}
//               onChange={(event) => handleFieldChange(communityId, key, event)} // Consider changing to onChange for semantic correctness
//               disabled={disableFields}
//             />
//             <span className="slider round"></span> {/* Ensure this class is styled for the slider appearance */}
//           </label>
//           <p>{settingsData.Settings[key] ? htmlSubheader : htmlSubheaderAlternative}</p>
//         </div>
//       </div>
//     );
//   }

//     return null;
//   });
// };

// const EditableFieldElements = generateFieldElements(false);
// const NonEditableFieldElements = generateFieldElements(true);

//       return (
//         <CommunitySettingsComponent
//           key={communityId}
//           communityId={communityId}
//           communitySettingsName={communityName}
//           formattedCommunitySettingsName={communityName}
//           NonEditableFieldElements={<div>{NonEditableFieldElements}</div>}
//           EditableFieldElements={<div>{EditableFieldElements}</div>}
//           handleSubmitCommunitySettings={handleSubmitCommunitySettings}
//         />
//       );
//     });
//   };

//   return (
//     <div>
//       {renderCommunitySettingss()}
//     </div>
//   );
// };

// export default SettingsSeperatePopUpEdit;

//////////////////////////////

// import React, { useState, useEffect } from 'react';
// import { FaEdit } from 'react-icons/fa';
// import "./SeperatePopUpEdit.css";
// import { useAuthContext } from '../../hooks/useAuthContext';
// import PopupForm from './Functions/PopupForm';

// const CommunitySettingsComponent = ({ firstNestedObject, communityName, formattedCommunityName, NonEditableFieldElements, EditableFieldElements, handleSubmitCommunitySettings, htmlTitle, htmlSubHeader }) => {
//   const [showPopup, setShowPopup] = useState(false);
//   return (
//     <div key={firstNestedObject} className="dynamic-form">
//       <h2>
//         {communityName}
//         <FaEdit onClick={() => setShowPopup(!showPopup)} />
//       </h2>
//       <h2>{htmlSubHeader}</h2>
//       {showPopup ? (
//         <PopupForm className="scrollable-popup" onSubmit={handleSubmitCommunitySettings()}>
//           <h1> {communityName} </h1>
//           {EditableFieldElements}
//           <button type="update">Submit</button>
//           <button onClick={() => setShowPopup(false)}>Close</button>
//         </PopupForm>
//       ) : (
//         NonEditableFieldElements
//       )}
//     </div>
//   );
// };

// const SettingsSeperatePopUpEdit = () => {
//   const [json, setJson] = useState(null);
//   const [formData, setFormData] = useState({firstName: "", lastName: "", dateOfBirth: ""});
//   const { user }= useAuthContext()
//   const [communityName, setCommunityName] = useState("");

//   console.log('user..',user)

//   useEffect(() => {
//     console.log('user in settings componentent is:', user)
//     if (user) {
//       const fetchSettings = async () => {
//         const response = await fetch('/api/settings/communiuser', {
//           method: 'POST',
//           body: JSON.stringify({ userId: user.userId }),
//           headers: {
//             'Content-Type': 'application/json'
//           }
//         });
//         const json = await response.json();
//         console.log('SETTINGS: fetchSettings:', json)
//         setJson(json);
//         setCommunityName(json.communityName)
//         const userSchema = json.communiUserSettingsSchema; // Updated schema key
//         const userFormData = json.communiUserSettings ?? {}; // Updated user key
//         setFormData({ schema: userSchema, data: userFormData });
//       };

//       fetchSettings();
//     }
//   }, [user]); // Added user as a dependency

//   const handleInputChange = (event, firstNestedObject, fieldName) => {
//     const value = event.target.value;
//     setFormData(prevState => ({
//       ...prevState,
//       data: {
//         ...prevState.data,
//         [firstNestedObject]: {
//           ...(prevState.data.Settings || {}),
//           [fieldName]: value
//         }
//       }
//     }));
//   };

//   const handleCheckboxChange = (event, firstNestedObject, fieldName, option) => {
//     const newFormData = { ...formData };
//     if (!newFormData.data.Settings) {
//       newFormData.data.Settings = {};
//     }
//     const interests = newFormData.data.Settings[fieldName] || [];
//     if (event.target.checked) {
//       interests.push(option);
//     } else {
//       const index = interests.indexOf(option);
//       if (index !== -1) {
//         interests.splice(index, 1);
//       }
//     }
//     newFormData.data[firstNestedObject][fieldName] = interests;
//     setFormData(newFormData);
//   };

//   const handleButtonClick = (firstNestedObject, fieldName) => {
//     setFormData(prevState => ({
//       ...prevState,
//       data: {
//         ...prevState.data,
//         [firstNestedObject]: {
//           ...(prevState.data.Settings || {}),
//           [fieldName]: !prevState.data.Settings[fieldName]
//         }
//       }
//     }));
//   };

//   const renderUserSettingsInterface = () => {
//     console.log('RENDERING SSETTINGS:')
//     console.log('FormData:', formData)

//     const communiUserSettings = formData.data;
//     const communitySettingsArray = [];

//     const schema = formData.schema;
//     console.log('sschema3', schema)
//     console.log('user33:', user.userId)
//     for (const [firstNestedObject, secondNestedObject] of Object.entries(schema)) {
//       const formattedCommunityName = firstNestedObject.replace(/([A-Z])/g, ' $1').trim();
//       const fields = Object.keys(secondNestedObject);

//       console.log('secondNestedObject,',secondNestedObject)

//       const FieldElements = (DisableFields) => {

//         return fields.map((fieldName) => {
//         const fieldType = secondNestedObject[fieldName].type;
//         const fieldEnum = secondNestedObject[fieldName].enum;
//         const fieldValue = communiUserSettings.Settings?.[fieldName] ?? "";
//         const htmlTitle = secondNestedObject[fieldName].htmlTitle;
//         const htmlSubheader = secondNestedObject[fieldName].htmlSubheader;
//         if (fieldType === 'String' && fieldEnum) {
//           const optionElements = fieldEnum.map((option) => (
//             <option key={option} value={option}>
//               {option}
//             </option>
//           ));
//           return (
//             <div key={fieldName}>
//               <label htmlFor={fieldName}>{fieldName}</label>
//               <select
//                 id={fieldName}
//                 name={fieldName}
//                 value={fieldValue}
//                 onChange={(event) => handleInputChange(event, firstNestedObject, fieldName)}
//                 disabled={DisableFields}
//               >
//                 {optionElements}
//               </select>
//             </div>
//           );

//         }
//         else if (Array.isArray(fieldType) && fieldType[0].type === 'String' && fieldType[0].htmlref === 'checkbox') {
//           const optionElements = fieldType[0].enum.map((option) => (
//             <div key={option} className="dynamic-form__field">
//               <input
//                 type="checkbox"
//                 id={option}
//                 name={fieldName}
//                 value={option}
//                 checked={fieldValue.includes(option)}
//                 onChange={(event) => handleCheckboxChange(event, firstNestedObject, fieldName, option)}
//                 disabled={DisableFields}
//               />
//               <label htmlFor={option} className="dynamic-form__checkbox-label">{option}</label>
//             </div>
//           ));
//           return (
//             <div key={fieldName} className="dynamic-form-checkboxes">
//               <label className="dynamic-form__checkbox-label">{fieldName}</label>
//               {optionElements}
//             </div>
//           );
//         } else if (fieldType === 'String') {
//           return (
//             <div key={fieldName}>
//               <label htmlFor={fieldName}>{htmlTitle}</label>
//               <p>{htmlSubheader}</p>
//               <input
//                 type="text"
//                 id={fieldName}
//                 name={fieldName}
//                 value={fieldValue}
//                 onChange={(event) => handleInputChange(event, firstNestedObject, fieldName)}
//                 readOnly={DisableFields}
//               />
//             </div>
//           );
//           } else if (fieldType === 'boolean') {
//             console.log('fieldValue',fieldValue)
//             return (
//               <div key={fieldName}>
//                 <label htmlFor={fieldName}>{htmlTitle}</label>
//                 <p>{fieldValue ? htmlSubheader : "htmlSubheaderAlternative"}</p>
//                 <label className="switch">
//                   <input
//                     type="checkbox"
//                     id={fieldName}
//                     name={fieldName}
//                     checked={fieldValue}
//                     onClick={() => handleButtonClick(firstNestedObject, fieldName)}
//                     disabled={DisableFields}
//                   />
//                   <span className="slider round"></span>
//                 </label>
//               </div>
//             );

//         } else {
//           return null;
//         }
//       });

//     }
//       const EditableFieldElements = FieldElements(false)
//       const NonEditableFieldElements = FieldElements(true)

//       const handleSubmitCommunitySettings = async (event) => {
//         // event.preventDefault();
//         const payload = {
//           userId: user.userId,
//           Settings: formData.data.Settings ,
//         };
//         console.log('Submitting community settings data. _id:', payload); // Log the payload before sending

//         const response = await fetch('/api/settings/communiuser/update', {
//           method: 'POST',
//           body: JSON.stringify(payload),
//           headers: {
//             'Content-Type': 'application/json',
//           },
//         });
//         const json = await response.json();
//         console.log('Response JSON:', json); // Log the response JSON
//       };

//       communitySettingsArray.push(
//         <CommunitySettingsComponent
//           key={firstNestedObject}
//           communityName={communityName}
//           formattedCommunityName={formattedCommunityName}
//           NonEditableFieldElements={NonEditableFieldElements}
//           EditableFieldElements={EditableFieldElements}
//           handleSubmitCommunitySettings={handleSubmitCommunitySettings}

//         />
//       );
//     }
//     console.log('communitySettingsArray:', communitySettingsArray)
//     return communitySettingsArray;
//   };

//   return (
//     <header>
//       {json && json.communiUserSettingsSchema && json.communiUserSettings && (
//         <div>
//           {renderUserSettingsInterface()}
//         </div>
//       )}
//     </header>
//   );
// };

// export default SettingsSeperatePopUpEdit;

////FORMATTING OKAY BUT NOT SURE ABOUT REST

// import React, { useState, useEffect } from 'react';
// import { FaEdit } from 'react-icons/fa';
// import { useAuthContext } from '../../hooks/useAuthContext';
// import PopupForm from './Functions/PopupForm';
// import "./SeperatePopUpEdit.css";

// const CommunitySettingsComponent = ({
//   communityId,
//   communitySettingsName,
//   formattedCommunitySettingsName,
//   NonEditableFieldElements,
//   EditableFieldElements,
//   handleSubmitCommunitySettings,
// }) => {
//   console.log(communityId);

//   return (
//     <div className="dynamic-form">
//       <h1>
//         {formattedCommunitySettingsName} Settings
//       </h1>
//       {EditableFieldElements}
//     </div>
//   );
// };

// const SettingsSeperatePopUpEdit = () => {
//   const [json, setJson] = useState(null);
//   const [formState, setFormState] = useState([]); // Array to hold form data and communityIds

//   const { user } = useAuthContext();
//   let currentController = null;

//   useEffect(() => {
//     if (user) {
//       const fetchSettings = async () => {
//         if (currentController) {
//           currentController.abort();
//         }
//         currentController = new AbortController();
//         const signal = currentController.signal;

//         try {
//          const response = await fetch('/api/settings/communiuser', {
//               method: 'POST',
//             body: JSON.stringify({ userId: user.userId }),
//             headers: {
//               'Content-Type': 'application/json'
//             },
//             signal: signal
//           });
//           if (!response.ok) {
//             throw new Error(`HTTP error! status: ${response.status}`);
//           }

//           const jsonData = await response.json();
//           setJson(jsonData);
//           console.log('json setting dynamic:', jsonData);

//           const initialFormState = jsonData.userSettings.map((setting, index) => ({
//             communityId: jsonData.communityId[index], // Correctly using communityId array for IDs
//             formData: setting, // Directly using the profile data
//           }));
//           setFormState(initialFormState);

//           console.log('formstate', initialFormState);

//         } catch (error) {
//           if (error.name === 'AbortError') {
//             console.log('Fetch aborted:', error);
//           } else {
//             console.error('Fetch error:', error);
//           }
//         }

//       };

//       fetchSettings();
//     }

//     return () => {
//       if (currentController) {
//         currentController.abort();
//       }
//     };
//   }, [user]);

//   const handleFieldChange = (communityId, fieldName, event) => {
//     const target = event.target;
//     const isChecked = target.checked; // Use checked for checkbox state
//     const isCheckbox = target.type === 'checkbox';
//     const isMultiSelect = target.options && target.multiple;

//     setFormState(prevFormState => prevFormState.map(profile => {
//       if (profile.communityId === communityId) {
//         let updatedField;
//         if (isCheckbox) {
//           // Handle checkboxes: toggle the boolean value
//           updatedField = isChecked; // Directly use the checked state
//         } else if (isMultiSelect) {
//           // Handle multi-select dropdowns: get all selected options
//           updatedField = Array.from(target.selectedOptions).map(opt => opt.value);
//         } else {
//           // Handle other fields (e.g., text inputs, single-select dropdowns)
//           updatedField = target.value; // Use value for other input types
//         }

//         // Update the specific field in the formData
//         const updatedFormData = {
//           ...profile.formData,
//           Settings: {
//             ...profile.formData.Settings,
//             [fieldName]: updatedField
//           }
//         };

//         // Return the updated profile with the modified formData
//         return {
//           ...profile,
//           formData: updatedFormData
//         };
//       }
//       return profile; // Return other profiles unchanged
//     }));
//   };

//   const handleSubmitCommunitySettings = async (communityId) => {
//       // Your logic to handle the form submission using communityId

//     console.log('community ID for submit', communityId)

//     // Find the profile data for the given communityId
//     const settingsToUpdate = formState.find(Settings => Settings.communityId === communityId);
//     console.log('settingsToUpdate', settingsToUpdate)

//     if (settingsToUpdate) {
//       const payload = {
//         userId: user.userId,
//         communityId: communityId,
//         Settings: settingsToUpdate.formData.Settings // Correctly structured for the backend
//       };

//       console.log('Submitting community profile data:', payload); // Log the payload before sending

//       try {
//         const response = await fetch('/api/settings/communiuser/update', {
//           method: 'POST',
//           body: JSON.stringify(payload),
//           headers: {
//             'Content-Type': 'application/json',
//           },
//         });

//         if (!response.ok) {
//           throw new Error(`HTTP error! status: ${response.status}`);
//         }

//         const json = await response.json();
//         console.log('Response JSON:', json); // Log the response JSON
//       } catch (error) {
//         console.error('Error submitting community profile:', error);
//       }
//     } else {
//       console.error('Settings to update not found');
//     }
//   };

//   const renderCommunitySettingss = () => {
//     if (!json || !json.schemaSettings || !json.userSettings || !json.communityNames) {
//       return null;
//     }

//     return json.communityNames.map((communityName, index) => {
//       const schema = json.schemaSettings[index].Settings;
//       const data = json.userSettings[index].Settings;
//       const communityId = json.communityId[index];
//       const settingsData = formState[index].formData
//       console.log('index',index)
//       console.log('schema iterating', schema)
//       console.log('data', data)
//       console.log('communitId iter',communityId )
//       console.log('settingsData',settingsData)

// // Function to generate field elements based on whether they should be disabled
// const generateFieldElements = (disableFields) => {
//   return Object.keys(schema).map((key) => {
//     const field = schema[key];
//     const value = settingsData.Settings[key] || ''; // Use formData from formState

//     const htmlTitle = field.htmlTitle;
//     const htmlSubheader = field.htmlSubheader;;
//     const htmlSubheaderAlternative = field.htmlSubheaderAlternative;

//     if (field.type === 'String' && field.enum) {
//       // This is a dropdown (enum), not a set of checkboxes
//       const optionElements = field.enum.map((option) => (
//         <option key={option} value={option}>{option}</option>
//       ));

//       return (
//         <div key={key}>
//           <label htmlFor={key}>{key}</label>
//           <select
//             id={key}
//             name={key}
//             value={settingsData.Settings[key]}
//             onChange={(event) => handleFieldChange(communityId, key, event)}
//             disabled={disableFields}
//           >
//             {optionElements}
//           </select>
//         </div>
//       );

//     } else if (field.type === 'String') {
//       return (
//         <div key={key}>
//           <label htmlFor={key}>{key}</label>
//           <input
//             type="text"
//             id={key}
//             name={key}
//             value={value}
//             onChange={(event) => handleFieldChange(communityId, key, event)}
//             readOnly={disableFields}
// />
//         </div>
//       );
//     } else if (Array.isArray(field.type) && field.type[0].type === 'String' && field.type[0].htmlref === 'checkbox') {
//       const optionElements = field.type[0].enum.map((option) => (
//         <div key={`${key}-${option}`} className="dynamic-form__field">
//           <input
//             type="checkbox"
//             id={`${key}-${option}`}
//             name={key}
//             value={option}
//             checked={settingsData.Settings[key].includes(option)}
//             onChange={(event) => handleFieldChange(communityId, key, event)}
//             disabled={disableFields}
//           />
//           <label htmlFor={`${key}-${option}`} className="dynamic-form__checkbox-label">{option}</label>
//         </div>
//       ));

//           return (
//         <div key={key} className="dynamic-form-checkboxes">
//           <label className="dynamic-form__checkbox-label">{key}</label>
//           {optionElements}
//         </div>
//       );
//     }

//    else if (field.type === 'boolean') { // Adjusted to check for a boolean type directly

//     return (
//       <div key={key} className="dynamic-form__field"> {/* Ensure the class name aligns with your styling conventions */}
//         <label htmlFor={key}>{htmlTitle}</label>
//         <div className="switchContainer"> {/* Added container with class switchContainer */}
//           <label className="switch"> {/* This class should be styled in your CSS to match the desired switch appearance */}
//             <input
//               type="checkbox"
//               id={key}
//               name={key}
//               checked={settingsData.Settings[key]}
//               onChange={(event) => handleFieldChange(communityId, key, event)} // Consider changing to onChange for semantic correctness
//               disabled={disableFields}
//             />
//             <span className="slider round"></span> {/* Ensure this class is styled for the slider appearance */}
//           </label>
//           <p>{settingsData.Settings[key] ? htmlSubheader : htmlSubheaderAlternative}</p>
//         </div>
//       </div>
//     );
//   }

//     return null;
//   });
// };

// const EditableFieldElements = generateFieldElements(false);
// const NonEditableFieldElements = generateFieldElements(true);

//       return (
//         <CommunitySettingsComponent
//           key={communityId}
//           communityId={communityId}
//           communitySettingsName={communityName}
//           formattedCommunitySettingsName={communityName}
//           NonEditableFieldElements={<div>{NonEditableFieldElements}</div>}
//           EditableFieldElements={<div>{EditableFieldElements}</div>}
//           handleSubmitCommunitySettings={handleSubmitCommunitySettings}
//         />
//       );
//     });
//   };

//   return (
//     <div>
//       {renderCommunitySettingss()}
//     </div>
//   );
// };

// export default SettingsSeperatePopUpEdit;

// import React, { useState, useEffect } from 'react';
// import { FaEdit } from 'react-icons/fa';
// import "./SeperatePopUpEdit.css";
// import { useAuthContext } from '../../hooks/useAuthContext';
// import PopupForm from './Functions/PopupForm';

// const CommunitySettingsComponent = ({ firstNestedObject, communityName, formattedCommunityName, NonEditableFieldElements, EditableFieldElements, handleSubmitCommunitySettings, htmlTitle, htmlSubHeader }) => {
//   const [showPopup, setShowPopup] = useState(false);
//   return (
//     <div key={firstNestedObject} className="dynamic-form">
//       <h2>
//         {communityName}
//         <FaEdit onClick={() => setShowPopup(!showPopup)} />
//       </h2>
//       <h2>{htmlSubHeader}</h2>
//       {showPopup ? (
//         <PopupForm className="scrollable-popup" onSubmit={handleSubmitCommunitySettings()}>
//           <h1> {communityName} </h1>
//           {EditableFieldElements}
//           <button type="update">Submit</button>
//           <button onClick={() => setShowPopup(false)}>Close</button>
//         </PopupForm>
//       ) : (
//         NonEditableFieldElements
//       )}
//     </div>
//   );
// };

// const SettingsSeperatePopUpEdit = () => {
//   const [json, setJson] = useState(null);
//   const [formData, setFormData] = useState({firstName: "", lastName: "", dateOfBirth: ""});
//   const { user }= useAuthContext()
//   const [communityName, setCommunityName] = useState("");

//   console.log('user..',user)

//   useEffect(() => {
//     console.log('user in settings componentent is:', user)
//     if (user) {
//       const fetchSettings = async () => {
//         const response = await fetch('/api/settings/communiuser', {
//           method: 'POST',
//           body: JSON.stringify({ userId: user.userId }),
//           headers: {
//             'Content-Type': 'application/json'
//           }
//         });
//         const json = await response.json();
//         console.log('SETTINGS: fetchSettings:', json)
//         setJson(json);
//         setCommunityName(json.communityName)
//         const userSchema = json.communiUserSettingsSchema; // Updated schema key
//         const userFormData = json.communiUserSettings ?? {}; // Updated user key
//         setFormData({ schema: userSchema, data: userFormData });
//       };

//       fetchSettings();
//     }
//   }, [user]); // Added user as a dependency

//   const handleInputChange = (event, firstNestedObject, fieldName) => {
//     const value = event.target.value;
//     setFormData(prevState => ({
//       ...prevState,
//       data: {
//         ...prevState.data,
//         [firstNestedObject]: {
//           ...(prevState.data.Settings || {}),
//           [fieldName]: value
//         }
//       }
//     }));
//   };

//   const handleCheckboxChange = (event, firstNestedObject, fieldName, option) => {
//     const newFormData = { ...formData };
//     if (!newFormData.data.Settings) {
//       newFormData.data.Settings = {};
//     }
//     const interests = newFormData.data.Settings[fieldName] || [];
//     if (event.target.checked) {
//       interests.push(option);
//     } else {
//       const index = interests.indexOf(option);
//       if (index !== -1) {
//         interests.splice(index, 1);
//       }
//     }
//     newFormData.data[firstNestedObject][fieldName] = interests;
//     setFormData(newFormData);
//   };

//   const handleButtonClick = (firstNestedObject, fieldName) => {
//     setFormData(prevState => ({
//       ...prevState,
//       data: {
//         ...prevState.data,
//         [firstNestedObject]: {
//           ...(prevState.data.Settings || {}),
//           [fieldName]: !prevState.data.Settings[fieldName]
//         }
//       }
//     }));
//   };

//   const renderUserSettingsInterface = () => {
//     console.log('RENDERING SSETTINGS:')
//     console.log('FormData:', formData)

//     const communiUserSettings = formData.data;
//     const communitySettingsArray = [];

//     const schema = formData.schema;
//     console.log('sschema3', schema)
//     console.log('user33:', user.userId)
//     for (const [firstNestedObject, secondNestedObject] of Object.entries(schema)) {
//       const formattedCommunityName = firstNestedObject.replace(/([A-Z])/g, ' $1').trim();
//       const fields = Object.keys(secondNestedObject);

//       console.log('secondNestedObject,',secondNestedObject)

//       const FieldElements = (DisableFields) => {

//         return fields.map((fieldName) => {
//         const fieldType = secondNestedObject[fieldName].type;
//         const fieldEnum = secondNestedObject[fieldName].enum;
//         const fieldValue = communiUserSettings.Settings?.[fieldName] ?? "";
//         const htmlTitle = secondNestedObject[fieldName].htmlTitle;
//         const htmlSubheader = secondNestedObject[fieldName].htmlSubheader;
//         if (fieldType === 'String' && fieldEnum) {
//           const optionElements = fieldEnum.map((option) => (
//             <option key={option} value={option}>
//               {option}
//             </option>
//           ));
//           return (
//             <div key={fieldName}>
//               <label htmlFor={fieldName}>{fieldName}</label>
//               <select
//                 id={fieldName}
//                 name={fieldName}
//                 value={fieldValue}
//                 onChange={(event) => handleInputChange(event, firstNestedObject, fieldName)}
//                 disabled={DisableFields}
//               >
//                 {optionElements}
//               </select>
//             </div>
//           );

//         }
//         else if (Array.isArray(fieldType) && fieldType[0].type === 'String' && fieldType[0].htmlref === 'checkbox') {
//           const optionElements = fieldType[0].enum.map((option) => (
//             <div key={option} className="dynamic-form__field">
//               <input
//                 type="checkbox"
//                 id={option}
//                 name={fieldName}
//                 value={option}
//                 checked={fieldValue.includes(option)}
//                 onChange={(event) => handleCheckboxChange(event, firstNestedObject, fieldName, option)}
//                 disabled={DisableFields}
//               />
//               <label htmlFor={option} className="dynamic-form__checkbox-label">{option}</label>
//             </div>
//           ));
//           return (
//             <div key={fieldName} className="dynamic-form-checkboxes">
//               <label className="dynamic-form__checkbox-label">{fieldName}</label>
//               {optionElements}
//             </div>
//           );
//         } else if (fieldType === 'String') {
//           return (
//             <div key={fieldName}>
//               <label htmlFor={fieldName}>{htmlTitle}</label>
//               <p>{htmlSubheader}</p>
//               <input
//                 type="text"
//                 id={fieldName}
//                 name={fieldName}
//                 value={fieldValue}
//                 onChange={(event) => handleInputChange(event, firstNestedObject, fieldName)}
//                 readOnly={DisableFields}
//               />
//             </div>
//           );
//           } else if (fieldType === 'boolean') {
//             console.log('fieldValue',fieldValue)
//             return (
//               <div key={fieldName}>
//                 <label htmlFor={fieldName}>{htmlTitle}</label>
//                 <p>{fieldValue ? htmlSubheader : "htmlSubheaderAlternative"}</p>
//                 <label className="switch">
//                   <input
//                     type="checkbox"
//                     id={fieldName}
//                     name={fieldName}
//                     checked={fieldValue}
//                     onClick={() => handleButtonClick(firstNestedObject, fieldName)}
//                     disabled={DisableFields}
//                   />
//                   <span className="slider round"></span>
//                 </label>
//               </div>
//             );
//   //     <div key={fieldName}>
//   //     <label htmlFor={fieldName}>{htmlTitle}</label>
//   //     <p>{htmlSubheader}</p>
//   //     <button
//   //       type="button"
//   //       id={fieldName}
//   //       name={fieldName}
//   //       onClick={() => handleButtonClick(communityName, fieldName)}
//   //       disabled={DisableFields}
//   //     >
//   //       {fieldValue ? 'Pause' : 'Unpause'}
//   //     </button>
//   //   </div>
//   // );
//         } else {
//           return null;
//         }
//       });

//     }
//       const EditableFieldElements = FieldElements(false)
//       const NonEditableFieldElements = FieldElements(true)

//       const handleSubmitCommunitySettings = async (event) => {
//         // event.preventDefault();
//         const payload = {
//           userId: user.userId,
//           Settings: formData.data.Settings ,
//         };
//         console.log('Submitting community settings data. _id:', payload); // Log the payload before sending

//         const response = await fetch('/api/settings/communiuser/update', {
//           method: 'POST',
//           body: JSON.stringify(payload),
//           headers: {
//             'Content-Type': 'application/json',
//           },
//         });
//         const json = await response.json();
//         console.log('Response JSON:', json); // Log the response JSON
//       };

//       communitySettingsArray.push(
//         <CommunitySettingsComponent
//           key={firstNestedObject}
//           communityName={communityName}
//           formattedCommunityName={formattedCommunityName}
//           NonEditableFieldElements={NonEditableFieldElements}
//           EditableFieldElements={EditableFieldElements}
//           handleSubmitCommunitySettings={handleSubmitCommunitySettings}

//         />
//       );
//     }
//     console.log('communitySettingsArray:', communitySettingsArray)
//     return communitySettingsArray;
//   };

//   return (
//     <header>
//       {json && json.communiUserSettingsSchema && json.communiUserSettings && (
//         <div>
//           {renderUserSettingsInterface()}
//         </div>
//       )}
//     </header>
//   );
// };

// export default SettingsSeperatePopUpEdit;

////random formatinghtmlstuff

// // } else if (fieldType === 'boolean') {
// //   return (
// //     <div key={fieldName}>
// //       <label htmlFor={fieldName}>{htmlTitle}</label>
// //       <p>{htmlSubheader}</p>
// //       <button
// //         type="button"
// //         id={fieldName}
// //         name={fieldName}
// //         onClick={() => handleButtonClick(communityName, fieldName)}
// //         disabled={DisableFields}
// //       >
// //         {fieldValue ? 'Unpause' : 'Pause'}
// //       </button>
// //     </div>
// //   );
// // } else {
// // return null;
// // }
// // });
