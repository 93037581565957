import { useEffect, useState } from "react";
import { useCommunitiesContext } from "../hooks/useCommunitiesContext";

// components
import CommunityDetails from "../components/CommunityDetails";
import { SearchBar } from "../components/SearchBar";

const GroupSearch = () => {
  const { communities, dispatch } = useCommunitiesContext();
  const [results, setResults] = useState([]);
  useEffect(() => {
    const fetchCommunities = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/communities`
      );

      const json = await response.json();

      if (response.ok) {
        dispatch({ type: "SET_COMMUNITIES", payload: json });
      }
    };

    fetchCommunities();
  }, [dispatch]);

  return (
    <div className="home">
      <div className="introhome">
        <h1>
          <strong>Find a group</strong>
        </h1>
        <p>Search for a group of people you'd like to connect with</p>
      </div>
      <div className="search-bar-container">
        <SearchBar setResultsInGroupSearchPage={setResults} />
      </div>
      {/* Display the number of search results */}
      {results.length > 0 && (
        <p className="search-results-count">
          {results.length} result{results.length !== 1 ? "s" : ""} found
        </p>
      )}
      {results.map((result) => (
        <CommunityDetails community={result} key={result._id} />
      ))}
    </div>
  );
};

export default GroupSearch;

// import { useEffect, useState } from "react"
// import { useCommunitiesContext } from "../hooks/useCommunitiesContext"

// // components
// import CommunityDetails from "../components/CommunityDetails"
// import { SearchBar } from "../components/SearchBar"

// const GroupSearch = () => {
//   const { communities, dispatch } = useCommunitiesContext()
//   const [results, setResults] = useState([]);
//   useEffect(() => {
//     const fetchCommunities = async () => {
//       const response = await fetch('/api/communities')
//       const json = await response.json()

//       if (response.ok) {
//         dispatch({type: 'SET_COMMUNITIES', payload: json})
//       }

//     }

//     fetchCommunities()
//   }, [dispatch])

//   return (
//     <div className="home">
//       <div className="introhome">
//         <h1><strong>Find a group to be matched with</strong></h1>
//         <p>Search for a group of people you'd like to connect with</p>
//         </div>
//       <div className="search-bar-container">
//       <SearchBar setResultsInGroupSearchPage={setResults} />
// </div>
//       {results && results.map(result => (
//           <CommunityDetails community={result} key={result._id} />
//         ))}

//     </div>
//   )
// }

// export default GroupSearch
