

// ///No pop up form, sends immediately ?? WORKING ON THAT!

// import React, { useState, useEffect } from "react";
// import { FaEdit } from "react-icons/fa";
// import "./DynamicProfileSeperatePopUpEdit.css";
// import { useAuthContext } from "../../hooks/useAuthContext";
// import PopupForm from "./Functions/PopupForm";
// import { FaTimes } from "react-icons/fa"; // Import the close icon
// import { initializeApp, getApps } from "firebase/app";
// import { getAuth } from "firebase/auth";
// import firebase from "../../Firebase";
// import Cookies from "js-cookie";

// if (!getApps().length) {
//   initializeApp(firebase);
// }

// const CommunityProfileComponent = ({
//   communityProfileName,
//   formattedCommunityProfileName,
//   NonEditableFieldElements,
//   EditableFieldElements,
//   handleSubmitCommunityProfile,
// }) => {
//   return (
//     <div key={communityProfileName} className="dynamic-form">
//       <h1>
//         {/* {formattedCommunityProfileName} Profile */}
//         {/* Removed the edit icon and associated logic to show the popup */}
//       </h1>
//       {/* Removed the conditional rendering for the popup form */}
//       {EditableFieldElements}
//     </div>
//   );
// };


// const EditableField = ({ fieldName, fieldValue, charactherLimit, handleSave, communityProfileName, DisableFields }) => {
//   const [value, setValue] = useState(fieldValue);
//   const [tempValue, setTempValue] = useState(fieldValue); // Temporary value to hold changes until saved

//   const handleChange = (event) => setTempValue(event.target.value);

//   const handleCancel = () => {
//     setTempValue(value); // Revert changes
//   };

//   const handleSaveClick = () => {
//     setValue(tempValue); // Update value with the temporary value
//     console.log('tempValue:', tempValue)
//     console.log('communityProfileName:', communityProfileName, 'fieldName:', fieldName)
  
//     // Create a synthetic event object
//     const syntheticEvent = {
//       target: { value: tempValue }
//     };
  
//     // Pass the synthetic event object along with other parameters
//     handleSave(syntheticEvent, communityProfileName, fieldName); // Save changes
//   };

//   return (
//     <div key={fieldName}>
//       <label htmlFor={fieldName}>{fieldName}</label>
//       {charactherLimit >= 20 ? (
//         <textarea
//           id={fieldName}
//           name={fieldName}
//           value={tempValue}
//           onChange={handleChange}
//           maxLength={charactherLimit}
//           readOnly={DisableFields}
//           className="mediumBoxSize"
//           placeholder={tempValue ? "" : "Introduce yourself and who you'd like to connect with and why..."}
//         />
//       ) : (
//         <input
//           type="text"
//           id={fieldName}
//           name={fieldName}
//           value={tempValue}
//           onChange={handleChange}
//           maxLength={20}
//           readOnly={DisableFields}
//         />
//       )}
//       {!DisableFields && (
//         <>
//           <button onClick={handleSaveClick}>Save</button>
//           <button onClick={handleCancel}>Discard</button>
//         </>
//       )}
//       <div>
//         {`${tempValue.length}/${charactherLimit >= 20 ? charactherLimit : 20} characters used`}
//       </div>
//     </div>
//   );
// };


// const DynamicProfileSeperatePopUpEdit = ({
//   user,
//   communityId,
//   onCommunityName,
// }) => {
//   const [json, setJson] = useState(null);
//   const [formData, setFormData] = useState("");
//   const [communityName, setCommunityName] = useState("");
//   const [token, setToken] = useState(null);
//   const [csrfToken, setCsrfToken] = useState(null); // New state variable for CSRF token
//   const [isCommunityProfileComplete, setIsCommunityProfileComplete] = useState(false);

//   const auth = getAuth();

//   // Define a variable to hold the current controller outside of your useEffect hook
//   let currentController = null;

//   useEffect(() => {
//     const fetchToken = async () => {
//       const token = await firebase.auth().currentUser.getIdToken(true);
//       setToken(token);
//     };

//     fetchToken();
//   }, []); // Empty dependency array means this effect runs once on component mount

//   useEffect(() => {
//     const fetchCsrfToken = async () => {
//       // Fetch the CSRF token from the "mytoken" cookie
//       const csrfToken1 = Cookies.get("mytoken");
//       setCsrfToken(csrfToken1);
//     };

//     fetchCsrfToken();
//   }, []);

//   useEffect(() => {
//     // Check if the bio field in the community profile is filled out correctly
//     const checkCommunityProfileCompletion = () => {
//       // Use optional chaining to safely access nested properties
//       const bio = formData?.data?.Profile?.Bio;
//       // Check to ensure bio is defined and is a string of at least 50 characters
//       const isComplete = typeof bio === 'string' && bio.trim().length >= 70;
//       console.log('formData.data.Profile.Bio:', bio);
//       setIsCommunityProfileComplete(isComplete);
//     };
  
//     checkCommunityProfileCompletion();
//   }, [formData]); // Ensure useEffect re-runs if formData changes


//   useEffect(() => {
//     if (formData?.data?.Profile?.Bio) {
//       console.log('sending bio to ai', formData.data.Profile.Bio)
//       const checkProfileBio = async () => {
//         try {
//           const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/aiGeneral/profilecheck`, {
//             method: "POST",
//             headers: {
//               "Content-Type": "application/json",
//               Authorization: `Bearer ${token}`,
//               "X-CSRF-Token": csrfToken,
//             },
//             body: JSON.stringify({ description: formData.data.Profile.Bio }),
//           });
//           if (!response.ok) {
//             throw new Error(`HTTP error! status: ${response.status}`);
//           }
//           const result = await response.json();
//           console.log('profile check ai', result); // Handle the response as needed
//         } catch (error) {
//           console.error("Error checking profile bio:", error);
//         }
//       };

//       checkProfileBio();
//     }
//   }, [formData, token, csrfToken]); // Re-run the effect if formData, token, or csrfToken changes


//   useEffect(() => {
//     if (user && communityId) {
//       const fetchProfile = async () => {
//         // If there's an ongoing request, cancel it
//         if (currentController) {
//           currentController.abort();
//         }

//         // Create a new AbortController for the new request
//         currentController = new AbortController();
//         const signal = currentController.signal;

//         try {
//           const response = await fetch(
//             `${process.env.REACT_APP_API_BASE_URL}/communiUser/getProfile`,
//             {
//               method: "POST",
//               body: JSON.stringify({
//                 _id: user.userId,
//                 communityId: communityId,
//               }),
//               headers: {
//                 "Content-Type": "application/json",
//                 Authorization: `Bearer ${token}`, // Include the token in the Authorization header
//                 "X-CSRF-Token": csrfToken, // Include the CSRF token here
//               },
//               signal: signal, // Pass the signal to the fetch call
//             }
//           );
//           if (!response.ok) {
//             throw new Error(`HTTP error! status: ${response.status}`);
//           }

//           const json = await response.json();
//           setJson(json);

//           const userSchema = await json.schemaProfile;
//           const userFormData = (await json.userProfile) ?? {};
//           const communityName = await json.communityName;
//           setCommunityName(communityName);
//           onCommunityName(communityName);

//           setFormData({ schema: userSchema, data: userFormData });
//         } catch (error) {
//           if (error.name === "AbortError") {
//             // Handle fetch being aborted
//           } else {
//             // Handle other errors
//             console.error("Fetch error:", error);
//           }
//         }
//       };

//       fetchProfile();
//     }
//   }, [user, communityId, csrfToken, token]);

//   useEffect(() => {
//     if (!formData) return;
//     // This code runs after formData has been updated
//     handleSubmitCommunityProfile();
//   }, [formData]); // Dependency array, effect runs when formData changes

//   const handleSubmitCommunityProfile = async (event) => {
//     // event.preventDefault();
//     const payload = {
//       communityId: communityId,
//       userId: user.userId,
//       Profile: formData.data.Profile, // Assuming this is the correct structure you want to send
//     };

//     const response = await fetch(
//       `${process.env.REACT_APP_API_BASE_URL}/communiUser/updateProfile`,
//       {
//         method: "POST",
//         body: JSON.stringify(payload),
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: `Bearer ${token}`, // Include the token in the Authorization header
//           "X-CSRF-Token": csrfToken, // Include the CSRF token in the 'X-CSRF-Token' header
//         },
//       }
//     );
//     const json = await response.json();
//   };

//   const handleInputChange = (event, communityProfileName, fieldName) => {
//     const value = event.target.value;
//     setFormData((prevState) => ({
//       ...prevState,
//       data: {
//         ...prevState.data,
//         Profile: {
//           ...(prevState.data.Profile || {}),
//           [fieldName]: value,
//         },
//       },
//     }));
//   };

//   const handleCheckboxChange = (
//     event,
//     communityProfileName,
//     fieldName,
//     option
//   ) => {
//     const newFormData = { ...formData };
//     if (!newFormData.data.Profile) {
//       newFormData.data.Profile = {};
//     }
//     const interests = newFormData.data.Profile[fieldName] || [];
//     if (event.target.checked) {
//       interests.push(option);
//     } else {
//       const index = interests.indexOf(option);
//       if (index !== -1) {
//         interests.splice(index, 1);
//       }
//     }
//     newFormData.data.Profile[fieldName] = interests;
//     setFormData(newFormData);
//   };

//   const renderCommunityProfiles = () => {
//     const communityProfiles = formData.data;
//     const communityProfilesArray = [];

//     const schema = formData.schema;

//     for (const [communityProfileName, communityProfileSchema] of Object.entries(
//       schema
//     )) {
//       const fields = Object.keys(communityProfileSchema);

//       const FieldElements = (DisableFields) => {
//         return fields.map((fieldName) => {
//           const fieldType = communityProfileSchema[fieldName].type;
//           const fieldEnum = communityProfileSchema[fieldName].enum;
//           const fieldValue = communityProfiles.Profile?.[fieldName] ?? "";
//           const htmlTitle = communityProfileSchema[fieldName].htmlTitle;
//           const wordLimit = communityProfileSchema[fieldName].wordLimit;
//           const charactherLimit = communityProfileSchema[fieldName].charactherLimit;

//           if (fieldType === "String" && fieldEnum) {
//             const optionElements = fieldEnum.map((option) => (
//               <option key={option} value={option}>
//                 {option}
//               </option>
//             ));
//             return (
//               <div key={fieldName}>
//                 <label htmlFor={fieldName}>{fieldName}</label>
//                 <select
//                   id={fieldName}
//                   name={fieldName}
//                   value={fieldValue}
//                   onChange={(event) =>
//                     handleInputChange(event, communityProfileName, fieldName)
//                   }
//                   disabled={DisableFields}
//                 >
//                   {optionElements}
//                 </select>
//               </div>
//             );
//           } else if (
//             Array.isArray(fieldType) &&
//             fieldType[0].type === "String" &&
//             fieldType[0].htmlref === "checkbox"
//           ) {
//             const optionElements = fieldType[0].enum.map((option, index) => {
//               const checkboxId = `${fieldName}-${index}`;
//               return (
//                 <div key={option} className="dynamic-form">
//                   <input
//                     type="checkbox"
//                     id={checkboxId}
//                     name={fieldName}
//                     value={option}
//                     checked={fieldValue.includes(option)}
//                     onChange={(event) =>
//                       handleCheckboxChange(
//                         event,
//                         communityProfileName,
//                         fieldName,
//                         option
//                       )
//                     }
//                     disabled={DisableFields}
//                   />
//                   <label
//                     id="checkBoxItems"
//                     htmlFor={checkboxId}
//                     className="dynamic-form__checkbox-label"
//                   >
//                     {option}
//                   </label>
//                 </div>
//               );
//             });
//             return (
//               <div key={fieldName} className="dynamic-form-checkboxes">
//                 <label
//                   id="checkBoxLabel"
//                   className="dynamic-form__checkbox-label"
//                 >
//                   {fieldName}
//                 </label>
//                 {optionElements}
//               </div>
//             );
//           } else if (fieldType === "String") {
//             return (
//               <EditableField
//                 fieldName={fieldName}
//                 fieldValue={fieldValue}
//                 charactherLimit={charactherLimit}
//                 handleSave={handleInputChange} // Assuming handleInputChange is adapted to also handle saving
//                 communityProfileName={communityProfileName}
//                 DisableFields={DisableFields}
//               />
//             );
        

// } else {
//   return null;
// }
// });
// };

//       const EditableFieldElements = FieldElements(false);
//       const NonEditableFieldElements = FieldElements(true);

//       ////Code continues

//       communityProfilesArray.push(
//         <CommunityProfileComponent
//           key={communityProfileName} // Add this line
//           communityProfileName={communityName}
//           formattedCommunityProfileName={communityName}
//           NonEditableFieldElements={NonEditableFieldElements}
//           EditableFieldElements={EditableFieldElements}
//           handleSubmitCommunityProfile={handleSubmitCommunityProfile}
//         />
//       );
//     }

//     return communityProfilesArray;
//   };

//   return (
//     <header>
//       <h1>Your {communityName} Profile!</h1>
//       <p><strong>We use this info to help suggest compatible matches</strong></p>      <p>Profile Status: {isCommunityProfileComplete ? (
//   <span>Complete <span style={{color: 'green'}}>✓</span></span>
// ) : (
//   <span> <span style={{color: 'red'}}>✕</span> - please write more in your bio</span>
// )}</p> 
// {json && json.schemaProfile && json.userProfile && (
//         <div>{renderCommunityProfiles()}</div>
//       )}
//     </header>
//   );
// };

// export default DynamicProfileSeperatePopUpEdit;


///No pop up form, sends immediately ?? WORKING ON THAT!

import React, { useState, useEffect } from "react";
import { FaEdit } from "react-icons/fa";
import "./DynamicProfileSeperatePopUpEdit.css";
import { useAuthContext } from "../../hooks/useAuthContext";
import PopupForm from "./Functions/PopupForm";
import { FaTimes } from "react-icons/fa"; // Import the close icon
import { initializeApp, getApps } from "firebase/app";
import { getAuth } from "firebase/auth";
import firebase from "../../Firebase";
import Cookies from "js-cookie";

if (!getApps().length) {
  initializeApp(firebase);
}

const CommunityProfileComponent = ({
  communityProfileName,
  formattedCommunityProfileName,
  NonEditableFieldElements,
  EditableFieldElements,
  handleSubmitCommunityProfile,
}) => {
  return (
    <div key={communityProfileName} className="dynamic-form">
      <h1>
        {/* {formattedCommunityProfileName} Profile */}
        {/* Removed the edit icon and associated logic to show the popup */}
      </h1>
      {/* Removed the conditional rendering for the popup form */}
      {EditableFieldElements}
    </div>
  );
};


const EditableField = ({ fieldName, fieldValue, charactherLimit, handleSave, communityProfileName, DisableFields }) => {
  const [value, setValue] = useState(fieldValue);
  const [tempValue, setTempValue] = useState(fieldValue); // Temporary value to hold changes until saved
  const [showButtons, setShowButtons] = useState(false); // Define showButtons state here

  const handleChange = (event) => setTempValue(event.target.value);

  const handleCancel = () => {
    setTempValue(value); // Revert changes
  };

  const handleSaveClick = () => {
    setValue(tempValue); // Update value with the temporary value
    console.log('tempValue:', tempValue)
    console.log('communityProfileName:', communityProfileName, 'fieldName:', fieldName)
  
    // Create a synthetic event object
    const syntheticEvent = {
      target: { value: tempValue }
    };
  
    // Pass the synthetic event object along with other parameters
    handleSave(syntheticEvent, communityProfileName, fieldName); // Save changes
  };

  // Inside your component
const handleBlur = () => {
  // Use setTimeout to delay the hiding of buttons, allowing the click event to be processed.
  setTimeout(() => setShowButtons(false), 200); // Adjust delay as needed
};


  return (
    <div key={fieldName}>
      <label htmlFor={fieldName}>{fieldName}</label>
      {charactherLimit >= 20 ? (
        <textarea
          id={fieldName}
          name={fieldName}
          value={tempValue}
          onChange={handleChange}
          onFocus={() => setShowButtons(true)} // Add onFocus event to show buttons
          onBlur={handleBlur}
          maxLength={charactherLimit}
          readOnly={DisableFields}
          className="mediumBoxSize"
          placeholder={tempValue ? "" : "Introduce yourself and who you'd like to connect with and why..."}
        />
      ) : (
        <input
          type="text"
          id={fieldName}
          name={fieldName}
          value={tempValue}
          onChange={handleChange}
          onFocus={() => setShowButtons(true)} // Add onFocus event to show buttons
          onBlur={() => setShowButtons(false)} // Optional: Add onBlur event to hide buttons when focus is lost
          maxLength={400}
          readOnly={DisableFields}
        />
      )}
      {!DisableFields && showButtons && ( // Use showButtons state to conditionally render buttons
       <div className="button-container">
       <button id="saveButton" onClick={handleSaveClick}>Save</button>
       <button id="cancelButton" onClick={handleCancel}>Cancel</button>
     </div>
   )}
   <div>
        {`${tempValue.length}/${charactherLimit >= 20 ? charactherLimit : 20} characters used`}
      </div>
    </div>
  );
};

const DynamicProfileSeperatePopUpEdit = ({
  user,
  communityId,
  onCommunityName,
  setDynamicProfile,
  index,
}) => {
  const [json, setJson] = useState(null);
  const [formData, setFormData] = useState("");
  const [communityName, setCommunityName] = useState("");
  const [token, setToken] = useState(null);
  const [csrfToken, setCsrfToken] = useState(null); // New state variable for CSRF token
  const [isCommunityProfileComplete, setIsCommunityProfileComplete] = useState(false);
  const [isBioComplete, setisBioComplete] = useState(false);
  const [isAIPassed, setisAIPassed] = useState(false);
  const [isAIFailed, setisAIFailed] = useState(false);


  const auth = getAuth();

  // Define a variable to hold the current controller outside of your useEffect hook
  let currentController = null;

  useEffect(() => {
    const fetchToken = async () => {
      const token = await firebase.auth().currentUser.getIdToken(true);
      setToken(token);
    };

    fetchToken();
  }, []); // Empty dependency array means this effect runs once on component mount

  useEffect(() => {
    const fetchCsrfToken = async () => {
      // Fetch the CSRF token from the "mytoken" cookie
      const csrfToken1 = Cookies.get("mytoken");
      setCsrfToken(csrfToken1);
    };

    fetchCsrfToken();
  }, []);

  useEffect(() => {
    // Check if the bio field in the community profile is filled out correctly
    const checkCommunityProfileCompletion = () => {
      // Use optional chaining to safely access nested properties
      const bio = formData?.data?.Profile?.Bio;
      // Check to ensure bio is defined and is a string of at least 50 characters
      const isComplete = typeof bio === 'string' && bio.trim().length >= 70;
      console.log('formData.data.Profile.Bio:', bio);
      setisBioComplete(isComplete);
    };
  
    checkCommunityProfileCompletion();
  }, [formData]); // Ensure useEffect re-runs if formData changes



  useEffect(() => {
    setIsCommunityProfileComplete(isAIPassed && isBioComplete);
    const isComplete = isAIPassed && isBioComplete;
    console.log('index', index, 'isComplete', isComplete);
    if (typeof setDynamicProfile === 'function') {
        setDynamicProfile(isComplete);
    }

    // setDynamicProfile(prevState => {
    //   // Create a copy of the current state
    //   const updatedProfiles = [...prevState];
    //   // Update the value at the specified index with the boolean value, not a function
    //   updatedProfiles[index] = isComplete;
    //   // Return the updated state
    //   return updatedProfiles;
    // });
  }, [isAIPassed, isBioComplete, index]); // Ensure 'index' is also listed if it's a prop or state that could change



  useEffect(() => {
    if (formData?.data?.Profile?.Bio) {
      console.log('sending bio to ai', formData.data.Profile.Bio);
      const checkProfileBio = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/aiGeneral/profilecheck`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
              "X-CSRF-Token": csrfToken,
            },
            body: JSON.stringify({ description: formData.data.Profile.Bio }),
          });
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const result = await response.json();
          console.log('profile check ai', result); // Log the AI response
  
          // Check if the response includes 'PASS!!' or 'FAILL!!' and update state accordingly
          if (result.aiResponse.includes('PASS!!')) {
            setisAIPassed(true);
            setisAIFailed(false); // Ensure isAIFailed is reset if previously set
          } else if (result.aiResponse.includes('FAIL!!')) {
            setisAIFailed(true);
            setisAIPassed(false); 
            console.log('isAIFailed', isAIFailed) // Ensure isAIPassed is reset if previously set
          }
        } catch (error) {
          console.error("Error checking profile bio:", error);
        }
      };
  
      checkProfileBio();
    }
  }, [formData, token, csrfToken]); // Re-run the effect if formData, token, or csrfToken changes

  useEffect(() => {
    if (user && communityId) {
      const fetchProfile = async () => {
        // If there's an ongoing request, cancel it
        if (currentController) {
          currentController.abort();
        }

        // Create a new AbortController for the new request
        currentController = new AbortController();
        const signal = currentController.signal;

        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/communiUser/getProfile`,
            {
              method: "POST",
              body: JSON.stringify({
                _id: user.userId,
                communityId: communityId,
              }),
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`, // Include the token in the Authorization header
                "X-CSRF-Token": csrfToken, // Include the CSRF token here
              },
              signal: signal, // Pass the signal to the fetch call
            }
          );
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          const json = await response.json();
          setJson(json);

          const userSchema = await json.schemaProfile;
          const userFormData = (await json.userProfile) ?? {};
          const communityName = await json.communityName;
          setCommunityName(communityName);
          onCommunityName(communityName);

          setFormData({ schema: userSchema, data: userFormData });
        } catch (error) {
          if (error.name === "AbortError") {
            // Handle fetch being aborted
          } else {
            // Handle other errors
            console.error("Fetch error:", error);
          }
        }
      };

      fetchProfile();
    }
  }, [user, communityId, csrfToken, token]);

  useEffect(() => {
    if (!formData) return;
    // This code runs after formData has been updated
    handleSubmitCommunityProfile();
  }, [formData]); // Dependency array, effect runs when formData changes

  const handleSubmitCommunityProfile = async (event) => {
    // event.preventDefault();
    const payload = {
      communityId: communityId,
      userId: user.userId,
      Profile: formData.data.Profile, // Assuming this is the correct structure you want to send
    };

    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/communiUser/updateProfile`,
      {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Include the token in the Authorization header
          "X-CSRF-Token": csrfToken, // Include the CSRF token in the 'X-CSRF-Token' header
        },
      }
    );
    const json = await response.json();
  };

  const handleInputChange = (event, communityProfileName, fieldName) => {
    const value = event.target.value;
    setFormData((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        Profile: {
          ...(prevState.data.Profile || {}),
          [fieldName]: value,
        },
      },
    }));
  };

  const handleCheckboxChange = (
    event,
    communityProfileName,
    fieldName,
    option
  ) => {
    const newFormData = { ...formData };
    if (!newFormData.data.Profile) {
      newFormData.data.Profile = {};
    }
    const interests = newFormData.data.Profile[fieldName] || [];
    if (event.target.checked) {
      interests.push(option);
    } else {
      const index = interests.indexOf(option);
      if (index !== -1) {
        interests.splice(index, 1);
      }
    }
    newFormData.data.Profile[fieldName] = interests;
    setFormData(newFormData);
  };

  const renderCommunityProfiles = () => {
    const communityProfiles = formData.data;
    const communityProfilesArray = [];

    const schema = formData.schema;

    for (const [communityProfileName, communityProfileSchema] of Object.entries(
      schema
    )) {
      const fields = Object.keys(communityProfileSchema);

      const FieldElements = (DisableFields) => {
        return fields.map((fieldName) => {
          const fieldType = communityProfileSchema[fieldName].type;
          const fieldEnum = communityProfileSchema[fieldName].enum;
          const fieldValue = communityProfiles.Profile?.[fieldName] ?? "";
          const htmlTitle = communityProfileSchema[fieldName].htmlTitle;
          const wordLimit = communityProfileSchema[fieldName].wordLimit;
          const charactherLimit = communityProfileSchema[fieldName].charactherLimit;

          if (fieldType === "String" && fieldEnum) {
            const optionElements = fieldEnum.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ));
            return (
              <div key={fieldName}>
                <label htmlFor={fieldName}>{fieldName}</label>
                <select
                  id={fieldName}
                  name={fieldName}
                  value={fieldValue}
                  onChange={(event) =>
                    handleInputChange(event, communityProfileName, fieldName)
                  }
                  disabled={DisableFields}
                >
                  {optionElements}
                </select>
              </div>
            );
          } else if (
            Array.isArray(fieldType) &&
            fieldType[0].type === "String" &&
            fieldType[0].htmlref === "checkbox"
          ) {
            const optionElements = fieldType[0].enum.map((option, index) => {
              const checkboxId = `${fieldName}-${index}`;
              return (
                <div key={option} className="dynamic-form">
                  <input
                    type="checkbox"
                    id={checkboxId}
                    name={fieldName}
                    value={option}
                    checked={fieldValue.includes(option)}
                    onChange={(event) =>
                      handleCheckboxChange(
                        event,
                        communityProfileName,
                        fieldName,
                        option
                      )
                    }
                    disabled={DisableFields}
                  />
                  <label
                    id="checkBoxItems"
                    htmlFor={checkboxId}
                    className="dynamic-form__checkbox-label"
                  >
                    {option}
                  </label>
                </div>
              );
            });
            return (
              <div key={fieldName} className="dynamic-form-checkboxes">
                <label
                  id="checkBoxLabel"
                  className="dynamic-form__checkbox-label"
                >
                  {fieldName}
                </label>
                {optionElements}
              </div>
            );
          } else if (fieldType === "String") {
            return (
              <EditableField
                fieldName={fieldName}
                fieldValue={fieldValue}
                charactherLimit={charactherLimit}
                handleSave={handleInputChange} // Assuming handleInputChange is adapted to also handle saving
                communityProfileName={communityProfileName}
                DisableFields={DisableFields}
              />
            );
        

} else {
  return null;
}
});
};

      const EditableFieldElements = FieldElements(false);
      const NonEditableFieldElements = FieldElements(true);

      ////Code continues

      communityProfilesArray.push(
        <CommunityProfileComponent
          key={communityProfileName} // Add this line
          communityProfileName={communityName}
          formattedCommunityProfileName={communityName}
          NonEditableFieldElements={NonEditableFieldElements}
          EditableFieldElements={EditableFieldElements}
          handleSubmitCommunityProfile={handleSubmitCommunityProfile}
        />
      );
    }

    return communityProfilesArray;
  };

  return (
    <header>
      <h1>Your {communityName} Profile!</h1>
      <p><strong>We use this info to help suggest compatible matches</strong></p>    <p>Profile Status: {isCommunityProfileComplete ? (
  <span>Complete <span style={{color: 'green'}}>✓</span></span>
) : isAIFailed ? (
  <span><span style={{color: 'red'}}>✕</span> - sorry, we've detected potentially inappropriate content, please rewrite</span>
) : (
  <span><span style={{color: 'red'}}>✕</span> - please write more in your bio</span>

)}</p> 
{json && json.schemaProfile && json.userProfile && (
        <div>{renderCommunityProfiles()}</div>
      )}
    </header>
  );
};

export default DynamicProfileSeperatePopUpEdit;

///////////////////////////////////////////FORMATTED BUT NOT SENDING PROPERLY

// import React, { useState, useEffect } from 'react';
// import { FaEdit } from 'react-icons/fa';
// import "./ProfileAll.css";
// import PopupForm from './Functions/PopupForm';
// import { FaTimes } from 'react-icons/fa'; // Import the close icon

// const CommunityProfileComponent = ({ communityProfileName, formattedCommunityProfileName, NonEditableFieldElements, EditableFieldElements, handleSubmitCommunityProfile, }) => {
//     return (
//       <div key={communityProfileName} className="dynamic-form">
//         {EditableFieldElements}
//       </div>
//     );
//   };

//   const DynamicProfileSeperatePopUpEdit = ({user, communityId, onCommunityName}) => {
//     const [json, setJson] = useState(null);
//     const [formData, setFormData] = useState({firstName: "", lastName: "", dateOfBirth: ""});
//     const [communityName, setCommunityName] = useState("");
// // Define a variable to hold the current controller outside of your useEffect hook
// let currentController = null;

// useEffect(() => {
//   if (user && communityId) {
//     const fetchProfile = async () => {
//       // If there's an ongoing request, cancel it
//       if (currentController) {
//         currentController.abort();
//       }

//       // Create a new AbortController for the new request
//       currentController = new AbortController();
//       const signal = currentController.signal;

//       try {
//         console.log('logging apil/communiUser/getProfile')
//         console.log('user.', user )

//         const response = await fetch('/api/communiUser/getProfile', {
//           method: 'POST',
//           body: JSON.stringify({ _id: user.userId, communityId: communityId}),
//           headers: {
//             'Content-Type': 'application/json'
//           },
//           signal: signal // Pass the signal to the fetch call
//         });
//         if (!response.ok) {
//           throw new Error(`HTTP error! status: ${response.status}`);
//         }

//         const json = await response.json();
//         setJson(json);
//         console.log('json', json)
//         const userSchema = await json.schemaProfile;
//         const userFormData = await json.userProfile ?? {};
//         const communityName = await json.communityName;
//         setCommunityName(communityName)
//         onCommunityName(communityName)
//         console.log('communityName:', communityName)
//         console.log('userSchema:', userSchema)
//         console.log('userFormData:', userFormData)
//         setFormData({ schema: userSchema, data: userFormData });
//       } catch (error) {
//         if (error.name === 'AbortError') {
//           // Handle fetch being aborted
//           console.log('Fetch aborted:', error);
//         } else {
//           // Handle other errors
//           console.error('Fetch error:', error);
//         }
//       }
//     };

//     fetchProfile();
//   }

//   // Clean up function to abort fetch request on component unmount
//   return () => {
//     if (currentController) {
//       currentController.abort();
//     }
//   };
// }, [user]); // Added user as a dependency

//   const handleSubmitCommunityProfile = async () => {
//       // Your logic to handle the form submission using communityId

//     console.log('community ID for submit', communityId)

//     if (user) {
//       const payload = {
//         userId: user.userId,
//         communityId: communityId,
//         Profile: formData // Correctly structured for the backend
//       };

//       console.log('Submitting community profile data:', payload); // Log the payload before sending

//       try {
//         const response = await fetch('/api/communiUser/updateProfile', {
//           method: 'POST',
//           body: JSON.stringify(payload),
//           headers: {
//             'Content-Type': 'application/json',
//           },
//         });

//         if (!response.ok) {
//           throw new Error(`HTTP error! status: ${response.status}`);
//         }

//         const json = await response.json();
//         console.log('Response JSON:', json); // Log the response JSON
//       } catch (error) {
//         console.error('Error submitting community profile:', error);
//       }
//     } else {
//       console.error('Profile to update not found');
//     }
//   };

// console.log('form data:', formData)

// const handleInputChange = (event, communityProfileName, fieldName) => {
//   const value = event.target.value;
//   handleSubmitCommunityProfile()
//   setFormData(prevState => ({
//     ...prevState,
//     data: {
//       ...prevState.data,
//       Profile: {
//         ...(prevState.data.Profile || {}),
//         [fieldName]: value
//       }
//     }
//   }), async () => {
//     // Call handleSubmitCommunityProfile after state update
//     await handleSubmitCommunityProfile();
//   });
// };

// const handleCheckboxChange = (event, communityProfileName, fieldName, option) => {
//   const newFormData = { ...formData };
//   if (!newFormData.data.Profile) {
//     newFormData.data.Profile = {};
//   }
//   const interests = newFormData.data.Profile[fieldName] || [];
//   if (event.target.checked) {
//     interests.push(option);
//   } else {
//     const index = interests.indexOf(option);
//     if (index !== -1) {
//       interests.splice(index, 1);
//     }
//   }
//   newFormData.data.Profile[fieldName] = interests;
//   setFormData(newFormData, async () => {
//     // Call handleSubmitCommunityProfile after state update
//     await handleSubmitCommunityProfile();
//   });
// };

//     const renderCommunityProfiles = () => {
//       const communityProfiles = formData.data;
//       const communityProfilesArray = [];

//       const schema = formData.schema;
//       console.log('schema!!:', schema);
//       console.log('userId2:', user.userId);

//       for (const [communityProfileName, communityProfileSchema] of Object.entries(schema)) {
//         const fields = Object.keys(communityProfileSchema);

//         const FieldElements = (DisableFields) => {
//           return fields.map((fieldName) => {
//             const fieldType = communityProfileSchema[fieldName].type;
//             const fieldEnum = communityProfileSchema[fieldName].enum;
//             const fieldValue = communityProfiles.Profile ?.[fieldName] ?? "";
//             const htmlTitle = communityProfileSchema[fieldName].htmlTitle;
//             const wordLimit = communityProfileSchema[fieldName].wordLimit;
//             console.log('worldLimit',communityProfileSchema)

//             if (fieldType === 'String' && fieldEnum) {
//             const optionElements = fieldEnum.map((option) => (
//               <option key={option} value={option}>
//                 {option}
//               </option>
//             ));
//             return (
//               <div key={fieldName}>
//                 <label htmlFor={fieldName}>{fieldName}</label>
//                 <select
//                   id={fieldName}
//                   name={fieldName}
//                   value={fieldValue}
//                   onChange={(event) => handleInputChange(event, communityProfileName, fieldName)}
//                   disabled={DisableFields}
//                 >
//                   {optionElements}
//                 </select>
//               </div>
//             );

//           }
//  else if (Array.isArray(fieldType) && fieldType[0].type === 'String' && fieldType[0].htmlref === 'checkbox') {
//           const optionElements = fieldType[0].enum.map((option, index) => {
//             const checkboxId = `${fieldName}-${index}`;
//             return (
//               <div key={option} className="dynamic-form">
//                 <input
//                   type="checkbox"
//                   id={checkboxId}
//                   name={fieldName}
//                   value={option}
//                   checked={fieldValue.includes(option)}
//                   onChange={(event) => handleCheckboxChange(event, communityProfileName, fieldName, option)}
//                   disabled={DisableFields}
//                 />
//             <label htmlFor={checkboxId} className="dynamic-form__checkbox-label">{option}</label>              </div>
//             );
//           });
//           return (
//             <div key={fieldName} className="dynamic-form-checkboxes">
//               <label  id="checkBoxLabel" className="dynamic-form__checkbox-label">{fieldName}</label>
//               {optionElements}
//             </div>
//           );
//         } else if (fieldType === 'String') {
//           // Check if wordLimit is greater than 20 and log if 'mediumBoxSize' class is set
//           if (wordLimit >= 20) {
//             console.log('The label has the class mediumBoxSize.');
//             return (
//               <div key={fieldName}>
//                 <label htmlFor={fieldName}>
//                   {fieldName}
//                 </label>
//                 <textarea
//                   id={fieldName}
//                   name={fieldName}
//                   value={fieldValue}
//                   onChange={(event) => handleInputChange(event, communityProfileName, fieldName)}
//                   readOnly={DisableFields}
//                   className='mediumBoxSize'
//                 />
//               </div>
//             );
//           } else {
//             console.log('The label does not have the class mediumBoxSize.');
//             return (
//               <div key={fieldName}>
//                 <label htmlFor={fieldName}>
//                   {fieldName}
//                 </label>
//                 <input
//                   type="text"
//                   id={fieldName}
//                   name={fieldName}
//                   value={fieldValue}
//                   onChange={(event) => handleInputChange(event, communityProfileName, fieldName)}
//                   readOnly={DisableFields}
//                 />
//               </div>
//             );
//           }
//         } else {
//             return null;
//           }
//         });

//       }

//       const EditableFieldElements = FieldElements(false)
//       const NonEditableFieldElements = FieldElements(true)

// //****  Code continues

//         communityProfilesArray.push(
//           <CommunityProfileComponent
//             key={communityProfileName} // Add this line
//             communityProfileName={communityName}
//             formattedCommunityProfileName={communityName}
//             NonEditableFieldElements={NonEditableFieldElements}
//             EditableFieldElements={EditableFieldElements}
//             handleSubmitCommunityProfile={handleSubmitCommunityProfile}
//           />
//         );
//       }
//       console.log('communityProfilesArray:', communityProfilesArray)
//       return communityProfilesArray;
//     };

//     console.log('formData',formData)
//     return (
//       <div>
//         {json && json.schemaProfile && json.userProfile && (
//           <div>

//             {renderCommunityProfiles()}
//           </div>
//         )}
//       </div>
//     );
//   };

//   export default DynamicProfileSeperatePopUpEdit;

//////////////////////////////////////////////

//GENERATES ONE OR MORE PROFILES

// import React, { useState, useEffect } from 'react';
// import { FaEdit } from 'react-icons/fa';
// import "./DynamicProfileSeperatePopUpEdit.css";
// import { useAuthContext } from '../../hooks/useAuthContext';
// import PopupForm from './Functions/PopupForm';

// const CommunityProfileComponent = ({
//   communityId,
//   communityProfileName,
//   formattedCommunityProfileName,
//   NonEditableFieldElements,
//   EditableFieldElements,
//   handleSubmitCommunityProfile,
// }) => {
//   const [showPopup, setShowPopup] = useState(false);
//   console.log(communityId)

//   return (
//     <div className="dynamic-form">
//       <h1>
//         {formattedCommunityProfileName} Profile
//         <FaEdit onClick={() => setShowPopup(!showPopup)} />
//       </h1>
//       {showPopup ? (
//         <PopupForm className="scrollable-popup" onSubmit={handleSubmitCommunityProfile(communityId)}>
//           <h1>{communityProfileName}</h1>
//           {EditableFieldElements}
//           <button type="button" onClick={(event) => handleSubmitCommunityProfile(event, communityId)}>Submit</button>
//           <button type="button" onClick={() => setShowPopup(false)}>Close</button>
//         </PopupForm>
//       ) : (
//         NonEditableFieldElements
//       )}
//     </div>
//   );
// };

// const DynamicProfileSeperatePopUpEdit = () => {
//   const [json, setJson] = useState(null);
//   const [formState, setFormState] = useState([]); // Array to hold form data and communityIds

//   const { user } = useAuthContext();
//   let currentController = null;

//   useEffect(() => {
//     if (user) {
//       const fetchProfile = async () => {
//         if (currentController) {
//           currentController.abort();
//         }
//         currentController = new AbortController();
//         const signal = currentController.signal;

//         try {
//           const response = await fetch('/api/communiUser/getProfile', {
//             method: 'POST',
//             body: JSON.stringify({ _id: user.userId }),
//             headers: {
//               'Content-Type': 'application/json'
//             },
//             signal: signal
//           });
//           if (!response.ok) {
//             throw new Error(`HTTP error! status: ${response.status}`);
//           }

//           const jsonData = await response.json();
//           setJson(jsonData);
//           console.log('json communi dynamic:', jsonData);

//           const initialFormState = jsonData.userProfiles.map((profile, index) => ({
//             communityId: jsonData.communityId[index], // Correctly using communityId array for IDs
//             formData: profile, // Directly using the profile data
//           }));
//           setFormState(initialFormState);

//           console.log('formstate', initialFormState);

//         } catch (error) {
//           if (error.name === 'AbortError') {
//             console.log('Fetch aborted:', error);
//           } else {
//             console.error('Fetch error:', error);
//           }
//         }

//       };

//       fetchProfile();
//     }

//     return () => {
//       if (currentController) {
//         currentController.abort();
//       }
//     };
//   }, [user]);

//   const handleFieldChange = (communityId, fieldName, event) => {
//     const target = event.target;
//     const value = target.value;
//     const isCheckbox = target.type === 'checkbox';
//     const isMultiSelect = target.options && target.multiple;

//     setFormState(prevFormState => prevFormState.map(profile => {
//       if (profile.communityId === communityId) {
//         let updatedField;
//         if (isCheckbox) {
//           // Handle checkboxes: add or remove the value from the array
//           const currentValues = profile.formData.Profile[fieldName] || [];
//           updatedField = target.checked
//             ? [...currentValues, value] // Add value to array
//             : currentValues.filter(item => item !== value); // Remove value from array
//         } else if (isMultiSelect) {
//           // Handle multi-select dropdowns: get all selected options
//           updatedField = Array.from(target.selectedOptions).map(opt => opt.value);
//         } else {
//           // Handle other fields (e.g., text inputs, single-select dropdowns)
//           updatedField = value;
//         }

//         // Update the specific field in the Profile data
//         const updatedProfileData = {
//           ...profile.formData.Profile,
//           [fieldName]: updatedField
//         };

//         // Return the updated profile with the modified Profile data
//         return {
//           ...profile,
//           formData: {
//             ...profile.formData,
//             Profile: updatedProfileData
//           }
//         };
//       }
//       return profile; // Return other profiles unchanged
//     }));
//   };

//   const handleSubmitCommunityProfile = async (communityId) => {
//       // Your logic to handle the form submission using communityId

//     console.log('community ID for submit', communityId)

//     // Find the profile data for the given communityId
//     const profileToUpdate = formState.find(profile => profile.communityId === communityId);
//     console.log('profileToUpdate', profileToUpdate)

//     if (profileToUpdate) {
//       const payload = {
//         userId: user.userId,
//         communityId: communityId,
//         Profile: profileToUpdate.formData.Profile // Correctly structured for the backend
//       };

//       console.log('Submitting community profile data:', payload); // Log the payload before sending

//       try {
//         const response = await fetch('/api/communiUser/updateProfile', {
//           method: 'POST',
//           body: JSON.stringify(payload),
//           headers: {
//             'Content-Type': 'application/json',
//           },
//         });

//         if (!response.ok) {
//           throw new Error(`HTTP error! status: ${response.status}`);
//         }

//         const json = await response.json();
//         console.log('Response JSON:', json); // Log the response JSON
//       } catch (error) {
//         console.error('Error submitting community profile:', error);
//       }
//     } else {
//       console.error('Profile to update not found');
//     }
//   };

//   const renderCommunityProfiles = () => {
//     if (!json || !json.schemaProfiles || !json.userProfiles || !json.communityNames) {
//       return null;
//     }

//     return json.communityNames.map((communityName, index) => {
//       const schema = json.schemaProfiles[index].profileSchema;
//       const data = json.userProfiles[index].Profile;
//       const communityId = json.communityId[index];
//       const profileData = formState[index].formData
//       console.log('index',index)
//       console.log('schema iterating', schema)
//       console.log('data', data)
//       console.log('communitId iter',communityId )
//       console.log('profileData',profileData)

// // Function to generate field elements based on whether they should be disabled
// const generateFieldElements = (disableFields) => {
//   return Object.keys(schema).map((key) => {
//     const field = schema[key];
//     const value = profileData.Profile[key] || ''; // Use formData from formState

//     if (field.type === 'String' && field.enum) {
//       // This is a dropdown (enum), not a set of checkboxes
//       const optionElements = field.enum.map((option) => (
//         <option key={option} value={option}>{option}</option>
//       ));

//       return (
//         <div key={key}>
//           <label htmlFor={key}>{key}</label>
//           <select
//             id={key}
//             name={key}
//             value={profileData.Profile[key]}
//             onChange={(event) => handleFieldChange(communityId, key, event)}
//             disabled={disableFields}
//           >
//             {optionElements}
//           </select>
//         </div>
//       );

//     } else if (field.type === 'String') {
//       return (
//         <div key={key}>
//           <label htmlFor={key}>{key}</label>
//           <input
//             type="text"
//             id={key}
//             name={key}
//             value={value}
//             onChange={(event) => handleFieldChange(communityId, key, event)}
//             readOnly={disableFields}
// />
//         </div>
//       );
//     } else if (Array.isArray(field.type) && field.type[0].type === 'String' && field.type[0].htmlref === 'checkbox') {
//       const optionElements = field.type[0].enum.map((option) => (
//         <div key={`${key}-${option}`} className="dynamic-form__field">
//           <input
//             type="checkbox"
//             id={`${key}-${option}`}
//             name={key}
//             value={option}
//             checked={profileData.Profile[key].includes(option)}
//             onChange={(event) => handleFieldChange(communityId, key, event)}
//             disabled={disableFields}
//           />
//           <label htmlFor={`${key}-${option}`} className="dynamic-form__checkbox-label">{option}</label>
//         </div>
//       ));

//       return (
//         <div key={key} className="dynamic-form-checkboxes">
//           <label className="dynamic-form__checkbox-label">{key}</label>
//           {optionElements}
//         </div>
//       );
//     }
//     return null;
//   });
// };

// // Generate field elements for both editable and non-editable scenarios
// const EditableFieldElements = generateFieldElements(false);
// const NonEditableFieldElements = generateFieldElements(true);

//       return (
//         <CommunityProfileComponent
//           key={communityId}
//           communityId={communityId}
//           communityProfileName={communityName}
//           formattedCommunityProfileName={communityName}
//           NonEditableFieldElements={<div>{NonEditableFieldElements}</div>}
//           EditableFieldElements={<div>{EditableFieldElements}</div>}
//           handleSubmitCommunityProfile={handleSubmitCommunityProfile}
//         />
//       );
//     });
//   };

//   return (
//     <div>
//       {renderCommunityProfiles()}
//     </div>
//   );
// };

// export default DynamicProfileSeperatePopUpEdit;

///////////////////////////////////////

//DYNAMICALLY HAS ONE OR MORE PROFILES AND SENDS ONLY ON SUBMIT:

// import React, { useState, useEffect } from 'react';
// import { FaEdit } from 'react-icons/fa';
// import "./DynamicProfileSeperatePopUpEdit.css";
// import { useAuthContext } from '../../hooks/useAuthContext';
// import PopupForm from './Functions/PopupForm';

// const CommunityProfileComponent = ({
//   communityId,
//   communityProfileName,
//   formattedCommunityProfileName,
//   NonEditableFieldElements,
//   EditableFieldElements,
//   handleSubmitCommunityProfile,
// }) => {
//   const [showPopup, setShowPopup] = useState(false);
//   console.log(communityId)

//   return (
//     <div className="dynamic-form">
//       <h1>
//         {formattedCommunityProfileName} Profile
//         <FaEdit onClick={() => setShowPopup(!showPopup)} />
//       </h1>
//       {showPopup ? (
//         <PopupForm className="scrollable-popup" onSubmit={(event) => handleSubmitCommunityProfile(event, communityId)}>
//           <h1>{communityProfileName}</h1>
//           {EditableFieldElements}
//           <button type="button" onClick={(event) => handleSubmitCommunityProfile(event, communityId)}>Submit</button>
//           <button type="button" onClick={() => setShowPopup(false)}>Close</button>
//         </PopupForm>
//       ) : (
//         NonEditableFieldElements
//       )}
//     </div>
//   );
// };

// const DynamicProfileSeperatePopUpEdit = () => {
//   const [json, setJson] = useState(null);
//   const [formState, setFormState] = useState([]); // Array to hold form data and communityIds

//   const { user } = useAuthContext();
//   let currentController = null;

//   useEffect(() => {
//     if (user) {
//       const fetchProfile = async () => {
//         if (currentController) {
//           currentController.abort();
//         }
//         currentController = new AbortController();
//         const signal = currentController.signal;

//         try {
//           const response = await fetch('/api/communiUser/getProfile', {
//             method: 'POST',
//             body: JSON.stringify({ _id: user.userId }),
//             headers: {
//               'Content-Type': 'application/json'
//             },
//             signal: signal
//           });
//           if (!response.ok) {
//             throw new Error(`HTTP error! status: ${response.status}`);
//           }

//           const jsonData = await response.json();
//           setJson(jsonData);
//           console.log('json communi dynamic:', jsonData);

//           const initialFormState = jsonData.userProfiles.map((profile, index) => ({
//             communityId: jsonData.communityId[index], // Correctly using communityId array for IDs
//             formData: profile, // Directly using the profile data
//           }));
//           setFormState(initialFormState);

//           console.log('formstate', initialFormState);

//         } catch (error) {
//           if (error.name === 'AbortError') {
//             console.log('Fetch aborted:', error);
//           } else {
//             console.error('Fetch error:', error);
//           }
//         }

//       };

//       fetchProfile();
//     }

//     return () => {
//       if (currentController) {
//         currentController.abort();
//       }
//     };
//   }, [user]);

//   const handleFieldChange = (communityId, fieldName, event) => {
//     const target = event.target;
//     const value = target.value;
//     const isCheckbox = target.type === 'checkbox';
//     const isMultiSelect = target.options && target.multiple;

//     setFormState(prevFormState => prevFormState.map(profile => {
//       if (profile.communityId === communityId) {
//         let updatedField;
//         if (isCheckbox) {
//           // Handle checkboxes: add or remove the value from the array
//           const currentValues = profile.formData.Profile[fieldName] || [];
//           updatedField = target.checked
//             ? [...currentValues, value] // Add value to array
//             : currentValues.filter(item => item !== value); // Remove value from array
//         } else if (isMultiSelect) {
//           // Handle multi-select dropdowns: get all selected options
//           updatedField = Array.from(target.selectedOptions).map(opt => opt.value);
//         } else {
//           // Handle other fields (e.g., text inputs, single-select dropdowns)
//           updatedField = value;
//         }

//         // Update the specific field in the Profile data
//         const updatedProfileData = {
//           ...profile.formData.Profile,
//           [fieldName]: updatedField
//         };

//         // Return the updated profile with the modified Profile data
//         return {
//           ...profile,
//           formData: {
//             ...profile.formData,
//             Profile: updatedProfileData
//           }
//         };
//       }
//       return profile; // Return other profiles unchanged
//     }));
//   };

//   const handleSubmitCommunityProfile = async (event, communityId) => {
//     event.preventDefault(); // Prevent the default form submission behavior
//     // Your logic to handle the form submission using communityId

//     console.log('community ID for submit', communityId)

//     // Find the profile data for the given communityId
//     const profileToUpdate = formState.find(profile => profile.communityId === communityId);
//     console.log('profileToUpdate', profileToUpdate)

//     if (profileToUpdate) {
//       const payload = {
//         userId: user.userId,
//         communityId: communityId,
//         Profile: profileToUpdate.formData.Profile // Correctly structured for the backend
//       };

//       console.log('Submitting community profile data:', payload); // Log the payload before sending

//       try {
//         const response = await fetch('/api/communiUser/updateProfile', {
//           method: 'POST',
//           body: JSON.stringify(payload),
//           headers: {
//             'Content-Type': 'application/json',
//           },
//         });

//         if (!response.ok) {
//           throw new Error(`HTTP error! status: ${response.status}`);
//         }

//         const json = await response.json();
//         console.log('Response JSON:', json); // Log the response JSON
//       } catch (error) {
//         console.error('Error submitting community profile:', error);
//       }
//     } else {
//       console.error('Profile to update not found');
//     }
//   };

//   const renderCommunityProfiles = () => {
//     if (!json || !json.schemaProfiles || !json.userProfiles || !json.communityNames) {
//       return null;
//     }

//     return json.communityNames.map((communityName, index) => {
//       const schema = json.schemaProfiles[index].profileSchema;
//       const data = json.userProfiles[index].Profile;
//       const communityId = json.communityId[index];
//       const profileData = formState[index].formData
//       console.log('index',index)
//       console.log('schema iterating', schema)
//       console.log('data', data)
//       console.log('communitId iter',communityId )
//       console.log('profileData',profileData)

// // Function to generate field elements based on whether they should be disabled
// const generateFieldElements = (disableFields) => {
//   return Object.keys(schema).map((key) => {
//     const field = schema[key];
//     const value = profileData.Profile[key] || ''; // Use formData from formState

//     if (field.type === 'String' && field.enum) {
//       // This is a dropdown (enum), not a set of checkboxes
//       const optionElements = field.enum.map((option) => (
//         <option key={option} value={option}>{option}</option>
//       ));

//       return (
//         <div key={key}>
//           <label htmlFor={key}>{key}</label>
//           <select
//             id={key}
//             name={key}
//             value={profileData.Profile[key]}
//             onChange={(event) => handleFieldChange(communityId, key, event)}
//             disabled={disableFields}
//           >
//             {optionElements}
//           </select>
//         </div>
//       );

//     } else if (field.type === 'String') {
//       return (
//         <div key={key}>
//           <label htmlFor={key}>{key}</label>
//           <input
//             type="text"
//             id={key}
//             name={key}
//             value={value}
//             onChange={(event) => handleFieldChange(communityId, key, event)}
//             readOnly={disableFields}
// />
//         </div>
//       );
//     } else if (Array.isArray(field.type) && field.type[0].type === 'String' && field.type[0].htmlref === 'checkbox') {
//       const optionElements = field.type[0].enum.map((option) => (
//         <div key={`${key}-${option}`} className="dynamic-form__field">
//           <input
//             type="checkbox"
//             id={`${key}-${option}`}
//             name={key}
//             value={option}
//             checked={profileData.Profile[key].includes(option)}
//             onChange={(event) => handleFieldChange(communityId, key, event)}
//             disabled={disableFields}
//           />
//           <label htmlFor={`${key}-${option}`} className="dynamic-form__checkbox-label">{option}</label>
//         </div>
//       ));

//       return (
//         <div key={key} className="dynamic-form-checkboxes">
//           <label className="dynamic-form__checkbox-label">{key}</label>
//           {optionElements}
//         </div>
//       );
//     }
//     return null;
//   });
// };

// // Generate field elements for both editable and non-editable scenarios
// const EditableFieldElements = generateFieldElements(false);
// const NonEditableFieldElements = generateFieldElements(true);

//       return (
//         <CommunityProfileComponent
//           key={communityId}
//           communityId={communityId}
//           communityProfileName={communityName}
//           formattedCommunityProfileName={communityName}
//           NonEditableFieldElements={<div>{NonEditableFieldElements}</div>}
//           EditableFieldElements={<div>{EditableFieldElements}</div>}
//           handleSubmitCommunityProfile={handleSubmitCommunityProfile}
//         />
//       );
//     });
//   };

//   return (
//     <header>
//       {renderCommunityProfiles()}
//     </header>
//   );
// };

// export default DynamicProfileSeperatePopUpEdit;

///////////////////

// import React, { useState, useEffect } from 'react';
// import { FaEdit } from 'react-icons/fa';
// import "./DynamicProfileSeperatePopUpEdit.css";
// import { useAuthContext } from '../../hooks/useAuthContext';
// import PopupForm from './Functions/PopupForm';
// import { FaTimes } from 'react-icons/fa';

// const CommunityProfileComponent = ({ communityProfileName, formattedCommunityProfileName, NonEditableFieldElements, EditableFieldElements, handleSubmitCommunityProfile, }) => {
//   const [showPopup, setShowPopup] = useState(false);
//   return (
//     <div key={communityProfileName} className="dynamic-form">
//       <h1>
//          {formattedCommunityProfileName} Profile
//         <FaEdit onClick={() => setShowPopup(!showPopup)} />
//       </h1>
//       {showPopup ? (
//         <PopupForm className="scrollable-popup" onSubmit={handleSubmitCommunityProfile}>
//           <h1> {communityProfileName} </h1>
//           {EditableFieldElements}
//           <button type="update">Submit</button>
//           <button onClick={() => setShowPopup(false)}>Close</button> {/* Close button */}
//         </PopupForm>
//       ) : (
//         NonEditableFieldElements
//       )}
//     </div>
//   );
// };

// const DynamicProfileSeperatePopUpEdit = () => {
//   const [json, setJson] = useState(null);
//   const [formData, setFormData] = useState([]);
//   const { user }= useAuthContext();
//   const [communityNames, setCommunityNames] = useState([]);
//   let currentController = null;

//   useEffect(() => {
//     if (user) {
//       const fetchProfile = async () => {
//         if (currentController) {
//           currentController.abort();
//         }
//         currentController = new AbortController();
//         const signal = currentController.signal;

//         try {
//           const response = await fetch('/api/communiUser/getProfile', {
//             method: 'POST',
//             body: JSON.stringify({ _id: user.userId }),
//             headers: {
//               'Content-Type': 'application/json'
//             },
//             signal: signal
//           });
//           if (!response.ok) {
//             throw new Error(`HTTP error! status: ${response.status}`);
//           }

//           const json = await response.json();
//           setJson(json);
//           // Assuming json.schemaProfile, json.userProfile, and json.communityName are arrays
//           setFormData(json.userProfile.map((profile, index) => ({
//             schema: json.schemaProfile[index],
//             data: profile,
//             communityName: json.communityName[index]
//           })));
//         } catch (error) {
//           if (error.name === 'AbortError') {
//             console.log('Fetch aborted:', error);
//           } else {
//             console.error('Fetch error:', error);
//           }
//         }
//       };

//       fetchProfile();
//     }

//     return () => {
//       if (currentController) {
//         currentController.abort();
//       }
//     };
//   }, [user]);

//     const handleInputChange = (event, communityProfileName, fieldName) => {
//       const value = event.target.value;
//       setFormData(prevState => ({
//         ...prevState,
//         data: {
//           ...prevState.data,
//           Profile: {
//             ...(prevState.data.Profile || {}),
//             [fieldName]: value
//           }
//         }
//       }));
//     };

//     const handleCheckboxChange = (event, communityProfileName, fieldName, option) => {
//       const newFormData = { ...formData };
//       if (!newFormData.data.Profile) {
//         newFormData.data.Profile = {};
//       }
//       const interests = newFormData.data.Profile[fieldName] || [];
//       if (event.target.checked) {
//         interests.push(option);
//       } else {
//         const index = interests.indexOf(option);
//         if (index !== -1) {
//           interests.splice(index, 1);
//         }
//       }
//       newFormData.data.Profile[fieldName] = interests;
//       setFormData(newFormData);
//     };

//     const renderCommunityProfiles = () => {
//       const communityProfiles = formData.data;
//       const communityProfilesArray = [];

//       const schema = formData.schema;
//       console.log('schema!!:', schema)
//       console.log('userId2:', user.userId)
//       //NB communityProfileName legacy term, better to rename it! E.g., UserProfileSchema
//       for (const [communityProfileName, communityProfileSchema] of Object.entries(schema)) {
//         const fields = Object.keys(communityProfileSchema);

//         const FieldElements = (DisableFields) => {

//           return fields.map((fieldName) => {
//           const fieldType = communityProfileSchema[fieldName].type;
//           const fieldEnum = communityProfileSchema[fieldName].enum;
//           const fieldValue = communityProfiles.Profile ?.[fieldName] ?? "";
//           if (fieldType === 'String' && fieldEnum) {
//             const optionElements = fieldEnum.map((option) => (
//               <option key={option} value={option}>
//                 {option}
//               </option>
//             ));
//             return (
//               <div key={fieldName}>
//                 <label htmlFor={fieldName}>{fieldName}</label>
//                 <select
//                   id={fieldName}
//                   name={fieldName}
//                   value={fieldValue}
//                   onChange={(event) => handleInputChange(event, communityProfileName, fieldName)}
//                   disabled={DisableFields}
//                 >
//                   {optionElements}
//                 </select>
//               </div>
//             );

//           }
//           else if (Array.isArray(fieldType) && fieldType[0].type === 'String' && fieldType[0].htmlref === 'checkbox') {
//             const optionElements = fieldType[0].enum.map((option) => (
//               <div key={option} className="dynamic-form__field">
//                 <input
//                   type="checkbox"
//                   id={option}
//                   name={fieldName}
//                   value={option}
//                   checked={fieldValue.includes(option)}
//                   onChange={(event) => handleCheckboxChange(event, communityProfileName, fieldName, option)}
//                   disabled={DisableFields}
//                 />
//                 <label htmlFor={option} className="dynamic-form__checkbox-label">{option}</label>
//               </div>
//             ));
//             return (
//               <div key={fieldName} className="dynamic-form-checkboxes">
//                 <label className="dynamic-form__checkbox-label">{fieldName}</label>
//                 {optionElements}
//               </div>
//             );
//           } else if (fieldType === 'String') {
//             return (
//               <div key={fieldName}>
//                 <label htmlFor={fieldName}>{fieldName}</label>
//                 <input
//                   type="text"
//                   id={fieldName}
//                   name={fieldName}
//                   value={fieldValue}
//                   onChange={(event) => handleInputChange(event, communityProfileName, fieldName)}
//                   readOnly={DisableFields}
//                 />
//               </div>
//             );
//           } else {
//             return null;
//           }
//         });

//       }

//       const EditableFieldElements = FieldElements(false)
//       const NonEditableFieldElements = FieldElements(true)

// //****  Code continues

//         const handleSubmitCommunityProfile = async (event) => {
//           // event.preventDefault();
//           const payload = {
//             userId: user.userId,
//             Profile: formData.data.Profile // Assuming this is the correct structure you want to send
//           };
//           console.log('Submitting community profile datax:', formData); // Log the payload before sending

//           const response = await fetch('/api/communiUser/upDateProfile', {
//             method: 'POST',
//             body: JSON.stringify(payload),
//             headers: {
//               'Content-Type': 'application/json',
//             },
//           });
//           const json = await response.json();
//           console.log('Response JSON:', json); // Log the response JSON
//         };

//         communityProfilesArray.push(
//           <CommunityProfileComponent
//             key={communityProfileName} // Add this line
//             communityProfileName={communityName}
//             formattedCommunityProfileName={communityName}
//             NonEditableFieldElements={NonEditableFieldElements}
//             EditableFieldElements={EditableFieldElements}
//             handleSubmitCommunityProfile={handleSubmitCommunityProfile}
//           />
//         );
//       }
//       console.log('communityProfilesArray:', communityProfilesArray)
//       return communityProfilesArray;
//     };

//   return (
//     <header>
//       {json && json.schemaProfile && json.userProfile && (
//         <div>
//           {renderCommunityProfiles()}
//         </div>
//       )}
//     </header>
//   );
// };

// export default DynamicProfileSeperatePopUpEdit;

// //  WORKS - just pre iteration of more than one profile

// import React, { useState, useEffect } from 'react';
// import { FaEdit } from 'react-icons/fa';
// import "./DynamicProfileSeperatePopUpEdit.css";
// import { useAuthContext } from '../../hooks/useAuthContext';
// import PopupForm from './Functions/PopupForm';
// import { FaTimes } from 'react-icons/fa'; // Import the close icon

// const CommunityProfileComponent = ({ communityProfileName, formattedCommunityProfileName, NonEditableFieldElements, EditableFieldElements, handleSubmitCommunityProfile, }) => {
//   const [showPopup, setShowPopup] = useState(false);
//   return (
//     <div key={communityProfileName} className="dynamic-form">
//       <h1>
//          {formattedCommunityProfileName} Profile
//         <FaEdit onClick={() => setShowPopup(!showPopup)} />
//       </h1>
//       {showPopup ? (
//         <PopupForm className="scrollable-popup" onSubmit={handleSubmitCommunityProfile()}>          <h1> {communityProfileName} </h1>
//           {EditableFieldElements}
//           <button type="update">Submit</button>
//           <button onClick={() => setShowPopup(false)}>Close</button> {/* Close button */}
//         </PopupForm>
//       ) : (
//         NonEditableFieldElements
//       )}
//     </div>
//   );

//       }

//   const DynamicProfileSeperatePopUpEdit = () => {
//     const [json, setJson] = useState(null);
//     const [formData, setFormData] = useState({firstName: "", lastName: "", dateOfBirth: ""});
//     const { user }= useAuthContext()
//     const [communityName, setCommunityName] = useState("");
// // Define a variable to hold the current controller outside of your useEffect hook
// let currentController = null;

// useEffect(() => {
//   if (user) {
//     const fetchProfile = async () => {
//       // If there's an ongoing request, cancel it
//       if (currentController) {
//         currentController.abort();
//       }

//       // Create a new AbortController for the new request
//       currentController = new AbortController();
//       const signal = currentController.signal;

//       try {
//         console.log('logging apil/communiUser/getProfile')
//         console.log('user.userId',user.userId )

//         const response = await fetch('/api/communiUser/getProfile', {
//           method: 'POST',
//           body: JSON.stringify({ _id: user.userId }),
//           headers: {
//             'Content-Type': 'application/json'
//           },
//           signal: signal // Pass the signal to the fetch call
//         });
//         if (!response.ok) {
//           throw new Error(`HTTP error! status: ${response.status}`);
//         }

//         const json = await response.json();
//         setJson(json);
//         console.log('json', json)
//         const userSchema = await json.schemaProfile;
//         const userFormData = await json.userProfile ?? {};
//         const communityName = await json.communityName;
//         setCommunityName(communityName)
//         console.log('communityName:', communityName)
//         console.log('userSchema:', userSchema)
//         console.log('userFormData:', userFormData)
//         setFormData({ schema: userSchema, data: userFormData });
//       } catch (error) {
//         if (error.name === 'AbortError') {
//           // Handle fetch being aborted
//           console.log('Fetch aborted:', error);
//         } else {
//           // Handle other errors
//           console.error('Fetch error:', error);
//         }
//       }
//     };

//     fetchProfile();
//   }

//   // Clean up function to abort fetch request on component unmount
//   return () => {
//     if (currentController) {
//       currentController.abort();
//     }
//   };
// }, [user]); // Added user as a dependency

// console.log('form data:', formData)

//     const handleInputChange = (event, communityProfileName, fieldName) => {
//       const value = event.target.value;
//       setFormData(prevState => ({
//         ...prevState,
//         data: {
//           ...prevState.data,
//           Profile: {
//             ...(prevState.data.Profile || {}),
//             [fieldName]: value
//           }
//         }
//       }));
//     };

//     const handleCheckboxChange = (event, communityProfileName, fieldName, option) => {
//       const newFormData = { ...formData };
//       if (!newFormData.data.Profile) {
//         newFormData.data.Profile = {};
//       }
//       const interests = newFormData.data.Profile[fieldName] || [];
//       if (event.target.checked) {
//         interests.push(option);
//       } else {
//         const index = interests.indexOf(option);
//         if (index !== -1) {
//           interests.splice(index, 1);
//         }
//       }
//       newFormData.data.Profile[fieldName] = interests;
//       setFormData(newFormData);
//     };

//     const renderCommunityProfiles = () => {
//       const communityProfiles = formData.data;
//       const communityProfilesArray = [];

//       const schema = formData.schema;
//       console.log('schema!!:', schema)
//       console.log('userId2:', user.userId)
//       //NB communityProfileName legacy term, better to rename it! E.g., UserProfileSchema
//       for (const [communityProfileName, communityProfileSchema] of Object.entries(schema)) {
//         const fields = Object.keys(communityProfileSchema);

//         const FieldElements = (DisableFields) => {

//           return fields.map((fieldName) => {
//           const fieldType = communityProfileSchema[fieldName].type;
//           const fieldEnum = communityProfileSchema[fieldName].enum;
//           const fieldValue = communityProfiles.Profile ?.[fieldName] ?? "";
//           if (fieldType === 'String' && fieldEnum) {
//             const optionElements = fieldEnum.map((option) => (
//               <option key={option} value={option}>
//                 {option}
//               </option>
//             ));
//             return (
//               <div key={fieldName}>
//                 <label htmlFor={fieldName}>{fieldName}</label>
//                 <select
//                   id={fieldName}
//                   name={fieldName}
//                   value={fieldValue}
//                   onChange={(event) => handleInputChange(event, communityProfileName, fieldName)}
//                   disabled={DisableFields}
//                 >
//                   {optionElements}
//                 </select>
//               </div>
//             );

//           }
//           else if (Array.isArray(fieldType) && fieldType[0].type === 'String' && fieldType[0].htmlref === 'checkbox') {
//             const optionElements = fieldType[0].enum.map((option) => (
//               <div key={option} className="dynamic-form__field">
//                 <input
//                   type="checkbox"
//                   id={option}
//                   name={fieldName}
//                   value={option}
//                   checked={fieldValue.includes(option)}
//                   onChange={(event) => handleCheckboxChange(event, communityProfileName, fieldName, option)}
//                   disabled={DisableFields}
//                 />
//                 <label htmlFor={option} className="dynamic-form__checkbox-label">{option}</label>
//               </div>
//             ));
//             return (
//               <div key={fieldName} className="dynamic-form-checkboxes">
//                 <label className="dynamic-form__checkbox-label">{fieldName}</label>
//                 {optionElements}
//               </div>
//             );
//           } else if (fieldType === 'String') {
//             return (
//               <div key={fieldName}>
//                 <label htmlFor={fieldName}>{fieldName}</label>
//                 <input
//                   type="text"
//                   id={fieldName}
//                   name={fieldName}
//                   value={fieldValue}
//                   onChange={(event) => handleInputChange(event, communityProfileName, fieldName)}
//                   readOnly={DisableFields}
//                 />
//               </div>
//             );
//           } else {
//             return null;
//           }
//         });

//       }

//       const EditableFieldElements = FieldElements(false)
//       const NonEditableFieldElements = FieldElements(true)

// //****  Code continues

//         const handleSubmitCommunityProfile = async (event) => {
//           // event.preventDefault();
//           const payload = {
//             userId: user.userId,
//             Profile: formData.data.Profile // Assuming this is the correct structure you want to send
//           };
//           console.log('Submitting community profile datax:', formData); // Log the payload before sending

//           const response = await fetch('/api/communiUser/upDateProfile', {
//             method: 'POST',
//             body: JSON.stringify(payload),
//             headers: {
//               'Content-Type': 'application/json',
//             },
//           });
//           const json = await response.json();
//           console.log('Response JSON:', json); // Log the response JSON
//         };

//         communityProfilesArray.push(
//           <CommunityProfileComponent
//             key={communityProfileName} // Add this line
//             communityProfileName={communityName}
//             formattedCommunityProfileName={communityName}
//             NonEditableFieldElements={NonEditableFieldElements}
//             EditableFieldElements={EditableFieldElements}
//             handleSubmitCommunityProfile={handleSubmitCommunityProfile}
//           />
//         );
//       }
//       console.log('communityProfilesArray:', communityProfilesArray)
//       return communityProfilesArray;
//     };

//     console.log('formData',formData)
//     return (
//       <header>
//         {json && json.schemaProfile && json.userProfile && (
//           <div>

//             {renderCommunityProfiles()}
//           </div>
//         )}
//       </header>
//     );
//   };

//   export default DynamicProfileSeperatePopUpEdit;

// import React, { useState, useEffect } from 'react';
// import { FaEdit } from 'react-icons/fa';
// import "./DynamicProfileSeperatePopUpEdit.css";
// import { useAuthContext } from '../../hooks/useAuthContext';
// import PopupForm from './Functions/PopupForm';
// import { FaTimes } from 'react-icons/fa'; // Import the close icon

// const CommunityProfileComponent = ({ communityProfileName, formattedCommunityProfileName, NonEditableFieldElements, EditableFieldElements, handleSubmitCommunityProfile, }) => {
//   const [showPopup, setShowPopup] = useState(false);
//   return (
//     <div key={communityProfileName} className="dynamic-form">
//       <h2>
//         {formattedCommunityProfileName}
//         <FaEdit onClick={() => setShowPopup(!showPopup)} />
//       </h2>
//       {showPopup ? (
//         <PopupForm className="scrollable-popup" onSubmit={handleSubmitCommunityProfile()}>          <h1> {communityProfileName} </h1>
//           {EditableFieldElements}
//           <button type="update">Submit</button>
//           <button onClick={() => setShowPopup(false)}>Close</button> {/* Close button */}
//         </PopupForm>
//       ) : (
//         NonEditableFieldElements
//       )}
//     </div>
//   );

//       }

//   const DynamicProfileSeperatePopUpEdit = () => {
//     const [json, setJson] = useState(null);
//     const [formData, setFormData] = useState({firstName: "", lastName: "", dateOfBirth: ""});
//     const { user }= useAuthContext()
// // Define a variable to hold the current controller outside of your useEffect hook
// let currentController = null;

// useEffect(() => {
//   if (user) {
//     const fetchProfile = async () => {
//       // If there's an ongoing request, cancel it
//       if (currentController) {
//         currentController.abort();
//       }

//       // Create a new AbortController for the new request
//       currentController = new AbortController();
//       const signal = currentController.signal;

//       try {
//         const response = await fetch('/api/communiUser/getProfile', {
//           method: 'POST',
//           body: JSON.stringify({ _id: user.userId }),
//           headers: {
//             'Content-Type': 'application/json'
//           },
//           signal: signal // Pass the signal to the fetch call
//         });
//         const json = await response.json();
//         console.log('fetchProfile useEffect refresh:', json)
//         setJson(json);
//         const userSchema = await json.schema.CommunityProfiles;
//         const userFormData = await json.user?.CommunityProfiles ?? {};
//         console.log('schema useffect:', userSchema)
//         console.log('data:', userFormData)
//         setFormData({ schema: userSchema, data: userFormData });
//       } catch (error) {
//         if (error.name === 'AbortError') {
//           // Handle fetch being aborted
//           console.log('Fetch aborted:', error);
//         } else {
//           // Handle other errors
//           console.error('Fetch error:', error);
//         }
//       }
//     };

//     fetchProfile();
//   }

//   // Clean up function to abort fetch request on component unmount
//   return () => {
//     if (currentController) {
//       currentController.abort();
//     }
//   };
// }, [user]); // Added user as a dependency

//     const handleInputChange = (event, communityProfileName, fieldName) => {
//       const value = event.target.value;
//       setFormData(prevState => ({
//         ...prevState,
//         data: {
//           ...prevState.data,
//           [communityProfileName]: {
//             ...(prevState.data[communityProfileName] || {}),
//             [fieldName]: value
//           }
//         }
//       }));
//     };

//     const handleCheckboxChange = (event, communityProfileName, fieldName, option) => {
//       const newFormData = { ...formData };
//       if (!newFormData.data[communityProfileName]) {
//         newFormData.data[communityProfileName] = {};
//       }
//       const interests = newFormData.data[communityProfileName][fieldName] || [];
//       if (event.target.checked) {
//         interests.push(option);
//       } else {
//         const index = interests.indexOf(option);
//         if (index !== -1) {
//           interests.splice(index, 1);
//         }
//       }
//       newFormData.data[communityProfileName][fieldName] = interests;
//       setFormData(newFormData);
//     };

//     const renderCommunityProfiles = () => {
//       const communityProfiles = formData.data;
//       const communityProfilesArray = [];

//       const schema = formData.schema;
//       console.log('schema:', schema)
//       console.log('userId2:', user.userId)
//       for (const [communityProfileName, communityProfileSchema] of Object.entries(schema)) {
//         const formattedCommunityProfileName = communityProfileName.replace(/([A-Z])/g, ' $1').trim();
//         const fields = Object.keys(communityProfileSchema);

//         const FieldElements = (DisableFields) => {

//           return fields.map((fieldName) => {
//           const fieldType = communityProfileSchema[fieldName].type;
//           const fieldEnum = communityProfileSchema[fieldName].enum;
//           const fieldValue = communityProfiles[communityProfileName]?.[fieldName] ?? "";
//           if (fieldType === 'String' && fieldEnum) {
//             const optionElements = fieldEnum.map((option) => (
//               <option key={option} value={option}>
//                 {option}
//               </option>
//             ));
//             return (
//               <div key={fieldName}>
//                 <label htmlFor={fieldName}>{fieldName}</label>
//                 <select
//                   id={fieldName}
//                   name={fieldName}
//                   value={fieldValue}
//                   onChange={(event) => handleInputChange(event, communityProfileName, fieldName)}
//                   disabled={DisableFields}
//                 >
//                   {optionElements}
//                 </select>
//               </div>
//             );

//           }
//           else if (Array.isArray(fieldType) && fieldType[0].type === 'String' && fieldType[0].htmlref === 'checkbox') {
//             const optionElements = fieldType[0].enum.map((option) => (
//               <div key={option} className="dynamic-form__field">
//                 <input
//                   type="checkbox"
//                   id={option}
//                   name={fieldName}
//                   value={option}
//                   checked={fieldValue.includes(option)}
//                   onChange={(event) => handleCheckboxChange(event, communityProfileName, fieldName, option)}
//                   disabled={DisableFields}
//                 />
//                 <label htmlFor={option} className="dynamic-form__checkbox-label">{option}</label>
//               </div>
//             ));
//             return (
//               <div key={fieldName} className="dynamic-form-checkboxes">
//                 <label className="dynamic-form__checkbox-label">{fieldName}</label>
//                 {optionElements}
//               </div>
//             );
//           } else if (fieldType === 'String') {
//             return (
//               <div key={fieldName}>
//                 <label htmlFor={fieldName}>{fieldName}</label>
//                 <input
//                   type="text"
//                   id={fieldName}
//                   name={fieldName}
//                   value={fieldValue}
//                   onChange={(event) => handleInputChange(event, communityProfileName, fieldName)}
//                   readOnly={DisableFields}
//                 />
//               </div>
//             );
//           } else {
//             return null;
//           }
//         });

//       }

//       const EditableFieldElements = FieldElements(false)
//       const NonEditableFieldElements = FieldElements(true)

// //****  Code continues

//         const handleSubmitCommunityProfile = async (event) => {
//           // event.preventDefault();
//           console.log('Submitting community profile data.  _id:', user.userId);
//           const response = await fetch('/api/dynamic/updateProfile', {
//             method: 'POST',
//             body: JSON.stringify({
//               userId: user.userId,
//               CommunityProfiles: { [communityProfileName]: formData.data[communityProfileName] },
//             }),
//             headers: {
//               'Content-Type': 'application/json',
//             },
//           });
//           const json = await response.json();
//           console.log(json);
//         };

//         communityProfilesArray.push(
//           <CommunityProfileComponent
//             key={communityProfileName} // Add this line
//             communityProfileName={communityProfileName}
//             formattedCommunityProfileName={formattedCommunityProfileName}
//             NonEditableFieldElements={NonEditableFieldElements}
//             EditableFieldElements={EditableFieldElements}
//             handleSubmitCommunityProfile={handleSubmitCommunityProfile}
//           />
//         );
//       }
//       console.log('communityProfilesArray:', communityProfilesArray)
//       return communityProfilesArray;
//     };

//     return (
//       <header>
//         {json && json.user && json.user && (
//           <div>

//             {renderCommunityProfiles()}
//           </div>
//         )}
//       </header>
//     );
//   };

//   export default DynamicProfileSeperatePopUpEdit;
