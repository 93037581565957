//import { useCommunitiesContext } from '../hooks/useCommunitiesContext'
import { Link } from "react-router-dom";
import "./ICommunity.css";

const ICommunity = ({ community }) => {
  const handleClick = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/communities/` + community._id,
      {
        method: "GET",
      }
    );
    const json = await response.json();
    alert(`You selected ${json.title}!`);
  };
  //const { dispatch } = useCommunitiesContext()
  // if (response.ok) {dispatch({type: 'DELETE_COMMUNITY', payload: json})}
  //alert(`You selected ${json.title}!`)
  return (
    <div className="i-community">
      <h4>{community.title}</h4>
      <h3>
        <strong>Type of Community: </strong>
        {community.type}
      </h3>
      <br></br>
      <h3>
        <strong>Location: </strong>
        {community.location}
      </h3>
      <br></br>
      <p>
        <strong>{community.description}</strong>
      </p>
      <br></br>
      <p>
        <strong>{community.groupinfo}</strong>
      </p>
      <br></br>
      <p>
        <strong>Tags: {community.tags}</strong>
      </p>
      <br></br>
      <div className="joinbtn">
        <span>
          <Link to={"/groupsearch/group/proxy/" + community._id}>
            <center>Join</center>
          </Link>
        </span>
      </div>
    </div>
  );
};

export default ICommunity;
//<span className="material-symbols-outlined" onClick={handleClick}>delete</span>
