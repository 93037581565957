import { useEffect} from "react"
import { useCommunitiesContext } from "../hooks/useCommunitiesContext"
import "./Login.css";
import MyForgotPasswordForm from "../components/Login/ForgotPasswordForm";

const ForgotPassword = () => {
  

  return (
    
  
      <div>
      
        {/* <h1>Forgot Password</h1> */}
        <MyForgotPasswordForm />

     
      </div>


  )
}

export default ForgotPassword