import React, { useState, useEffect } from "react";
import { FaEdit } from "react-icons/fa";
import { useAuthContext } from "../../hooks/useAuthContext";
import PopupForm from "./Functions/PopupForm";
import "./SeperatePopUpEdit.css";

const CommunityPreferencesComponent = ({
  communityId,
  communityPreferencesName,
  formattedCommunityPreferencesName,
  NonEditableFieldElements,
  EditableFieldElements,
  handleSubmitCommunityPreferences,
}) => {
  const [showPopup, setShowPopup] = useState(false);
  console.log(communityId);

  return (
    <div className="dynamic-form">
      <h1>
        {formattedCommunityPreferencesName} Preferences
        <FaEdit onClick={() => setShowPopup(!showPopup)} />
      </h1>
      {showPopup ? (
        <PopupForm
          className="scrollable-popup"
          onSubmit={handleSubmitCommunityPreferences(communityId)}
        >
          <h1>{communityPreferencesName}</h1>
          {EditableFieldElements}
          <button
            type="button"
            onClick={(event) =>
              handleSubmitCommunityPreferences(event, communityId)
            }
          >
            Submit
          </button>
          <button type="button" onClick={() => setShowPopup(false)}>
            Close
          </button>
        </PopupForm>
      ) : (
        NonEditableFieldElements
      )}
    </div>
  );
};

const PreferencesSeperatePopUpEdit = () => {
  const [json, setJson] = useState(null);
  const [formState, setFormState] = useState([]); // Array to hold form data and communityIds

  const { user } = useAuthContext();
  let currentController = null;

  useEffect(() => {
    if (user) {
      const fetchPreferences = async () => {
        if (currentController) {
          currentController.abort();
        }
        currentController = new AbortController();
        const signal = currentController.signal;

        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/preferences/`,
            {
              method: "POST",
              body: JSON.stringify({ userId: user.userId }),
              headers: {
                "Content-Type": "application/json",
              },
              signal: signal,
            }
          );
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          const jsonData = await response.json();
          setJson(jsonData);
          console.log("json setting dynamic:", jsonData);

          const initialFormState = jsonData.userPreferences.map(
            (preferences, index) => ({
              communityId: jsonData.communityId[index], // Correctly using communityId array for IDs
              formData: preferences, // Directly using the profile data
            })
          );
          setFormState(initialFormState);

          console.log("formstate", initialFormState);
        } catch (error) {
          if (error.name === "AbortError") {
            console.log("Fetch aborted:", error);
          } else {
            console.error("Fetch error:", error);
          }
        }
      };

      fetchPreferences();
    }

    return () => {
      if (currentController) {
        currentController.abort();
      }
    };
  }, [user]);

  const handleFieldChange = (communityId, fieldName, event) => {
    const target = event.target;
    const isChecked = target.checked; // Use checked for checkbox state
    const isCheckbox = target.type === "checkbox";
    const isMultiSelect = target.options && target.multiple;

    setFormState((prevFormState) =>
      prevFormState.map((profile) => {
        if (profile.communityId === communityId) {
          let updatedField;
          if (isCheckbox) {
            // Handle checkboxes: toggle the boolean value
            updatedField = isChecked; // Directly use the checked state
          } else if (isMultiSelect) {
            // Handle multi-select dropdowns: get all selected options
            updatedField = Array.from(target.selectedOptions).map(
              (opt) => opt.value
            );
          } else {
            // Handle other fields (e.g., text inputs, single-select dropdowns)
            updatedField = target.value; // Use value for other input types
          }

          // Update the specific field in the formData
          const updatedFormData = {
            ...profile.formData,
            Preferences: {
              ...profile.formData.Preferences,
              [fieldName]: updatedField,
            },
          };

          // Return the updated profile with the modified formData
          return {
            ...profile,
            formData: updatedFormData,
          };
        }
        return profile; // Return other profiles unchanged
      })
    );
  };

  const handleSubmitCommunityPreferences = async (communityId) => {
    // Your logic to handle the form submission using communityId

    console.log("community ID for submit", communityId);

    // Find the profile data for the given communityId
    const preferencesToUpdate = formState.find(
      (Preferences) => Preferences.communityId === communityId
    );
    console.log("preferencesToUpdate", preferencesToUpdate);

    if (preferencesToUpdate) {
      const payload = {
        userId: user.userId,
        communityId: communityId,
        Preferences: preferencesToUpdate.formData.Preferences, // Correctly structured for the backend
      };

      console.log("Submitting community profile data:", payload); // Log the payload before sending

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/preferences/communiuser/update`,
          {
            method: "POST",
            body: JSON.stringify(payload),
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const json = await response.json();
        console.log("Response JSON:", json); // Log the response JSON
      } catch (error) {
        console.error("Error submitting community profile:", error);
      }
    } else {
      console.error("Preferences to update not found");
    }
  };

  const renderCommunityPreferencess = () => {
    if (
      !json ||
      !json.schemaPreferences ||
      !json.userPreferences ||
      !json.communityNames
    ) {
      return null;
    }

    return json.communityNames.map((communityName, index) => {
      const schema = json.schemaPreferences[index].Preferences;
      const data = json.userPreferences[index].Preferences;
      const communityId = json.communityId[index];
      const preferencesData = formState[index].formData;
      console.log("index", index);
      console.log("schema iterating", schema);
      console.log("data", data);
      console.log("communitId iter", communityId);
      console.log("preferencesData", preferencesData);

      // Function to generate field elements based on whether they should be disabled
      const generateFieldElements = (disableFields) => {
        return Object.keys(schema).map((key) => {
          const field = schema[key];
          const value = preferencesData.Preferences[key] || ""; // Use formData from formState

          const htmlTitle = field.htmlTitle;
          const htmlSubheader = field.htmlSubheader;
          const htmlSubheaderAlternative = field.htmlSubheaderAlternative;

          if (field.type === "String" && field.enum) {
            // This is a dropdown (enum), not a set of checkboxes
            const optionElements = field.enum.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ));

            return (
              <div key={key}>
                <label htmlFor={key}>{key}</label>
                <select
                  id={key}
                  name={key}
                  value={preferencesData.Preferences[key]}
                  onChange={(event) =>
                    handleFieldChange(communityId, key, event)
                  }
                  disabled={disableFields}
                >
                  {optionElements}
                </select>
              </div>
            );
          } else if (field.type === "String") {
            return (
              <div key={key}>
                <label htmlhtmlFor={key}>{htmlTitle ? htmlTitle : key}</label>
                <p>{htmlSubheader}</p>
                <input
                  type="text"
                  id={key}
                  name={key}
                  value={value}
                  onChange={(event) =>
                    handleFieldChange(communityId, key, event)
                  }
                  readOnly={disableFields}
                />
              </div>
            );
          } else if (
            Array.isArray(field.type) &&
            field.type[0].type === "String" &&
            field.type[0].htmlref === "checkbox"
          ) {
            const optionElements = field.type[0].enum.map((option) => (
              <div key={`${key}-${option}`} className="dynamic-form__field">
                <input
                  type="checkbox"
                  id={`${key}-${option}`}
                  name={key}
                  value={option}
                  checked={preferencesData.Preferences[key].includes(option)}
                  onChange={(event) =>
                    handleFieldChange(communityId, key, event)
                  }
                  disabled={disableFields}
                />
                <label
                  htmlFor={`${key}-${option}`}
                  className="dynamic-form__checkbox-label"
                >
                  {option}
                </label>
              </div>
            ));

            return (
              <div key={key} className="dynamic-form-checkboxes">
                <label className="dynamic-form__checkbox-label">{key}</label>
                {optionElements}
              </div>
            );
          } else if (field.type === "boolean") {
            // Adjusted to check for a boolean type directly

            return (
              <div key={key} className="dynamic-form__field">
                {" "}
                {/* Ensure the class name aligns with your styling conventions */}
                <label htmlFor={key}>{htmlTitle}</label>
                <p>
                  {preferencesData.Preferences[key]
                    ? htmlSubheader
                    : htmlSubheaderAlternative}
                </p>
                <label className="switch">
                  {" "}
                  {/* This class should be styled in your CSS to match the desired switch appearance */}
                  <input
                    type="checkbox"
                    id={key}
                    name={key}
                    checked={preferencesData.Preferences[key]}
                    onChange={(event) =>
                      handleFieldChange(communityId, key, event)
                    } // Consider changing to onChange for semantic correctness
                    disabled={disableFields}
                  />
                  <span className="slider round"></span>{" "}
                  {/* Ensure this class is styled for the slider appearance */}
                </label>
              </div>
            );
          }

          return null;
        });
      };

      // } else if (fieldType === 'boolean') {
      //             console.log('fieldValue',fieldValue)
      //             return (
      //               <div key={fieldName}>
      //                 <label htmlFor={fieldName}>{htmlTitle}</label>
      //                 <p>{fieldValue ? htmlSubheader : "htmlSubheaderAlternative"}</p>
      //                 <label className="switch">
      //                   <input
      //                     type="checkbox"
      //                     id={fieldName}
      //                     name={fieldName}
      //                     checked={fieldValue}
      //                     onClick={() => handleButtonClick(firstNestedObject, fieldName)}
      //                     disabled={DisableFields}
      //                   />
      //                   <span className="slider round"></span>
      //                 </label>
      //               </div>
      //             );
      //   //     <div key={fieldName}>
      //   //     <label htmlFor={fieldName}>{htmlTitle}</label>
      //   //     <p>{htmlSubheader}</p>
      //   //     <button
      //   //       type="button"
      //   //       id={fieldName}
      //   //       name={fieldName}
      //   //       onClick={() => handleButtonClick(communityName, fieldName)}
      //   //       disabled={DisableFields}
      //   //     >
      //   //       {fieldValue ? 'Pause' : 'Unpause'}
      //   //     </button>
      //   //   </div>
      //   // );

      // Generate field elements for both editable and non-editable scenarios

      const EditableFieldElements = generateFieldElements(false);
      const NonEditableFieldElements = generateFieldElements(true);

      return (
        <CommunityPreferencesComponent
          key={communityId}
          communityId={communityId}
          communityPreferencesName={communityName}
          formattedCommunityPreferencesName={communityName}
          NonEditableFieldElements={<div>{NonEditableFieldElements}</div>}
          EditableFieldElements={<div>{EditableFieldElements}</div>}
          handleSubmitCommunityPreferences={handleSubmitCommunityPreferences}
        />
      );
    });
  };

  return <header>{renderCommunityPreferencess()}</header>;
};

export default PreferencesSeperatePopUpEdit;

////////////////////////////////////////////////////////////

// import React, { useState, useEffect } from 'react';
// import { FaEdit } from 'react-icons/fa';
// import "./DynamicProfileSeperatePopUpEdit.css";
// import { useAuthContext } from '../../hooks/useAuthContext';
// import PopupForm from './Functions/PopupForm';

// const CommunityProfileComponent = ({ firstNestedObject, communityName, formattedCommunityName, NonEditableFieldElements, EditableFieldElements, handleSubmitCommunityPreferences, htmlTitle, htmlSubHeader }) => {
//   const [showPopup, setShowPopup] = useState(false);
//   return (
//     <div key={firstNestedObject} className="dynamic-form">
//       <h2>
//         {communityName}
//         <FaEdit onClick={() => setShowPopup(!showPopup)} />
//       </h2>
//       <h2>{htmlSubHeader}</h2>
//       {showPopup ? (
//         <PopupForm className="scrollable-popup" onSubmit={handleSubmitCommunityPreferences()}>
//           <h1> {communityName} </h1>
//           {EditableFieldElements}
//           <button type="update">Submit</button>
//           <button onClick={() => setShowPopup(false)}>Close</button>
//         </PopupForm>
//       ) : (
//         NonEditableFieldElements
//       )}
//     </div>
//   );
// };

// const PreferencesSeparatePopupEdit = () => {
//   const [json, setJson] = useState(null);
//   const [formData, setFormData] = useState({firstName: "", lastName: "", dateOfBirth: ""});
//   const [communityName, setCommunityName] = useState("")
//   const { user }= useAuthContext()

//   useEffect(() => {
//     if (user) {
//       const fetchPreferences = async () => {
//         const response = await fetch('/api/preferences/', {
//           method: 'POST',
//           body: JSON.stringify({ userId: user.userId }),
//           headers: {
//             'Content-Type': 'application/json'
//           }
//         });
//         const json = await response.json();
//         setJson(json);
//         console.log('comname,', communityName)
//         setCommunityName(json.communityName)
//         const userSchema = json.communiUserPreferencesSchema;
//         const userFormData = json.communiUserPreferences ?? {};
//         setFormData({ schema: userSchema, data: userFormData });
//       };

//       fetchPreferences();
//     }
//   }, [user]);

//   const handleInputChange = (event, firstNestedObject, fieldName) => {
//     const value = event.target.value;
//     setFormData(prevState => ({
//       ...prevState,
//       data: {
//         ...prevState.data,
//         [firstNestedObject]: {
//           ...(prevState.data[firstNestedObject] || {}),
//           [fieldName]: value
//         }
//       }
//     }));
//   };

//   const handleCheckboxChange = (event, firstNestedObject, fieldName, option) => {
//     const newFormData = { ...formData };
//     if (!newFormData.data[firstNestedObject]) {
//       newFormData.data[firstNestedObject] = {};
//     }
//     const interests = newFormData.data[firstNestedObject][fieldName] || [];
//     if (event.target.checked) {
//       interests.push(option);
//     } else {
//       const index = interests.indexOf(option);
//       if (index !== -1) {
//         interests.splice(index, 1);
//       }
//     }
//     newFormData.data[firstNestedObject][fieldName] = interests;
//     setFormData(newFormData);
//   };

//   const handleButtonClick = (firstNestedObject, fieldName) => {
//     setFormData(prevState => ({
//       ...prevState,
//       data: {
//         ...prevState.data,
//         [firstNestedObject]: {
//           ...(prevState.data[firstNestedObject] || {}),
//           [fieldName]: !prevState.data[firstNestedObject][fieldName]
//         }
//       }
//     }));
//   };

//   const renderUserPreferencesInterface = () => {
//     console.log('FormData:', formData)

//     const communityProfiles = formData.data;
//     const communityProfilesArray = [];

//     const schema = formData.schema;
//     console.log('schema, renderUserPreferencesInterface:', schema)
//     console.log('userId2:', user.userId)
//     for (const [firstNestedObject, secondNestedObject] of Object.entries(schema)) {
//       const formattedCommunityName = firstNestedObject.replace(/([A-Z])/g, ' $1').trim();
//       const fields = Object.keys(secondNestedObject);

//       const FieldElements = (DisableFields) => {

//         return fields.map((fieldName) => {
//         const fieldType = secondNestedObject[fieldName].type;
//         const fieldEnum = secondNestedObject[fieldName].enum;
//         const fieldValue = communityProfiles[firstNestedObject]?.[fieldName] ?? "";
//         const htmlTitle = secondNestedObject[fieldName].htmlTitle;
//         console.log('htmlTitle:',htmlTitle)
//         const htmlSubheader = secondNestedObject[fieldName].htmlSubheader;
//         if (fieldType === 'String' && fieldEnum) {
//           const optionElements = fieldEnum.map((option) => (
//             <option key={option} value={option}>
//               {option}
//             </option>
//           ));
//           return (
//             <div key={fieldName}>
//               <label htmlFor={fieldName}>{fieldName}</label>
//               <select
//                 id={fieldName}
//                 name={fieldName}
//                 value={fieldValue}
//                 onChange={(event) => handleInputChange(event, firstNestedObject, fieldName)}
//                 disabled={DisableFields}
//               >
//                 {optionElements}
//               </select>
//             </div>
//           );

//         }
//         else if (Array.isArray(fieldType) && fieldType[0].type === 'String' && fieldType[0].htmlref === 'checkbox') {
//           const optionElements = fieldType[0].enum.map((option) => (
//             <div key={option} className="dynamic-form__field">
//               <input
//                 type="checkbox"
//                 id={option}
//                 name={fieldName}
//                 value={option}
//                 checked={fieldValue.includes(option)}
//                 onChange={(event) => handleCheckboxChange(event, firstNestedObject, fieldName, option)}
//                 disabled={DisableFields}
//               />
//               <label htmlFor={option} className="dynamic-form__checkbox-label">{option}</label>
//             </div>
//           ));
//           return (
//             <div key={fieldName} className="dynamic-form-checkboxes">
//               <label className="dynamic-form__checkbox-label">{fieldName}</label>
//               {optionElements}
//             </div>
//           );
//         } else if (fieldType === 'String') {
//           return (
//             <div key={fieldName}>
//               <label htmlFor={fieldName}>{htmlTitle}</label>
//               <p>{htmlSubheader}</p>
//               <input
//                 type="text"
//                 id={fieldName}
//                 name={fieldName}
//                 value={fieldValue}
//                 onChange={(event) => handleInputChange(event, firstNestedObject, fieldName)}
//                 readOnly={DisableFields}
//               />
//             </div>
//           );
//           } else if (fieldType === 'boolean') {
//             return (
//               <div key={fieldName}>
//                 <label htmlFor={fieldName}>{htmlTitle}</label>
//                 <p>{htmlSubheader}</p>
//                 <label className="switch">
//                   <input
//                     type="checkbox"
//                     id={fieldName}
//                     name={fieldName}
//                     onClick={() => handleButtonClick(firstNestedObject, fieldName)}
//                     disabled={DisableFields}
//                   />
//                   <span className="slider round"></span>
//                 </label>
//               </div>
//             );
//         } else {
//           return null;
//         }
//       });

//     }
//       const EditableFieldElements = FieldElements(false)
//       const NonEditableFieldElements = FieldElements(true)

//       const handleSubmitCommunityPreferences = async (event) => {
//         // event.preventDefault();
//         const payload = {
//           userId: user.userId,
//           Preferences: formData.data.Preferences ,
//         };
//         console.log('Submitting community settings data. _id:', payload); // Log the payload before sending

//         const response = await fetch('/api/preferences/communiuser/update', {
//           method: 'POST',
//           body: JSON.stringify(payload),
//           headers: {
//             'Content-Type': 'application/json',
//           },
//         });
//         const json = await response.json();
//         console.log('Response JSON:', json); // Log the response JSON
//       };

//       communityProfilesArray.push(
//         <CommunityProfileComponent
//           key={firstNestedObject}
//           communityName={communityName}
//           formattedCommunityName={formattedCommunityName}
//           NonEditableFieldElements={NonEditableFieldElements}
//           EditableFieldElements={EditableFieldElements}
//           handleSubmitCommunityPreferences={handleSubmitCommunityPreferences}
//           // htmlTitle={htmlTitle}
//           // htmlSubHeader={htmlSubHeader}
//         />
//       );
//     }
//     console.log('communityProfilesArray:', communityProfilesArray)
//     return communityProfilesArray;
//   };

//   return (
//     <header>
//       {json && json.communiUserPreferencesSchema && json.communiUserPreferences && (
//         <div>
//           {renderUserPreferencesInterface()}
//         </div>
//       )}
//     </header>
//   );
// };

// export default PreferencesSeparatePopupEdit;
