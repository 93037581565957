import React, { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import "./CommunityDetails.css";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import { useAuthContext } from "../hooks/useAuthContext";
import Modal from "react-modal";
import { initializeApp, getApps } from "firebase/app";
import { getAuth } from "firebase/auth";
import firebase from "../Firebase";
import Cookies from "js-cookie";

if (!getApps().length) {
  initializeApp(firebase);
}

Modal.setAppElement("#root"); // This line is needed for accessibility reasons

const CommunityDetails = ({ community }) => {
  const navigate = useNavigate();
  const { user, dispatch } = useAuthContext();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [csrfToken, setCsrfToken] = useState(null); // New state variable for CSRF token

  // Fetch the CSRF token when the component mounts
  useEffect(() => {
    const fetchCsrfToken = async () => {
      // Fetch the CSRF token from the "mytoken" cookie
      const csrfToken = Cookies.get("mytoken");
      setCsrfToken(csrfToken);
    };

    fetchCsrfToken();
  }, []);

  const handleJoin = async () => {
    if (user?.userId) {
      // Check if user is already part of this community
      if (user.listOfCommunities.includes(community._id)) {
        setModalIsOpen(true);
        return;
      }

      try {
        const auth = getAuth();
        const token = await auth.currentUser.getIdToken(true);

        const requestBody = {
          communityId: community._id,
          userId: user.userId,
        };

        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "X-CSRF-Token": csrfToken, // Include the CSRF token here
          },
          body: JSON.stringify(requestBody),
        };

        // First POST request to '/api/userProfile/joinCommunity'
        const response1 = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/userProfile/joinCommunity`,
          requestOptions
        );

        // Check if the first request was successful
        if (response1.ok) {
          // Second POST request to '/api/communiUser/joinCommunity'
          const response2 = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/communiUser/joinCommunity`,
            requestOptions
          );

          // Check if the second request was successful
          if (response2.ok) {
            // Both requests were successful, update user data and navigate to '/profileUpdate'
            const data1 = await response1.json();
            dispatch({
              type: "UPDATE_COMMUNITIES",
              payload: data1.profile.listOfCommunities,
            });
            navigate("/joinAdditionalCommunity/" + community._id);
          } else {
            // Handle unsuccessful second POST request
            console.error("Failed to join community on second request");
          }
        } else {
          // Handle unsuccessful first POST request
          console.error("Failed to join community on first request");
        }
      } catch (error) {
        // Handle errors for either request
        console.error("Error joining community:", error);
      }
    } else {
      // If user is not logged in or userId is not available, navigate to the community join page
      navigate(`/groupsearch/join/${community._id}`);
    }
  };
 
  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div className="communityTopLevelDisplayBox" id="boxDisplayCommunityBox">
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Already Joined Modal"
        className="modal"
        overlayClassName="overlay"
      >
        <h2>You are already part of this community!</h2>
        <p>
          Go to messages to see who you've connected with or find another
          community to join :)
        </p>
        <button onClick={closeModal}>Close</button>
      </Modal>
      <div className="communityTopLevelDisplayBox" id="boxDisplayCommunityBox">
        <div className="community-details" id="boxDisplayCommunityDetails">
          <h4 id="boxDisplayCommunityTitle">{community.title}</h4>
          <img
            src={community.pics[0]}
            alt="Community"
            id="boxDisplayCommunityImage"
          />
          <div className="line" id="lineAbove"></div> {/* Line above */}
          <div
            style={{ display: "flex", justifyContent: "space-between" }}
            id="boxDisplayCommunityInfo"
          >
            <h3 id="boxDisplayCommunityLocation">{community.location}</h3>
            <h3 id="boxDisplayCommunityType">{community.type}</h3>
          </div>
          <div className="line" id="lineBelow"></div> {/* Line below */}
          <p id="boxDisplayCommunitySummary">
            <p>{community.topLevelSummary}</p>
          </p>
          <div id="boxDisplayCommunityTagsContainer">
            {community.tags.split(",").map((tag, index) => (
              <span
                key={index}
                className="tag"
                id={`boxDisplayCommunityTag-${index}`}
              >
                {tag.trim()}
              </span>
            ))}
          </div>
          <div className="button">
            {user ? (
              <button onClick={handleJoin}>
                <center>Join</center>
              </button>
            ) : (
              <Link to={"/groupsearch/join/" + community._id}>
                <center>Join</center>
              </Link>
            )}
          </div>
          <div className="buttonSecondary">
            <Link to={"/" + community.databaseRef}>
              <center>More Info</center>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommunityDetails;

// import React, { useContext } from 'react'; // Import useContext if you're using context to manage user state
// import { Link, useNavigate } from 'react-router-dom';
// import axios from 'axios'; // Import axios for making HTTP requests
// import "./CommunityDetails.css";
// import formatDistanceToNow from 'date-fns/formatDistanceToNow';
// import { useAuthContext } from '../hooks/useAuthContext';

// // Assuming you have a UserContext that provides user information
// // import { UserContext } from 'path/to/UserContext';

// const CommunityDetails = ({ community }) => {
//   const navigate = useNavigate();
//   const user = useAuthContext()

//   const handleJoin = async () => {
//     if (user?.user?.userId) {
//       try {
//         // First POST request to '/api/userProfile/joinCommunity'
//         const response1 = await axios.post('/api/userProfile/joinCommunity', {
//           communityId: community._id,
//           userId: user.user.userId,
//         });

//         // Check if the first request was successful
//         if (response1.status === 200) {
//           // Second POST request to '/api/communiUser/joinCommunity'
//           const response2 = await axios.post('/api/communiUser/joinCommunity', {
//             communityId: community._id,
//             userId: user.user.userId,
//           });

//           // Check if the second request was successful
//           if (response2.status === 200) {
//             // Both requests were successful, navigate to '/profileUpdate'
//             navigate('/profileUpdate');
//           } else {
//             // Handle unsuccessful second POST request
//             console.error('Failed to join community on second request');
//           }
//         } else {
//           // Handle unsuccessful first POST request
//           console.error('Failed to join community on first request');
//         }
//       } catch (error) {
//         // Handle errors for either request
//         console.error('Error joining community:', error);
//       }
//     } else {
//       // If user is not logged in or userId is not available, navigate to the community join page
//       navigate(`/groupsearch/join/${community._id}`);
//     }
//   };

//   return (
//     <div className="communityTopLevelDisplayBox" id="boxDisplayCommunityBox">
//       <div className="community-details" id="boxDisplayCommunityDetails">
//         <h4 id="boxDisplayCommunityTitle">{community.title}</h4>
//         <img src={community.pics[0]} alt="Community" id="boxDisplayCommunityImage" />
//         <div className="line" id="lineAbove"></div> {/* Line above */}
//         <div style={{ display: 'flex', justifyContent: 'space-between' }} id="boxDisplayCommunityInfo">
//           <h3 id="boxDisplayCommunityLocation">{community.location}</h3>
//           <h3 id="boxDisplayCommunityType">{community.type}</h3>
//         </div>
//         <div className="line" id="lineBelow"></div> {/* Line below */}
//         <p id="boxDisplayCommunitySummary"><p>{community.topLevelSummary}</p></p>
//         <div id="boxDisplayCommunityTagsContainer">
//           {community.tags.split(',').map((tag, index) => (
//             <span key={index} className="tag" id={`boxDisplayCommunityTag-${index}`}>{tag.trim()}</span>
//           ))}
//         </div>
//         <div className="button">
//           <Link to={'/groupsearch/join/' + community._id}><center>Join</center></Link>
//         </div>
//         <div className="buttonSecondary">
//           <Link to={'/' + community.databaseRef}><center>More Info</center></Link>
//         </div>
//       </div>
//     </div>
//   );
//   };
//   export default CommunityDetails;

// import { Link } from 'react-router-dom'
// import "./CommunityDetails.css";

// // date fns
// import formatDistanceToNow from 'date-fns/formatDistanceToNow'

// const CommunityDetails = ({ community }) => {

//   return (
//     <div className="community-details">
//       <h4>{community.title}</h4>
//       <h3><strong>Type of Community!*!: </strong>{community.type}</h3>
//       <br></br>
//       <h3><strong>Location: </strong>{community.location}</h3>
//       <br></br>
//       <p><strong>{community.description}</strong></p>
//       <br></br>
//       <p><strong>Tags: {community.tags}</strong></p>
//       <br></br>
//       <p>{formatDistanceToNow(new Date(community.createdAt), { addSuffix: true })}</p>
//       <div className="joinbtn">
//         <span><Link to = {'/groupsearch/join/' + community._id}><center>Join</center></Link></span>
//       </div>
//       <div className="moreinfobtn">
//         <span><Link to = {'/groupsearch/group/' + community._id}><center>More Info</center></Link></span>
//       </div>
//     </div>
//   )
// }

// export default CommunityDetails

//<span className="material-symbols-outlined" onClick={handleClick}>delete</span>
//

// ///OLD///

// import { Link } from 'react-router-dom'
// import "./CommunityDetails.css";

// // date fns
// import formatDistanceToNow from 'date-fns/formatDistanceToNow'

// const CommunityDetails = ({ community }) => {

//   return (
//     <div className="community-details">
//       <h4>{community.title}</h4>
//       <h3><strong>Type of Community: </strong>{community.type}</h3>
//       <br></br>
//       <h3><strong>Location: </strong>{community.location}</h3>
//       <br></br>
//       <p><strong>{community.description}</strong></p>
//       <br></br>
//       <p><strong>Tags: {community.tags}</strong></p>
//       <br></br>
//       <p>{formatDistanceToNow(new Date(community.createdAt), { addSuffix: true })}</p>
//       <div className="joinbtn">
//         <span><Link to = {'/groupsearch/join/' + community._id}><center>Join</center></Link></span>
//       </div>
//       <div className="moreinfobtn">
//         <span><Link to = {'/groupsearch/group/' + community._id}><center>More Info</center></Link></span>
//       </div>
//     </div>
//   )
// }

// export default CommunityDetails
// //<span className="material-symbols-outlined" onClick={handleClick}>delete</span>
// //
