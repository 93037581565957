import React, { useEffect, useState, useMemo, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DOMPurify from "dompurify";
import "../index.css";
import "./CommunityHome.css";
import "./Home.css";
import { useAuthContext } from "../hooks/useAuthContext";
import axios from "axios";
import { getAuth } from "firebase/auth";
import Cookies from "js-cookie";

const CommunityHome = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user, dispatch } = useAuthContext();
  const url = new URL(location.pathname, "http://dummy.com");
  const params = url.pathname.split("/").pop();
  const [community, setCommunity] = useState(null);
  const [csrfToken, setCsrfToken] = useState(null);

  console.log('community is:', community);

  useEffect(() => {
    const getCommunityDisplay = async () => {
      console.log("ffs", params);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/communities/fulldisplay/${encodeURIComponent(params)}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const communityData = await response.json();
        if (typeof communityData !== "object" || communityData === null) {
          throw new Error("Invalid JSON data");
        }
        setCommunity(communityData);
      } catch (error) {
        console.error("Failed to fetch community full display:", error);
      }
    };

    getCommunityDisplay();

    // Fetch the CSRF token
    const fetchCsrfToken = async () => {
      const csrfToken = Cookies.get("mytoken");
      setCsrfToken(csrfToken);
    };

    fetchCsrfToken();
  }, [params]);

  const sanitizedCommunity = useMemo(() => {
    return {
      ...community,
      title: DOMPurify.sanitize(community?.title),
      pics: DOMPurify.sanitize(community?.pics),
      topLevelSummary: DOMPurify.sanitize(community?.topLevelSummary),
      type: DOMPurify.sanitize(community?.type),
      location: DOMPurify.sanitize(community?.location),
      mainDescription: DOMPurify.sanitize(community?.mainDescription),
      tags: DOMPurify.sanitize(community?.tags),
    };
  }, [community]);

  const handleJoin = async () => {
    if (user?.userId) {
      if (user.listOfCommunities.includes(community.communityId)) {
        alert("You are already part of this community!");
        return;
      }

      try {
        const auth = getAuth();
        const token = await auth.currentUser.getIdToken(true);

        const requestBody = {
          communityId: community.communityId,
          userId: user.userId,
        };

        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "X-CSRF-Token": csrfToken,
          },
          body: JSON.stringify(requestBody),
        };

        const response1 = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/userProfile/joinCommunity`,
          requestOptions
        );

        if (response1.ok) {
          const response2 = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/communiUser/joinCommunity`,
            requestOptions
          );

          if (response2.ok) {
            const data1 = await response1.json();
            dispatch({
              type: "UPDATE_COMMUNITIES",
              payload: data1.profile.listOfCommunities,
            });
            navigate("/joinAdditionalCommunity/" + community.communityId);
          } else {
            console.error("Failed to join community on second request");
          }
        } else {
          console.error("Failed to join community on first request");
        }
      } catch (error) {
        console.error("Error joining community:", error);
      }
    } else {
      navigate(`/groupsearch/join/${community.communityId}`);
    }
  };

  if (!sanitizedCommunity) {
    return <div>Loading...</div>;
  }

  console.log("community is:", sanitizedCommunity);

  const sanitizeUrl = (url) => {
    const sanitizedUrl = DOMPurify.sanitize(url);
    const a = document.createElement("a");
    a.href = sanitizedUrl;
    return a.href;
  };

  return (
    <div className="home-content-container">
      <div className="MainTitle">
        <h1>
          <strong>{sanitizedCommunity.title}</strong>
        </h1>
        <div id="imageContainer">
          <img src={sanitizeUrl(sanitizedCommunity.pics)} alt="Introduction" />
        </div>
        <div id="afterImgPositioning">
          <h3>{sanitizedCommunity.topLevelSummary}</h3>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <p><strong>Type:</strong> {sanitizedCommunity.type}</p>
            <p><strong>Location:</strong> {sanitizedCommunity.location}</p>
          </div>
          <p dangerouslySetInnerHTML={{ __html: sanitizedCommunity.mainDescription }}></p>
          <div className="button">
            {user ? (
              <button onClick={handleJoin}>
                <center>Join</center>
              </button>
            ) : (
              <Link to={"/groupsearch/join/" + sanitizedCommunity.communityId}>
                <center>Join</center>
              </Link>
            )}
          </div>
          <div id="boxDisplayCommunityTagsContainer">
            {sanitizedCommunity.tags.split(",").map((tag, index) => (
              <span
                key={index}
                className="tag"
                id={`boxDisplayCommunityTag-${index}`}
              >
                {DOMPurify.sanitize(tag.trim())}
              </span>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommunityHome;