import { useState, useEffect, useCallback } from "react";

/**
 * Custom hook to fetch a single user profile.
 *
 * @param {string} communityId - The ID of the community to which the profile belongs.
 * @param {AbortSignal} signal - An AbortSignal to optionally abort the fetch request.
 * @returns {Object} An object containing the fetched profiles and a loading state.
 */
export const useFetchProfile = (userId, communityId, signal) => {
  const [profile, setProfile] = useState(null); // Changed from profiles to profile and default state to null
  const [isLoading, setIsLoading] = useState(false);

  const fetchProfile = useCallback(async () => {
    setIsLoading(true);
    try {
      const requestBody = {
        userId: userId,
        communityId: communityId,
      };

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(requestBody),
        signal: signal,
      };

      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/userProfile/getProfileToDisplay`,
        requestOptions
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setProfile(data);
    } catch (error) {
      if (error.name === "AbortError") {
        console.log("Fetch aborted:", error);
      } else {
        console.error("Error fetching profile:", error);
      }
    } finally {
      setIsLoading(false);
    }
  }, [userId, communityId, signal]);

  useEffect(() => {
    fetchProfile();
    // Cleanup function to abort fetch on component unmount
    return () => {
      if (signal) {
        signal.abort();
      }
    };
  }, [fetchProfile, signal]);

  return { profile, isLoading }; // Changed from profiles to profile
};

/**
 * Custom hook to fetch multiple user profiles.
 *
 * @param {Array<string>} userIds - The IDs of the users to fetch profiles for.
 * @param {string} communityId - The ID of the community to which the profiles belong.
 * @param {AbortSignal} signal - An AbortSignal to optionally abort the fetch request.
 * @returns {Object} An object containing the fetched profiles and a loading state.
 */
export const useFetchProfiles = (userIds, communityId, signal) => {
  const [profiles, setProfiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchProfiles = useCallback(async () => {
    setIsLoading(true);
    try {
      const requestBody = {
        userIds: userIds,
        communityId: communityId,
      };

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(requestBody),
        signal: signal,
      };

      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/userProfile/getProfilesToDisplay`,
        requestOptions
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setProfiles(data);
    } catch (error) {
      if (error.name === "AbortError") {
        console.log("Fetch aborted:", error);
      } else {
        console.error("Error fetching profiles:", error);
      }
    } finally {
      setIsLoading(false);
    }
  }, [userIds, communityId, signal]);

  useEffect(() => {
    fetchProfiles();
    // Cleanup function to abort fetch on component unmount
    return () => {
      if (signal) {
        signal.abort();
      }
    };
  }, [fetchProfiles, signal]);

  return { profiles, isLoading };
};
