import React, { useState, useEffect } from 'react';
import "./Conversation.css";
import { initializeApp, getApps } from 'firebase/app';
import { getAuth, getIdToken } from 'firebase/auth';
import firebase from '../../Firebase';
import DOMPurify from 'dompurify';

if (!getApps().length) {
  initializeApp(firebase);
}

const Conversation = ({conversation, currentUser, newMessage}) => {
  const [sender, setUser] = useState(null);
  const [communityBot, setCommunityBot] = useState(null);

  const isCommunityBotMember = conversation.members.includes(conversation.communityId);

  useEffect(() => {
    const fetchWithToken = async (url, options) => {
      const auth = getAuth();
      const token = await getIdToken(auth.currentUser);
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      };
      return fetch(url, { ...options, headers });
    };

    if (isCommunityBotMember) {
      // Fetch community bot details if a member is the communityId
      const getCommunityBot = async () => {
        try {
          const response = await fetchWithToken(`${process.env.REACT_APP_API_BASE_URL}/communities/${conversation.communityId}`, {
            method: 'GET'
          });
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const json = await response.json();
          console.log('Community Bot Conversation:', json);
          setCommunityBot(json);
        } catch (error) {
          console.error('Failed to fetch community bot:', error);
        }
      }
      getCommunityBot();
    } else {
      // Fetch sender details if no member is the communityId
      const friendId = conversation.members.find((m) => m !== currentUser.userId);
      const getUsers = async () => {
        try {
          const response = await fetchWithToken(`${process.env.REACT_APP_API_BASE_URL}/userProfile/getBasicProfileToDisplay`, {
            method: 'POST',
            body: JSON.stringify({ userId: friendId})
          });
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const json = await response.json();
          console.log('Sender Conversation*:', json);
          setUser(json);
        } catch (error) {
          console.error('Failed to fetch sender:', error);
        }
      }
      getUsers();
    }
    }, [currentUser, conversation]);

  const sanitizeUrl = (url) => {
    const sanitizedUrl = DOMPurify.sanitize(url);
    const a = document.createElement('a');
    a.href = sanitizedUrl;
    return a.href;
  }

  return (
    <div className="conversation">
      <img className="conversationImg" 
        src={sanitizeUrl(isCommunityBotMember ? (communityBot && communityBot.pics ? communityBot.pics[0] :
         "https://upload.wikimedia.org/wikipedia/commons/thumb/a/aa/Saw_Spiral.svg/768px-Saw_Spiral.svg.png") : 
         (sender && sender.pics ? sender.pics[0] : 
         "https://cdn.pixabay.com/photo/2018/11/13/22/01/avatar-3814081_640.png"))} alt=""/>
      <div className="span conversationName">
        {isCommunityBotMember ? (communityBot && communityBot.title) : (sender && sender?.name)}
        {newMessage && 
      <span className="newMessageAlertIcon"></span>}
      </div>
    </div>
  );
}  

export default Conversation;