import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';
import { useLogout } from '../hooks/useLogout';
import { useAuthContext } from '../hooks/useAuthContext';
import './NavBar.css';

const Navbar = () => {
  const { logout } = useLogout();
  const { user } = useAuthContext();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleClick = () => {
    logout();
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    if (!isMenuOpen) {
      document.body.classList.add('body-menu-active');
    } else {
      document.body.classList.remove('body-menu-active');
    }
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
    document.body.classList.remove('body-menu-active');
  };

return (
  <header id="navBarHeader">
      <div className="navTitle">
        <Link to="/" onClick={closeMenu}>
          <h1>Beeble</h1>
        </Link>
        <nav>
          <div className="menu-icon" onClick={toggleMenu}>
            {isMenuOpen ? null : <FaBars />}
          </div>
          <div className={`menu-active ${isMenuOpen ? "show" : "hide"}`}>
            <FaTimes className="close-icon" onClick={closeMenu} />
            {user ? (
              <>
                <div className="navButton" onClick={closeMenu}>
                  <span className="navButtonText"><Link to="/ProfileUpdate">Profile</Link></span>
                </div>
                <div className="navButton" onClick={closeMenu}>
                  <span className="navButtonText"><Link to="/tester1">Messenger</Link></span>
                </div>
                <div className="navButton" onClick={closeMenu}>
                  <span className="navButtonText"><Link to="/settings">Settings</Link></span>
                </div>
                <button className="navButton" onClick={() => { handleClick(); closeMenu(); }}>
                  <Link to="/" className="navButtonText">Log out</Link>
                </button>
              </>
            ) : (
              <div className="navButton" onClick={closeMenu}>
                <span className="navButtonText"><Link to="/login">Login</Link></span>
              </div>
            )}
            <div className="navButton" onClick={closeMenu}>
              <span className="navButtonText"><Link to="/groupsearch">Inter Groups</Link></span>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Navbar;