import "./Form.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth, signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup, sendSignInLinkToEmail } from "firebase/auth";
import firebaseApp from "../Firebase";
import { useLogin } from "../hooks/useLogin"; // Import useLogin hook
import { Link } from "react-router-dom";

const LoginForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState("");
  const navigate = useNavigate();
  const auth = getAuth(firebaseApp);
  const { login, error, isLoading } = useLogin(); // Use useLogin hook

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Sign in the user with Firebase
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const uid = userCredential.user.uid; // Get uid from userCredential

      console.log('uid:', uid);

      // Use login function from useLogin hook and wait for it to complete
      const result = await login(uid);

      // If login was successful, result should be truthy
      if (result) {
        console.log('result truthy?:', result);
        // Navigate to the desired page after successful login
        navigate('/ProfileUpdate');
      }
    } catch (error) {
      console.error('Error logging in:', error);
      if (error.code === 'auth/user-not-found' || error.code === 'auth/wrong-password') {
        setLoginError('Invalid email or password.');
      } else {
        setLoginError('An error occurred during login. Please try again.');
      }
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      // Handle successful Google sign-in
      navigate('/dashboard');
    } catch (error) {
      console.error('Error signing in with Google:', error);
      setLoginError('An error occurred during Google sign-in. Please try again.');
    }
  };

  const handleEmailLinkSignIn = async () => {
    try {
      const actionCodeSettings = {
        url: 'http://localhost:3000/ProfileUpdate', // Replace with your app's URL
        handleCodeInApp: true,
      };
      await sendSignInLinkToEmail(auth, email, actionCodeSettings);
      window.localStorage.setItem('emailForSignIn', email);
      setLoginError('Check your email for the sign-in link.');
    } catch (error) {
      console.error('Error sending sign-in link:', error);
      setLoginError('An error occurred while sending the sign-in link. Please try again.');
    }
  };

  return (
    <form className="form-container login" onSubmit={handleSubmit}>
      <h1 className="form-heading">Login</h1>

      <label className="form-label">Email</label>
      <input className="form-input" type="email" onChange={(e) => setEmail(e.target.value)} value={email} />

      <label className="form-label">Password</label>
      <input className="form-input" type="password" onChange={(e) => setPassword(e.target.value)} value={password} />

      <button className="form-button">Login</button>
      <Link to="/forgot-password">Forgot Password</Link>
      {loginError && <div className="form-error">{loginError}</div>}

      <div className="social-buttons">
        <button className="social-button" onClick={handleGoogleSignIn}>Sign in with Google</button>
        <button className="social-button" onClick={handleEmailLinkSignIn}>Sign in with Email Link</button>
      </div>
    </form>
  );
};

export default LoginForm;