import { useEffect } from "react";
import { useCommunitiesContext } from "../hooks/useCommunitiesContext";

import "./ProxyHome.css";
import Proxy from "../components/Proxy";

const ProxyHome = () => {
  const currentURL2 = window.location.href;

  // Split the URL by '/'
  const segments2 = currentURL2.split("/");

  // Get the last segment (part) of the URL
  const lastSegment2 = segments2.pop();

  const { communities, dispatch } = useCommunitiesContext();

  useEffect(() => {
    const fetchCommunities = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/communities`,
        {
          method: "GET",
        }
      );
      const json = await response.json();

      if (response.ok) {
        dispatch({ type: "SET_COMMUNITIES", payload: json });
      }
    };

    fetchCommunities();
  }, [dispatch]);

  return (
    <div className="igroup3">
      <h1>Proxy Home Page</h1>
      {communities
        .filter((community) => community._id === lastSegment2)
        .map((filteredCommunity) => (
          <Proxy community={filteredCommunity} key={filteredCommunity._id} />
        ))}
    </div>
  );
};

export default ProxyHome;
