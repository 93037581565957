import React, { useState, useEffect } from "react";
import "./ProfileAll.css";
import { useAuthContext } from "../../hooks/useAuthContext";
import { initializeApp, getApps } from "firebase/app";
import { getAuth } from "firebase/auth";
import firebase from "../../Firebase";
import Cookies from "js-cookie";

if (!getApps().length) {
  initializeApp(firebase);
}

const ProfileBasics = ({setProfileInfo}) => {
  console.log("Rendering ProfileBasics");
  const [json, setJson] = useState(null);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    user: { firstName: "", dateOfBirth: null },
  });
  const [shouldSendUpdate, setShouldSendUpdate] = useState(false);
  const [token, setToken] = useState(null);
  const [csrfToken, setCsrfToken] = useState(null);
  const [profileComplete, setIsProfileComplete] = useState(false);
  const { user } = useAuthContext();
  const auth = getAuth();
  console.log("AUTH99,", auth.currentUser);

  let currentController = null;

  useEffect(() => {
    const fetchToken = async () => {
      const token = await firebase.auth().currentUser.getIdToken(true);
      setToken(token);
    };

    fetchToken();
  }, []);


  useEffect(() => {
    // Check if formData is defined and all required fields are filled out
    if (formData.user.firstName) {
      const checkProfileCompletion = () => {
        console.log('formData.user.firstName', formData.user.firstName)
        const isComplete = formData.user.firstName.trim() !== "" && formData.user.dateOfBirth !== null;
        if (typeof setProfileInfo === 'function') {
          setProfileInfo(isComplete);
        }
        setIsProfileComplete(isComplete);
      };
  
      checkProfileCompletion();
    }
  }, [formData]);
  useEffect(() => {
    const fetchCsrfToken = async () => {
      const csrfToken = Cookies.get("mytoken");
      setCsrfToken(csrfToken);
    };

    fetchCsrfToken();
  }, []);

  useEffect(() => {
    if (user) {
      const fetchProfile = async () => {
        setLoading(true);
        if (currentController) {
          currentController.abort();
        }

        currentController = new AbortController();
        const signal = currentController.signal;

        const body = JSON.stringify({ _id: user.userId });
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "X-CSRF-Token": csrfToken,
        };

        console.log("Sending request with body:", body);
        console.log("Headers:", headers);

        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/userProfile/`,
          {
            method: "POST",
            body: body,
            headers: headers,
            signal: signal,
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const json = await response.json();
        setJson(json);
        setLoading(false);
        setFormData({
          user: {
            ...json.user,
            dateOfBirth: json.user.dateOfBirth
              ? new Date(json.user.dateOfBirth)
              : null,
          },
        });
        console.log("json:", json);
      };

      fetchProfile();
    }
  }, [user, token, csrfToken]);

  useEffect(() => {
    const sendPostRequest = async () => {
      if (user && shouldSendUpdate) {
        console.log("Updating UserBasics Profile info:", user.userId);

        const dateOfBirthValue =
          formData.user.dateOfBirth instanceof Date
            ? formData.user.dateOfBirth.toISOString()
            : formData.user.dateOfBirth;

        const requestBody = {
          userId: user.userId,
          UserBasics: {
            ...formData.user,
            dateOfBirth: dateOfBirthValue,
          },
        };

        console.log("Sending POST request with body:", requestBody);

        if (currentController) {
          currentController.abort();
        }

        currentController = new AbortController();
        const signal = currentController.signal;

        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/userProfile/updateProfile`,
          {
            method: "POST",
            body: JSON.stringify(requestBody),
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
              "X-CSRF-Token": csrfToken,
            },
            signal: signal,
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const json = await response.json();
        console.log("Response:", json);
        setShouldSendUpdate(false);
      }
    };

    sendPostRequest();
  }, [shouldSendUpdate, formData, user, token, csrfToken]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      user: {
        ...prevFormData.user,
        [name]: name === "dateOfBirth" ? new Date(value) : value,
      },
    }));
    if (name === "dateOfBirth") {
      setShouldSendUpdate(false); // Prevent immediate update until onBlur validation
    } else {
      setShouldSendUpdate(true);
    }
  };

  const handleDateOfBirthBlur = () => {
    const birthDate = formData.user.dateOfBirth;
    if (birthDate instanceof Date) {
      const today = new Date();
      let age = today.getFullYear() - birthDate.getFullYear();
      const m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      if (age < 18) {
        alert("You must be 18 years or over.");
        return;
      }
      setShouldSendUpdate(true); // Now allow update after validation
    }
  };

  const form = (
    <form className="dynamic-form">
              <p>Profile Status: {profileComplete ? "Complete" : "Incomplete"}</p>

      <div>
        <label htmlFor="firstName">First Name:</label>
        <input
          type="text"
          id="firstName"
          name="firstName"
          value={formData.user.firstName}
          onChange={handleInputChange}
        />
        <label htmlFor="dateOfBirth">Date of Birth:</label>
        <input
          type="date"
          id="dateOfBirth"
          name="dateOfBirth"
          value={
            formData.user.dateOfBirth
              ? formData.user.dateOfBirth.toISOString().slice(0, 10)
              : ""
          }
          onChange={handleInputChange}
          onBlur={handleDateOfBirthBlur}
        />
      </div>
    </form>
  );

  return (
    <div>
      {loading ? <p>Loading...</p> : json && json.user && form}
    </div>
  );
};

export default ProfileBasics;