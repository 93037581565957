import { useState } from "react";
import { useCommunitiesContext } from "../hooks/useCommunitiesContext";

const CommunityForm = () => {
  const { dispatch } = useCommunitiesContext();

  const [title, setTitle] = useState("");
  const [type, setType] = useState("");
  const [location, setLocation] = useState("");
  const [description, setDescription] = useState("");
  const [groupinfo, setGroupinfo] = useState("");
  const [tags, setTags] = useState("");
  const [profileArray, setProfileArray] = useState("");
  const [databaseRef, setDatabaseRef] = useState(""); // New state for Database Ref
  const [error, setError] = useState(null);
  const [emptyFields, setEmptyFields] = useState([]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const crcommunity = {
      title,
      type,
      location,
      description,
      groupinfo,
      tags,
      databaseRef, // Include the databaseRef field in the crcommunity object
      schemaDefinition: JSON.parse(profileArray),
    };

    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/communities`,
      {
        method: "POST",
        body: JSON.stringify(crcommunity),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const json = await response.json();

    if (!response.ok) {
      setError(json.error);
      setEmptyFields(json.emptyFields);
    }
    if (response.ok) {
      setEmptyFields([]);
      setError(null);
      setTitle("");
      setType("");
      setLocation("");
      setDescription("");
      setGroupinfo("");
      setTags("");
      setProfileArray("");
      dispatch({ type: "CREATE_COMMUNITY", payload: json });
    }
  };

  return (
    <form className="create" onSubmit={handleSubmit}>
      <h3>Add a New Community</h3>
      <label>Community Title:</label>
      <input
        type="text"
        onChange={(e) => setTitle(e.target.value)}
        value={title}
        className={emptyFields.includes("title") ? "error" : ""}
      />
      <label>Database Ref: </label> {/* New label for Database Ref */}
      <input
        type="text"
        onChange={(e) => setDatabaseRef(e.target.value)}
        value={databaseRef}
        className={emptyFields.includes("databaseRef") ? "error" : ""}
      />
      <label>Type of Community:</label>
      <input
        type="text"
        onChange={(e) => setType(e.target.value)}
        value={type}
        className={emptyFields.includes("type") ? "error" : ""}
      />
      <label>Location: </label>
      <input
        type="text"
        onChange={(e) => setLocation(e.target.value)}
        value={location}
        className={emptyFields.includes("location") ? "error" : ""}
      />
      <label>Description of Community: </label>
      <input
        type="text"
        onChange={(e) => setDescription(e.target.value)}
        value={description}
        className={emptyFields.includes("description") ? "error" : ""}
      />
      <label>Detailed Group Information: </label>
      <input
        type="text"
        onChange={(e) => setGroupinfo(e.target.value)}
        value={groupinfo}
        className={emptyFields.includes("groupinfo") ? "error" : ""}
      />
      <label>Tags: </label>
      <input
        type="text"
        onChange={(e) => setTags(e.target.value)}
        value={tags}
        className={emptyFields.includes("tags") ? "error" : ""}
      />
      <label>Profile Array: </label>
      <input
        type="text"
        onChange={(e) => setProfileArray(e.target.value)}
        value={profileArray}
        className={emptyFields.includes("profileArray") ? "error" : ""}
      />
      <button>Add Community</button>
      {error && <div className="error">{error}</div>}
    </form>
  );
};

export default CommunityForm;
