import React, { useEffect, useRef, useState } from "react";
import "./NewMessages.css";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useMessengerContext } from "../../hooks/useMessengerContext";
import Message from "./CopyMessage";
import { useLayoutEffect } from "react";

// ...

const Messages = ({ onToChatProfileForCSS }) => {
  const { state, dispatch } = useMessengerContext();
  const { user } = useAuthContext();
  const scrollRef = useRef();

  const {
    messages,
    otherUserDisplay,
    community,
    userDisplayProfile,
    currentConversation,
    lastReadMessageId,
  } = state;

  useEffect(() => {
    const chatBoxMessages = document.querySelector(".chatBoxMessages");
    if (chatBoxMessages) {
      const observer = new MutationObserver(() => {
        const lastMessage = chatBoxMessages.lastChild;
        if (lastMessage) {
          lastMessage.scrollIntoView({ behavior: "smooth" });
        }
      });

      observer.observe(chatBoxMessages, { childList: true });

      return () => observer.disconnect();
    }
  }, []);

  useLayoutEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  }, [messages.length, lastReadMessageId, messages]);

  // useLayoutEffect(() => {
  //   if (scrollRef.current) {
  //     scrollRef.current.scrollIntoViewIfNeeded({ behavior: 'smooth', block: 'end' });
  //     const scrollContainer = document.querySelector('.scrollContainer');
  //     if (scrollContainer) {
  //       scrollContainer.scrollTop += -1000;  // Adjust this value as needed
  //     }
  //   }
  // }, [messages.length]);

  // Function to send a POST request to update the last message read
  // Function to send a POST request to update the last message read
  const updateLastMessageRead = async (messageId) => {
    if (currentConversation.unReadMessages === true) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/messages/lastMessageViewed`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              conversationId: currentConversation._id,
              messageId: messageId,
              userId: user.userId,
            }),
          }
        );
        if (!response.ok) {
          throw new Error("Failed to update last message read");
        }
        const data = await response.json();
        console.log("Update successful:", data);

        // Create a new object for the current conversation with the updated unReadMessages property
        const updatedCurrentConversation = {
          ...currentConversation,
          unReadMessages: false, // Assuming you want to mark messages as read
          lastReadMessageId: data.message._id, // Update lastReadMessageId
        };

        // Dispatch the updated current conversation object using SET_CURRENT_CONVERSATION action type
        dispatch({
          type: "SET_CURRENT_CONVERSATION",
          payload: updatedCurrentConversation,
        });
      } catch (error) {
        console.error("Error updating last message read:", error);
      }
    }
  };

  const handleLastMessageRender = (messageId, isLast) => {
    if (isLast) {
      updateLastMessageRead(messageId);
    }
  };

  console.log("lastReadMessageId:", lastReadMessageId);

  const getOtherUserPic = (message) => {
    console.log("getOtherUserPic message:", message);
    if (message.sender === currentConversation.communityId) {
      return community?.pics;
    } else if (message.suggestions > 0) {
      return community?.pics;
    } else {
      return otherUserDisplay?.pics;
    }
  };

  return (
    <div>
      {messages.map((m, index) => {
        const isLastMessage = index === messages.length - 1;
        if (isLastMessage) {
          handleLastMessageRender(m._id, isLastMessage);
        }

        // Check if the current message is the last read message
        const isLastReadMessage = m._id === lastReadMessageId;
        console.log("isLastReadMessage newmessages:", isLastReadMessage);

        return (
          <div
            // Set the ref to scrollRef if this is the last read message
            ref={isLastReadMessage ? scrollRef : null}
            key={m._id}
            className="messageElement"
            id={isLastReadMessage ? "scrollRefElement" : ""}
          >
            <Message
              message={m}
              own={m.sender === user.userId}
              otherUserPic={getOtherUserPic(m)}
              reciverUserPic={userDisplayProfile?.pics}
              onToChatProfileForCSS={onToChatProfileForCSS}
            />
          </div>
        );
      })}
    </div>
  );
};

export default Messages;

/////////////////////////////

// import React, { useEffect, useRef } from 'react';
// import "./NewMessages.css";
// import { useAuthContext } from '../../hooks/useAuthContext';
// import { useMessengerContext } from '../../hooks/useMessengerContext';
// import Message from './CopyMessage';

// const Messages = ({ onToChatProfileForCSS }) => {
//   const { state, dispatch } = useMessengerContext();
//   const { user } = useAuthContext();
//   const scrollRef = useRef();

//   const { messages, otherUserDisplay, community, userDisplayProfile, currentConversation } = state;

//   // useEffect to log changes to currentConversation
//   useEffect(() => {
//     console.log('currentConversation changed:', currentConversation);
//   }, [currentConversation]);

//   // Function to send a POST request to update the last message read
//   const updateLastMessageRead = async (messageId) => {
//     if (currentConversation.unReadMessages === true) {
//       try {
//         const response = await fetch('http://localhost:4000/api/messages/lastMessageViewed', {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/json',
//           },
//           body: JSON.stringify({
//             conversationId: currentConversation._id,
//             messageId: messageId,
//             userId: user.userId,
//           }),
//         });
//         if (!response.ok) {
//           throw new Error('Failed to update last message read');
//         }
//         const data = await response.json();
//         console.log('Update successful:', data);

//         // Create a new object for the current conversation with the updated unReadMessages property
//         const updatedCurrentConversation = {
//           ...currentConversation,
//           unReadMessages: false, // Assuming you want to mark messages as read
//         };

//         // Dispatch the updated current conversation object using SET_CURRENT_CONVERSATION action type
//         dispatch({ type: 'SET_CURRENT_CONVERSATION', payload: updatedCurrentConversation });

//         console.log('should have dispatched SET_CURRENT_CONVERSATION with updated currentConversation')
//       } catch (error) {
//         console.error('Error updating last message read:', error);
//       }
//     }
//   };

//   const handleLastMessageRender = (messageId, isLast) => {
//     if (isLast) {
//       updateLastMessageRead(messageId);
//     }
//   };

//       //SCROL REF
//       useEffect(() => {
//         if (scrollRef.current) {
//           const chatBoxMessages = scrollRef.current.closest('.chatBoxMessages');
//           if (chatBoxMessages) {
//             const elementTop = scrollRef.current.getBoundingClientRect().top;
//             const containerTop = chatBoxMessages.getBoundingClientRect().top;
//             const scrollPosition = elementTop - containerTop + chatBoxMessages.scrollTop;
//             chatBoxMessages.scrollTo({ top: scrollPosition, behavior: 'smooth' });
//           }
//         }
//       }, [messages]);

//   const getOtherUserPic = (message) => {
//     console.log('getOtherUserPic message:', message);
//     if (message.sender === currentConversation.communityId) {
//       return community?.pics;
//     } else if (message.suggestions > 0) {
//       return community?.pics;
//     } else {
//       return otherUserDisplay?.pics;
//     }
//   };

//   return (
//     <div>
//       {messages.map((m, index) => {
//         const isLastMessage = index === messages.length - 1;
//         if (isLastMessage) {
//           handleLastMessageRender(m._id, isLastMessage);
//         }

//         return (
//           <div
//             ref={isLastMessage ? scrollRef : null}
//             key={m._id}
//             className="messageElement"
//             id={isLastMessage ? "scrollRefElement" : ""}
//           >
//             <Message
//               message={m}
//               own={m.sender === user.userId}
//               otherUserPic={getOtherUserPic(m)}
//               reciverUserPic={userDisplayProfile?.pics}
//               onToChatProfileForCSS={onToChatProfileForCSS}
//             />
//           </div>
//         );
//       })}
//     </div>
//   );
// };

// export default Messages;

// import React, { useState, useEffect, useRef } from 'react';
// import "./NewMessages.css";
// import { format } from 'timeago.js';
// import ProfileSuggestions from './Suggestions';
// import Message from './CopyMessage';
// import { useAuthContext } from '../../hooks/useAuthContext';
// import { useMessengerContext } from '../../hooks/useMessengerContext';

// const Messages = ({ onToChatProfileForCSS }) => {
//   const { state } = useMessengerContext();
//   const { user } = useAuthContext();
//   const scrollRef = useRef();

//   const { messages, otherUserDisplay, community, userDisplayProfile, currentConversation } = state;

//   // Function to determine the correct picture URL
//   const getOtherUserPic = (message) => {
//     if (message.sender === currentConversation.communityId) {
//       return community?.pics;
//     } else if (message.suggestions > 0) {
//       return community?.pics;
//     } else {
//       return otherUserDisplay?.pics;
//     }
//   };

//   // Scroll to the last message when messages update
//   useEffect(() => {
//     if (scrollRef.current) {
//       const chatBoxMessages = scrollRef.current.closest('.chatBoxMessages');
//       if (chatBoxMessages) {
//         const elementTop = scrollRef.current.getBoundingClientRect().top;
//         const containerTop = chatBoxMessages.getBoundingClientRect().top;
//         const scrollPosition = elementTop - containerTop + chatBoxMessages.scrollTop;
//         chatBoxMessages.scrollTo({ top: scrollPosition, behavior: 'smooth' });
//       }
//     }
//   }, [messages]);

//   return (
//     <div>
//       {messages.map((m, index) => (
//         <div
//           ref={index === messages.length - 1 ? scrollRef : null}
//           key={m._id}
//           className="messageElement" // This applies the CSS class to all message elements
//           id={index === messages.length - 1 ? "scrollRefElement" : ""} // Conditionally add the ID to the last message element
//         >
//           <Message
//             message={m}
//             own={m.sender === user.userId}
//             otherUserPic={getOtherUserPic(m)}
//             receiverUserPic={userDisplayProfile?.pics}
//             onToChatProfileForCSS={onToChatProfileForCSS}
//           />
//         </div>
//       ))}
//     </div>
//   );
// };

// export default Messages;

// return (
//   <div className={own ? "message own" : "message"}>
//       <div className="messageTop">
//           <img
//               className="messageImg"
//               src= {own ? "https://static.vecteezy.com/system/resources/previews/034/222/551/non_2x/bearded-black-man-winking-smiling-2d-avatar-illustration-african-american-adult-sparkling-cartoon-character-face-portrait-friendly-flirty-guy-flat-color-user-profile-image-isolated-on-white-vector.jpg" : otherUserPic }
//               alt=""
//           />
// {/* Use dangerouslySetInnerHTML to render the HTML content */}
// <div className="messageText" dangerouslySetInnerHTML={{ __html: message.text }}></div>
//       </div>
//       <div>
//         {message.suggestions.length > 0  ?  <ProfileSuggestions userIds={message.suggestions} communityId={message.communityId} communityPic={otherUserPic} conversationId={message.conversationId}/>  : null /* <label> NO! suggestions </label> */}
//       </div>
//       <div className="messageBottom">{format(message.createdAt)}</div>
//   </div>
// );
// }

// import React from 'react';
// import "./Message.css";
// import {format} from 'timeago.js'

// const Message = ({message, own}) => {
//   return (
//     <div className={own ? "message own" : "message"}>
//         <div className="messageTop">
//             <img
//                 className="messageImg"
//                 src="https://static.vecteezy.com/system/resources/previews/034/222/551/non_2x/bearded-black-man-winking-smiling-2d-avatar-illustration-african-american-adult-sparkling-cartoon-character-face-portrait-friendly-flirty-guy-flat-color-user-profile-image-isolated-on-white-vector.jpg"
//                 alt=""
//             />
//             <p className="messageText">{message.text}</p>
//         </div>
//         <div className="messageBottom">{format(message.createdAt)}</div>
//     </div>
//   );
// }

// export default Message;

//PROFILE SUGGESTIONS:

// import React, { useState } from 'react';
// import "./Message.css";

// // Assuming you have a list of profiles like this
// const profiles = [
//   {
//     id: 1,
//     name: "John Doe",
//     bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
//     imageUrl: "https://example.com/john.jpg"
//   },
//   {
//     id: 2,
//     name: "Jane Doe",
//     bio: "Pellentesque habitant morbi tristique senectus et netus.",
//     imageUrl: "https://example.com/jane.jpg"
//   },
//   {
//     id: 3,
//     name: "Jim Doe",
//     bio: "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
//     imageUrl: "https://example.com/jim.jpg"
//   }
// ];

// const Message = () => {
//   const [selectedProfiles, setSelectedProfiles] = useState([]);

//   const handleCheckboxChange = (profileId) => {
//     setSelectedProfiles(prev => {
//       if (prev.includes(profileId)) {
//         return prev.filter(id => id !== profileId);
//       } else {
//         return [...prev, profileId];
//       }
//     });
//   };

//   const handleSubmit = (event) => {
//     event.preventDefault();
//     console.log("Selected Profiles: ", selectedProfiles);
//     // Here you can do whatever you need with the selected profiles
//   };

//   return (
//     <form onSubmit={handleSubmit}>
//       {profiles.map(profile => (
//         <div key={profile.id} className="profile">
//           <img className="profileImg" src={profile.imageUrl} alt={profile.name} />
//           <p className="profileBio">{profile.bio}</p>
//           <label>
//             <input
//               type="checkbox"
//               checked={selectedProfiles.includes(profile.id)}
//               onChange={() => handleCheckboxChange(profile.id)}
//             />
//             Connect with this person?
//           </label>
//         </div>
//       ))}
//       <button type="submit">Submit</button>
//     </form>
//   );
// }

// export default Message;
