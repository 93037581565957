import { BrowserRouter, Routes, Route, Navigate, } from 'react-router-dom';
import { useAuthContext } from './hooks/useAuthContext';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './/Firebase'
import React, { useEffect, useState } from 'react'; // Import useEffect and useState from 'react'

// pages & components
import GroupSearch from './pages/GroupSearch';
import Navbar from './components/Navbar';
import IndividualGroup from './pages/IndividualGroup';
import IndividualJoin from './pages/IndividualJoin';
import Login from './pages/Login';
import Home from './pages/Home';
import ProxyHome from './pages/ProxyHome';
import Test from './pages/Test';
import Tester1 from './pages/Tester1';
import ProfileUpdate from './pages/ProfileUpdate';
import Settings from './pages/Settings';
import Preferences from './pages/Preferences';
import NewCommunityEntry from './pages/NewCommunityEntry';
import Messenger from './pages/Messenger';
import ForgotPassword from "./pages/ForgotPassword"; // Import ForgotPasswordForm
import Onboarding from './pages/Onboarding';
import CommunityHome from './pages/CommunityHome';
import JoinAdditionalCommunity from './pages/JoinAdditionalCommunity';
import MoreInfo from './pages/MoreInfo';

function App() {
  const { setUser } = useAuthContext();
  const [fireBaseUser, setFireBaseUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true); // Add this line

  // Use the useEffect hook to set up the onAuthStateChanged observer
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      // Check if the user state has changed before updating
      if (currentUser !== fireBaseUser) {
        setFireBaseUser(currentUser);
      }
      setIsLoading(false); // Add this line
    });

    // Clean up the observer on component unmount
    return unsubscribe;
  }, [fireBaseUser, setUser]);

  if (isLoading) { // Add this block
    return <div>Loading...</div>;
  }


  return (
    <div className="App">
      <BrowserRouter>
        <Navbar />
        <div className="pages">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/:communityName" element={<CommunityHome />} />
            <Route path="/moreInfo" element={<MoreInfo />} />
            <Route path="/groupsearch" element={<GroupSearch />} />
            <Route path="/groupsearch/group/:groupID" element={<IndividualGroup />} />
            <Route path="/login" element={<Login />} />
            <Route path="/forgotpassword" element={<ForgotPassword />} /> {/* Add this line */}
            <Route path="/groupsearch/join/:groupID" element={<IndividualJoin />} />
            <Route path="/groupsearch/group/proxy/:groupID" element={<ProxyHome />} />
            <Route path="/test" element={<Test />} />
            <Route
              path="/ProfileUpdate"
              element={
                fireBaseUser ? (
                  <ProfileUpdate />
                ) : (
                  <Navigate to="/login" replace />
                )
              }
            />
            <Route
              path="/Onboarding"
              element={
                fireBaseUser ? (
                  <Onboarding />
                ) : (
                  <Navigate to="/login" replace />
                )
              }
            />
             <Route
              path="/JoinAdditionalCommunity/:communityId"
              element={
                fireBaseUser ? (
                  <JoinAdditionalCommunity />
                ) : (
                  <Navigate to="/login" replace />
                )
              }
            />
            <Route
              path="/tester1"
              element={
                fireBaseUser ? (
                  <Tester1 />
                ) : (
                  <Navigate to="/login" replace />
                )
              }
            />
            <Route
              path="/settings"
              element={
                fireBaseUser ? (
                  <Settings />
                ) : (
                  <Navigate to="/login" replace />
                )
              }
            />
            <Route
              path="/preferences"
              element={
                fireBaseUser ? (
                  <Preferences />
                ) : (
                  <Navigate to="/login" replace />
                )
              }
            />
            <Route path="/newcommunityentry" element={<NewCommunityEntry />} />
            <Route
              path="/Messenger"
              element={
                fireBaseUser ? (
                  <Messenger />
                ) : (
                  <Navigate to="/login" replace />
                )
              }
            />
          </Routes>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;