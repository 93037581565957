import React, { useState, useEffect } from 'react';
import { useAuthContext } from '../../hooks/useAuthContext';
import "./AlertsSettings.css";
import { initializeApp, getApps } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import firebase from '../../Firebase';

if (!getApps().length) {
  initializeApp(firebase);
}

const Alerts = ({ onCheckboxChange }) => {
  const [isChecked, setIsChecked] = useState(false);
  const { user } = useAuthContext();
  const auth = getAuth();

  useEffect(() => {
    auth.currentUser.getIdToken().then(token => {
      fetch(`http://localhost:4000/api/usersettings/get/${user.userId}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
        .then(response => response.json())
        .then(data => setIsChecked(data.interconnectionAlerts))
        .catch(error => console.error('Error:', error));
    });
  }, [user.userId]);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
    if (onCheckboxChange) {
      onCheckboxChange(event.target.checked);
    }
    auth.currentUser.getIdToken().then(token => {
      fetch('http://localhost:4000/api/usersettings/update', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          userId: user.userId,
          interconnectionAlerts: event.target.checked,
        }),
      })
      .then(response => response.json())
      .then(data => console.log(data))
      .catch((error) => {
        console.error('Error:', error);
      });
    });
  };

 // ...rest of your code...


 return (
  <div className="alert-container">
    <h2 id="alert-heading">        
      Confirm you are happy to be contacted by email:
    </h2>

    <div key="contactConsent" className="alert-field">
      <ul className="alert-info" >
        <li>You'll only be contacted about people to connect with and people you've choosen to connected with.</li>
        <li>Your personal email address won't be shared with other members.</li>
        <li>You can turn off at anytime.</li>
      </ul>
      <div id="checkbox-container" style={{ display: 'flex', alignItems: 'center' }}>
        <label htmlFor="contactConsent" className="alert-label">
          I confirm
        </label>
        <input
          type="checkbox"
          id="contactConsent"
          name="contactConsent"
          checked={isChecked}
          onChange={handleCheckboxChange}
          className="alert-input"
        />
      </div>
    </div>
  </div>
);


};

export default Alerts;