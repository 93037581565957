import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { CommunitiesContextProvider } from './context/CommunitiesContext';
import { AuthContextProvider } from './context/AuthContext';
import { MessengerContextProvider } from './context/MessengerContext'; 
import { Auth0Provider } from '@auth0/auth0-react';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Auth0Provider
      domain="dev-hxo2tg57h41wbzvg.us.auth0.com"
      clientId="9kWstp982NJ7t9bH9PgBQcEjXSQiJVqA"
      redirectUri={window.location.origin}
    >
      <AuthContextProvider>
        <MessengerContextProvider> {/* Corrected provider */}
          <CommunitiesContextProvider>
            <App />
          </CommunitiesContextProvider>
        </MessengerContextProvider> {/* Corrected provider */}
      </AuthContextProvider>
    </Auth0Provider>
  </React.StrictMode>
);