import React, { useState, useEffect } from "react";
import "./DynamicProfileSeperatePopUpEdit.css";
import { useAuthContext } from "../../../hooks/useAuthContext";

const CommunityProfileComponent = ({
  communityProfileName,
  formattedCommunityProfileName,
  NonEditableFieldElements,
}) => {
  return (
    <div key={communityProfileName} className="dynamic-form">
      <h3>{formattedCommunityProfileName} Member</h3>
      {NonEditableFieldElements}
    </div>
  );
};

const DynamicProfileSeperatePopUpEditDisplay = () => {
  const [json, setJson] = useState(null);
  const { user } = useAuthContext();

  console.log("communitydynamicjson", json);
  useEffect(() => {
    let currentController = new AbortController();

    const fetchProfile = async () => {
      currentController.abort();
      currentController = new AbortController();
      const signal = currentController.signal;

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/communiUser/getProfile`,
          {
            method: "POST",
            body: JSON.stringify({ _id: user.userId }),
            headers: {
              "Content-Type": "application/json",
            },
            signal: signal,
          }
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const json = await response.json();
        setJson(json);
      } catch (error) {
        if (error.name !== "AbortError") {
          console.error("Fetch error:", error);
        }
      }
    };

    if (user) {
      fetchProfile();
    }

    return () => {
      currentController.abort();
    };
  }, [user]);

  const renderCommunityProfiles = () => {
    if (!json || !json.schemaProfile || !json.userProfile) {
      return null;
    }

    const communityProfiles = json.userProfile;
    const schema = json.schemaProfile;
    const communityProfilesArray = [];

    for (const [communityProfileName, communityProfileSchema] of Object.entries(
      schema
    )) {
      const fields = Object.keys(communityProfileSchema);

      const NonEditableFieldElements = fields.map((fieldName) => {
        const fieldType = communityProfileSchema[fieldName].type;
        const fieldValue = communityProfiles.Profile?.[fieldName] ?? "";

        if (
          Array.isArray(fieldType) &&
          fieldType[0].type === "String" &&
          fieldType[0].htmlref === "checkbox"
        ) {
          // Filter and map over fieldValue to only include checked options
          const checkedOptions = fieldType[0].enum
            .filter((option) => fieldValue.includes(option))
            .map((option) => (
              <div key={option} className="dynamic-form__field">
                <span>{option}</span>
              </div>
            ));

          if (checkedOptions.length > 0) {
            return (
              <div key={fieldName} className="dynamic-form-checkboxes">
                <label className="dynamic-form__checkbox-label">
                  {fieldName}
                </label>
                {checkedOptions}
              </div>
            );
          }
          return null; // Return null if no options are checked
        } else if (fieldType === "String") {
          return (
            <div key={fieldName}>
              <label htmlFor={fieldName}>{fieldName}</label>
              <span id={fieldName}>{fieldValue}</span>
            </div>
          );
        } else {
          return null;
        }
      });

      communityProfilesArray.push(
        <CommunityProfileComponent
          key={communityProfileName}
          communityProfileName={communityProfileName}
          formattedCommunityProfileName={json.communityName}
          NonEditableFieldElements={NonEditableFieldElements}
        />
      );
    }

    return communityProfilesArray;
  };

  return <header>{renderCommunityProfiles()}</header>;
};

export default DynamicProfileSeperatePopUpEditDisplay;
