import React, { useState, useEffect } from 'react';
import "./ChatProfile.css";
import { useMessengerContext } from '../../hooks/useMessengerContext';
import SwipeableViews from 'react-swipeable-views';
import { useAuthContext } from '../../hooks/useAuthContext';
import { useFetchProfile } from '../../hooks/useGetProfile';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';

const ChatProfile = ({ onBackToChatBoxForCSS }) => {
    const { state } = useMessengerContext(); 
    const { otherUserDisplay, community, currentConversation } = state;
    const { user } = useAuthContext();

    const { profile, isLoading } = useFetchProfile(otherUserDisplay?.userId, otherUserDisplay?.communityId);

    const [currentIndex, setCurrentIndex] = useState(0);

    if (currentConversation && !currentConversation.members.includes(currentConversation.communityId)) {
      if (isLoading || !profile || !otherUserDisplay?.userId || !otherUserDisplay?.communityId) {
            return <div>Loading profile...</div>;
        }
    } else {
        if (!community) {
            return <div>Loading profile...</div>;
        }
    }

    if (currentConversation.members.includes(currentConversation.communityId)) {
        return (
            <div className="profile-container">
                <div className="icon-container">
                <button onClick={onBackToChatBoxForCSS} className="icon back-to-chat-menu-btn hide-on-desktop">&#8678;</button>                </div>
                <SwipeableViews enableMouseEvents index={currentIndex} onChangeIndex={setCurrentIndex}>
                    {community?.pics.map((pic, index) => (
                        <div key={index} className="profile-image-container">
                            <img src={pic} alt={`Profile ${index + 1}`} className="profile-image" />
                            <div onClick={() => setCurrentIndex(currentIndex > 0 ? currentIndex - 1 : 0)} className="icon arrow-left">&lt;</div>
                            <div onClick={() => setCurrentIndex(currentIndex < community.pics.length - 1 ? currentIndex + 1 : currentIndex)} className="icon arrow-right">&gt;</div>
                        </div>
                    ))}
                </SwipeableViews>
                <div className="user-info">
                    <h2>{community?.title}</h2>
                    <p id="user-bio">Hi I'm your {community?.title} bot.  I'll help find and introduce you to people in your community. </p>
                </div>
            </div>
        );
    } else {
        return (
            <div className="profile-container">
                <div className="icon-container">
                <button onClick={onBackToChatBoxForCSS} className="icon back-to-chat-menu-btn hide-on-desktop">&#8678;</button>
                
                </div>
                <SwipeableViews enableMouseEvents index={currentIndex} onChangeIndex={setCurrentIndex}>
                {profile.pics.map((pic, index) => (
                    <div key={index} className="profile-image-container">
                        <img src={encodeURI(pic)} alt={`Profile ${index + 1}`} className="profile-image" />
                        <div onClick={() => setCurrentIndex(currentIndex > 0 ? currentIndex - 1 : 0)} className="icon arrow-left">&lt;</div>
                        <div onClick={() => setCurrentIndex(currentIndex < profile.pics.length - 1 ? currentIndex + 1 : currentIndex)} className="icon arrow-right">&gt;</div>
                    </div>
                ))}
                </SwipeableViews>
                <div className="user-info">
                    <h2>{profile.name}, {profile.age}</h2>
                </div>
                <div className="user-profile">
                    <p id="user-bio">{profile.bio}</p>
                </div>
            </div>
        );
    }
};

export default ChatProfile;
// import React, { useState, useEffect } from 'react';
// import "./ChatProfile.css";
// import { useMessengerContext } from '../../hooks/useMessengerContext';
// import SwipeableViews from 'react-swipeable-views';
// import { useAuthContext } from '../../hooks/useAuthContext';
// import { useFetchProfile } from '../../hooks/useGetProfile';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';

// const ChatProfile = ({ onBackToChatBoxForCSS }) => {
//     const { state } = useMessengerContext();
//     const { otherUserDisplay, community } = state;
//     const { user } = useAuthContext();

//     const { profile, isLoading } = useFetchProfile(otherUserDisplay?.userId, otherUserDisplay?.communityId);

//     const [currentIndex, setCurrentIndex] = useState(0);

//     if (isLoading || !profile || !otherUserDisplay?.userId || !otherUserDisplay?.communityId) {
//         return <div>Loading profile...</div>;
//     }

//     return (
//       <div className="profile-container">
//         <div className="icon-container">
//           <button onClick={onBackToChatBoxForCSS} className="icon back-to-chat-menu-btn">&#8678;</button>
//         </div>
//         <SwipeableViews enableMouseEvents index={currentIndex} onChangeIndex={setCurrentIndex}>
//           {profile.pics.map((pic, index) => (
//             <div key={index} className="profile-image-container">
//               <img src={pic} alt={`Profile ${index + 1}`} className="profile-image" />
//               <div onClick={() => setCurrentIndex(currentIndex > 0 ? currentIndex - 1 : 0)} className="icon arrow-left">&lt;</div>
//               <div onClick={() => setCurrentIndex(currentIndex < profile.pics.length - 1 ? currentIndex + 1 : currentIndex)} className="icon arrow-right">&gt;</div>
//             </div>
//           ))}
//         </SwipeableViews>
//         <div className="user-info">
//           <h2>{profile.name}, {profile.age}</h2>
//         </div>
//         <div className="user-profile">
//           <p id="user-bio">{profile.bio}</p>
//         </div>
//       </div>
//     );
// };

// export default ChatProfile;

// import React, { useEffect } from 'react';
// import "./ChatProfile.css";
// import { useMessengerContext } from '../../hooks/useMessengerContext';
// import SwipeableViews from 'react-swipeable-views';
// import { useAuthContext } from '../../hooks/useAuthContext';
// import { useFetchProfile } from '../../hooks/useGetProfile';

// const ChatProfile = ({ onBackToChatBoxForCSS }) => {
//     const { state } = useMessengerContext();
//     const { otherUserDisplay, community } = state;
//     const { user } = useAuthContext();

//     // Always call useFetchProfile at the top level, regardless of whether the IDs are defined
//     const { profile, isLoading } = useFetchProfile(otherUserDisplay?.userId, otherUserDisplay?.communityId);

//     if (isLoading || !profile || !otherUserDisplay?.userId || !otherUserDisplay?.communityId) {
//         return <div>Loading profile...</div>;
//     }

//     return (
//         <div className="chat-profile">
//             {/* Button to go back to chat menu */}
//             <button onClick={onBackToChatBoxForCSS} className="back-to-chat-menu-btn">
//                 Back to Chat Menu
//             </button>
//             {/* Swipeable display for user's pictures */}
//             <SwipeableViews enableMouseEvents>
//                 {profile.pics.map((pic, index) => (
//                     <div key={index} className="profile-pic-container">
//                         <img src={pic} alt={`Profile ${index + 1}`} className="profile-pic" />
//                     </div>
//                 ))}
//             </SwipeableViews>
//             {/* User's name, age, and location */}
//             <div className="user-info">
//                 <h2>{profile.name}, {profile.age}</h2>
//                 <p>{profile.location}</p>
//             </div>
//             {/* User's profile description */}
//             <div className="user-profile">
//                 <p>{profile.bio}</p>
//             </div>
//         </div>
//     );
    
// };

// export default ChatProfile;


// import React, { useEffect } from 'react';
// import "./ChatProfile.css";
// import { useMessengerContext } from '../../hooks/useMessengerContext';
// import SwipeableViews from 'react-swipeable-views';
// import { useAuthContext } from '../../hooks/useAuthContext';
// import { useFetchProfile } from '../../hooks/useGetProfile';

// const ChatProfile = ({ onBackToChatBoxForCSS }) => {
//     const { state } = useMessengerContext();
//     const { otherUserDisplay, community } = state;
//     const { user } = useAuthContext();

//     // Always call useFetchProfile at the top level, regardless of whether the IDs are defined
//     const { profile, isLoading } = useFetchProfile(otherUserDisplay?.userId, otherUserDisplay?.communityId);

//     if (isLoading || !profile || !otherUserDisplay?.userId || !otherUserDisplay?.communityId) {
//         return <div>Loading profile...</div>;
//     }

//     return (
//         <div className="chat-profile">
//             {/* Button to go back to chat menu */}
//             <button onClick={onBackToChatBoxForCSS} className="back-to-chat-menu-btn">
//                 Back to Chat Menu
//             </button>
//             {/* Swipeable display for user's pictures */}
//             <SwipeableViews enableMouseEvents>
//                 {profile.pics.map((pic, index) => (
//                     <div key={index} className="profile-pic-container">
//                         <img src={pic} alt={`Profile ${index + 1}`} className="profile-pic" />
//                     </div>
//                 ))}
//             </SwipeableViews>
//             {/* User's name, age, and location */}
//             <div className="user-info">
//                 <h2>{profile.name}, {profile.age}</h2>
//                 <p>{profile.location}</p>
//             </div>
//             {/* User's profile description */}
//             <div className="user-profile">
//                 <p>{profile.bio}</p>
//             </div>
//         </div>
//     );
    
// };

// export default ChatProfile;

//FIRST PASS WORKS NICELY
// import React, { useEffect } from 'react';
// import "./ChatProfile.css";
// import { useMessengerContext } from '../../hooks/useMessengerContext';
// import ChatBoxHeader from './ChatBoxHeader';
// import MessengerInput from './MessengerInput';
// import SwipeableViews from 'react-swipeable-views';
// import { useAuthContext } from '../../hooks/useAuthContext';
// import { useFetchProfile } from '../../hooks/useGetProfile';

// const ChatProfile = ({ currentConversation, onBackToChatMenuForCSS }) => {
//     const { state, dispatch } = useMessengerContext();
//     const {  otherUserDisplay, community } = state;
//     const { user } = useAuthContext();

//   // Use the hook with the user ID and community ID
//   const { profile, isLoading } = useFetchProfile(otherUserDisplay.userId, otherUserDisplay.communityId); // userid, communityId

//   // Use the hook with the user ID and community ID
// //   const { profile, isLoading } = useFetchProfile('660d63c5b2933d23f0eeac4c', '65d869abb2028ba1580121bc'); // userid, communityId


//   useEffect(() => {
//     // Only log the profile if both community and user objects are not null
//     if (user && profile) {
//       console.log('profile profile:', profile);
//       console.log('community', community)
//       console.log('otherUserDisplay', otherUserDisplay)
//     }
//   }, [user, profile, community, otherUserDisplay]);

//   // Check if profile is loading or undefined to prevent rendering errors
//   if (isLoading || !profile) {
//     return <div>Loading profile...</div>;
//   }

//   return (
//     <div className="chat-profile">
//       {/* Swipeable display for user's pictures */}
//       <SwipeableViews enableMouseEvents>
//         {profile.pics.map((pic, index) => (
//           <div key={index} className="profile-pic-container">
//             <img src={pic} alt={`Profile ${index + 1}`} className="profile-pic" />
//           </div>
//         ))}
//       </SwipeableViews>
//       {/* User's name, age, and location */}
//       <div className="user-info">
//         <h2>{profile.name}, {profile.age}</h2>
//         <p>{profile.location}</p>
//       </div>
//       {/* User's profile description */}
//       <div className="user-profile">
//         <p>{profile.bio}</p>
//       </div>
//     </div>
//   );
// };

// export default ChatProfile;
