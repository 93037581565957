import { CommunitiesContext } from "../context/CommunitiesContext"
import { useContext } from "react"

export const useCommunitiesContext = () => {
  const context = useContext(CommunitiesContext)

  if(!context) {
    throw Error('useCommunitiesContext must be used inside an CommunitiesContextProvider')
  }

  return context
}