import React, { useState, useEffect } from "react";
import "./Suggestions.css";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useMessengerContext } from "../../hooks/useMessengerContext"; // Import the useSuggestionsContext hook
import { initializeApp, getApps } from "firebase/app";
import { getAuth } from "firebase/auth";
import firebase from "../../Firebase";
import Cookies from "js-cookie";

if (!getApps().length) {
  initializeApp(firebase);
} 

const ProfileSuggestions = ({ userIds, communityId, conversationId }) => {
  const [profiles, setProfiles] = useState([]);
  const [statedInterests, setStatedInterests] = useState([]);
  const { user } = useAuthContext();
  const [csrfToken, setCsrfToken] = useState(null); // New state variable for CSRF token

  const { state } = useMessengerContext(); // Use the useSuggestionsContext hook to get state

  const {
    userDisplayProfile
  } = state; // Destructure the needed state variables



  // Fetch the CSRF token when the component mounts
  useEffect(() => {
    const fetchCsrfToken = async () => {
      // Fetch the CSRF token from the "mytoken" cookie
      const csrfToken = Cookies.get("mytoken");
      setCsrfToken(csrfToken);
    };

    fetchCsrfToken();
  }, []);

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    const fetchProfiles = async () => {
      try {
        const auth = getAuth();
        const token = await auth.currentUser.getIdToken(true);

        const requestBody = {
          userIds: userIds,
          communityId: communityId,
        };

        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "X-CSRF-Token": csrfToken, // Include the CSRF token here
          },
          body: JSON.stringify(requestBody),
          signal: signal,
        };

        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/userProfile/getProfilesToDisplay`,
          requestOptions
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setProfiles(data);
      } catch (error) {
        if (error.name === "AbortError") {
          console.log("Fetch aborted:", error);
        } else {
          console.error("Error fetching profiles:", error);
        }
      }
    };

    if (firebase.auth().currentUser) {
      fetchProfiles();
    }
  }, [userIds, communityId, user.userI, csrfToken]); // Include csrfToken in the dependency array


  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
  
    const checkInterests = async () => {
      try {
        const auth = getAuth();
        const token = await auth.currentUser.getIdToken(true);
  
        const requestBody = {
          userId: user.userId, // Assuming user.userI is the userId
          userIds: userIds,
        };
  
        console.log("requestBody checkInterests", requestBody);
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "X-CSRF-Token": csrfToken,
          },
          body: JSON.stringify(requestBody),
          signal: signal,
        };
  
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/interRecord/checkInterests`,
          requestOptions
        );
  
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        console.log("Success checkInterests:", data);
  
        // Update the statedInterests state to reflect the change immediately
        setStatedInterests((prev) => [...prev, ...data.userInterestedIn]);      } catch (error) {
        if (error.name === "AbortError") {
          console.log("Fetch aborted:", error);
        } else {
          console.error("Error fetching interests:", error);
        }
      }
    };
  
    if (firebase.auth().currentUser) {
      checkInterests();
    }
  
    return () => {
      abortController.abort();
    };
  }, [userIds, user.userId, csrfToken]); // Include csrfToken in the dependency array


  const handleIntroduceClick = async (consideredUser) => {
    try {
      const auth = getAuth();
      const token = await auth.currentUser.getIdToken(true);


      //"66632d56b18725f559679fef"
      const requestBody = { 
        choosingUser: userDisplayProfile,
        consideredUser: consideredUser,
        verdict: true,
        communityId: communityId,
      };

      console.log("user suggestion click", user);
      console.log("consideredUser", consideredUser);

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "X-CSRF-Token": csrfToken, // Include the CSRF token here
        },
        body: JSON.stringify(requestBody),
      };

      const requestBody2 = {
        choosingUser: user.userId,
        interestedInUserName: consideredUser.name,
        communityId: communityId,
        conversationId: conversationId,
      };

      const requestOptions2 = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "X-CSRF-Token": csrfToken, // Include the CSRF token here
        },
        body: JSON.stringify(requestBody2),
      };

      // First API call to /api/interconnecting/update
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/interRecord/update`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      console.log("Success:", data);

      // Optionally update the statedInterests state to reflect the change immediately
      setStatedInterests((prev) => [...prev, consideredUser.userId]);

      // If the first API call is successful, proceed with the second API call
      if (data) {
        console.log("2nd API Commencing");
        // Assuming the endpoint for the second API call is /api/interconnecting/confirm (Please replace with the actual endpoint)
        const confirmResponse = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/interMessaging/interestAcknowledgeMessage`,
          requestOptions2
        );

        if (!confirmResponse.ok) {
          throw new Error(`HTTP error! status: ${confirmResponse.status}`);
        }
        const confirmData = await confirmResponse.json();
        console.log("Confirmation Success:", confirmData);
      }
    } catch (error) {
      console.error("Error during the introduction process:", error);
    }
  };
  return (
    <div>
      {/* <h1>Suggestions</h1>
      <h3>Are you interested in connecting with:</h3> */}
      {profiles.length > 0 ? (
        profiles.map((profile) => (
          <div
            key={profile.id}
            className={`profile ${
              statedInterests.includes(profile.userId) ? "interconnecting" : ""
            }`}
          >
            <div className="profileContent">
              <img
                className="profileImg"
                src={
                  profile.pics[0]
                    ? encodeURI(profile.pics[0])
                    : "https://cdn.pixabay.com/photo/2023/09/22/12/18/profile-8268938_640.png"
                }
                alt={profile.name}
              />
              <div>
                <span className="profileName">{profile.name}</span>
                <span className="profileAge"> {profile.age}</span>
                <p className="profileLocation">{profile.location}</p>
              </div>
            </div>
            <p className="profileBio">{profile.bio}</p>
            <button
              type="button"
              className="introduceButton"
              onClick={() => handleIntroduceClick(profile)}
              disabled={statedInterests.includes(profile.userId)}
            >
              {statedInterests.includes(profile.userId)
                ? "Interconnecting!"
                : "Yes! - interested in connecting"}
            </button>
          </div>
        ))
      ) : (
        <p>Loading profiles...</p>
      )}
    </div>
  );
};

export default ProfileSuggestions;
