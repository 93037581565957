
import { Link } from 'react-router-dom';
import '../index.css';
import "./Home.css";

const Home = () => {
    return (
        <div className="home-content-container"> 
          <div className="MainTitle">
            <h1><strong>Find people you click with</strong></h1>
            {/* Container for the image */}
            <div id="imageContainer">
              <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTjHQ1zmRha3sLrGW9Pj5UhSiJ1vXLg5AXTejlykaHD8vDtPPZmQVCwoKczvFAj1YADTwg&usqp=CAU" alt="Introduction" />
            </div>
            <div id="afterImgPositioning">
              <h3>Join a group. Say a bit about yourself. Get matched and introduced with its members.</h3>
              <div className="subtleLink">
                <span><Link to="/moreinfo">More Info</Link></span>
              </div>
              <div className="button">
                <span><Link to="/groupsearch">Get Started</Link></span>
              </div>
              <div className="discreteLink">
                <span><Link to="/login">Login</Link></span>
              </div>
            </div>
          </div>
        </div>
      )
}
export default Home;// import { Link } from 'react-router-dom';


// import "./Home.css";

// const Home = () => {
//   return (
//     <div className="home2 container py-5"> {/* Use Bootstrap's container class for alignment and padding */}
//         <div className="MainTitle text-center"> {/* Center the text */}
//             <h1 className="display-4 font-weight-bold">Get introduced to your people</h1>
//             {/* Adding the image just after the h1 tag with Bootstrap's img-fluid class for responsive images */}
//             <img src="https://i.pinimg.com/736x/01/09/b6/0109b6ae0ad33c069bce0677047d3f7a.jpg" alt="Introduction" className="img-fluid my-4" />
//             <p className="lead">Join a group. Say a bit about yourself. Get Matched and introduced with its members.</p>
//         </div>
//         <div className="text-center mt-4"> {/* Center the links and add margin */}
//             <Link to="/groupsearch" className="btn btn-primary mr-2">More Info</Link> {/* Use Bootstrap's button classes */}
//             <Link to="/groupsearch" className="btn btn-success">Get Started</Link>
//         </div>
//         <div className="text-center mt-4">
//             <Link to="/login" className="link-secondary">Login</Link> {/* Use Bootstrap's link color classes */}
//         </div>
//     </div>
//   )
// }

// export default Home;

