import { createContext, useReducer, useEffect } from 'react';

export const AuthContext = createContext();

export const authReducer = (state, action) => {
  switch (action.type) {
    case 'LOGIN':
      return { user: action.payload };
    case 'LOGOUT':
      return { user: null };
    case 'UPDATE_COMMUNITIES':
      const newState = { user: { ...state.user, listOfCommunities: action.payload } };
      return newState;
    default:
      return state;
  }
};

export const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, {
    user: null,
  });

  const setUser = (user) => {
    dispatch({ type: 'LOGIN', payload: user });
  };

  const updateCommunities = (communities) => {
    dispatch({ type: 'UPDATE_COMMUNITIES', payload: communities });

    // Update local storage
    const currentUser = JSON.parse(localStorage.getItem('user'));
    if (currentUser) {
      currentUser.listOfCommunities = communities;
      localStorage.setItem('user', JSON.stringify(currentUser));
    }
  };

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));

    if (user) {
      setUser(user);
    }
  }, []);

  console.log('AuthContext state:', state);

  return (
    <AuthContext.Provider value={{ ...state, dispatch, setUser, updateCommunities }}>
      {children}
    </AuthContext.Provider>
  );
};