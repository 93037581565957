import React, { useState, useEffect } from 'react';
import "./Settings.css";
import CommuniSettings from "../components/Profile&Settings/CommuniSettings"; // Assuming both files are in the same directory
import { Link } from 'react-router-dom'
import { useAuthContext } from '../hooks/useAuthContext';

const Settings = () => {
  const [communityNames, setCommunityNames] = useState([]); // Array to hold community names

  const { user } = useAuthContext();

  const handleCommunityName = (communityName, index) => {
    // Update the community name at the specific index
    setCommunityNames(prevNames => {
      const newNames = [...prevNames];
      newNames[index] = communityName;
      return newNames;
    });
  };

  return (
    <>
      <header>
        <h1>Settings</h1>
      </header>
      <div className="getstartedbtn">
        {/* <span><Link to="/profileupdate">Profile</Link></span> */}
      </div>
      <div className="getstartedbtn">
        {/* <span><Link to="/preferences">Preferences</Link></span> */}
      </div>
      {Array.isArray(user.listOfCommunities) && user.listOfCommunities.map((communityId, index) => (
        <CommuniSettings 
          key={index}
          user={user}
          communityId={communityId.toString()}
          onCommunityName={(name) => handleCommunityName(name, index)}
        />
      ))}
    </>
  );
}
export default Settings;