import React from 'react';
import DOMPurify from 'dompurify';
import "./CopyMessage.css";
import {format} from 'timeago.js'
import ProfileSuggestions from './Suggestions';


const Message = ({message, own, otherUserPic, reciverUserPic, onToChatProfileForCSS}) => {

  console.log('message',message)
  
  const sanitizedHTML = DOMPurify.sanitize(message.text);

  return (
    <div className={`message ${own ? "own" : ""}`}>
      <div className="messageTop">
        <img 
          className="messageImg"
          src={own ? (reciverUserPic?.[0] || 'https://i.pinimg.com/736x/dd/8b/a9/dd8ba98ba0b06489ac96f76b74fe7fc6.jpg') : (otherUserPic?.[0] || 'https://i.pinimg.com/736x/dd/8b/a9/dd8ba98ba0b06489ac96f76b74fe7fc6.jpg')} 
          alt=""
          onClick={onToChatProfileForCSS}
        />
        <p className="messageText" dangerouslySetInnerHTML={{ __html: sanitizedHTML }}></p>      </div>
      <div> 
        {message?.suggestions?.length > 0 && <ProfileSuggestions userIds={message.suggestions} communityId={message.communityId} communityPic={otherUserPic} conversationId={message.conversationId}/>}
      </div>
      <div> 
        {message.leaveConvoAfter && <div className="leftConversation"> {message.communityName} left the conversation </div>}
      </div>
      <div className="messageBottom">{format(message.createdAt)}</div>
    </div>
  );
  
}

export default Message;    





// return (
//   <div className={own ? "message own" : "message"}>
//       <div className="messageTop">
//           <img 
//               className="messageImg"
//               src= {own ? "https://static.vecteezy.com/system/resources/previews/034/222/551/non_2x/bearded-black-man-winking-smiling-2d-avatar-illustration-african-american-adult-sparkling-cartoon-character-face-portrait-friendly-flirty-guy-flat-color-user-profile-image-isolated-on-white-vector.jpg" : otherUserPic } 
//               alt=""
//           />
// {/* Use dangerouslySetInnerHTML to render the HTML content */}
// <div className="messageText" dangerouslySetInnerHTML={{ __html: message.text }}></div>
//       </div>
//       <div> 
//         {message.suggestions.length > 0  ?  <ProfileSuggestions userIds={message.suggestions} communityId={message.communityId} communityPic={otherUserPic} conversationId={message.conversationId}/>  : null /* <label> NO! suggestions </label> */}
//       </div>
//       <div className="messageBottom">{format(message.createdAt)}</div>
//   </div>
// ); 
// }




// import React from 'react';
// import "./Message.css";
// import {format} from 'timeago.js'

// const Message = ({message, own}) => {
//   return (
//     <div className={own ? "message own" : "message"}>
//         <div className="messageTop">
//             <img 
//                 className="messageImg"
//                 src="https://static.vecteezy.com/system/resources/previews/034/222/551/non_2x/bearded-black-man-winking-smiling-2d-avatar-illustration-african-american-adult-sparkling-cartoon-character-face-portrait-friendly-flirty-guy-flat-color-user-profile-image-isolated-on-white-vector.jpg" 
//                 alt=""
//             />
//             <p className="messageText">{message.text}</p>
//         </div>
//         <div className="messageBottom">{format(message.createdAt)}</div>
//     </div>
//   ); 
// }

// export default Message;    


//PROFILE SUGGESTIONS: 

// import React, { useState } from 'react';
// import "./Message.css";

// // Assuming you have a list of profiles like this
// const profiles = [
//   {
//     id: 1,
//     name: "John Doe",
//     bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
//     imageUrl: "https://example.com/john.jpg"
//   },
//   {
//     id: 2,
//     name: "Jane Doe",
//     bio: "Pellentesque habitant morbi tristique senectus et netus.",
//     imageUrl: "https://example.com/jane.jpg"
//   },
//   {
//     id: 3,
//     name: "Jim Doe",
//     bio: "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
//     imageUrl: "https://example.com/jim.jpg"
//   }
// ];

// const Message = () => {
//   const [selectedProfiles, setSelectedProfiles] = useState([]);

//   const handleCheckboxChange = (profileId) => {
//     setSelectedProfiles(prev => {
//       if (prev.includes(profileId)) {
//         return prev.filter(id => id !== profileId);
//       } else {
//         return [...prev, profileId];
//       }
//     });
//   };

//   const handleSubmit = (event) => {
//     event.preventDefault();
//     console.log("Selected Profiles: ", selectedProfiles);
//     // Here you can do whatever you need with the selected profiles
//   };

//   return (
//     <form onSubmit={handleSubmit}>
//       {profiles.map(profile => (
//         <div key={profile.id} className="profile">
//           <img className="profileImg" src={profile.imageUrl} alt={profile.name} />
//           <p className="profileBio">{profile.bio}</p>
//           <label>
//             <input  
//               type="checkbox"
//               checked={selectedProfiles.includes(profile.id)}
//               onChange={() => handleCheckboxChange(profile.id)}
//             />
//             Connect with this person?
//           </label>
//         </div>
//       ))}
//       <button type="submit">Submit</button>
//     </form>
//   );
// }

// export default Message;