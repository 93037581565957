import { useEffect, useState } from "react";
import { FaSearch } from "react-icons/fa";
import Autosuggest from 'react-autosuggest';
import { useAuthContext } from "../hooks/useAuthContext";
import "./SearchBar.css";
import Fuse from 'fuse.js';
import debounce from 'lodash.debounce'; // Assuming lodash.debounce is installed

export const SearchBar = ({ setResultsInGroupSearchPage }) => {
  const [input, setInput] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [results, setResults] = useState([]);
  const [userCommunitiesIds, setUserCommunitiesIds] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(true); // State to control the visibility of suggestions

  const user = useAuthContext();

  useEffect(() => {
    if (!user || !user.user || !user.user.listOfCommunities) return;
    setUserCommunitiesIds(user.user.listOfCommunities);
  }, [user]); 

  const fetchData = async (value) => {
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/communities`, {method: 'GET'});
    const json = await response.json();
    
    const fuse = new Fuse(json, {
      keys: ['title', 'tags'],
      includeScore: true,
      threshold: 0.4,
      ignoreLocation: true,
    });

    const results = fuse.search(value).map(result => ({
      ...result.item,
      score: result.score
    }));

    setResults(results);
    setSuggestions(results);
  };


  const debouncedFetchData = debounce(fetchData, 300);

  useEffect(() => {
    if (input) {
      debouncedFetchData(input);
      setShowSuggestions(true); // Show suggestions when there is input
    }
  }, [input, userCommunitiesIds]);

  const handleSearch = async () => {
    await fetchData(input);
    setResultsInGroupSearchPage(results);
    setInput(""); // Clear the input field after search
    setShowSuggestions(false); // Hide suggestions after search
  };

  const handleChange = (event, { newValue }) => {
    setInput(newValue);
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    debouncedFetchData(value);
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
    setShowSuggestions(false); // Hide suggestions when cleared
  };

  const getSuggestionValue = (suggestion) => suggestion.title;

  const renderSuggestion = (suggestion) => <div>{suggestion.title}</div>;

  const inputProps = {
    placeholder: "Type to search...",
    value: input,
    onChange: handleChange,
    onKeyDown: (event) => {
      if (event.key === 'Enter') {
        handleSearch();
      }
    }
  };

  return (
    <div className="search-bar-wrapper">
      <div className="input-wrapper">
        <Autosuggest
          suggestions={showSuggestions ? suggestions : []} // Control the visibility of suggestions
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps}
          onSuggestionSelected={(event, { suggestion }) => {
            setInput(suggestion.title);
            const filteredResults = results.filter(result => result.title === suggestion.title);
            setResultsInGroupSearchPage(filteredResults.length > 0 ? filteredResults : [suggestion]);
            setShowSuggestions(false); // Hide suggestions after selection
          }}
        />
        <FaSearch id="search-icon" onClick={handleSearch} />
      </div>
    </div>
  );
};