import React from "react";
import { Link } from "react-router-dom";
import DOMPurify from "dompurify";
import "../index.css";
import "./MoreInfo.css";

const MoreInfo = () => {
  // Hardcoded community information
  const community = {
    title: "Welcome to Beeble!",
    pics: "https://img.freepik.com/premium-vector/set-speech-bubble-clouds-online-chat-with-different-words-colored-shapes_602734-294.jpg?w=360",
    topLevelSummary: "Connecting people within community groups.",
    type: "Social",
    location: "Global",
    mainDescription: `
      Beeble matches and introduces people together within different community groups. 
      Members join or create their own groups. Beeble then introduces them together, based on compatibility.
    `,
    howItWorks: `
      **How it works:**
      1. Join a group and sign up to Beeble.
      2. Provide some info about yourself particular to that group.
      3. Receive messages from the group's 'community bot' suggesting members you might want to connect with.
      4. Let the bot know if you want to connect with these members.
      5. If there's mutual interest, the bot will put you both in contact.
      6. In the future, the bot will help you schedule times and places to meet online or in person.
    `,
    additionalInfo: `
      We are a new startup that aims to connect people together in community groups. 
      People can create their own community groups or join ones in existence. 
      We use AI to help match people, along with some human intervention at times! 
      Groups can be for anyone and for any region or type - for example, hobby groups, professional networks, or local community support.
    `,
  };

  // Sanitize community data
  const sanitizedCommunity = {
    ...community,
    title: DOMPurify.sanitize(community.title),
    pics: DOMPurify.sanitize(community.pics),
    topLevelSummary: DOMPurify.sanitize(community.topLevelSummary),
    type: DOMPurify.sanitize(community.type),
    location: DOMPurify.sanitize(community.location),
    mainDescription: DOMPurify.sanitize(community.mainDescription),
    howItWorks: DOMPurify.sanitize(community.howItWorks),
    additionalInfo: DOMPurify.sanitize(community.additionalInfo),
  };

  // Function to sanitize URLs
  const sanitizeUrl = (url) => {
    const sanitizedUrl = DOMPurify.sanitize(url);
    const a = document.createElement("a");
    a.href = sanitizedUrl;
    return a.href;
  };

  return (
    <div className="more-info-content-container">
      <div className="more-info-main-title">
        <h1>
          <strong>{sanitizedCommunity.title}</strong>
        </h1>
        <div className="more-info-after-img-positioning">
          <h3>{sanitizedCommunity.topLevelSummary}</h3>
          <div className="more-info-how-it-works">
            <h4>How it works:</h4>

            <ol>
              <li><span className="number">1.</span> Join a group and sign up to Beeble.</li>
              <li><span className="number">2.</span> Provide some info about yourself particular to that group.</li>
              <li><span className="number">3.</span> Receive messages from the group's 'community bot' suggesting members you might want to connect with.</li>
              <li><span className="number">4.</span> Let the bot know if you want to connect with these members.</li>
              <li><span className="number">5.</span> If there's mutual interest, the bot will put you both in contact.</li>
              <li><span className="number">6.</span> In the future, the bot will help you schedule times and places to meet online or in person.</li>
            </ol>
          </div>
        <div className="more-info-image-container">
          <img src={sanitizeUrl(sanitizedCommunity.pics)} alt="Community" />
        </div>


    

          <div className="button">
            <Link to="/groupsearch">
              <center>Get Started</center>
            </Link>
          </div>
          <div className="more-info-additional-info">
          <p>{sanitizedCommunity.mainDescription}</p>

            <p>{sanitizedCommunity.additionalInfo}</p>

          </div>
        </div>
      </div>
    </div>
  );
};

export default MoreInfo;
