import React, { useState, useEffect } from 'react';
import { useAuthContext } from '../../hooks/useAuthContext';
import "./CopyConversation.css";
import { initializeApp, getApps } from 'firebase/app';
import { getAuth, getIdToken } from 'firebase/auth';
import firebase from '../../Firebase';
import DOMPurify from 'dompurify';


if (!getApps().length) {
  initializeApp(firebase);
}

const Conversation = ({conversation, newMessage}) => {
  const [userSender, setUser] = useState(null);
  const [communitySender, setcommunity] = useState(null);
  
  const { user } = useAuthContext();
  const auth = getAuth();

  // Declare isCommunityBotMember outside of useEffect so it's accessible in the JSX
  const communityAsOtherMessenger = conversation.members.includes(conversation.communityId);

  useEffect(() => {
    const fetchWithAuth = async (url, options) => {
      const token = await getIdToken(auth.currentUser);
      const response = await fetch(url, {
        ...options,
        headers: {
          ...options.headers,
          'Authorization': `Bearer ${token}`
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    };
  
    if (communityAsOtherMessenger) {
      console.log('newMessage',newMessage)
      console.log('conversation',conversation)
  
      // Fetch communityAsOtherMessenger if a member is the communityId
      const getCommunityAsOtherMessenger = async () => {
        try {
          const json = await fetchWithAuth(`${process.env.REACT_APP_API_BASE_URL}/communities/${conversation.communityId}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json'
            }
          });
          console.log('Community Bot Conversation:', json);
          setcommunity(json);
        } catch (error) {
          console.error('Failed to fetch communitySender bot:', error);
        }
      }
      getCommunityAsOtherMessenger();
    } else {
      // Fetch userSender details if no member is the communityId
      const userAsOtherMessenger = conversation.members.find((m) => m !== user.userId);
      const getUsers = async () => {
        try {
          const json = await fetchWithAuth(`${process.env.REACT_APP_API_BASE_URL}/userProfile/getBasicProfileToDisplay`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ userId: userAsOtherMessenger})
          });
          setUser(json);
        } catch (error) {
          console.error('Failed to fetch userSender:', error);
        }
      }
      getUsers();
    }
  }, [user, conversation]);

  console.log('conversation',conversation)
  console.log('userSender.user.pics', userSender)


  const sanitizedCommunitySenderPics = DOMPurify.sanitize(communitySender && communitySender.pics ? communitySender.pics[0] : "https://upload.wikimedia.org/wikipedia/commons/thumb/a/aa/Saw_Spiral.svg/768px-Saw_Spiral.svg.png");
  const sanitizedUserSenderPics = DOMPurify.sanitize(userSender && userSender.pics ? userSender.pics[0] : "https://cdn.pixabay.com/photo/2018/11/13/22/01/avatar-3814081_640.png");

  const sanitizedCommunitySenderTitle = DOMPurify.sanitize(communitySender && communitySender.title);
  const sanitizedUserSenderName = DOMPurify.sanitize(userSender && userSender?.name);

  return (
    <div className="conversation">
      <img className="conversationImg" 
        src={communityAsOtherMessenger ? sanitizedCommunitySenderPics : sanitizedUserSenderPics} alt=""/>
      <div className="span conversationName">
        {communityAsOtherMessenger ? sanitizedCommunitySenderTitle : sanitizedUserSenderName}
        {/* Render the bell icon if newMessage is true */}
        {newMessage && 
      <span className="newMessageAlertIcon"></span>}
      </div>
    </div>
  );
}

export default Conversation;
// import React, { useState, useEffect } from 'react';
// import "./Conversation.css";

// const Conversation = ({conversation, currentUser}) => {
//   const [user, setUser] = useState(null);
//   const [communityBot, setCommunityBot] = useState(null);
//   useEffect(() => {
//     if (conversation.communityBot) {
//       const communityId = conversation.members.find((m) => m !== currentUser.userId);
//       const getCommunityBot = async () => {
//         try {
//           const response = await fetch(`/api/communities/${communityId}`, {
//             method: 'GET',
//             headers: {
//               'Content-Type': 'application/json'
//             }
//           });
//           if (!response.ok) {
//             throw new Error('Network response was not ok');
//           }
//           const json = await response.json();
//           console.log('Community Bot Conversation:', json);
//           setCommunityBot(json);
//         } catch (error) {
//           console.error('Failed to fetch community bot:', error);
//         }
//       }
//       getCommunityBot();
//     } else {
//       const friendId = conversation.members.find((m) => m !== currentUser.userId);
//       const getUsers = async () => {
//         try {
//           const response = await fetch('/api/dynamic/', {
//             method: 'POST',
//             headers: {
//               'Content-Type': 'application/json'
//             },
//             body: JSON.stringify({ _id: friendId})
//           });
//           if (!response.ok) {
//             throw new Error('Network response was not ok');
//           }
//           const json = await response.json();
//           console.log('Sender Conversation:', json);
//           setUser(json);
//         } catch (error) {
//           console.error('Failed to fetch user:', error);
//         }
//       }
//       getUsers();
//     }
//   }, [currentUser, conversation]);

//   return (
//     <div className="conversation">
//       <img className="conversationImg" 
//         src={conversation.communityBot ? (communityBot && communityBot.pics ? communityBot.pics[0] :
//          "https://upload.wikimedia.org/wikipedia/commons/thumb/a/aa/Saw_Spiral.svg/768px-Saw_Spiral.svg.png") : 
//          (user && user.user.profilePicture ? user.user.profilePicture : 
//          "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png")} alt=""/>
//       <div className="span conversationName">{conversation.communityBot ? (communityBot && communityBot.title) : (user && user.user.firstName)}</div>
//     </div>
//   );
// }

// export default Conversation;

// import React, { useState, useEffect } from 'react';
// import "./Conversation.css";

// const Conversation = ({conversation, currentUser}) => {
//   const [user, setUser] = useState(null);

//   useEffect(() =>{
//     //if conversation.communityBot(true) then 
//    // const communityBotId = conversation.members.find((m) => m !== currentUser.userId);
//   /*    const getcommunityBot = async () => {
//       const response = await fetch('/api/community/', {
//         method: 'POST',
//         body: JSON.stringify({ _id: communityBotId}),
//         headers: {
//           'Content-Type': 'application/json'
//         }
//       });
//       const json = await response.json();
//       console.log('Conversation:', json);
//       setCommunityBot(json)
//     }

//     getUsers(); 
//   },[currentUser, conversation]);
  
//   // ELSE: */

//     const friendId = conversation.members.find((m) => m !== currentUser.userId);

//     const getUsers = async () => {
//       const response = await fetch('/api/dynamic/', {
//         method: 'POST',
//         body: JSON.stringify({ _id: friendId}),
//         headers: {
//           'Content-Type': 'application/json'
//         }
//       });
//       const json = await response.json();
//       console.log('Conversation:', json);
//       setUser(json)
//     }

//     getUsers(); 
//   },[currentUser, conversation]);

//   return (
//     <div className="conversation">
//         <img className="conversationImg" 
//         //{if conversation.communityBot(true) then} src={user && user.user.profilePicture ? user.user.profilePicture : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"} alt=""/>
//         {user && <div className="span conversationName">{user.user.firstName}</div>}    </div>
//         //else src={communityBot && communityBot.profilePicture ? communityBot.profilePicture : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"} alt=""/>
//         {user && <div className="span conversationName">{user.user.firstName}</div>} 
//   );
// }

// export default Conversation;